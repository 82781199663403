import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import language from '../../Common/language/language';
import isMobile from '../../Common/isMobile/isMobile';
import { generateDate } from '../../Common/helperFunctions/date';
import { createAxiosInstance } from '../../Common/helperFunctions/axios';
import { readFile } from '../../Common/helperFunctions/readFile';
import { FILE_TYPES } from '../../Common/types/types';

// import -> material ui -> core && icons
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { CameraAlt, PhotoCameraOutlined, Storage } from '@material-ui/icons';

// import -> components
import CoConfirmation from '../../Common/Components/CoConfirmation';
import CoCamera from '../../Common/Components/CoCamera';
import CoDisplayFilesList from '../../Common/Components/CoDisplayFilesList';
import { useEffect } from 'react';

// component -> React-root
const CoCreateNotification = ({ objGlobalState, objWarenannahmeavisierungState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [cameraState, setCameraState] = useState(false);
  const [gotStreamState, setGotStreamState] = useState(false);
  const [filesState, setFilesState] = useState([]);
  const [confirmationDialogState, setConfirmationDialogState] = useState(false);
  const [canCameraToggleState, setCanCameraToggleState] = useState(false);
  const [newDataValueState, setNewDataValueState] = useState({
    lieferant: '',
    spedition: '',
    datum: ''
  });

  // function ->
  const canCameraStop = () => {
    if ((cameraState && gotStreamState) || (!cameraState && !gotStreamState)) {
      return true;
    } else {
      return false;
    }
  };

  // function ->
  const stopCamera = () => {
    if (isMobile.any()) {
      return;
    }
    const stream = document.querySelector('#tracePort_camera')?.srcObject;
    if (!stream) {
      return;
    }
    const tracks = stream.getTracks();
    tracks.forEach((track) => {
      track.stop();
    });
    document.querySelector('#tracePort_camera').srcObject = null;
    setGotStreamState(false);
    setCameraState(false);
  };

  // function ->
  const handleFilesOnChange = (event) => {
    // array -> the new picked files from the user
    const newFiles = [...event.target.files];
    newFiles.forEach((file) => {
      readFile(file)
        .then((result) => {
          const type = file.type.includes('image') ? FILE_TYPES.JPEG : file.type === 'application/pdf' ? FILE_TYPES.PDF : FILE_TYPES.NO_TYPE;
          if (!type) {
            return;
          }
          const { name, lastModified } = file;
          const timestamp = generateDate(lastModified).dateAndTime;
          const newFile = { fileRef: file, timestamp: timestamp, type: type, dataUri: result, name: name, beschreibung: { text: '', error: false } };
          setFilesState((pre) => [newFile, ...pre]);
        })
        .catch((error) => console.log(error));
    });
  };

  // function ->
  const toggleCameraState = () => {
    if (cameraState) {
      if (canCameraStop()) {
        stopCamera();
      }
    } else {
      setCameraState(true);
    }
  };

  // function ->
  const closeCreateDialogOnClick = () => {
    if (!canCameraStop()) {
      return;
    }
    setFilesState([]);
    stopCamera();
    objWarenannahmeavisierungState.setCreateDialogState(false);
  };

  // function ->
  const closeConfirmationOnClick = () => {
    setConfirmationDialogState(false);
  };

  // function ->
  const submitFormOnClick = (event) => {
    event.preventDefault();
    if (!canCameraStop()) {
      return;
    }
    stopCamera();

    const lieferant = event.currentTarget.elements.lieferant.value;
    const spedition = event.currentTarget.elements.spedition.value;
    const datum = event.currentTarget.elements.datum.value;

    setNewDataValueState({ lieferant, spedition, datum });
    setConfirmationDialogState(true);
  };

  // function ->
  const confirmed = () => {
    // make request to the backend to put this information

    const { lieferant, spedition, datum } = newDataValueState;
    const noDate = generateDate().noDate;
    const costumDate = generateDate(datum).dateAndTimeForDataBase;

    objGlobalState.setPageLoadingState(true);

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    const formData = new FormData();
    const files = filesState.map((element) => element.fileRef);
    files.forEach((file) => {
      formData.append('files', file);
    });

    const requestValues = {
      date: datum.length ? costumDate : noDate,
      shippingCompany: spedition.length ? spedition : '_',
      status: 1,
      supplier: lieferant.length ? lieferant : ' '
    };

    authAxios
      .post('/notification/create', requestValues)
      .then((response) => {
        const { data } = response;
        console.log(data);

        closeConfirmationOnClick();
        closeCreateDialogOnClick();

        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT', objGlobalState.lang), { variant: 'success' });
        // const date = generateDate(data.date).justDate === '30.11.1110' ? '' : generateDate(data.date).justDate;
        // const newNotification = {
        //   id: data.id,
        //   dateien: [],
        //   lieferant: data.supplier,
        //   spedition: data.shippingCompany,
        //   datum: date,
        //   erstellungsdatum: generateDate(data.created).justDate
        // };
        // objWarenannahmeavisierungState.setNotificationsState((pre) => [newNotification, ...pre]);
        if (!files.length) {
          objWarenannahmeavisierungState.rerunEffect();
        } else {
          authAxios
            .post(`/file/multiplenotification/${data.id}`, formData, { headers: { 'Content-Type': 'multipart/form-data;' } })
            .then(() => {})
            .catch((error) => {
              enqueueSnackbar(language('ALERT', 'TEXT', 'FOTOSNICHTHOCHGELADEN'), { variant: 'error' });
              console.log(error);
            })
            .finally(() => {
              objWarenannahmeavisierungState.rerunEffect();
            });
        }
      })
      .catch((error) => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
        console.error(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  const isItMobile = isMobile.any();

  const isInIframe = objGlobalState.isInIframe;
  const iconClassName = isInIframe ? 'button__icon--smallRed' : 'button__icon--smallGreen';

  // jsx -> if browsing from mobile show the camera icon and not the storage icon
  const photosUploadIcon = isItMobile ? (
    <IconButton component="span" title={language('BUTTON', 'TITEL', 'KAMERA', objGlobalState.lang)}>
      <CameraAlt className={iconClassName} />
    </IconButton>
  ) : (
    <IconButton component="span" title={language('BUTTON', 'TITEL', 'SPEICHERPLATZ', objGlobalState.lang)}>
      <Storage className={iconClassName} />
    </IconButton>
  );

  // jsx -> if not browsing from mobile show this extra option
  const photosUpLoadExtraOptions = !isItMobile ? (
    <IconButton onClick={toggleCameraState} title={language('BUTTON', 'TITEL', 'KAMERAEINAUS', objGlobalState.lang)} data-testid="kamera-button">
      {!canCameraToggleState ? <CameraAlt className={iconClassName} /> : cameraState ? <PhotoCameraOutlined className={iconClassName} /> : <CameraAlt className={iconClassName} />}
    </IconButton>
  ) : (
    ''
  );

  const photosSectionTitle = 'IHREDATEIEN';
  const photosSection = { sectionTitle: photosSectionTitle, files: filesState };
  const confirmationGallery = [photosSection];

  const objCreateNotificationStateAndFunctions = {
    closeConfirmationOnClick,
    confirmed,
    confirmationDialogState,
    confirmationGallery,
    filesState,
    setFilesState,
    gotStreamState,
    setGotStreamState,
    newDataValueState,
    setCameraState,
    setCanCameraToggleState
  };

  return (
    <>
      {confirmationDialogState && <CoConfirmation objGlobalState={objGlobalState} objParentState={objCreateNotificationStateAndFunctions} />}
      <Dialog fullWidth maxWidth="sm" open={objWarenannahmeavisierungState.createDialogState} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{language('DIALOG', 'TITEL', 'NEUSATZERSTELLEN', objGlobalState.lang)}</DialogTitle>
        <DialogContent className="dialog__content">
          <form name="submitForm" noValidate autoComplete="on" onSubmit={submitFormOnClick}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextField id="createNotification-lieferant" fullWidth type="text" variant="outlined" name="lieferant" label={language('FORM', 'LABEL', 'LIEFERANT', objGlobalState.lang)} autoFocus />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField id="createNotification-spedition" fullWidth type="text" variant="outlined" name="spedition" label={language('FORM', 'LABEL', 'SPEDITION', objGlobalState.lang)} />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id="createNotification-datum"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  type="date"
                  variant="outlined"
                  name="datum"
                  label={language('FORM', 'LABEL', 'DATUM', objGlobalState.lang)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className="typography">{language('FORM', 'TITEL', 'DATEIENHOCHLADEN', objGlobalState.lang)}</Typography>
                <input
                  accept="image/*,application/pdf"
                  encType="multipart/form-data"
                  style={{ display: 'none' }}
                  name="fotos"
                  id="warenannahme-avisierung-input-foto"
                  multiple
                  type="file"
                  onChange={handleFilesOnChange}
                />
                <label htmlFor="warenannahme-avisierung-input-foto">{photosUploadIcon}</label>
                {photosUpLoadExtraOptions}
              </Grid>
              {!isItMobile ? (
                <Grid item xs={12}>
                  {cameraState ? (
                    <CoCamera objGlobalState={objGlobalState} objParentState={{ ...objCreateNotificationStateAndFunctions, photosState: filesState, setPhotosState: setFilesState }} />
                  ) : (
                    ''
                  )}
                </Grid>
              ) : (
                ''
              )}
              <CoDisplayFilesList objGlobalState={objGlobalState} filesState={filesState} setFilesState={setFilesState} title={'IHREDATEIEN'} />
              <Grid item xs={12} md={6}>
                <Button fullWidth type="submit" color="primary" className="button__middle" variant="contained" color="primary">
                  {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button onClick={closeCreateDialogOnClick} fullWidth className="button__middle">
                  {language('BUTTON', 'TITEL', 'ABBRECHEN', objGlobalState.lang)}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoCreateNotification;
