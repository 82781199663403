export const FILE_TYPES = {
  JPEG: 'jpg',
  PDF: 'pdf',
  NO_TYPE: null
};

export const TABLE_TYPES = {
  ARTIKELUEBERSICHT: 1,
  BESTANDSUEBERSICHT: 2,
  TRANSPORTEINHEITEN: 3
};
