import React, { useEffect, useState } from 'react';
import { createAxiosInstance } from '../helperFunctions/axios';
import language from '../language/language';

// import -> material ui -> core & icons
import { Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';

const CoLogistikunternhemenSelect = ({ objGlobalState, objParentState, options }) => {
  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const [logistikunternehmenState, setLogistikunternehmenState] = useState([]);

  // function ->
  const fetchSpeditionenFromAPI = () => {
    authAxios
      .get('/speditionen')
      .then((response) => {
        const logistikunternehmen = response.data;
        setLogistikunternehmenState(logistikunternehmen);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;
    fetchSpeditionenFromAPI();

    return () => {
      isMounted = false;
    };
  }, []);

  const noLabel = options?.noLabel;

  const inputLabel = objParentState.inputLabel ?? 'SPEDITION';
  const toIncludeStricted = objParentState.speditionenToInclude?.length;
  const toExcludeStricted = objParentState.speditionenToExclude?.length;
  const speditionen = objParentState.speditionenToInclude ?? objParentState.speditionenToExclude ?? [];

  const formControlClassName = objParentState.formControlClassName ?? '';
  const formControlError = objParentState.formControlError ?? null;
  const formControlVariant = objParentState.formControlVariant ?? 'outlined';

  const menuItemValue = objParentState.menuItemValue ?? 'name';

  return (
    <FormControl fullWidth variant={formControlVariant} className={formControlClassName} error={formControlError}>
      {!noLabel && <InputLabel id="common_components_logisitkunternhemenselect_spedition_label">{language('FORM', 'LABEL', inputLabel, objGlobalState.lang)}</InputLabel>}
      <Select
        value={objParentState.selectValue}
        onChange={(event) => objParentState.handleInputOnChange(event)}
        labelId="common_components_logisitkunternhemenselect_spedition_label"
        label={language('FORM', 'LABEL', inputLabel, objGlobalState.lang)}
      >
        <MenuItem value={''}>{language('FORM', 'LABEL', 'KEINE', objGlobalState.lang)}</MenuItem>
        {logistikunternehmenState.map((unternehmen, key) => {
          if (toIncludeStricted) {
            if (!speditionen.includes(unternehmen.shortcut)) {
              return;
            }
          }

          if (toExcludeStricted) {
            if (speditionen.includes(unternehmen.shortcut)) {
              return;
            }
          }

          return (
            <MenuItem key={key} value={unternehmen[menuItemValue]}>
              {unternehmen.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CoLogistikunternhemenSelect;
