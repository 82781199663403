import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import language from '../Common/language/language';
import { changeLanguage } from '../Common/helperFunctions/changeLanguage';
import axios from 'axios';

// import -> material ui -> core
import { Button, Container, Grid, IconButton, Paper, TextField, Typography } from '@material-ui/core';
import CoEmailSentDialog from './Append/CoEmailSentDialog';
import { Language } from '@material-ui/icons';

// component -> React-root
const CoResetPasswordEmail = ({ objGlobalState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [textFieldState, setTextFieldState] = useState({
    email: { error: false }
  });

  const [errorsState, setErrorsState] = useState({
    emailInvalid: false
  });

  const [emailSentDialogState, setEmailSentDialogState] = useState(false);

  // function ->
  const checkIfTextFieldIsEmpty = (valueForCheck) => {
    //  statement -> checks if valueForCheck is not empty
    return valueForCheck ? false : true;
  };

  // function ->
  const fetchTextFieldChange = (event, textField) => {
    // useState -> show error message depending on return boolean of checkIfTextFieldIsEmpty
    setTextFieldState({
      ...textFieldState,
      [textField]: { error: checkIfTextFieldIsEmpty(event.target.value) }
    });

    // useState -> when the user write in a text field, then take away the error message
    setErrorsState({
      ...errorsState,
      emailInvalid: false
    });
  };

  // function ->
  const checkIfEmailIsInvalid = (email) => {
    const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !emailPattern.test(email);
  };

  // function ->
  const checkAndHandleInputErrors = (email) => {
    const isEmailTextFieldEmpty = checkIfTextFieldIsEmpty(email);
    const isEmailInvalid = checkIfEmailIsInvalid(email);

    if (isEmailTextFieldEmpty || isEmailInvalid) {
      setTextFieldState({
        ...textFieldState,
        email: { error: isEmailTextFieldEmpty }
      });
      setErrorsState({
        ...errorsState,
        emailInvalid: isEmailInvalid
      });
      return true;
    }
    return false;
  };

  // function ->
  const postInformationsToAPI = (email) => {
    objGlobalState.setPageLoadingState(true);

    const requestValues = {
      email: email
    };

    axios
      .post(`${process.env.REACT_APP_API_HOST}/user/reset-password-mail`, requestValues)
      .then(() => {
        setEmailSentDialogState(true);

        // to go right to the signIn-site
        objGlobalState.setAuthenticateState({ auth: false, user: {} });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), {
          variant: 'error'
        });
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const submitFormOnClick = (event) => {
    event.preventDefault();
    const email = event.target.email.value.toLowerCase();

    if (checkAndHandleInputErrors(email)) {
      return;
    }
    postInformationsToAPI(email);
  };

  const objResetPasswordEmail = {
    emailSentDialogState,
    setEmailSentDialogState
  };

  return (
    <>
      {emailSentDialogState && <CoEmailSentDialog objGlobalState={objGlobalState} objParentState={objResetPasswordEmail} />}
      <Container component="main" maxWidth="xs">
        <Paper variant="outlined" square className="container__anmeldungscontainer">
          <form name="submitForm" noValidate className="form__anmeldungsform" autoComplete="off" onSubmit={submitFormOnClick}>
            <Grid container spacing={3}>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={12}>
                  <IconButton color="inherit" onClick={() => changeLanguage(objGlobalState)} title={language('BUTTON', 'TITEL', 'SPRACHEAENDERN', objGlobalState.lang)}>
                    <Language />
                    <Typography>{objGlobalState.lang === 'DE' ? 'EN' : 'DE'}</Typography>
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography align="center" className="typography__formTitle">
                    {language('FORM', 'TITEL', 'PASSWORTZURUECKSETZEN', objGlobalState.lang)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography align="center">{language('KONTENT', 'TEXT', 'EMAILPASSWORTZURUECKSETZEN', objGlobalState.lang)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="email"
                  variant="outlined"
                  name="email"
                  label={language('FORM', 'LABEL', 'EMAIL', objGlobalState.lang)}
                  error={textFieldState.email.error || errorsState.emailInvalid}
                  helperText={
                    textFieldState.email.error
                      ? language('FORM', 'ERROR', 'LEERESTEXTFELD', objGlobalState.lang)
                      : errorsState.emailInvalid
                      ? language('FORM', 'ERROR', 'EMAILNICHTGUELTIG', objGlobalState.lang)
                      : ''
                  }
                  onChange={(event) => fetchTextFieldChange(event, 'email')}
                />
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth variant="contained" type="submit" color="primary" className="button">
                  {language('BUTTON', 'TITEL', 'WEITER', objGlobalState.lang)}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </>
  );
};

export default CoResetPasswordEmail;
