import React, { useLayoutEffect, useRef } from 'react';
import language from '../../Common/language/language';
import { mouseScrollHorizontally } from '../../Common/helperFunctions/mouseScrollHorizontally';

// import -> material ui -> core
import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@material-ui/core';

import { Fragment } from 'react';

// component -> React-root
const CoPaketeOrPaletteInput = ({ objGlobalState, objParentState }) => {
  const tableRef = useRef();

  // function ->
  const handleAnzahlDerPaketeChange = (event) => {
    let { value } = event.target;
    const maxNumber = 99;
    const minNumber = 0;
    const noValue = '';

    if ((value <= maxNumber && value >= minNumber) || value === noValue) {
      objParentState.setAnzahlDerPaketeState(value);

      const currentPaketeProPaketsendungState = [...objParentState.paketeProPaketsendungState];
      currentPaketeProPaketsendungState.splice(value, Infinity);
      objParentState.setPaketeProPaketsendungState(currentPaketeProPaketsendungState);
    }
  };

  // function ->
  const handleAnzahlDerPalettenChange = (event) => {
    let { value } = event.target;
    const maxNumber = 66;
    const minNumber = 0;
    const noValue = '';

    if ((value <= maxNumber && value >= minNumber) || value === noValue) {
      objParentState.setAnzahlDerPalettenState(value);

      const currentPaketeProPaletteState = [...objParentState.paketeProPaletteState];
      currentPaketeProPaletteState.splice(value, Infinity);
      objParentState.setPaketeProPaletteState(currentPaketeProPaletteState);
    }
  };

  // function ->
  const handlePaketeProPaketsendungChange = (event, key) => {
    const { value } = event.target;
    const minNumber = 0;
    const noValue = '';
    if (value >= minNumber || value === noValue) {
      const currentPaketeProPaketsendungState = [...objParentState.paketeProPaketsendungState];
      currentPaketeProPaketsendungState[key] = value;
      objParentState.setPaketeProPaketsendungState(currentPaketeProPaketsendungState);
    }
  };

  // function ->
  const handlePaketeProPaletteChange = (event, key) => {
    const { value } = event.target;
    const minNumber = 0;
    const noValue = '';
    if (value >= minNumber || value === noValue) {
      const currentPaketeProPaletteState = [...objParentState.paketeProPaletteState];
      currentPaketeProPaletteState[key] = value;
      objParentState.setPaketeProPaletteState(currentPaketeProPaletteState);
    }
  };

  // function ->
  const createArrayWithLength = (length) => {
    length = Number(length) > 0 ? Number(length) : 1;
    return Array.apply(null, Array(length));
  };

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, [objParentState.sendungsartState.paketsendung, objParentState.sendungsartState.palettensendung]);

  return objParentState.sendungsartState.paketsendung.checked ? (
    <>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          value={objParentState.anzahlDerPaketeState}
          onChange={handleAnzahlDerPaketeChange}
          type="number"
          variant="outlined"
          label={language('FORM', 'LABEL', 'ANZAHLDERPAKETE', objGlobalState.lang)}
        />
      </Grid>
      <Grid item xs={12}>
        <Box className={`table__title coVersandlieferung__tableTitle ${Number(objParentState.anzahlDerPaketeState) ? '' : 'coVersandlieferung__tableTitle--disable'}`}>
          <Typography align="center" color="primary">
            {language('TABLE', 'HEADER', 'ANZAHLDERPAKETEPROPAKETSENDUNG', objGlobalState.lang)}
          </Typography>
        </Box>
        <TableContainer
          ref={tableRef}
          component={Paper}
          variant="outlined"
          elevation={0}
          square
          className={`table__container coVersandlieferung__tableContainer ${Number(objParentState.anzahlDerPaketeState) ? '' : 'coVersandlieferung__tableContainer--disable'}`}
        >
          <Table size="small">
            <TableBody>
              {createArrayWithLength(objParentState.anzahlDerPaketeState).map((paketsendung, key) => {
                const lastTableCellClassName = key === createArrayWithLength(objParentState.anzahlDerPaketeState).length - 1 ? 'noBorderBottom' : '';
                return (
                  <Fragment key={key + 'paketsendung'}>
                    <TableRow>
                      <TableCell align="center" component="th" className={`table__rowTitle ${lastTableCellClassName}`}>
                        <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'PAKETSENDUNGNR', objGlobalState.lang) + (key + 1)}</Typography>
                      </TableCell>
                      <TableCell align="right" className={lastTableCellClassName}>
                        <TextField
                          value={objParentState.paketeProPaketsendungState[key] || ''}
                          onChange={(event) => handlePaketeProPaketsendungChange(event, key)}
                          disabled={!Number(objParentState.anzahlDerPaketeState)}
                          type="number"
                          variant="outlined"
                        />
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  ) : objParentState.sendungsartState.palettensendung.checked ? (
    <>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          value={objParentState.anzahlDerPalettenState}
          onChange={handleAnzahlDerPalettenChange}
          type="number"
          variant="outlined"
          label={language('FORM', 'LABEL', 'ANZAHLDERPALETTEN', objGlobalState.lang)}
        />
      </Grid>
      <Grid item xs={12}>
        <Box className={`table__title coVersandlieferung__tableTitle ${Number(objParentState.anzahlDerPalettenState) ? '' : 'coVersandlieferung__tableTitle--disable'}`}>
          <Typography align="center" color="primary">
            {language('TABLE', 'HEADER', 'ANZAHLDERPAKETEPROPALETTE', objGlobalState.lang)}
          </Typography>
        </Box>
        <TableContainer
          ref={tableRef}
          component={Paper}
          variant="outlined"
          elevation={0}
          square
          className={`table__container coVersandlieferung__tableContainer ${Number(objParentState.anzahlDerPalettenState) ? '' : 'coVersandlieferung__tableContainer--disable'}`}
        >
          <Table size="small">
            <TableBody>
              {createArrayWithLength(objParentState.anzahlDerPalettenState).map((paketsendung, key) => {
                const lastTableRowClassName = key === createArrayWithLength(objParentState.anzahlDerPalettenState).length - 1 ? 'noBorderBottom' : '';
                return (
                  <Fragment key={key + 'palettensendung'}>
                    <TableRow>
                      <TableCell align="center" component="th" className={`table__rowTitle ${lastTableRowClassName}`}>
                        <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'PALETTENSENDUNGNR', objGlobalState.lang) + (key + 1)}</Typography>
                      </TableCell>
                      <TableCell align="right" className={lastTableRowClassName}>
                        <TextField
                          value={objParentState.paketeProPaletteState[key] || ''}
                          onChange={(event) => handlePaketeProPaletteChange(event, key)}
                          disabled={!Number(objParentState.anzahlDerPalettenState)}
                          type="number"
                          variant="outlined"
                        />
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  ) : (
    ''
  );
};

export default CoPaketeOrPaletteInput;
