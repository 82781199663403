import React, { useRef, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import language from '../Common/language/language';
import { generateDate } from '../Common/helperFunctions/date';
import { createAxiosInstance } from '../Common/helperFunctions/axios';

// import -> services
import ServiceBreadcrumbs from '../Common/services/Breadcrumbs/ServiceBreadcrumbs';

// import -> material ui -> core & icons
import { Button, Container, FormControl, FormControlLabel, FormLabel, Grid, ListItem, ListItemIcon, ListItemText, Switch, TextField, Typography, IconButton } from '@material-ui/core';
import { Public } from '@material-ui/icons';

// import -> component
import CoConfirmation from '../Common/Components/CoConfirmation';
import CoDisplayFilesList from '../Common/Components/CoDisplayFilesList';
import CoLieferungTable from './Append/CoLieferungTable';
import CoLogistikunternhemenSelect from '../Common/Components/CoLogistikunternhemenSelect';
import CoPaketeOrPaletteInput from './Append/CoPaketeOrPaletteInput';
import CoUploadFiles from '../Common/Components/CoUploadFiles';
import { Autocomplete } from '@material-ui/lab';

// component -> React-root
const CoVersandlieferung = ({ objGlobalState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const [logistikunternehmenState, setLogistikunternehmenState] = useState([]);
  const [auftraegeState, setAuftraegeState] = useState([]);
  const [begleitpapiereState, setBegleitpapiereState] = useState([]);
  const [labelsState, setLabelsState] = useState([]);
  const [uploadFilesBegleitpapiereDialogState, setUploadFilesBegleitpapiereDialogState] = useState({ open: false, title: '' });
  const [uploadFilesLabelsDialogState, setUploadFilesLabelsDialogState] = useState({ open: false, title: '' });
  const [confirmationDialogState, setConfirmationDialogState] = useState(false);
  const [lieferungTableDialogState, setLieferungTableDialogState] = useState(false);
  const [sendungsartState, setSendungsartState] = useState({
    paketsendung: { id: 1, name: 'PAKETSENDUNG', checked: false },
    palettensendung: { id: 2, name: 'PALETTENSENDUNG', checked: false }
  });
  const [versandbestaetigungState, setVersandbestaetigungState] = useState({
    elektronisch: { id: 1, name: 'ELEKTRONISCH', checked: false },
    begleitpapiere: { id: 2, name: 'UEBERBEGLEITPAPIERE', checked: false }
  });
  const [webLinkState, setWebLinkState] = useState({ active: false, url: '' });
  const [anzahlDerPalettenState, setAnzahlDerPalettenState] = useState('');
  const [anzahlDerPaketeState, setAnzahlDerPaketeState] = useState('');
  const [paketeProPaketsendungState, setPaketeProPaketsendungState] = useState([]);
  const [paketeProPaletteState, setPaketeProPaletteState] = useState([]);
  const [inputsState, setInputsState] = useState({
    auftragsnummer: '',
    kunde: '',
    spedition: '',
    liveTracker: '',
    trackingNummer: ''
  });

  const [newDataValueState, setNewDataValueState] = useState({
    auftragsnummer: '',
    kunde: '',
    spedition: '',
    liveTracker: '',
    trackingNummer: '',
    datum: '',
    sendungsart: '',
    anzahlDerPakete: '',
    anzahlDerPaketeProPaketsendung: '',
    anzahlDerPaletten: '',
    anzahlDerPaketeProPalette: '',
    versandbestaetigung: ''
  });

  const hasLiveTrackingAccess = objGlobalState.authenticateState.user.access?.includes('LIVE_TRACKING');

  const versandbestaetigungChecked = versandbestaetigungState.begleitpapiere.checked || versandbestaetigungState.elektronisch.checked;

  // function ->
  const showLieferungTableDialogOnClick = () => {
    setLieferungTableDialogState(true);
  };

  // function ->
  const showUploadFilesDialogOnClick = (title) => {
    if (title === 'begleitpapiere') {
      setUploadFilesBegleitpapiereDialogState({ open: true, title: undefined });
    } else if (title === 'labels') {
      setUploadFilesLabelsDialogState({ open: true, title: 'LABELHOCHLADEN' });
    }
  };

  // function ->
  const setAuftragOnClick = (_, auftrag) => {
    if (!auftrag) return;
    const { auftragsnummer, kunde, spedition } = auftrag;

    setInputsState((pre) => ({
      ...pre,
      auftragsnummer,
      kunde: kunde || pre.kunde,
      spedition: spedition || pre.spedition
    }));

    if (spedition) {
      changeWebLink(spedition);
    }
  };

  // function ->
  const handleInputOnChange = (event, inputName) => {
    const inputValue = event.target.value;

    setInputsState((pre) => ({
      ...pre,
      [inputName]: inputValue
    }));

    if (inputName === 'spedition') {
      changeWebLink(inputValue);
      return;
    }
  };

  // function ->
  const handleAutoCompleteLiveTrackerOnChange = (event, inputValue) => {
    setInputsState((pre) => ({
      ...pre,
      liveTracker: inputValue
    }));
  };

  // function ->
  const handleAuftragsnummerOnChange = (event) => {
    const auftragValue = event.target.value + '';
    const foundedAuftrag = auftraegeState.find((auftrag) => auftrag.auftragsnummer.toLowerCase() === auftragValue.toLowerCase());
    setInputsState((pre) => ({
      ...pre,
      auftragsnummer: auftragValue
    }));
    if (!foundedAuftrag) return;

    setInputsState((pre) => {
      return {
        ...pre,
        kunde: foundedAuftrag.kunde || pre.kunde,
        spedition: foundedAuftrag.spedition || pre.spedition
      };
    });
  };

  // function ->
  const changeWebLink = (selectedSpedition) => {
    const spedition = logistikunternehmenState.find((unternehmen) => unternehmen.name === selectedSpedition);
    if (spedition) {
      setWebLinkState({ active: true, url: spedition.auftragUrl });
    } else {
      setWebLinkState({ active: false, url: '' });
    }
  };

  // function ->
  const checkSendungsart = (name) => {
    // if one is true then the other one is false
    setSendungsartState((pre) => {
      switch (name) {
        case 'paketsendung':
          return {
            paketsendung: { ...pre.paketsendung, checked: !pre.paketsendung.checked },
            palettensendung: { ...pre.palettensendung, checked: false }
          };
        case 'palettensendung':
          return {
            palettensendung: { ...pre.palettensendung, checked: !pre.palettensendung.checked },
            paketsendung: { ...pre.paketsendung, checked: false }
          };
        default:
          return pre;
      }
    });
  };

  // function ->
  const checkVersandbestaetigung = (name) => {
    // if one is true then the other one is false
    setVersandbestaetigungState((pre) => {
      switch (name) {
        case 'elektronisch':
          return {
            elektronisch: { ...pre.elektronisch, checked: !pre.elektronisch.checked },
            begleitpapiere: { ...pre.begleitpapiere, checked: false }
          };
        case 'begleitpapiere':
          return {
            begleitpapiere: { ...pre.begleitpapiere, checked: !pre.begleitpapiere.checked },
            elektronisch: { ...pre.elektronisch, checked: false }
          };
        default:
          return pre;
      }
    });
  };

  // function ->
  const submitFormOnClick = (event) => {
    event.preventDefault();

    const { auftragsnummer, kunde, spedition, liveTracker, trackingNummer } = inputsState;
    const datum = event.target.datum.value;

    const anzahlDerPakete = anzahlDerPaketeState;
    const anzahlDerPaletten = anzahlDerPalettenState;
    const anzahlDerPaketeProPaketsendung = paketeProPaketsendungState.filter((element) => element !== undefined).map((element) => Number(element));
    const anzahlDerPaketeProPalette = paketeProPaletteState.filter((element) => element !== undefined).map((element) => Number(element));

    const numberInputsValues = sendungsartState.paketsendung.checked
      ? { anzahlDerPakete, anzahlDerPaketeProPaketsendung }
      : sendungsartState.palettensendung.checked
      ? { anzahlDerPaletten, anzahlDerPaketeProPalette }
      : '';

    const sendungsartValue = sendungsartState.paketsendung.checked
      ? language('KONTENT', 'TEXT', sendungsartState.paketsendung.name, objGlobalState.lang)
      : sendungsartState.palettensendung.checked
      ? language('KONTENT', 'TEXT', sendungsartState.palettensendung.name, objGlobalState.lang)
      : '';
    const versandbestaetigungValue = versandbestaetigungState.elektronisch.checked
      ? language('KONTENT', 'TEXT', versandbestaetigungState.elektronisch.name, objGlobalState.lang)
      : versandbestaetigungState.begleitpapiere.checked
      ? language('KONTENT', 'TEXT', versandbestaetigungState.begleitpapiere.name, objGlobalState.lang)
      : '';

    setNewDataValueState({ auftragsnummer, kunde, spedition, liveTracker, trackingNummer, datum, sendungsart: sendungsartValue, ...numberInputsValues, versandbestaetigung: versandbestaetigungValue });
    setConfirmationDialogState(true);
  };

  // function ->
  const closeConfirmationOnClick = () => {
    setConfirmationDialogState(false);
  };

  // function ->
  const pushNumberInEmptyArray = (array) => {
    if (!array.length) {
      array.push(0);
    }
  };

  // function ->
  const confirmed = () => {
    objGlobalState.setPageLoadingState(true);

    const { auftragsnummer, kunde, spedition, liveTracker, trackingNummer, datum, anzahlDerPakete, anzahlDerPaketeProPaketsendung, anzahlDerPaletten, anzahlDerPaketeProPalette } = newDataValueState;

    const requestSendungsart = sendungsartState.paketsendung.checked ? 'PACKETSENDUNG' : sendungsartState.palettensendung.checked ? 'PALETTENSENDUNG' : 'NOTHING';
    const requestVersandbestaetigung = versandbestaetigungState.begleitpapiere.checked ? 'BEGLEITPAPIERE' : versandbestaetigungState.elektronisch.checked ? 'ELEKTRONISCH' : 'NOTHING';

    const auftragId = auftraegeState.find((auftrag) => auftrag.auftragsnummer === auftragsnummer)?.id;

    const begleitpapiere = begleitpapiereState.map((begleitpapier) => begleitpapier.fileRef);
    const labels = labelsState.map((label) => label.fileRef);
    const requestDatum = datum.length ? new Date(datum) : new Date(0);

    const formData = new FormData();

    const anzahlPakete = anzahlDerPaketeProPaketsendung ? [...anzahlDerPaketeProPaketsendung] : anzahlDerPaketeProPalette ? [...anzahlDerPaketeProPalette] : [0];
    const anzahl = sendungsartState.paketsendung.checked ? Number(anzahlDerPakete) || 0 : sendungsartState.palettensendung.checked ? Number(anzahlDerPaletten) || 0 : 0;

    pushNumberInEmptyArray(anzahlPakete);

    formData.append('kunde', kunde);
    formData.append('spedition', spedition);
    formData.append('liveTrackerNummer', liveTracker);
    formData.append('trackingnummer', trackingNummer);
    formData.append('datum', requestDatum);
    formData.append('sendungsart', requestSendungsart);
    formData.append('anzahl', anzahl);
    formData.append('anzahlPakete', anzahlPakete);
    formData.append('bestaetigungsart', requestVersandbestaetigung);

    begleitpapiere.forEach((begleitpapier) => {
      formData.append('versandbestaetigung', begleitpapier);
    });
    labels.forEach((label) => {
      formData.append('label', label);
    });

    authAxios
      .post(`/auftrag/${auftragId}/versand`, formData, { headers: { 'Content-Type': 'multipart/form-data;' } })
      .then(() => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT', objGlobalState.lang), { variant: 'success' });

        setBegleitpapiereState([]);
        setLabelsState([]);
        closeConfirmationOnClick();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const getAuftraegeFromAPI = () => {
    authAxios
      .get('/auftrag/all')
      .then((response) => {
        const auftraege = response.data;

        const newAuftraegeState = [];

        auftraege.forEach((auftrag) => {
          const { id, auftragsnummer, name1: kunde, dienstleister: spedition } = auftrag;

          const newAuftraegeItem = { id, auftragsnummer, kunde, spedition };

          newAuftraegeState.unshift(newAuftraegeItem);
        });

        setAuftraegeState(newAuftraegeState);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // function ->
  const fetchSpeditionenFromAPI = () => {
    authAxios
      .get('/speditionen')
      .then((response) => {
        const logistikunternehmen = response.data;
        setLogistikunternehmenState(logistikunternehmen);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchSpeditionenFromAPI();
      getAuftraegeFromAPI();
    }

    return () => (isMounted = false);
  }, []);

  const lablesSectionTitle = 'LABELS';
  const labelsSection = { sectionTitle: lablesSectionTitle, files: labelsState };

  const begleitpapiereSectionTitle = 'BEGLEITPAPIERE';
  const begleitpapiereSection = { sectionTitle: begleitpapiereSectionTitle, files: begleitpapiereState };

  const confirmationGallery = [labelsSection];

  if (versandbestaetigungChecked) {
    confirmationGallery.push(begleitpapiereSection);
  }

  const objLieferung = {
    confirmation: {
      closeConfirmationOnClick,
      confirmationDialogState,
      confirmed,
      newDataValueState
    },
    lieferungTable: {
      lieferungTableDialogState,
      setLieferungTableDialogState
    },
    paketeOrPaletteInput: {
      anzahlDerPaketeState,
      setAnzahlDerPaketeState,
      anzahlDerPalettenState,
      setAnzahlDerPalettenState,
      paketeProPaketsendungState,
      setPaketeProPaketsendungState,
      paketeProPaletteState,
      setPaketeProPaletteState,
      sendungsartState
    },
    logistikunternehmenSelect: {
      selectValue: inputsState.spedition,
      handleInputOnChange: (event) => handleInputOnChange(event, 'spedition'),
      speditionenToExclude: ['chors']
    }
  };

  // array -> create breadcrumbs
  const arrBreadcrumbs = [
    { url: '/', title: language('SEITE', 'TITEL', 'HOME', objGlobalState.lang) },
    { url: '/versand_lieferung', title: language('SEITE', 'TITEL', 'VERSANDLIEFERUNG', objGlobalState.lang) }
  ];

  return (
    <>
      {uploadFilesBegleitpapiereDialogState && (
        <CoUploadFiles
          objGlobalState={objGlobalState}
          objParentState={{
            filesState: begleitpapiereState,
            setFilesState: setBegleitpapiereState,
            uploadFilesDialogState: uploadFilesBegleitpapiereDialogState,
            setUploadFilesDialogState: setUploadFilesBegleitpapiereDialogState
          }}
        />
      )}
      {uploadFilesLabelsDialogState && (
        <CoUploadFiles
          objGlobalState={objGlobalState}
          objParentState={{
            filesState: labelsState,
            setFilesState: setLabelsState,
            uploadFilesDialogState: uploadFilesLabelsDialogState,
            setUploadFilesDialogState: setUploadFilesLabelsDialogState
          }}
        />
      )}
      {confirmationDialogState && <CoConfirmation objGlobalState={objGlobalState} objParentState={objLieferung.confirmation} />}
      {lieferungTableDialogState && <CoLieferungTable objGlobalState={objGlobalState} objParentState={objLieferung.lieferungTable} />}
      <Container component="main" maxWidth="xl" className="container__container">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ServiceBreadcrumbs arrBreadcrumbs={arrBreadcrumbs} />
          </Grid>
          <Grid item xs={12}>
            <ListItem className="container__listItem">
              <ListItemIcon>
                <img src={`../assets/images/icons/icon_lieferung.png`} alt="" className="images__headerIcon" />
              </ListItemIcon>
              <ListItemText>
                <Typography className="typography__headerTitle">{language('SEITE', 'TITEL', 'VERSANDLIEFERUNG', objGlobalState.lang)}</Typography>
              </ListItemText>
            </ListItem>
          </Grid>
          <Grid item xs={12} container justify="flex-end">
            <Button onClick={showLieferungTableDialogOnClick} className="button__middle" variant="contained" color="primary">
              {language('BUTTON', 'TITEL', 'DATENZEIGEN', objGlobalState.lang)}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <form name="submitForm" noValidate autoComplete="on" onSubmit={submitFormOnClick}>
              <Grid container spacing={3}>
                <Grid container item xs={12}>
                  <Grid item xs={12} lg={5}>
                    <Autocomplete
                      clearText=""
                      openText=""
                      onChange={setAuftragOnClick}
                      onInputChange={handleAuftragsnummerOnChange}
                      options={auftraegeState}
                      getOptionLabel={(option) => option.auftragsnummer}
                      filterSelectedOptions
                      renderInput={(params) => <TextField {...params} variant="outlined" label={language('FORM', 'LABEL', 'AUFTRAGSNUMMER', objGlobalState.lang)} />}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    disabled={true}
                    value={inputsState.kunde}
                    onChange={(event) => handleInputOnChange(event, 'kunde')}
                    fullWidth
                    type="text"
                    variant="outlined"
                    label={language('FORM', 'LABEL', 'KUNDE', objGlobalState.lang)}
                  />
                </Grid>
                <Grid container spcing={1} item xs={12} md={4}>
                  <Grid item xs={11}>
                    <CoLogistikunternhemenSelect objGlobalState={objGlobalState} objParentState={objLieferung.logistikunternehmenSelect} />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton href={webLinkState.url} target="_blank" className={webLinkState.active ? 'coVersandlieferung__iconButton' : ''} disabled={!webLinkState.active}>
                      <Public className="coVersandlieferung__icon" />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    disabled={true}
                    value={generateDate().dateForInputField}
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    type="date"
                    variant="outlined"
                    name="datum"
                    label={language('FORM', 'LABEL', 'DATUM', objGlobalState.lang)}
                  />
                </Grid>
                {hasLiveTrackingAccess ? (
                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      options={objGlobalState.liveTrackerState}
                      clearText=""
                      openText=""
                      noOptionsText={language('FORM', 'LABEL', 'KEINEOPTIONEN', objGlobalState.lang)}
                      onChange={handleAutoCompleteLiveTrackerOnChange}
                      renderInput={(params) => (
                        <TextField {...params} value={inputsState.liveTracker} fullWidth type="text" variant="outlined" label={language('FORM', 'LABEL', 'LIVETRACKER', objGlobalState.lang)} />
                      )}
                    />
                  </Grid>
                ) : (
                  ''
                )}
                <Grid item xs={12} md={4}>
                  <TextField
                    value={inputsState.trackingNummer}
                    onChange={(event) => handleInputOnChange(event, 'trackingNummer')}
                    fullWidth
                    type="text"
                    variant="outlined"
                    label={language('FORM', 'LABEL', 'TRACKINGNUMMER', objGlobalState.lang)}
                  />
                </Grid>
                <Grid item xs={12} className="coVersandlieferung__grid--formSection">
                  <Button onClick={() => showUploadFilesDialogOnClick('labels')} className="button__middle coVersandlieferung__button" variant="outlined" color="primary">
                    {language('BUTTON', 'TITEL', 'LABELHOCHLADEN', objGlobalState.lang)}
                  </Button>
                </Grid>
                <CoDisplayFilesList objGlobalState={objGlobalState} filesState={labelsState} setFilesState={setLabelsState} title="LABELS" />
                <Grid item xs={12} className="coVersandlieferung__grid--formSection">
                  <FormControl component="fieldset" className="coVersandlieferung__formControl">
                    <FormLabel className="coVersandlieferung__formLabel">
                      <Typography className="typography">{language('FORM', 'LABEL', 'SENDUNGSART', objGlobalState.lang)}</Typography>
                    </FormLabel>
                    <FormControlLabel
                      control={<Switch checked={sendungsartState.paketsendung.checked} onChange={() => checkSendungsart('paketsendung')} color="primary" />}
                      label={language('FORM', 'LABEL', 'PAKETSENDUNG', objGlobalState.lang)}
                    />
                    <FormControlLabel
                      control={<Switch checked={sendungsartState.palettensendung.checked} onChange={() => checkSendungsart('palettensendung')} color="primary" />}
                      label={language('FORM', 'LABEL', 'PALETTENSENDUNG', objGlobalState.lang)}
                    />
                  </FormControl>
                </Grid>
                <CoPaketeOrPaletteInput objGlobalState={objGlobalState} objParentState={objLieferung.paketeOrPaletteInput} />
                <Grid item xs={12} className="coVersandlieferung__grid--formSection">
                  <FormControl component="fieldset" className="coVersandlieferung__formControl">
                    <FormLabel className="coVersandlieferung__formLabel">
                      <Typography className="typography">{language('FORM', 'LABEL', 'VERSANDBESTAETIGUNG', objGlobalState.lang)}</Typography>
                    </FormLabel>
                    <FormControlLabel
                      control={<Switch checked={versandbestaetigungState.elektronisch.checked} onChange={() => checkVersandbestaetigung('elektronisch')} color="primary" />}
                      label={language('FORM', 'LABEL', 'ELEKTRONISCH', objGlobalState.lang)}
                    />
                    <FormControlLabel
                      control={<Switch checked={versandbestaetigungState.begleitpapiere.checked} onChange={() => checkVersandbestaetigung('begleitpapiere')} color="primary" />}
                      label={language('FORM', 'LABEL', 'UEBERBEGLEITPAPIERE', objGlobalState.lang)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button onClick={() => showUploadFilesDialogOnClick('begleitpapiere')} disabled={!versandbestaetigungChecked} className="button__middle" variant="outlined" color="primary">
                    {language('BUTTON', 'TITEL', 'DATEIENHOCHLADEN', objGlobalState.lang)}
                  </Button>
                </Grid>
                <CoDisplayFilesList
                  objGlobalState={objGlobalState}
                  filesState={begleitpapiereState}
                  setFilesState={setBegleitpapiereState}
                  title="BEGLEITPAPIERE"
                  disabled={!versandbestaetigungChecked}
                />
                <Grid item xs={12}>
                  <Button fullWidth type="submit" color="primary" className="button__middle" variant="contained" color="primary">
                    {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CoVersandlieferung;
