import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import language from '../../language/language';
import { displayArraysInTable } from '../../helperFunctions/displayArrayInTable';
import { createAxiosInstance, getAndCostumFiles } from '../../helperFunctions/axios';
import { generateDate } from '../../helperFunctions/date';
import { mouseScrollHorizontally } from '../../helperFunctions/mouseScrollHorizontally';

// import -> material ui -> core & icons
import { FormControl, Select, MenuItem, Box, Paper, Table, Button, ButtonGroup, TableBody, TableCell, TableContainer, TableRow, Typography, TableHead } from '@material-ui/core';
import { CancelOutlined, CheckCircleOutline } from '@material-ui/icons';

// import -> components
import CoShowFiles from '../CoShowFiles';
import { exportTableAsCSV } from '../../helperFunctions/exportTable';
import { generateCSVString } from '../../helperFunctions/generateCSVString';
import { removeFromArray } from '../../helperFunctions/arrays';

// component -> React-root
const CoLieferungtabelle = ({ objGlobalState, objParentState, options }) => {
  const noTableTitle = options?.noTableTitle;
  const heightFourty = options?.heightFourty;
  const heightFifty = options?.heightFifty;
  const noBorderTop = options?.noBorderTop;
  const selectLieferung = options?.selectLieferung;
  const isHome = options?.isHome;
  const isWarenannahmestatus = options?.isWarenannahmestatus;
  const isWareneingangStatus = options?.isWareneingangStatus;
  const isWareneingangEinlagerung = options?.isWareneingangEinlagerung;

  const [showFilesDialogState, setShowFilesDialogState] = useState({ open: false, files: [], title: null });
  const [sendungenState, setSendungenState] = useState([]);

  const tableRef = useRef();
  const tableHeaders = isHome
    ? ['LIEFERANT', 'SPEDITION', 'DATUM', 'SENDUNGSART', 'LIEFERSCHEINVORHANDEN', 'ERSTELLUNGSDATUM']
    : isWareneingangStatus
    ? [
        'NUMMER',
        'STATUS',
        'ANNAHMESTELLPLATZKENNZEICHNUNG',
        'ANNAHMESTELLPLATZFOTOS',
        'EINGANGSTELLPLATZKENNZEICHNUNG',
        'EINGANGSTELLPLATZFOTOS',
        'LIEFERSCHEINVORHANDEN',
        'LIEFERANT',
        'SPEDITION',
        'DATUM',
        'SENDUNGSART',
        'ANZAHLDERPAKETE',
        'ANZAHLDERPAKETEPROPAKETSENDUNG',
        'ANZAHLDERPALETTEN',
        'ANZAHLDERPAKETEPROPALETTE',
        'SENDUNGBESCHAEDIGT',
        'BESCHREIBUNGDERBESCHAEDIGUNG',
        'ERSTELLUNGSDATUM'
      ]
    : [
        ' ',
        'LIEFERANT',
        'SPEDITION',
        'DATUM',
        'SENDUNGSART',
        'ANZAHLDERPAKETE',
        'ANZAHLDERPAKETEPROPAKETSENDUNG',
        'ANZAHLDERPALETTEN',
        'ANZAHLDERPAKETEPROPALETTE',
        'SENDUNGBESCHAEDIGT',
        'BESCHREIBUNGDERBESCHAEDIGUNG',
        'EMPFANGSBESTAETIGUNG',
        'ERSTELLUNGSDATUM',
        'DATEIEN'
      ];

  const tableValuesLabels = isWareneingangStatus
    ? [
        'id',
        'status',
        'annahmestellplatzkennzeichnung',
        'eingangstellplatzkennzeichnung',
        'lieferscheinVorhanden',
        'lieferant',
        'spedition',
        'datum',
        'sendungsart',
        'anzahlDerPakete',
        'anzahlDerPaketeProPaketSendung',
        'anzahlDerPalette',
        'anzahlDerPaketeProPalette',
        'sendungBeschaedigt',
        'beschreibungDerBeschaedigung',
        'erstellungsDatum'
      ]
    : [
        'id',
        'lieferant',
        'spedition',
        'datum',
        'sendungsart',
        'anzahlDerPakete',
        'anzahlDerPaketeProPaketSendung',
        'anzahlDerPalette',
        'anzahlDerPaketeProPalette',
        'sendungBeschaedigt',
        'beschreibungDerBeschaedigung',
        'empfangsbestaetigung',
        'erstellungsDatum'
      ];

  // function ->
  const chooseSendungOnClick = (sendung) => {
    objParentState.setAusgewaehlteSendungState({ chosen: true, sendung: sendung });

    const currentSendungenState = [...sendungenState];

    currentSendungenState.map((_sendung) => {
      _sendung.chosen = false;

      if (_sendung === sendung) {
        _sendung.chosen = true;
      }

      return _sendung;
    });

    setSendungenState(currentSendungenState);
  };

  // function ->
  const stateToCSV = () => {
    const csvRows = [];
    const headers = [...tableHeaders];

    removeFromArray(headers, 'DATEIEN');
    removeFromArray(headers, 'ANNAHMESTELLPLATZFOTOS');
    removeFromArray(headers, 'EINGANGSTELLPLATZFOTOS');

    const headerRow = headers.map((header) => generateCSVString(language('TABLE', 'TITEL', header, objGlobalState.lang)));

    csvRows.push(headerRow.join(';'));
    sendungenState.forEach((sendung) => {
      const valueRow = tableValuesLabels.map((value) => {
        if (value === 'status') {
          const valueString =
            sendung[value] === 1
              ? language('FORM', 'LABEL', 'WARTEND', objGlobalState.lang)
              : sendung[value] === 2
              ? language('FORM', 'LABEL', 'INBEARBEITUNG', objGlobalState.lang)
              : sendung[value] === 3
              ? language('FORM', 'LABEL', 'EINGELAGERT', objGlobalState.lang)
              : '';
          return generateCSVString(valueString);
        }
        if (value === 'lieferscheinVorhanden') {
          const valueString = sendung[value] ? language('KONTENT', 'TEXT', 'JA', objGlobalState.lang) : language('KONTENT', 'TEXT', 'NEIN', objGlobalState.lang);
          return generateCSVString(valueString);
        }

        return generateCSVString(sendung[value]);
      });
      csvRows.push(valueRow.join(';'));
    });
    return csvRows.join('\n');
  };

  // function ->
  const showFilesDialogPapiereBeschaedigungOnClick = (event, deliveryId, filesName) => {
    event.stopPropagation();
    const title = filesName.toUpperCase();
    const files = [];

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    const endpoint = filesName === 'begleitpapiere' ? `delivery/findPreviewsForDeliveryNote/${deliveryId}` : filesName === 'beschaedigungsfotos' ? `/delivery/findpreviews/${deliveryId}` : '';

    authAxios
      .get(endpoint)
      .then((response) => {
        const { data } = response;

        let dataIterator;

        if (filesName === 'begleitpapiere') {
          dataIterator = data[0]?.previewDTOList;
        }
        if (filesName === 'beschaedigungsfotos') {
          dataIterator = data;
        }

        if (!dataIterator.length) {
          setShowFilesDialogState({ open: true, title: title, files: files });
        }

        dataIterator.forEach((fileObj) => {
          getAndCostumFiles({ file: fileObj, filesArray: files, authAxios: authAxios }).then((result) => {
            setShowFilesDialogState({ open: true, title: title, files: result });
          });
        });
      })
      .catch((error) => console.log(error));
  };

  // function ->
  const showFilesDialogStellplatzOnClick = (fotos) => {
    const title = 'STELLPLATZFOTOS';
    const files = [];

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    fotos.forEach((foto) => {
      getAndCostumFiles({ file: foto, filesArray: files, authAxios: authAxios }).then((result) => {
        setShowFilesDialogState({ open: true, title: title, files: result });
      });
    });
  };

  // function ->
  const findSelectValue = (id) => {
    const dataset = sendungenState.find((item) => item.id === id);
    return dataset.status;
  };

  // function ->
  const changeSelectValue = (event, deliveryId) => {
    const { value } = event.target;

    const requestObj = { deliveryId: deliveryId, statusId: value };

    objGlobalState.setPageLoadingState(true);

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);
    authAxios
      .post('/delivery/setStatus', requestObj)
      .then(() => {
        const datasetIndex = sendungenState.findIndex((item) => item.id === deliveryId);
        const currentStatusInfoState = [...sendungenState];
        currentStatusInfoState[datasetIndex].status = event.target.value;
        setSendungenState(currentStatusInfoState);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const fetchLieferungInformationFromAPI = () => {
    objGlobalState.setPageLoadingState(true);

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    authAxios
      .get('/delivery/list')
      .then((response) => {
        const { data } = response;

        const shipmentTypes = [
          {
            name: 'PAKETSENDUNG',
            id: 1
          },
          {
            name: 'PALETTENSENDUNG',
            id: 2
          }
        ];

        const confirmations = [
          {
            name: 'ELEKTRONISCH',
            id: 1
          },
          {
            name: 'UEBERBEGLEITPAPIERE',
            id: 2
          }
        ];

        const newLieferungInfoState = [];
        data.forEach((dataObj) => {
          if (isWarenannahmestatus && dataObj.warenannahmestellplatz) {
            return;
          }
          if (isWareneingangEinlagerung && dataObj.wareneingangstellplatz) {
            return;
          }
          // 07.12.1110 is the date we get when the user don't enter a date value; we then send a default date value
          const date = generateDate(dataObj.deliveryDate).justDate === '30.11.1110' ? '' : generateDate(dataObj.deliveryDate).justDate;
          const packagesPerUnit = dataObj.packagesPerUnit.map((element) => element.quantity);

          const foundShipment = shipmentTypes.find((element) => element.id === dataObj.shipmentType.id);
          const shipmentType = foundShipment ? language('KONTENT', 'TEXT', foundShipment.name, objGlobalState.lang) : '';

          const foundConfirmation = confirmations.find((element) => element.id === dataObj.confirmation.id);
          const confirmation = foundConfirmation ? language('KONTENT', 'TEXT', foundConfirmation.name, objGlobalState.lang) : '';

          const newdataObj = isHome
            ? {
                lieferant: dataObj.supplier,
                spedition: dataObj.shippingCompany,
                datum: date,
                sendungsart: shipmentType,
                lieferscheinVorhanden: dataObj.deliveryNote,
                erstellungsDatum: generateDate(dataObj.dateCreated).justDate
              }
            : isWareneingangStatus
            ? {
                id: dataObj.id,
                annahmestellplatzkennzeichnung: dataObj.warenannahmestellplatz?.pitch ? dataObj.warenannahmestellplatz.pitch : '',
                annahmestellplatzfotos: dataObj.warenannahmestellplatz?.statusPhotos,
                eingangstellplatzkennzeichnung: dataObj.wareneingangstellplatz?.pitch ? dataObj.wareneingangstellplatz.pitch : '',
                eingangstellplatzfotos: dataObj.wareneingangstellplatz?.statusPhotos,
                lieferant: dataObj.supplier,
                spedition: dataObj.shippingCompany,
                datum: date,
                sendungsart: shipmentType,
                anzahlDerPakete: foundShipment?.id === 1 ? dataObj.quantity : '',
                anzahlDerPaketeProPaketSendung: foundShipment?.id === 1 ? packagesPerUnit : [],
                anzahlDerPalette: foundShipment?.id === 2 ? dataObj.quantity : '',
                anzahlDerPaketeProPalette: foundShipment?.id === 2 ? packagesPerUnit : [],
                sendungBeschaedigt: dataObj.damage ? language('KONTENT', 'TEXT', 'JA', objGlobalState.lang) : '',
                beschreibungDerBeschaedigung: dataObj.damageDescription || '',
                status: dataObj.currentStatus?.id || 1,
                lieferscheinVorhanden: dataObj.deliveryNote,
                erstellungsDatum: generateDate(dataObj.dateCreated).justDate
              }
            : {
                id: dataObj.id,
                chosen: false,
                lieferant: dataObj.supplier || '',
                spedition: dataObj.shippingCompany || '',
                datum: date,
                sendungsart: shipmentType,
                anzahlDerPakete: foundShipment?.id === 1 ? dataObj.quantity : '',
                anzahlDerPaketeProPaketSendung: foundShipment?.id === 1 ? packagesPerUnit : [],
                anzahlDerPalette: foundShipment?.id === 2 ? dataObj.quantity : '',
                anzahlDerPaketeProPalette: foundShipment?.id === 2 ? packagesPerUnit : [],
                sendungBeschaedigt: dataObj.damage ? language('KONTENT', 'TEXT', 'JA', objGlobalState.lang) : '',
                beschreibungDerBeschaedigung: dataObj.damageDescription || '',
                empfangsbestaetigung: confirmation,
                erstellungsDatum: generateDate(dataObj.dateCreated).justDate
              };

          newLieferungInfoState.unshift(newdataObj);
        });
        setSendungenState(newLieferungInfoState);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchLieferungInformationFromAPI();
    }

    return () => (isMounted = false);
  }, [objParentState?.rerunEffectState]);

  const objLieferungtabelle = {
    showFiles: {
      showFilesDialogState,
      setShowFilesDialogState
    }
  };

  const createSelectSendungProps = (item) =>
    selectLieferung
      ? {
          className: item.chosen ? 'table__row--active' : 'table__row--hover',
          onClick: () => chooseSendungOnClick(item)
        }
      : {};

  const tableTitle = objParentState?.tableTitle ?? 'LIEFERUNGSDATEN';

  return (
    <>
      {showFilesDialogState.open && <CoShowFiles objGlobalState={objGlobalState} objParentState={objLieferungtabelle.showFiles} />}

      <Box className="table__title">
        {!noTableTitle && (
          <Typography align="center" className="typography__singleTableTitle">
            {language('TABLE', 'HEADER', tableTitle, objGlobalState.lang)}
          </Typography>
        )}{' '}
        {isHome ? (
          ''
        ) : (
          <div className="marginTwenty">
            {
              <Button variant="contained" color="primary" onClick={() => exportTableAsCSV(stateToCSV(), 'lieferung')} className="table__titleButton">
                {language('BUTTON', 'TITEL', 'TABELLEEXPORTIEREN', objGlobalState.lang)}
              </Button>
            }
          </div>
        )}
      </Box>
      <TableContainer
        ref={tableRef}
        component={Paper}
        variant="outlined"
        elevation={0}
        square
        className={`table__container ${heightFourty ? 'heightFour' : ''} ${isHome ? 'maxHeight__330' : ''} ${heightFifty ? 'heightFive' : ''} ${noBorderTop ? 'border__noTop' : ''}`}
      >
        <Table size="medium" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, key) => {
                return (
                  <TableCell align="center" component="th" className="table__headerCell" key={`common_components_sendungen_TableHeaders_${key}`}>
                    <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', header, objGlobalState.lang)}</Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {sendungenState.map((item, key) => {
              // destructuring the needed data to display
              const {
                annahmestellplatzkennzeichnung,
                annahmestellplatzfotos,
                eingangstellplatzkennzeichnung,
                eingangstellplatzfotos,
                lieferant,
                spedition,
                datum,
                sendungsart,
                anzahlDerPakete,
                anzahlDerPaketeProPaketSendung,
                anzahlDerPalette,
                anzahlDerPaketeProPalette,
                sendungBeschaedigt,
                beschreibungDerBeschaedigung,
                empfangsbestaetigung,
                erstellungsDatum,
                status,
                lieferscheinVorhanden,
                id
              } = item;

              // array -> put the destructured data in an array to map on them
              const values = isHome
                ? [
                    { name: 'lieferant', value: lieferant },
                    { name: 'spedition', value: spedition },
                    { name: 'datum', value: datum },
                    { name: 'sendungsart', value: sendungsart },
                    { name: 'lieferscheinVorhanden', value: lieferscheinVorhanden },
                    { name: 'erstellungsDatum', value: erstellungsDatum }
                  ]
                : isWareneingangStatus
                ? [
                    { name: 'id', value: id },
                    { name: 'status', value: status },
                    { name: 'annahmestellplatzkennzeichnung', value: annahmestellplatzkennzeichnung },
                    { name: 'annahmestellplatzfotos', value: annahmestellplatzfotos },
                    { name: 'eingangstellplatzkennzeichnung', value: eingangstellplatzkennzeichnung },
                    { name: 'eingangstellplatzfotos', value: eingangstellplatzfotos },
                    { name: 'lieferscheinVorhanden', value: lieferscheinVorhanden },
                    { name: 'lieferant', value: lieferant },
                    { name: 'spedition', value: spedition },
                    { name: 'datum', value: datum },
                    { name: 'sendungsart', value: sendungsart },
                    { name: 'anzahlDerPakete', value: anzahlDerPakete },
                    { name: 'anzahlDerPaketeProPaketSendung', value: anzahlDerPaketeProPaketSendung },
                    { name: 'anzahlDerPalette', value: anzahlDerPalette },
                    { name: 'anzahlDerPaketeProPalette', value: anzahlDerPaketeProPalette },
                    { name: 'sendungBeschaedigt', value: sendungBeschaedigt },
                    { name: 'beschreibungDerBeschaedigung', value: beschreibungDerBeschaedigung },
                    { name: 'erstellungsDatum', value: erstellungsDatum }
                  ]
                : [
                    { name: 'nummer', value: id },
                    { name: 'lieferant', value: lieferant },
                    { name: 'spedition', value: spedition },
                    { name: 'datum', value: datum },
                    { name: 'sendungsart', value: sendungsart },
                    { name: 'anzahlDerPakete', value: anzahlDerPakete },
                    { name: 'anzahlDerPaketeProPaketSendung', value: anzahlDerPaketeProPaketSendung },
                    { name: 'anzahlDerPalette', value: anzahlDerPalette },
                    { name: 'anzahlDerPaketeProPalette', value: anzahlDerPaketeProPalette },
                    { name: 'sendungBeschaedigt', value: sendungBeschaedigt },
                    { name: 'beschreibungDerBeschaedigung', value: beschreibungDerBeschaedigung },
                    { name: 'empfangsbestaetigung', value: empfangsbestaetigung },
                    { name: 'erstellungsDatum', value: erstellungsDatum },
                    { name: 'dateien' }
                  ];

              const selectClassNameForColors = status === 1 ? 'red' : status === 2 ? 'orange' : status === 3 ? 'green' : '';

              return (
                <TableRow key={`common_components_sendungen_TableRow_${key}`} {...createSelectSendungProps(item)}>
                  {values.map((value, _key) => {
                    if (value.name === 'status') {
                      return (
                        <TableCell align="center" key={`wareneingagn_status_tableCell_${key}_${_key}`}>
                          <FormControl variant="standard">
                            <Select value={findSelectValue(id)} onChange={(event) => changeSelectValue(event, id)} className={`statusSelect statusSelect--${selectClassNameForColors}`}>
                              <MenuItem value={1}>{language('FORM', 'LABEL', 'WARTEND', objGlobalState.lang)}</MenuItem>
                              <MenuItem value={2}>{language('FORM', 'LABEL', 'INBEARBEITUNG', objGlobalState.lang)}</MenuItem>
                              <MenuItem value={3}>{language('FORM', 'LABEL', 'EINGELAGERT', objGlobalState.lang)}</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                      );
                    } else if (value.name === 'lieferscheinVorhanden') {
                      return (
                        <TableCell align="center" key={`wareneingagn_status_tableCell_${key}_${_key}`}>
                          {lieferscheinVorhanden ? <CheckCircleOutline className="icon__true" /> : <CancelOutlined className="icon__false" />}
                        </TableCell>
                      );
                    } else if (value.name === 'annahmestellplatzfotos' || value.name === 'eingangstellplatzfotos') {
                      return (
                        <TableCell align="center" key={`wareneingagn_status_tableCell_${key}_${_key}`}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => showFilesDialogStellplatzOnClick(value.value)}
                            className={value.value?.length ? 'button__small' : ''}
                            disabled={!value.value?.length}
                          >
                            {language('BUTTON', 'TITEL', 'ANSEHEN', objGlobalState.lang)}
                          </Button>
                        </TableCell>
                      );
                    } else if (value.name === 'dateien') {
                      return (
                        <TableCell align="center" key={`common_components_sendungen_TableCell_${key}_${_key}`}>
                          <ButtonGroup>
                            <Button onClick={(event) => showFilesDialogPapiereBeschaedigungOnClick(event, id, 'beschaedigungsfotos')} className="button__small" variant="outlined" color="primary">
                              {language('BUTTON', 'TITEL', 'BESCHAEDIGUNGSFOTOS', objGlobalState.lang)}
                            </Button>
                            <Button onClick={(event) => showFilesDialogPapiereBeschaedigungOnClick(event, id, 'begleitpapiere')} className="button__small" variant="outlined" color="primary">
                              {language('BUTTON', 'TITEL', 'BEGLEITPAPIERE', objGlobalState.lang)}
                            </Button>
                          </ButtonGroup>
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell align="center" key={`common_components_sendungen_TableCell_${key}_${_key}`}>
                          <Typography className="typography__singleTableRowContent">{displayArraysInTable(value.value)}</Typography>
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CoLieferungtabelle;
