import React, { useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import language from '../../Common/language/language';
import { displayArraysInTable } from '../../Common/helperFunctions/displayArrayInTable';
import { createAxiosInstance, getAndCostumFiles } from '../../Common/helperFunctions/axios';

// import -> material ui -> core
import { Paper, Table, Button, ButtonGroup, Grid, TableBody, TableCell, TableContainer, TableRow, TextField, Typography, Box } from '@material-ui/core';

// import -> components
import CoConfirmation from '../../Common/Components/CoConfirmation';
import CoUploadFiles from '../../Common/Components/CoUploadFiles';
import CoShowFiles from '../../Common/Components/CoShowFiles';
import CoLieferungtabelle from '../../Common/Components/Warenannahme/CoLieferungtabelle';
import CoArtikeltabelle from '../../Common/Components/Artikelverwaltung/CoArtikeltabelle';
import CoMultipleScannerWithSelectStellplatz from '../../Common/Components/CoMultipleScannerWithSelectStellplatz';

// component -> React-root
const CoEinlagerungWithTransporteinheit = ({ objGlobalState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const [rerunEffectState, setRerunEffekcState] = useState(true);
  const [showFilesDialogState, setShowFilesDialogState] = useState({ open: false, files: [], title: null });
  const [confirmationDialogState, setConfirmationDialogState] = useState(false);
  const [uploadFilesDialogState, setUploadFilesDialogState] = useState({ open: false, title: '' });
  const [filesState, setFilesState] = useState([]);
  const [ausgewaehlteSendungState, setAusgewaehlteSendungState] = useState({ chosen: false, sendung: {} });
  const [ausgewaehlteArtikelState, setAusgewaehlteArtikelState] = useState({ chosen: false, artikel: {} });

  const [selectedStellplaetzeState, setSelectedStellplaetzeState] = useState([]);

  const [newDataValueState, setNewDataValueState] = useState({
    sendung: '',
    artikel: '',
    stellplatzkennzeichnung: '',
    charge: '',
    seriennummer: '',
    verfallsdatum: '',
    menge: 0
  });

  const tableRef = useRef();

  // function ->
  const showFilesDialogOnClick = (event, deliveryId, filesName) => {
    event.stopPropagation();
    const title = filesName.toUpperCase();
    const files = [];

    const endpoint = filesName === 'begleitpapiere' ? `delivery/findPreviewsForDeliveryNote/${deliveryId}` : filesName === 'beschaedigungsfotos' ? `/delivery/findpreviews/${deliveryId}` : '';

    authAxios
      .get(endpoint)
      .then((response) => {
        const { data } = response;

        let dataIterator;

        if (filesName === 'begleitpapiere') {
          dataIterator = data[0]?.previewDTOList;
        }
        if (filesName === 'beschaedigungsfotos') {
          dataIterator = data;
        }

        if (!dataIterator.length) {
          setShowFilesDialogState({ open: true, title: title, files: files });
        }

        dataIterator.forEach((fileObj) => {
          getAndCostumFiles({ file: fileObj, filesArray: files, authAxios: authAxios }).then((result) => {
            setShowFilesDialogState({ open: true, title: title, files: result });
          });
        });
      })
      .catch((error) => console.log(error));
  };

  // function ->
  const removeAusgewahlteDatenAfterConfirm = () => {
    setAusgewaehlteSendungState({ chosen: false, sendung: {} });
    setAusgewaehlteArtikelState({ chosen: false, artikel: {} });
  };

  // function ->
  const submitFormOnClick = (event) => {
    event.preventDefault();

    const charge = event.target.charge.value;
    const seriennummer = event.target.seriennummer.value;
    const verfallsdatum = event.target.verfallsdatum.value;
    const menge = event.target.menge.value;
    const stellplatzkennzeichnung = getSelectedStellplaetze().confirmationStellplaetze;
    const sendung = ausgewaehlteSendungState.sendung?.id;
    const artikel = ausgewaehlteArtikelState.artikel?.id;

    setNewDataValueState({ sendung, artikel, stellplatzkennzeichnung, charge, seriennummer, verfallsdatum, menge });
    setConfirmationDialogState(true);
  };

  // function ->
  const closeConfirmationOnClick = () => {
    setConfirmationDialogState(false);
  };

  // function ->
  const getSelectedStellplaetze = () => {
    // to display in the confirmation dialog
    const confirmationStellplaetze = selectedStellplaetzeState.map((stellplatz) => stellplatz.name).join(', ');

    // to send with the request
    const requestStellplaetze = selectedStellplaetzeState.map((stellplatz) => ({ id: stellplatz.id }));

    return {
      confirmationStellplaetze,
      requestStellplaetze
    };
  };

  // function ->
  const confirmed = () => {
    objGlobalState.setPageLoadingState(true);

    const { sendung: sendungId, artikel: artikelId, charge, seriennummer, verfallsdatum, menge } = newDataValueState;

    const requestBody = {
      lieferungId: sendungId,
      stellplaetze: getSelectedStellplaetze().requestStellplaetze,
      artikelStammId: artikelId,
      charge,
      seriennummer,
      verfallsdatum,
      menge: menge.length ? +menge : null
    };

    authAxios
      .post('/transporteinheit/lager-lieferung', requestBody)
      .then(() => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT', objGlobalState.lang), { variant: 'success' });

        closeConfirmationOnClick();
        removeAusgewahlteDatenAfterConfirm();
        setRerunEffekcState((pre) => !pre);
      })
      .catch((error) => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });

        console.error(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const showAusgewaehlteSendung = () => {
    // destructuring the needed data to display
    const {
      id,
      lieferant,
      spedition,
      datum,
      sendungsart,
      anzahlDerPakete,
      anzahlDerPaketeProPaketSendung,
      anzahlDerPalette,
      anzahlDerPaketeProPalette,
      sendungBeschaedigt,
      beschreibungDerBeschaedigung,
      empfangsbestaetigung,
      erstellungsDatum
    } = ausgewaehlteSendungState.sendung;

    // array -> put the destructured data in an array to map on them
    const values = [
      { name: 'nummer', value: id },
      { name: 'lieferant', value: lieferant },
      { name: 'spedition', value: spedition },
      { name: 'datum', value: datum },
      { name: 'sendungsart', value: sendungsart },
      { name: 'anzahlDerPakete', value: anzahlDerPakete },
      { name: 'anzahlDerPaketeProPaketSendung', value: anzahlDerPaketeProPaketSendung },
      { name: 'anzahlDerPalette', value: anzahlDerPalette },
      { name: 'anzahlDerPaketeProPalette', value: anzahlDerPaketeProPalette },
      { name: 'sendungBeschaedigt', value: sendungBeschaedigt },
      { name: 'beschreibungDerBeschaedigung', value: beschreibungDerBeschaedigung },
      { name: 'empfangsbestaetigung', value: empfangsbestaetigung },
      { name: 'erstellungsDatum', value: erstellungsDatum },
      { name: 'dateien' }
    ];
    return (
      <TableRow>
        {values.map((value, key) => {
          if (value.name === 'dateien') {
            return (
              <TableCell align="center" key={`warenannahme_status_ausgewaehlteSendung_TableCell_${key}`}>
                <ButtonGroup>
                  <Button onClick={(event) => showFilesDialogOnClick(event, id, 'beschaedigungsfotos')} className="button__small" variant="outlined" color="primary">
                    {language('BUTTON', 'TITEL', 'BESCHAEDIGUNGSFOTOS', objGlobalState.lang)}
                  </Button>
                  <Button onClick={(event) => showFilesDialogOnClick(event, id, 'begleitpapiere')} className="button__small" variant="outlined" color="primary">
                    {language('BUTTON', 'TITEL', 'BEGLEITPAPIERE', objGlobalState.lang)}
                  </Button>
                </ButtonGroup>
              </TableCell>
            );
          } else {
            return (
              <TableCell align="center" key={`warenannahme_status_sendungen_TableCell_${key}`} className="table__rowTitle">
                <Typography className="typography__singleTableRowContent">{displayArraysInTable(value.value)}</Typography>
              </TableCell>
            );
          }
        })}
      </TableRow>
    );
  };

  // function ->
  const showAusgewaehlteArtikel = () => {
    const { id, pzn, hersteller, herstellerNummer, bezeichnung, artikelnummer } = ausgewaehlteArtikelState.artikel;
    const values = [id, pzn, hersteller, herstellerNummer, bezeichnung, artikelnummer];
    return (
      <TableRow>
        {values.map((value, key) => (
          <TableCell align="center" key={`artikelverwaltung_transporteinheiten_ausgewaehlteArtikel_tableCell_${key}`}>
            <Typography className="typography__singleTableRowContent">{value}</Typography>
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const photosSectionTitle = 'STELLPLATZFOTOS';
  const photosSection = { sectionTitle: photosSectionTitle, files: filesState };
  const confirmationGallery = [photosSection];

  const objEinlagerung = {
    artikeltabelle: {
      setAusgewaehlteArtikelState
    },
    confirmation: {
      closeConfirmationOnClick,
      confirmationDialogState,
      confirmed,
      confirmationGallery,
      newDataValueState
    },
    lieferungtabelle: {
      setAusgewaehlteSendungState,
      rerunEffectState
    },
    multipleScannerWithSelectStellplatz: {
      freiStellplaetze: true,
      setSelectedStellplaetzeState
    },
    showFiles: { showFilesDialogState, setShowFilesDialogState },
    uploadFiles: {
      uploadFilesDialogState,
      setUploadFilesDialogState,
      filesState,
      setFilesState
    }
  };

  return (
    <>
      {uploadFilesDialogState.open && <CoUploadFiles objGlobalState={objGlobalState} objParentState={objEinlagerung.uploadFiles} />}
      {confirmationDialogState && <CoConfirmation objGlobalState={objGlobalState} objParentState={objEinlagerung.confirmation} />}
      {showFilesDialogState.open && <CoShowFiles objGlobalState={objGlobalState} objParentState={objEinlagerung.showFiles} />}
      <Grid item xs={12} className="marginThirty">
        <Grid item xs={12}>
          <CoLieferungtabelle objGlobalState={objGlobalState} objParentState={objEinlagerung.lieferungtabelle} options={{ heightFourty: true, noBorderTop: true, selectLieferung: true }} />
        </Grid>
        <Grid item xs={12} className="marginThirty">
          <Typography className="typography__sectionTitle">{language('ABTEILUNG', 'TITEL', 'SENDUNG', objGlobalState.lang)}</Typography>
        </Grid>
        <Grid item xs={12} className="marginTwenty">
          {ausgewaehlteSendungState.chosen ? (
            <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} className="table__container">
              <Table size="medium">
                <TableBody>{showAusgewaehlteSendung()}</TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography className="typography__colorDamage">{language('KONTENT', 'TEXT', 'KEINESENDUNGAUSGEWAEHLT', objGlobalState.lang)}</Typography>
          )}
        </Grid>

        <Grid item xs={12} className="marginEighty">
          <CoArtikeltabelle objGlobalState={objGlobalState} objParentState={objEinlagerung.artikeltabelle} options={{ heightFourty: true, selectArtikel: true }} />
        </Grid>
        <Grid item xs={12} className="marginThirty">
          <Typography className="typography__sectionTitle">{language('ABTEILUNG', 'TITEL', 'ARTIKEL', objGlobalState.lang)}</Typography>
        </Grid>
        <Grid item xs={12} className="marginTwenty">
          {ausgewaehlteArtikelState.chosen ? (
            <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} className="table__container">
              <Table size="medium">
                <TableBody>{showAusgewaehlteArtikel()}</TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography className="typography__colorDamage">{language('KONTENT', 'TEXT', 'KEINEARTIKELAUSGEWAEHLT', objGlobalState.lang)}</Typography>
          )}
        </Grid>

        <form name="submitForm" noValidate autoComplete="on" onSubmit={submitFormOnClick}>
          <Grid container spacing={3} justify="flex-start" alignContent="center" alignItems="center" className="marginThirty">
            <CoMultipleScannerWithSelectStellplatz objGlobalState={objGlobalState} objParentState={objEinlagerung.multipleScannerWithSelectStellplatz} />
            <Grid item xs={12} sm={6} md={3}>
              <TextField fullWidth variant="outlined" name="charge" label={language('FORM', 'LABEL', 'CHARGE', objGlobalState.lang)} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField fullWidth variant="outlined" name="seriennummer" label={language('FORM', 'LABEL', 'SERIENNUMMER', objGlobalState.lang)} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField fullWidth variant="outlined" name="verfallsdatum" type="date" InputLabelProps={{ shrink: true }} label={language('FORM', 'LABEL', 'VERFALLSDATUM', objGlobalState.lang)} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField fullWidth variant="outlined" name="menge" type="number" label={language('FORM', 'LABEL', 'MENGE', objGlobalState.lang)} />
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth type="submit" color="primary" className="button__middle" variant="contained" color="primary">
                {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
};

export default CoEinlagerungWithTransporteinheit;
