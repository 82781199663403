import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import language from '../Common/language/language';
import { mouseScrollHorizontally } from '../Common/helperFunctions/mouseScrollHorizontally';
import { displayArraysInTable } from '../Common/helperFunctions/displayArrayInTable';
import { createAxiosInstance, getAndCostumFiles } from '../Common/helperFunctions/axios';
import { generateDate } from '../Common/helperFunctions/date';
import { useSnackbar } from 'notistack';

// import -> services
import ServiceBreadcrumbs from '../Common/services/Breadcrumbs/ServiceBreadcrumbs';

// import -> material ui -> core && icons
import {
  ButtonGroup,
  Container,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  TableHead,
  Tabs,
  Tab
} from '@material-ui/core';
import { ExpandMore, Search } from '@material-ui/icons';

// import -> components
import CoPaginationTableFooter from '../Common/Components/CoPaginationTableFooter';
import CoShowFiles from '../Common/Components/CoShowFiles';
import CoWarenannahme from './Append/CoWarenannahme';
import CoWareneingang from './Append/CoWareneingang';
import CoWarenausgang from './Append/CoWarenausgang';
import CoVersand from './Append/CoVersand';

const TABELLEKATEGORIEN = {
  LIEFERUNGAUFTRAG: 'LIEFERUNGAUFTRAG',
  AVISIERUNG: 'AVISIERUNG'
};

const VORGAENGE = {
  WARENANNAHME: 'warenannahme',
  WARENEINGANG: 'wareneingang',
  WARENAUSGANG: 'warenausgang',
  VERSAND: 'versand'
};

const INITIAL_ELEMENTS_PER_PAGE = 5;

// component -> React-root
const CoHistorie = ({ objGlobalState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const [showFilesDialogState, setShowFilesDialogState] = useState({ open: false, title: '', files: [], scan: false });
  const [tabellekategorieState, setTabellekatorieState] = useState(TABELLEKATEGORIEN.LIEFERUNGAUFTRAG);
  const [lieferungAuftraegeHistorieState, setLieferungAuftraegeHistorieState] = useState([]);
  const [avisierungHistorieState, setAvisierungHistorieState] = useState([]);
  const [vorgaengeDialogState, setVorgaengeDialogState] = useState({
    warenannahme: { open: false, data: {} },
    wareneingang: { open: false, data: {} },
    warenausgang: { open: false, data: {} },
    versand: { open: false, data: {} }
  });

  const [suchStringState, setSuchSringState] = useState('');
  const [elementsPerPageState, setElementsPerPageState] = useState(INITIAL_ELEMENTS_PER_PAGE);
  const [numOfPagesState, setNumOfPagesState] = useState(1);
  const [currentPageState, setCurrentPageState] = useState(1);
  const tableRef = useRef();
  const tableLieferungAuftraegeHeaders = ['SPEDITION', 'DATUM', 'AUFTRAGSNUMMER', 'WARENANNAHME', 'WARENEINGANG', 'WARENAUSGANG', 'VERSAND'];
  const tableAvisierungHeaders = ['LIEFERANT', 'SPEDITION', 'DATUM', 'DATEIEN'];
  const tableHeaders = tabellekategorieState === TABELLEKATEGORIEN.LIEFERUNGAUFTRAG ? tableLieferungAuftraegeHeaders : tableAvisierungHeaders;

  // function ->
  const openVorgangDialogOnClick = (vorgangname, lieferungAuftragId) => {
    objGlobalState.setPageLoadingState(true);

    const requestVorgangname = vorgangname === 'wareneingang' ? 'wareneinlagerung' : vorgangname === 'versand' ? 'warenversand' : vorgangname;

    authAxios
      .get(`/historie/${lieferungAuftragId}/${requestVorgangname}`)
      .then((response) => {
        const vorgangsdaten = response.data;
        setVorgaengeDialogState((pre) => ({
          ...pre,
          [vorgangname]: { open: true, data: vorgangsdaten }
        }));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const closeVorgaengeDialogOnClose = (vorgangname) => {
    setVorgaengeDialogState((pre) => ({
      ...pre,
      [vorgangname]: { open: false, data: {} }
    }));
  };

  // function ->
  const handleTabellekategorieOnChange = (event, tabValue) => {
    setTabellekatorieState(tabValue);
    setCurrentPageState(1);
    setElementsPerPageState(INITIAL_ELEMENTS_PER_PAGE);
  };

  // function ->
  const handleExeptionWithOneSetOfFiles = (key, header, data, filesDialogTitle, lastTableCells) => {
    return (
      <TableRow key={key}>
        <TableCell component="th" scope="row" className={`table__rowTitle coHistorie__dialogTableRowTitle ${lastTableCells ? 'coHistorie__lastTableCell' : ''}`}>
          <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', header, objGlobalState.lang)}</Typography>
        </TableCell>
        <TableCell align="left" className={lastTableCells ? 'coHistorie__lastTableCell' : ''}>
          <Button onClick={() => showFilesDialogOnClick(filesDialogTitle, data)} className="button__small" variant="outlined" color="primary">
            {language('BUTTON', 'TITEL', 'ANSEHEN', objGlobalState.lang)}
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  // function ->
  const createTable = ({ tableId, ref, title, headers, data, exeptions }) => {
    return (
      <Accordion className="accordion">
        <AccordionSummary expandIcon={<ExpandMore />} className="accordion__summary">
          <Typography align="center" className="typography__accordionSummary">
            {language('TABLE', 'HEADER', title, objGlobalState.lang)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer ref={ref} variant="outlined" elevation={0} className="coHistorie__tableContainer">
            <Table size="medium">
              <TableBody>
                {headers.map((header, key) => {
                  const lastTableCells = key === headers.length - 1;
                  const keyInData = header.toLowerCase();
                  const matchingData = data[keyInData];
                  const exeption = exeptions.find((exeption) => exeption.name === header);
                  if (exeption) {
                    return exeption.handler(`lagerverwaltung_historie_${tableId}_${key}`, header, data[keyInData], lastTableCells);
                  } else {
                    return (
                      <TableRow key={`lagerverwaltung_historie_${tableId}_${key}`}>
                        <TableCell component="th" scope="row" className={`table__rowTitle coHistorie__dialogTableRowTitle ${lastTableCells ? 'coHistorie__lastTableCell' : ''}`}>
                          <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', header, objGlobalState.lang)}</Typography>
                        </TableCell>
                        <TableCell align="left" className={lastTableCells ? 'coHistorie__lastTableCell' : ''}>
                          <Typography className="typography__singleTableRowContent">{displayArraysInTable(matchingData)}</Typography>
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    );
  };

  // // function ->
  // const handleSearchTextFieldOnChange = (event) => {
  //   const { value } = event.target;

  //   // filter historieList
  //   let currentHistorieState = [...lieferungAuftraegeHistorieState];
  //   currentHistorieState = currentHistorieState.filter((item) => {
  //     const smallLetterValue = value.toLowerCase().replace(/\s+/g, '');
  //     const smallLetterSendung = item.sendung.toLowerCase().replace(/\s+/g, '');
  //     const smallLetterSpedition = item.spedition.toLowerCase().replace(/\s+/g, '');
  //     const smallLetterAuftragsnummer = item.auftragsnummer.toLowerCase().replace(/\s+/g, '');
  //     const datum = item.datum.toLowerCase().replace(/\s+/g, '');
  //     let result = true;
  //     smallLetterValue.split('').forEach((char) => {
  //       const valueInSendung = smallLetterSendung.includes(smallLetterValue);
  //       const valueInSpedition = smallLetterSpedition.includes(smallLetterValue);
  //       const valueInAuftragsnummer = smallLetterAuftragsnummer.includes(smallLetterValue);
  //       const valueInDatum = datum.includes(smallLetterValue);
  //       if (!valueInSendung && !valueInSpedition && !valueInDatum && !valueInAuftragsnummer) {
  //         result = false;
  //       }
  //     });
  //     return result;
  //   });
  //   setDisplayLieferungAuftraegeHistorieState(currentHistorieState);
  // };

  // function ->
  const showFilesDialogOnClick = (title, data, scan) => {
    if (!data.length) {
      setShowFilesDialogState({ open: true, title: title, files: [], scan: false });
    }

    const filesArray = [];

    data.forEach((file) => {
      getAndCostumFiles({ file, filesArray, authAxios }).then(() => {
        setShowFilesDialogState({ open: true, title: title, files: filesArray, scan: scan ?? false });
      });
    });
  };

  // function ->
  const downloadFile = (url, name, type) => {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${name}.${type}`);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
  };

  // function ->
  const downloadFilesOnClick = (datasetId) => {
    authAxios
      .get(`/notification/findfiles/${datasetId}`)
      .then((response) => {
        const { data } = response;

        const files = [];

        data.forEach((file) => {
          getAndCostumFiles({ file: file, filesArray: files, authAxios: authAxios }).then((result) => {
            if (data.length === files.length) {
              files.forEach((_file) => {
                const dataUri = _file.dataUri;
                const name = _file.name;
                const type = _file.type;
                downloadFile(dataUri, name, type);
              });
            }
          });
        });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
      });
  };

  // function ->
  const handleFetchLieferungAuftragErfolg = (data) => {
    const totalElements = data.totalElements;
    const elementsPerPage = +elementsPerPageState <= 0 ? 1 : elementsPerPageState;
    const numOfPages = Math.ceil(totalElements / elementsPerPage);

    setNumOfPagesState(numOfPages);

    if (currentPageState > numOfPages) {
      setCurrentPageState(numOfPages);
      return;
    }

    const newLieferungAuftragState = data.content.map((item) => {
      const newItem = {
        spedition: item.spedtion ?? '',
        datum: item.auftragsDatum ? generateDate(item.auftragsDatum).justDate : '',
        auftragsnummer: item.auftragsNummer,
        warenannahme: item.warenannahme,
        wareneingang: item.wareneingang,
        warenausgang: item.warenausgang,
        versand: item.versand,
        auftragId: item.auftragId,
        lieferungId: item.lieferungId
      };
      return newItem;
    });
    setLieferungAuftraegeHistorieState(newLieferungAuftragState);
  };

  // function ->
  const fetchLieferungAuftrag = () => {
    objGlobalState.setPageLoadingState(true);

    const page = currentPageState - 1 <= 0 ? 0 : currentPageState - 1;
    const size = elementsPerPageState <= 0 ? 1 : elementsPerPageState;

    authAxios
      .get(`/historie?page=${page}&size=${size}${suchStringState}`)
      .then((response) => {
        const historiedaten = response.data;
        handleFetchLieferungAuftragErfolg(historiedaten);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const handleFetchAvisierungErfolg = (data) => {
    const totalElements = data.totalElements;
    const elementsPerPage = +elementsPerPageState <= 0 ? 1 : elementsPerPageState;
    const numOfPages = Math.ceil(totalElements / elementsPerPage);

    setNumOfPagesState(numOfPages);

    if (currentPageState > numOfPages) {
      setCurrentPageState(numOfPages);
      return;
    }

    const newAvisierungState = data.content.map((item) => {
      const newItem = {
        datum: item.date ? generateDate(item.date).justDate : '',
        dateien: item.fileEntity,
        id: item.id,
        spedition: item.shippingCompany,
        status: 1,
        lieferant: item.supplier
      };
      return newItem;
    });

    setAvisierungHistorieState(newAvisierungState);
  };

  // function ->
  const fetchAvisierung = () => {
    objGlobalState.setPageLoadingState(true);

    const page = currentPageState - 1 <= 0 ? 0 : currentPageState - 1;
    const size = elementsPerPageState <= 0 ? 1 : elementsPerPageState;

    authAxios
      .get(`/historie/avisierung?page=${page}&size=${size}`)
      .then((response) => {
        const historiedaten = response.data;
        handleFetchAvisierungErfolg(historiedaten);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const submitSearchFormOnSubmit = (event) => {
    event.preventDefault();

    const auftragsnummer = event.target.auftragsnummer.value;
    const auftragsdatumVon = event.target.auftragsdatumVon.value;
    const auftragsdatumBis = event.target.auftragsdatumBis.value;
    const lieferungsdatumVon = event.target.lieferungsdatumVon.value;
    const lieferungsdatumBis = event.target.lieferungsdatumBis.value;

    const suchString = `&auftragsnummer=${auftragsnummer}&auftragvon=${auftragsdatumVon}&lieferungvon=${lieferungsdatumVon}&auftragbis=${auftragsdatumBis}&lieferungbis=${lieferungsdatumBis}`;

    setSuchSringState(suchString);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      tabellekategorieState === TABELLEKATEGORIEN.LIEFERUNGAUFTRAG && fetchLieferungAuftrag();
      tabellekategorieState === TABELLEKATEGORIEN.AVISIERUNG && fetchAvisierung();
    }
    return () => (isMounted = false);
  }, [currentPageState, elementsPerPageState, tabellekategorieState, suchStringState]);

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, []);

  const objHistorieStateAndFunctions = {
    closeVorgaengeDialogOnClose,
    createTable,
    handleExeptionWithOneSetOfFiles,
    lieferungAuftraegeHistorieState,
    setLieferungAuftraegeHistorieState,
    showFilesDialogState,
    setShowFilesDialogState,
    showFilesDialogOnClick,
    VORGAENGE,
    vorgaengeDialogState,
    setVorgaengeDialogState,
    paginationTableFooter: {
      currentPageState,
      setCurrentPageState,
      setElementsPerPageState,
      numOfPagesState,
      setNumOfPagesState,
      firstCellColSpan: tabellekategorieState === TABELLEKATEGORIEN.LIEFERUNGAUFTRAG ? 3 : 2,
      secondCellColSpan: tabellekategorieState === TABELLEKATEGORIEN.LIEFERUNGAUFTRAG ? 2 : 1,
      thirdCellColSpan: tabellekategorieState === TABELLEKATEGORIEN.LIEFERUNGAUFTRAG ? 2 : 1
    }
  };

  // array -> create breadcrumbs
  const arrBreadcrumbs = [
    { url: '/', title: language('SEITE', 'TITEL', 'HOME', objGlobalState.lang) },
    { url: '/historie', title: language('SEITE', 'TITEL', 'HISTORIE', objGlobalState.lang) }
  ];

  return (
    <>
      {showFilesDialogState.open && <CoShowFiles objGlobalState={objGlobalState} objParentState={objHistorieStateAndFunctions} options={{ scan: showFilesDialogState.scan }} />}
      {vorgaengeDialogState.warenannahme.open && <CoWarenannahme objGlobalState={objGlobalState} objHistorieStateAndFunctions={objHistorieStateAndFunctions} />}
      {vorgaengeDialogState.wareneingang.open && <CoWareneingang objGlobalState={objGlobalState} objHistorieStateAndFunctions={objHistorieStateAndFunctions} />}
      {vorgaengeDialogState.warenausgang.open && <CoWarenausgang objGlobalState={objGlobalState} objHistorieStateAndFunctions={objHistorieStateAndFunctions} />}
      {vorgaengeDialogState.versand.open && <CoVersand objGlobalState={objGlobalState} objHistorieStateAndFunctions={objHistorieStateAndFunctions} />}
      <Container component="main" maxWidth="xl" className="container__container">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ServiceBreadcrumbs arrBreadcrumbs={arrBreadcrumbs} />
          </Grid>
          <Grid item xs={12}>
            <ListItem className="container__listItem">
              <ListItemIcon>
                <img src={`../assets/images/icons/icon_historie.png`} alt="" className="images__headerIcon" />
              </ListItemIcon>
              <ListItemText>
                <Typography className="typography__headerTitle">{language('SEITE', 'TITEL', 'HISTORIE', objGlobalState.lang)}</Typography>
              </ListItemText>
            </ListItem>
          </Grid>
          <Grid item xs={12} className="marginThirty">
            <Box className="table__title">
              <Tabs className="table__headerTabs" value={tabellekategorieState} indicatorColor="primary" textColor="primary" onChange={handleTabellekategorieOnChange}>
                <Tab value={TABELLEKATEGORIEN.LIEFERUNGAUFTRAG} label={language('TABLE', 'HEADER', 'LIEFERUNGAUFTRAG', objGlobalState.lang)} />
                <Tab value={TABELLEKATEGORIEN.AVISIERUNG} label={language('TABLE', 'HEADER', 'AVISIERUNG', objGlobalState.lang)} />
              </Tabs>
            </Box>
            <Box className="border__noBottom">
              <Accordion className="coHistorie__accordion">
                <AccordionSummary className="accordion__summary" expandIcon={<ExpandMore />}>
                  <Typography align="center" className="typography__accordionSummary">
                    {language('ABTEILUNG', 'TITEL', 'SUCHE', objGlobalState.lang)}
                  </Typography>
                  <Search className="icon__green marginLeft__10" />
                </AccordionSummary>
                <AccordionDetails className="display__block padding__bottom40">
                  <form onSubmit={submitSearchFormOnSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>
                        <TextField fullWidth variant="outlined" name="auftragsnummer" label={language('FORM', 'LABEL', 'AUFTRAGSNUMMER', objGlobalState.lang)} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                          type="date"
                          variant="outlined"
                          name="auftragsdatumVon"
                          label={language('FORM', 'LABEL', 'AUFTRAGSDATUMVON', objGlobalState.lang)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                          type="date"
                          variant="outlined"
                          name="auftragsdatumBis"
                          label={language('FORM', 'LABEL', 'AUFTRAGSDATUMBIS', objGlobalState.lang)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                          type="date"
                          variant="outlined"
                          name="lieferungsdatumVon"
                          label={language('FORM', 'LABEL', 'LIEFERUNGSDATUMVON', objGlobalState.lang)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                          type="date"
                          variant="outlined"
                          name="lieferungsdatumBis"
                          label={language('FORM', 'LABEL', 'LIEFERUNGSDATUMBIS', objGlobalState.lang)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Button fullWidth type="submit" className="button__small" variant="outlined" color="primary">
                          {language('BUTTON', 'TITEL', 'SUCHEN', objGlobalState.lang)}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </AccordionDetails>
              </Accordion>
            </Box>
            <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className="table__container border__noTop">
              <Table size="medium" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((header, key) => {
                      return (
                        <TableCell align="center" component="th" className="table__headerCell" key={`historie_tableHeaders_${key}`}>
                          <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', header, objGlobalState.lang)}</Typography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tabellekategorieState === TABELLEKATEGORIEN.LIEFERUNGAUFTRAG
                    ? lieferungAuftraegeHistorieState.map((item, key) => {
                        // destructuring the needed data to display
                        const { spedition, datum, auftragsnummer, warenannahme, wareneingang, warenausgang, versand, auftragId, lieferungId } = item;
                        return (
                          <TableRow key={`historie_liefeungauftrag_tableRow_${key}`}>
                            <TableCell align="center">
                              <Typography className="typography__singleTableRowContent">{spedition}</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography className="typography__singleTableRowContent">{datum}</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography className="typography__singleTableRowContent">{auftragsnummer}</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                disabled={!warenannahme}
                                onClick={() => openVorgangDialogOnClick(VORGAENGE.WARENANNAHME, lieferungId)}
                                className="button__small"
                                variant="outlined"
                                color="primary"
                              >
                                {language('BUTTON', 'TITEL', 'WARENANNAHME', objGlobalState.lang)}
                              </Button>
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                disabled={!wareneingang}
                                onClick={() => openVorgangDialogOnClick(VORGAENGE.WARENEINGANG, lieferungId)}
                                className="button__small"
                                variant="outlined"
                                color="primary"
                              >
                                {language('BUTTON', 'TITEL', 'WARENEINGANG', objGlobalState.lang)}
                              </Button>
                            </TableCell>
                            <TableCell align="center">
                              <Button disabled={!warenausgang} onClick={() => openVorgangDialogOnClick(VORGAENGE.WARENAUSGANG, auftragId)} className="button__small" variant="outlined" color="primary">
                                {language('BUTTON', 'TITEL', 'WARENAUSGANG', objGlobalState.lang)}
                              </Button>
                            </TableCell>
                            <TableCell align="center">
                              <Button disabled={!versand} onClick={() => openVorgangDialogOnClick(VORGAENGE.VERSAND, auftragId)} className="button__small" variant="outlined" color="primary">
                                {language('BUTTON', 'TITEL', 'VERSAND', objGlobalState.lang)}
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : avisierungHistorieState.map((item, key) => {
                        const { lieferant, spedition, datum, dateien, id } = item;
                        return (
                          <TableRow key={`historie_avisierung_tableRow_${key}`}>
                            <TableCell align="center">
                              <Typography className="typography__singleTableRowContent">{lieferant}</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography className="typography__singleTableRowContent">{spedition}</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography className="typography__singleTableRowContent">{datum}</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <ButtonGroup aria-label="outlined primary button group">
                                <Button onClick={() => showFilesDialogOnClick(null, dateien)} className="button__small" variant="outlined" color="primary">
                                  {language('BUTTON', 'TITEL', 'ANSEHEN', objGlobalState.lang)}
                                </Button>
                                <Button onClick={() => downloadFilesOnClick(id)} className="button__small" variant="outlined" color="primary">
                                  {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
                                </Button>
                              </ButtonGroup>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
                <CoPaginationTableFooter objParent={objHistorieStateAndFunctions.paginationTableFooter} objGlobalState={objGlobalState} />
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CoHistorie;
