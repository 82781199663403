import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PasswordValidator from 'password-validator';
import language from '../Common/language/language';
import axios from 'axios';
import { changeLanguage } from '../Common/helperFunctions/changeLanguage';

// import -> material ui -> core
import { Button, Container, Grid, IconButton, Paper, TextField, Typography } from '@material-ui/core';
import { Language } from '@material-ui/icons';

const QUERYTOKEN = 'token';

// component -> React-root
const CoPassworteingabe = ({ objGlobalState }) => {
  const history = useHistory();
  const location = useLocation();

  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const query = useQuery();

  const { enqueueSnackbar } = useSnackbar();

  const [textFieldState, setTextFieldState] = useState({
    password: { error: false },
    passwordRepeat: { error: false }
  });

  const [errorsState, setErrorsState] = useState({
    passwordInvalid: false,
    passwordsNotIdentical: false
  });

  // function ->
  const checkIfTextFieldIsEmpty = (valueForCheck) => {
    //  statement -> checks if valueForCheck is not empty
    return valueForCheck ? false : true;
  };

  // function ->
  const getRidOfErrorMessages = (textField) => {
    switch (textField) {
      case 'password':
        setErrorsState({
          ...errorsState,
          passwordInvalid: false
        });
        break;
      case 'passwordRepeat':
        setErrorsState({
          ...errorsState,
          passwordsNotIdentical: false
        });
        break;
      default:
        return;
    }
  };

  // function ->
  const fetchTextFieldChange = (event, textField) => {
    // useState -> show error message depending on return boolean of checkIfTextFieldIsEmpty
    setTextFieldState({
      ...textFieldState,
      [textField]: { error: checkIfTextFieldIsEmpty(event.target.value) }
    });

    // function -> when the user write in a text field, then take away the error message
    getRidOfErrorMessages(textField);
  };

  // function ->
  const checkIfPasswordsAreNotIdentical = (passwordNrOne, passwordNrTwo) => {
    return passwordNrOne !== passwordNrTwo;
  };

  // function ->
  const checkIfPasswordIsInvalid = (password) => {
    const validator = new PasswordValidator();
    const minLength = 8;
    const maxLength = 20;
    validator.is().min(minLength).is().max(maxLength).has().lowercase().has().uppercase().has().digits().has().symbols();
    return !validator.validate(password);
  };

  // function ->
  const checkAndHandleInputErrors = (password, passwordRepeat) => {
    const isPasswordTextFieldEmpty = checkIfTextFieldIsEmpty(password);
    const isPasswordRepeatTextFieldEmpty = checkIfTextFieldIsEmpty(passwordRepeat);

    const isPasswordInvalid = checkIfPasswordIsInvalid(password);
    const arePasswordsNotIdentical = checkIfPasswordsAreNotIdentical(password, passwordRepeat);

    if (isPasswordTextFieldEmpty || isPasswordRepeatTextFieldEmpty || isPasswordInvalid || arePasswordsNotIdentical) {
      // useState -> show error message depending on return boolean of checkIfTextFieldIsEmpty

      setTextFieldState({
        ...textFieldState,
        password: { error: isPasswordTextFieldEmpty },
        passwordRepeat: { error: isPasswordRepeatTextFieldEmpty }
      });

      setErrorsState({
        ...errorsState,
        passwordInvalid: isPasswordInvalid,
        passwordsNotIdentical: arePasswordsNotIdentical
      });
      return true;
    }
    return false;
  };

  // function ->
  const postInformationsToAPI = (password, passwordRepeat) => {
    objGlobalState.setPageLoadingState(true);

    const token = query.get(QUERYTOKEN);

    const requestValues = {
      newPassword: password,
      confirmNewPassword: passwordRepeat,
      token: token
    };

    axios
      .post(`${process.env.REACT_APP_API_HOST}/user/reset-password`, requestValues)
      .then(() => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'PASSWORTZURUECKGESETZT', objGlobalState.lang), {
          variant: 'success'
        });
        sendToSignIn();
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), {
          variant: 'error'
        });
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const submitFormOnClick = (event) => {
    event.preventDefault();

    const password = event.target.password.value;
    const passwordRepeat = event.target.passwordRepeat.value;

    if (checkAndHandleInputErrors(password, passwordRepeat)) {
      return;
    }
    postInformationsToAPI(password, passwordRepeat);
  };

  // function ->
  const sendToSignIn = () => {
    history.push('/signin', { from: location });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper variant="outlined" square className="container__anmeldungscontainer">
        <form name="submitForm" noValidate className="form__anmeldungsform" autoComplete="off" onSubmit={submitFormOnClick}>
          <Grid container spacing={3}>
            <Grid item xs={12} container spacing={3}>
              <Grid item xs={12}>
                <IconButton color="inherit" onClick={() => changeLanguage(objGlobalState)} title={language('BUTTON', 'TITEL', 'SPRACHEAENDERN', objGlobalState.lang)}>
                  <Language />
                  <Typography>{objGlobalState.lang === 'DE' ? 'EN' : 'DE'}</Typography>
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Typography align="center" className="typography__formTitle">
                  {language('FORM', 'TITEL', 'NEUESPASSWORT', objGlobalState.lang)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center">{language('KONTENT', 'TEXT', 'GEBENSIEPASSWORT', objGlobalState.lang)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                variant="outlined"
                name="password"
                label={language('FORM', 'LABEL', 'PASSWORT', objGlobalState.lang)}
                error={textFieldState.password.error || errorsState.passwordInvalid}
                helperText={
                  textFieldState.password.error
                    ? language('FORM', 'ERROR', 'LEERESTEXTFELD', objGlobalState.lang)
                    : errorsState.passwordInvalid
                    ? language('FORM', 'ERROR', 'PASSWORTNICHTGUELTIG', objGlobalState.lang)
                    : ''
                }
                onChange={(event) => fetchTextFieldChange(event, 'password')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                variant="outlined"
                name="passwordRepeat"
                label={language('FORM', 'LABEL', 'PASSWORTWIEDERHOLUNG', objGlobalState.lang)}
                error={textFieldState.passwordRepeat.error || errorsState.passwordsNotIdentical}
                helperText={
                  textFieldState.passwordRepeat.error
                    ? language('FORM', 'ERROR', 'LEERESTEXTFELD', objGlobalState.lang)
                    : errorsState.passwordsNotIdentical
                    ? language('FORM', 'ERROR', 'PASSWOERTERNICHTIDENTISCH', objGlobalState.lang)
                    : ''
                }
                onChange={(event) => fetchTextFieldChange(event, 'passwordRepeat')}
              />
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant="contained" type="submit" color="primary" className="button">
                {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default CoPassworteingabe;
