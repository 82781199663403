import { CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { handleBeforeUnload } from '../../Common/eventHanlders/eventHandlers';
import { createAxiosInstance } from '../../Common/helperFunctions/axios';
import { checkIfEmailIsInvalid } from '../../Common/helperFunctions/validator';
import LPH from './CoHeader.module.scss';

const CoHeader = ({ kostenlosTestenModalState, setKostenlosTestenModalState, objGlobalState }) => {
  const navigationRef = useRef();
  const kostenlosTestenLinkRef1 = useRef();
  const kostenlosTestenLinkRef2 = useRef();
  const kostenlosTestenModalCloserRef = useRef();
  const sidebarOpenerRef = useRef();
  const sidebarCloserRef = useRef();
  const sidebarRef = useRef();
  const pageLinksContainersSideRef = useRef();
  const pageLinksContainersTopRef = useRef();

  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const { enqueueSnackbar } = useSnackbar();

  const [emailInvalidState, setEmailInvalidState] = useState(false);
  const [pageLoadingState, setPageLoadingState] = useState(false);

  // function ->
  const handleEmailOnChange = (event) => {
    if (!emailInvalidState) {
      return;
    }

    const isValidAfterChange = !checkIfEmailIsInvalid(event.target.value);
    if (isValidAfterChange) {
      setEmailInvalidState(false);
    }
  };

  // function ->
  const submitForm = (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const paket = kostenlosTestenModalState.paket;
    const isEmailValid = !checkIfEmailIsInvalid(email);
    if (!isEmailValid) {
      setEmailInvalidState(true);
      return;
    }

    const requestBody = {
      email,
      paket
    };

    setPageLoadingState(true);
    authAxios
      .post('/landing-page/send-mail', requestBody)
      .then(() => {
        enqueueSnackbar('Deine E-Mail wurde erfolgreich gesendet.', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar('Ein Fehler ist aufgetreten.', { variant: 'error' });
      })
      .finally(() => {
        setPageLoadingState(false);
      });
  };

  const history = useHistory();

  const openOrCloseSidebarOnClick = (trigger) => {
    const classListMethod = trigger ? 'add' : 'remove';
    navigationRef.current?.classList[classListMethod](LPH['navigation--sidebarOpen']);
  };

  const handleSidebarOpenerClick = () => {
    openOrCloseSidebarOnClick(true);
    preventScrollingForSidebar();
  };

  const handleSidebarCloserClick = () => {
    openOrCloseSidebarOnClick(false);
    allowScrollingForSidebar();
  };

  const handlePageLinkOnClick = (event) => {
    const { href } = window.location;
    const dataName = event.target.dataset.name;

    const isSidebarOpen = navigationRef.current?.classList.contains(LPH['navigation--sidebarOpen']);

    if (isSidebarOpen) {
      openOrCloseSidebarOnClick(false);
      allowScrollingForSidebar();
    }

    if (href.includes('datenschutz') || href.includes('agb') || href.includes('impressum')) {
      history.push('/');
      const elTargetContainer = document.querySelector(`[data-type="linked-target"][data-name="${dataName}"]`);
      setTimeout(() => {
        window.scroll({
          top: elTargetContainer.offsetTop,
          behavior: 'smooth'
        });
      }, 500);
      return;
    }
    const elTargetContainer = document.querySelector(`[data-type="linked-target"][data-name="${dataName}"]`);

    (!isSidebarOpen &&
      window.scroll({
        top: elTargetContainer.offsetTop,
        behavior: 'smooth'
      })) ||
      (isSidebarOpen &&
        setTimeout(() => {
          window.scroll({
            top: elTargetContainer.offsetTop,
            behavior: 'smooth'
          });
        }, 500));
  };

  const preventScrollingForSidebar = () => {
    document.body.style.position = 'fixed';
    document.body.style.width = `100%`;
  };

  const allowScrollingForSidebar = () => {
    setTimeout(() => {
      document.body.style.position = '';
      document.body.style.width = `auto`;
    }, 500);
  };

  const allowScrollingForKostenlosModal = () => {
    setTimeout(() => {
      document.documentElement.classList.remove('noScroll');
    }, 300);
  };

  const preventScrollingForKostenlosModal = () => {
    document.documentElement.classList.add('noScroll');
  };

  const openKostenlosTestenModal = () => {
    openOrCloseSidebarOnClick(false);
    allowScrollingForSidebar();
    preventScrollingForKostenlosModal();
    setKostenlosTestenModalState({ open: true, paket: 'Kein Paket ausgewählt' });
  };

  const handleKostenlosTestenModalClose = () => {
    setKostenlosTestenModalState({ open: false, paket: '' });
    allowScrollingForKostenlosModal();
  };

  const pagesLinks = [
    { label: 'Funktionen', dataName: 'funktionen' },
    { label: 'Preise', dataName: 'preise' },
    { label: 'Kontakt', dataName: 'kontakt' }
  ];

  const createPageLinks = (name) => {
    return pagesLinks.map((pageLink, key) => (
      <div key={`landingPage-header_pageLinks_${name}${key}`}>
        <a data-name={pageLink.dataName} data-type="links">
          {pageLink.label}
        </a>
      </div>
    ));
  };

  const addEventListeners = () => {
    kostenlosTestenModalCloserRef.current?.addEventListener('click', handleKostenlosTestenModalClose);
    sidebarOpenerRef.current?.addEventListener('click', handleSidebarOpenerClick);
    sidebarCloserRef.current?.addEventListener('click', handleSidebarCloserClick);

    [pageLinksContainersSideRef.current, pageLinksContainersTopRef.current].forEach((child) => {
      if (child) {
        [...child.children].forEach((_child) => {
          if (_child) {
            _child.firstChild.addEventListener('click', handlePageLinkOnClick);
          }
        });
      }
    });
    [kostenlosTestenLinkRef1.current, kostenlosTestenLinkRef2.current].forEach((kostenlosTestenLink) => {
      kostenlosTestenLink.addEventListener('click', openKostenlosTestenModal);
    });
  };

  const hideNavigation = () => {
    objGlobalState.setHideNavigationState(true);
  };

  const handleLinkToHomeOnClick = () => {
    allowScrollingForSidebar();
    history.push('/home');
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;
    window.removeEventListener('beforeunload', handleBeforeUnload);
    addEventListeners();
    hideNavigation();

    return () => (isMounted = false);
  }, []);

  const goToPage = (pathname) => {
    handleKostenlosTestenModalClose();
    history.push(pathname);
  };

  return (
    <header className={LPH.header}>
      <section className={`${LPH.modal__kostenlosTesten} ${kostenlosTestenModalState.open ? LPH['modal__kostenlosTesten--open'] : ''}`}>
        <div className={LPH.modal__kostenlosTestenContainer}>
          <div className={LPH.modal__kostenlosTestenContainerCloser} ref={kostenlosTestenModalCloserRef}>
            <div className={LPH.divContainer}>
              <div className={LPH.div1}>
                <div className={LPH.div2}></div>
              </div>
            </div>
          </div>
          <div className={LPH.header}>
            <div className={LPH.modal__kostenlosTestenLogo}>
              <Link onClick={() => goToPage('/')} to="/">
                <img src={`${process.env.REACT_APP_HOST}/logo_green.png`} width="200" alt="logo" />
              </Link>
            </div>
          </div>
          <div className={LPH.content}>
            <div className={LPH.contentContainer}>
              <div className={LPH.top}>
                <h2>Danke für Ihr Interesse. Wir melden uns kurzfristig bei Ihnen.</h2>
              </div>
              <div className={LPH.middle}>
                <form onSubmit={submitForm} noValidate>
                  <div className={LPH.inputContainer}>
                    <CircularProgress className={`${LPH.circularProgress} ${pageLoadingState ? LPH['circularProgress--active'] : ''}`} />
                    <input
                      className={emailInvalidState ? LPH['kostenlosTestenEmail--invalid'] : ''}
                      onChange={handleEmailOnChange}
                      type="email"
                      size="40"
                      name="email"
                      aria-label="email"
                      aria-required="true"
                      aria-invalid="false"
                      placeholder="E-Mail-Adresse eingeben"
                    />
                    <span className={`${LPH.kostenlosTestenErrorInputMessage} ${emailInvalidState ? LPH['kostenlosTestenErrorInputMessage--active'] : ''}`}>
                      <span>Bitte gib eine gültige E-Mail-Adresse ein</span>
                    </span>
                    <div className={LPH.kostenlosTestenLink}>
                      <button type="submit">ABSENDEN</button>
                    </div>
                  </div>
                </form>
              </div>
              <div className={LPH.bottom}>
                <p>
                  Mit der Anmeldung akzeptierst du die
                  <Link to="/agb" onClick={() => goToPage('/agb')}>
                    AGB
                  </Link>{' '}
                  und die
                  <Link to="/datenschutzerklaerung" onClick={() => goToPage('/datenschutzerklaerung')}>
                    Datenschutzerklärung
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={LPH.header__container}>
        <nav className={LPH.navigation} ref={navigationRef}>
          <div className={LPH.navigation__topbar}>
            <div className={LPH.navigation__logo}>
              <Link to="/" onClick={() => goToPage('/')}>
                <img src={`${process.env.REACT_APP_HOST}/logo_green.png`} alt="logo" width="160" />
              </Link>
            </div>
            <div className={LPH.navigation__pageLinks} ref={pageLinksContainersTopRef}>
              {createPageLinks('top')}
            </div>
            <div className={LPH.navigation__appLinks}>
              <div className={LPH.navigation__openSidebar} ref={sidebarOpenerRef}>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className={LPH.navigation__loginLink}>
                <Link to="/home">LOGIN</Link>
              </div>
              <div className={LPH.navigation__kostenlosTestenLink}>
                <button ref={kostenlosTestenLinkRef1}>KOSTENLOS TESTEN</button>
              </div>
            </div>
          </div>
          <div className={LPH.navigation__sidebar} ref={sidebarRef}>
            <div className={LPH.sidebar__top}>
              <div className={LPH.navigation__logo}>
                <Link to="/" onClick={() => goToPage('/')}>
                  <img src={`${process.env.REACT_APP_HOST}/logo_green.png`} alt="logo" width="130" />
                </Link>
              </div>
              <div className={LPH.navigation__closeSidebar} ref={sidebarCloserRef}>
                <div className={LPH.divContainer}>
                  <div className={LPH.div1}>
                    <div className={LPH.div2}></div>
                  </div>
                </div>
              </div>
            </div>
            <div className={LPH.sidebar__middle}>
              <div className={LPH.navigation__pageLinks} ref={pageLinksContainersSideRef}>
                {createPageLinks('side')}
              </div>
            </div>
            <div className={LPH.sidebar__bottom}>
              <div className={LPH.navigation__appLinks}>
                <div className={LPH.navigation__loginLink}>
                  <Link to="/home" onClick={handleLinkToHomeOnClick}>
                    LOGIN
                  </Link>
                </div>
                <div className={LPH.navigation__kostenlosTestenLink}>
                  <button ref={kostenlosTestenLinkRef2}>KOSTENLOS TESTEN</button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </section>
    </header>
  );
};

export default CoHeader;
