import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import language from '../../Common/language/language';
import { mouseScrollHorizontally } from '../../Common/helperFunctions/mouseScrollHorizontally';
import { generateDate } from '../../Common/helperFunctions/date';
import { isObjectEmpty } from '../../Common/helperFunctions/objects';

// import -> material ui -> core & icons
import { Dialog, DialogContent, DialogTitle, Grid, TableRow, TableCell, Typography, IconButton, Button, ButtonGroup } from '@material-ui/core';
import { Close } from '@material-ui/icons';

// component -> React-root
const CoVersand = ({ objGlobalState, objHistorieStateAndFunctions }) => {
  const { open, data } = objHistorieStateAndFunctions.vorgaengeDialogState.versand;

  const [dialogIsRenderedState, setDialogIsRenderedState] = useState(false);
  const [lieferungState, setLieferungState] = useState({});
  const [avisierungState, setAvisierungState] = useState({});

  const lieferungTable = {
    tableId: 'versand_lieferung',
    ref: useRef(),
    title: 'LIEFERUNG',
    data: lieferungState,
    headers: ['TRACKINGNUMMER', 'SENDUNGSART', 'ANZAHLDERPAKETE', 'ANZAHLDERPAKETEPROPAKETSENDUNG', 'ANZAHLDERPALETTEN', 'ANZAHLDERPAKETEPROPALETTE', 'VERSANDBESTAETIGUNG', 'DATEIEN'],
    exeptions: [
      {
        name: 'DATEIEN',
        handler: (key, header, data, lastTableCells) => {
          return (
            <TableRow key={key}>
              <TableCell component="th" scope="row" className={`table__rowTitle coHistorie__dialogTableRowTitle ${lastTableCells ? 'coHistorie__lastTableCell' : ''}`}>
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', header, objGlobalState.lang)}</Typography>
              </TableCell>
              <TableCell align="left" className={lastTableCells ? 'coHistorie__lastTableCell' : ''}>
                <ButtonGroup orientation="vertical">
                  <Button onClick={() => objHistorieStateAndFunctions.showFilesDialogOnClick('LABEL', data.label, true)} className="button__small" variant="outlined" color="primary">
                    {language('BUTTON', 'TITEL', 'LABEL', objGlobalState.lang)}
                  </Button>
                  <Button
                    onClick={() => objHistorieStateAndFunctions.showFilesDialogOnClick('VERSANDBESTAETIGUNG', data.versandbestaetigung)}
                    className="button__small"
                    variant="outlined"
                    color="primary"
                  >
                    {language('BUTTON', 'TITEL', 'VERSANDBESTAETIGUNG', objGlobalState.lang)}
                  </Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          );
        }
      }
    ]
  };

  const avisierungTable = {
    tableId: 'versand_avisierung',
    ref: useRef(),
    title: 'AVISIERUNG',
    data: avisierungState,
    headers: ['KUNDE', 'SPEDITION', 'VERSANDDATUM', 'EMAIL', 'EMAILANHANG'],
    exeptions: [
      {
        name: 'EMAILANHANG',
        handler: (key, header, data, lastTableCells) => {
          return objHistorieStateAndFunctions.handleExeptionWithOneSetOfFiles(key, header, data, undefined, lastTableCells);
        }
      }
    ]
  };

  // function ->
  const handleDialogOnRendered = () => {
    setDialogIsRenderedState(true);
  };

  // function ->
  const mapParentDataToVersand = () => {
    const { versand: parentLieferung, avisierung: parentAvisierung } = data;

    const sendungsart =
      parentLieferung?.anzahlPaletten !== null
        ? language('KONTENT', 'TEXT', 'PALETTENSENDUNG', objGlobalState.lang)
        : parentLieferung?.anzahlPakete !== null
        ? language('KONTENT', 'TEXT', 'PAKETSENDUNG', objGlobalState.lang)
        : '';

    const confirmations = [
      {
        name: 'ELEKTRONISCH',
        id: 1
      },
      {
        name: 'BEGLEITPAPIERE',
        id: 2
      }
    ];

    const foundConfirmation = confirmations.find((element) => element.name === parentLieferung.bestaetigungsart);
    const confirmation = foundConfirmation ? language('KONTENT', 'TEXT', foundConfirmation.name, objGlobalState.lang) : '';

    const versandLieferung = {
      trackingnummer: parentLieferung?.trackingnummer ?? '',
      sendungsart: sendungsart,
      anzahlderpakete: parentLieferung?.anzahlPakete ?? '',
      anzahlderpaketepropaketsendung: parentLieferung?.anzahlPaketeProPaket ?? [],
      anzahlderpaletten: parentLieferung?.anzahlPaletten ?? '',
      anzahlderpaketepropalette: parentLieferung?.anzahlPaketeProPalette ?? [],
      versandbestaetigung: confirmation,
      dateien: {
        label: parentLieferung?.trackinglabel ?? [],
        versandbestaetigung: parentLieferung?.versandbestaetigung ?? []
      }
    };

    const versandAvisierung = {
      kunde: parentAvisierung?.kunde,
      spedition: parentAvisierung?.spedition,
      versanddatum: parentAvisierung?.versendedatum ? generateDate(parentAvisierung?.versendedatum).justDate : '',
      email: parentAvisierung?.email,
      emailanhang: parentAvisierung?.anhang ?? []
    };

    setLieferungState(parentLieferung ? versandLieferung : {});
    setAvisierungState(parentAvisierung ? versandAvisierung : {});
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    mapParentDataToVersand();

    return () => {
      isMounted = false;
    };
  }, []);

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const avisierungTableElement = avisierungTable.ref.current;
      const lieferungTableElement = lieferungTable.ref.current;
      mouseScrollHorizontally(avisierungTableElement);
      mouseScrollHorizontally(lieferungTableElement);
    }

    return () => (isMounted = false);
  }, [dialogIsRenderedState]);

  return (
    <Dialog
      fullWidth
      onClose={() => objHistorieStateAndFunctions.closeVorgaengeDialogOnClose(objHistorieStateAndFunctions.VORGAENGE.VERSAND)}
      maxWidth="md"
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onRendered={handleDialogOnRendered}
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Typography align="left" className="typography__dialogTitle">
              {language('DIALOG', 'TITEL', 'VERSAND', objGlobalState.lang)}
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <IconButton className="button__iconButton--close" onClick={() => objHistorieStateAndFunctions.closeVorgaengeDialogOnClose(objHistorieStateAndFunctions.VORGAENGE.VERSAND)}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className="dialog__content">
        <Grid container spacing={1}>
          {!isObjectEmpty(lieferungState) && (
            <Grid item xs={12}>
              {objHistorieStateAndFunctions.createTable(lieferungTable)}
            </Grid>
          )}
          {!isObjectEmpty(avisierungState) && (
            <Grid item xs={12}>
              {objHistorieStateAndFunctions.createTable(avisierungTable)}
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CoVersand;
