// function ->
export const displayArraysInTable = (value) => {
  if (Array.isArray(value)) {
    value = value.filter((element) => element !== undefined);
    const result = value.join(', ');
    return result;
  } else {
    return value;
  }
};
