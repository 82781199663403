import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const hocSuche = (WrappedComponent) => {
  const WithSuche = (props) => {
    const history = useHistory();

    const { objGlobalState } = props;
    const [logistikunternehmenState, setLogistikunternehmenState] = useState('');
    const [textFieldState, setTextFieldState] = useState({
      trackingnummer: { error: false },
      logistikunternehmen: { error: false }
    });

    //  function ->
    const chooseLogistikunternehmenFromSelect = (event) => {
      event.preventDefault();

      setTextFieldState({
        ...textFieldState,
        logistikunternehmen: { error: false }
      });
      setLogistikunternehmenState(event.target.value);
    };

    // function ->
    const checkIfTextFieldIsEmpty = (valueForCheck) => {
      //  statement -> checks if valueForCheck is not empty
      return valueForCheck ? false : true;
    };

    // function ->
    const fetchTextFieldChange = (event, textField) => {
      // useState -> show error message depending on return boolean of checkIfTextFieldIsEmpty
      setTextFieldState({
        ...textFieldState,
        [textField]: { error: checkIfTextFieldIsEmpty(event.target.value) }
      });
    };

    // function ->
    const submitFormOnClick = (event) => {
      event.preventDefault();

      // useState -> change the searchFormSubmit so the tracking request will be triggerd again even if given the same information
      objGlobalState.setSearchFormSubmitState((pre) => (pre === 0 ? 1 : 0));

      const logistikunternehmen = logistikunternehmenState;
      const trackingnummer = event.target.trackingnummer.value;

      const isLogistikunternehmenTextFieldEmpty = checkIfTextFieldIsEmpty(logistikunternehmen);
      const isTrackingnummerTextFieldEmpty = checkIfTextFieldIsEmpty(trackingnummer);

      if (isTrackingnummerTextFieldEmpty || isLogistikunternehmenTextFieldEmpty) {
        // useState -> show error message depending on return boolean of checkIfTextFieldIsEmpty
        setTextFieldState({
          ...textFieldState,
          trackingnummer: { error: isTrackingnummerTextFieldEmpty },
          logistikunternehmen: { error: isLogistikunternehmenTextFieldEmpty }
        });
      } else {
        setLogistikunternehmenState('');

        history.push(`/tracking/${logistikunternehmen}/${trackingnummer}`);

        objGlobalState.setDialogState({ title: '', content: '', state: false });
      }
    };
    const functionsAndStates = {
      logistikunternehmenState,
      setLogistikunternehmenState,
      textFieldState,
      setTextFieldState,
      chooseLogistikunternehmenFromSelect,
      checkIfTextFieldIsEmpty,
      fetchTextFieldChange,
      submitFormOnClick
    };
    return <WrappedComponent {...props} functionsAndStates={functionsAndStates} />;
  };
  return WithSuche;
};

export default hocSuche;
