import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { language } from '../../Common/language/language';

// import -> material ui -> core & icons
import { AccordionDetails, Accordion, Typography, Grid, AccordionSummary, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';

// components -> lvl-1
const CoTopBarTitle = ({ item, lang, setAccordionsExpandedState }) => {
  // function ->
  const handleOpenOrCloseAccordionOnClick = () => {
    const title = item.label;
    setAccordionsExpandedState((pre) => {
      const newState = {};
      for (let key in pre) {
        newState[key] = false;
      }
      newState[title] = !pre[title];
      return newState;
    });
  };

  return (
    <AccordionSummary onClick={handleOpenOrCloseAccordionOnClick} className="coNavigation__accordionSummary">
      <Typography className={`coNavigation__accordionSummaryTypography${!item.visible ? '--disabled' : ''}`}>{language('MENU', 'TITEL', item.label, lang)}</Typography>
    </AccordionSummary>
  );
};

// components -> lvl-2
const CoTopBarLinks = ({ objGlobalState, item, lang, setAccordionsExpandedState, liveTrackerState }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const itemActive = pathname === item.url;

  const hasLiveTrackingAccess = objGlobalState.authenticateState.user.access?.includes('LIVE_TRACKING');

  // function ->
  const closeAccordionsOnClick = () => {
    setAccordionsExpandedState((pre) => {
      const newState = {};
      for (let key in pre) {
        newState[key] = false;
      }
      return newState;
    });
  };

  // function ->
  const handleLiveTrackersOnClick = (trackernummer) => {
    closeAccordionsOnClick();
    goToLiveTracking(trackernummer);
  };

  // function ->
  const goToLiveTracking = (trackernummer) => {
    history.push(`/live-tracking/${trackernummer}`);
  };

  if (item.label === 'LIVETRACKING') {
    return (
      <ListItem
        onClick={closeAccordionsOnClick}
        disabled={!item.visible}
        className={`coNavigation__topNavListItem ${objGlobalState.isInIframe ? 'coNavigation__topNavListItem--inIframe' : ''} coNavigation__topNavLiveTracking ${
          itemActive ? 'coNavigation__topNavListItem--active' : ''
        }`}
      >
        <ListItemIcon className="coNavigation__topNavListItemIcon">
          <img src={`${process.env.REACT_APP_HOST}/assets/images/icons/${item.icon}`} alt="" className="coNavigation__listIcon" />
        </ListItemIcon>
        <ListItemText>
          <div className="coNavigation__topNavListItemText coNavigation__topNavLiveTrackingText">
            {language('MENU', 'LINKS', item.label, lang)} {hasLiveTrackingAccess ? <KeyboardArrowRight /> : ''}
          </div>
        </ListItemText>
        {hasLiveTrackingAccess ? (
          <List className={`coNavigation__topNavLiveTrackingTrackers ${objGlobalState.isInIframe ? 'coNavigation__topNavLiveTrackingTrackers--inIframe' : ''}`}>
            {liveTrackerState.map((trackernummer, key) => {
              return (
                <ListItem button onClick={() => handleLiveTrackersOnClick(trackernummer)} key={`navigation_topbar_live-tracking_trackersId_${key}`}>
                  <ListItemText className="coNavigation__topNavListItemText">{trackernummer}</ListItemText>
                </ListItem>
              );
            })}
          </List>
        ) : (
          ''
        )}
      </ListItem>
    );
  }
  return (
    <ListItem
      onClick={closeAccordionsOnClick}
      button
      disabled={!item.visible}
      className={`coNavigation__topNavListItem ${objGlobalState.isInIframe ? 'coNavigation__topNavListItem--inIframe' : ''} ${itemActive ? 'coNavigation__topNavListItem--active' : ''}`}
    >
      <ListItemIcon className="coNavigation__topNavListItemIcon">
        <img src={`${process.env.REACT_APP_HOST}/assets/images/icons/${item.icon}`} alt="" className="coNavigation__listIcon" />
      </ListItemIcon>
      <ListItemText>
        <div className="coNavigation__topNavListItemText">{language('MENU', 'LINKS', item.label, lang)}</div>
      </ListItemText>
    </ListItem>
  );
};

// components -> lvl-1
const CoTopBarMenu = ({ objGlobalState, item, lang, setAccordionsExpandedState, liveTrackerState }) => {
  return (
    <>
      {item.visible && item.label !== 'LIVETRACKING' ? (
        <Link to={item.url} className="coNavigation__link">
          <CoTopBarLinks objGlobalState={objGlobalState} item={item} lang={lang} setAccordionsExpandedState={setAccordionsExpandedState} liveTrackerState={liveTrackerState} />
        </Link>
      ) : (
        <CoTopBarLinks objGlobalState={objGlobalState} item={item} lang={lang} setAccordionsExpandedState={setAccordionsExpandedState} liveTrackerState={liveTrackerState} />
      )}
    </>
  );
};

// component -> React-root
const CoTopBar = ({ objGlobalState, liveTrackerState }) => {
  const { lang } = objGlobalState;

  const { pathname } = useLocation();

  // function ->
  const initAccordionsExpandedState = () => {
    const arrCurrentMenu = objGlobalState.menuStructureState.currentMenu;

    const iniState = {};
    arrCurrentMenu.forEach((menuItem) => {
      iniState[menuItem.title.label] = false;
    });

    return iniState;
  };

  const [accordionsExpandedState, setAccordionsExpandedState] = useState(initAccordionsExpandedState() || {});

  // function ->
  const compareAccessAndBuildNewView = () => {
    const arrCurrentMenu = objGlobalState.menuStructureState.currentMenu;
    const arrUserAccess = objGlobalState.authenticateState.user.access;
    // loop -> iterate over current menu and compare with user access that content is similar
    arrCurrentMenu.forEach((currentMenuItem, menuKey) => {
      arrUserAccess.forEach((accessItem) => {
        // variable -> function check if access from user is include in access from menu
        const isAccessSimilar = accessItem === currentMenuItem.title.access;
        // statement -> if access from user is similar to access from menu, than set choosen link to visible true
        if (menuKey === 0) {
          currentMenuItem.links.forEach((link) => {
            if (link.access === accessItem) {
              link.visible = true;
            }
          });
          return;
        }
        if (isAccessSimilar) {
          currentMenuItem.title.visible = true;
          currentMenuItem.links.forEach((link) => (link.visible = true));
        }
      });
    });
  };

  compareAccessAndBuildNewView();

  const closeAccordionsOnClickOutside = () => {
    const content = document.querySelector('.app__boxContent');
    content.addEventListener('click', () => {
      setAccordionsExpandedState((pre) => {
        const newState = {};
        for (let key in pre) {
          newState[key] = false;
        }
        return newState;
      });
    });
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      closeAccordionsOnClickOutside();
    }

    return () => (isMounted = false);
  }, []);

  return (
    <>
      {objGlobalState.menuStructureState.currentMenu.map((currentMenuItem, key) => {
        const menuItem = currentMenuItem.title.label.toLowerCase();
        let isMenuItemActive = pathname.includes(menuItem);

        if (menuItem === 'navigation') {
          if (pathname === '/' || pathname === '/qualitaetssicherung' || pathname === '/tracking') {
            isMenuItemActive = true;
          }
        }

        if (menuItem === 'lagerverwaltung') {
          if (pathname === '/artikelverlauf' || pathname === '/inventur' || pathname === '/historie') {
            isMenuItemActive = true;
          }
        }

        return (
          <Grid
            item
            md={3}
            sm={4}
            xs={12}
            lg={'auto'}
            key={`navigation_topBar_item_${key}`}
            className={`coNavigation__menuItemGrid ${objGlobalState.isInIframe ? 'coNavigation__menuItemGrid--inIframe' : ''} ${isMenuItemActive ? 'coNavigation__menuItemGrid--active' : ''}`}
          >
            <Accordion
              className={`coNavigation__accordion ${objGlobalState.isInIframe ? 'coNavigation__accordion--inIframe' : ''} ${isMenuItemActive ? 'coNavigation__accordion--active' : ''}`}
              expanded={accordionsExpandedState[currentMenuItem.title.label]}
            >
              <CoTopBarTitle item={currentMenuItem.title} lang={lang} setAccordionsExpandedState={setAccordionsExpandedState} />
              <AccordionDetails className={`coNavigation__accordionDetails ${objGlobalState.isInIframe ? 'coNavigation__accordionDetails--inIframe' : ''}`}>
                {objGlobalState.canNavBarOpenState && (
                  <List className="coNavigation__topNavList">
                    {currentMenuItem.links.map((menuItemLink, _key) => {
                      return (
                        <CoTopBarMenu
                          liveTrackerState={liveTrackerState}
                          objGlobalState={objGlobalState}
                          key={`navigation_topBar_links_${key}_${_key}`}
                          item={menuItemLink}
                          lang={lang}
                          setAccordionsExpandedState={setAccordionsExpandedState}
                        />
                      );
                    })}
                  </List>
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        );
      })}
    </>
  );
};

export default CoTopBar;
