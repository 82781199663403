import React from 'react';
import { useHistory } from 'react-router-dom';

// import -> material ui -> core, icons
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { NavigateNext as NavigateNextIcon } from '@material-ui/icons';

// component -> React-root
const ServiceBreadcrumbs = ({ arrBreadcrumbs }) => {
  const history = useHistory();

  // function ->
  const changePageOnClick = (event, url) => {
    event.preventDefault();

    history.push(url);
  };

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {arrBreadcrumbs.map((item, key) => {
        // statement -> if last element than show different style
        if (arrBreadcrumbs.length - 1 === key) {
          return (
            <Typography key={key} color="textPrimary" className="typography__breadcrumbs">
              {item.title}
            </Typography>
          );
        } else {
          return (
            <Link key={key} color="inherit" href={item.url} onClick={(event) => changePageOnClick(event, item.url)} className="typography__breadcrumbs">
              {item.title}
            </Link>
          );
        }
      })}
    </Breadcrumbs>
  );
};

export default ServiceBreadcrumbs;
