import React, { useEffect, useRef, useState } from 'react';
import language from '../Common/language/language';
import { createAxiosInstance } from '../Common/helperFunctions/axios';

// import -> services
import ServiceBreadcrumbs from '../Common/services/Breadcrumbs/ServiceBreadcrumbs';

// import -> material ui -> core, icons
import { Button, Container, Grid, IconButton, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { useParams } from 'react-router';
import { Close } from '@material-ui/icons';

// component -> React-root
const CoLiveTracking = ({ objGlobalState }) => {
  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const [trackingSiteUrlState, setTrackingSiteUrlState] = useState('');
  const [fullWidthState, setFullWidthState] = useState(false);
  const [trackingDocumentFoundState, setTrackingDocumentFoundState] = useState(false);

  const iframeRef = useRef();

  const { trackerId } = useParams();

  // function ->
  const openOrCloseFullWidthIframe = (trigger) => {
    setFullWidthState(trigger);
  };

  const getLiveTrackingDocument = () => {
    objGlobalState.setPageLoadingState(true);

    authAxios
      .get(`/live-tracking/${trackerId}`)
      .then((response) => {
        const trackingUrl = response.data;
        setTrackingSiteUrlState(trackingUrl);
        setTrackingDocumentFoundState(true);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const preventIframeCaching = () => {
    const iframe = iframeRef?.current;
    iframe.contentWindow.location.href = iframe.src;
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    getLiveTrackingDocument();

    return () => {
      isMounted = false;
    };
  }, [trackerId]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    if (trackingDocumentFoundState) {
      // iframeRef?.current?.addEventListener('load', preventIframeCaching);
      preventIframeCaching();
    }

    return () => {
      isMounted = false;
    };
  }, [trackingDocumentFoundState]);

  // array -> create breadcrumbs
  const arrBreadcrumbs = [
    { url: '/', title: language('SEITE', 'TITEL', 'HOME', objGlobalState.lang) },
    { url: '/live-tracking', title: language('SEITE', 'TITEL', 'LIVETRACKING', objGlobalState.lang) }
  ];

  return (
    <>
      <Container component="main" maxWidth="xl" className="container__container">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ServiceBreadcrumbs arrBreadcrumbs={arrBreadcrumbs} />
          </Grid>
          <Grid item xs={12}>
            <ListItem className="container__listItem">
              <ListItemIcon>
                <img src={`../assets/images/icons/icon_live-tracking.png`} alt="" className="images__headerIcon" />
              </ListItemIcon>
              <ListItemText>
                <Typography className="typography__headerTitle">{language('SEITE', 'TITEL', 'LIVETRACKING', objGlobalState.lang)}</Typography>
              </ListItemText>
            </ListItem>
          </Grid>
          <Grid item xs={12} container justify="center">
            <Grid item xs={1}>
              <Button onClick={() => openOrCloseFullWidthIframe(true)} className="button__middle width150" variant="contained" color="primary">
                {language('BUTTON', 'TITEL', 'VOLLBILD', objGlobalState.lang)}
              </Button>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={10} className="marginTwenty">
              <div style={{ position: fullWidthState ? 'static' : 'relative' }}>
                <div className={`overSection ${fullWidthState ? 'zIndex' : ''}`}>
                  <img src={`../logo_green.png`} alt="logo" />
                </div>
                <IconButton className={`coLiveTracking__closeFullWidth ${fullWidthState ? 'coLiveTracking__closeFullWidth--active' : ''}`} onClick={() => openOrCloseFullWidthIframe(false)}>
                  <Close />
                </IconButton>
                {trackingDocumentFoundState ? (
                  <iframe
                    ref={iframeRef}
                    title="coLiveTracking__iframe"
                    className={`coLiveTracking__iframe ${fullWidthState ? 'coLiveTracking__iframe--fullWidth  ' : ''}`}
                    src={trackingSiteUrlState}
                  ></iframe>
                ) : (
                  ''
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CoLiveTracking;
