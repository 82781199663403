import React, { useLayoutEffect, useRef, useState } from 'react';
import language from '../../language/language';
import { mouseScrollHorizontally } from '../../helperFunctions/mouseScrollHorizontally';
import generateArtikelEntityLabel from '../../helperFunctions/generateArtikelEntityLabel';
import { createAxiosInstance } from '../../helperFunctions/axios';
import { useSnackbar } from 'notistack';

// import -> material ui -> core && icons
import { Dialog, DialogContent, DialogTitle, Grid, Button, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { TABLE_TYPES } from '../../types/types';

// component -> React-root
const CoSpaltenlisteDialog = ({ objGlobalState, objParentState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const [dialogIsRenderedState, setDialogIsRenderedState] = useState(false);
  const [showTableState, setShowTableState] = useState(false);
  const [spaltenState, setSpaltenState] = useState({});

  const tableRef = useRef();

  // function ->
  const closeDialogOnClose = () => {
    objParentState.setSpaltenlisteDialogState(false);
  };

  // function ->
  const handleDialogOnRendered = () => {
    setDialogIsRenderedState(true);
  };

  // function ->
  const changeInputOnChange = (event) => {
    const entityName = event.target.name;
    const entityChecked = event.target.checked;
    setSpaltenState((pre) => ({
      ...pre,
      [entityName]: entityChecked
    }));
  };

  // function ->
  const createSwitchForEntity = (entity) => {
    return <Switch checked={spaltenState[entity]} onChange={changeInputOnChange} color="primary" name={entity} />;
  };

  // function ->
  const mapSelectedSpaltenToArray = () => {
    const result = [];
    for (let spalte in spaltenState) {
      if (spaltenState[spalte]) result.push(spalte);
    }
    return result;
  };

  // function ->
  const findTableInfos = () => {
    let postEndPoint;
    let gesamtspaltenObjectName;
    let gewaehltspaltenObjectName;
    switch (objParentState.tableCategory) {
      case TABLE_TYPES.ARTIKELUEBERSICHT:
        postEndPoint = '/usersettings/spalten-artikeluebersicht';
        gesamtspaltenObjectName = 'spaltenGesamtArtikeluebersicht';
        gewaehltspaltenObjectName = 'spaltenGewaehltArtikeluebersicht';
        break;
      case TABLE_TYPES.BESTANDSUEBERSICHT:
        postEndPoint = '/usersettings/spalten-bestandsuebersicht';
        gesamtspaltenObjectName = 'spaltenGesamtBestandsuebersicht';
        gewaehltspaltenObjectName = 'spaltenGewaehltBestandsuebersicht';
        break;
      case TABLE_TYPES.TRANSPORTEINHEITEN:
        postEndPoint = '/usersettings/spalten-transporteinheit-artikel';
        gesamtspaltenObjectName = 'spaltenGesamtTransporteinheitenProArtikel';
        gewaehltspaltenObjectName = 'spaltenGewaehltTransporteinheitenProArtikel';
        break;
    }

    return { postEndPoint, gesamtspaltenObjectName, gewaehltspaltenObjectName };
  };

  // function ->
  const sendSelectedTableSpalten = () => {
    const requestBody = mapSelectedSpaltenToArray();
    objGlobalState.setPageLoadingState(true);
    authAxios
      .post(findTableInfos().postEndPoint, requestBody)
      .then(() => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT'), { variant: 'success' });
        closeDialogOnClose();
        objParentState.setRerunEffectState((pre) => !pre);
      })
      .catch((error) => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
        console.log(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const initSpaltenStateTrueValues = () => {
    authAxios
      .get('/usersettings')
      .then((response) => {
        const allTableSpalten = response.data[findTableInfos().gesamtspaltenObjectName];
        const selectedTableSpalten = response.data[findTableInfos().gewaehltspaltenObjectName];
        const newSpaltenState = {};
        selectedTableSpalten.forEach((spalte) => {
          newSpaltenState[spalte] = true;
        });
        allTableSpalten.forEach((spalte) => {
          if (spalte === 'id' || spalte === 'createdAt' || spalte === 'lastModifiedAt' || spalte === 'artikelStammId') return;
          if (selectedTableSpalten.includes(spalte)) return;
          newSpaltenState[spalte] = false;
        });
        setSpaltenState(newSpaltenState);
        setShowTableState(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // function ->
  const mapObjToArrayOfObjects = (obj) => {
    const array = [];
    for (let key in obj) {
      array.push(key);
    }
    return array;
  };

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted && dialogIsRenderedState) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, [dialogIsRenderedState]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    initSpaltenStateTrueValues();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={objParentState.spaltenlisteDialogState}
      onClose={closeDialogOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onRendered={handleDialogOnRendered}
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Typography align="left" className="typography__dialogTitle">
              {language('DIALOG', 'TITEL', 'SPALTENAUSWAEHLEN', objGlobalState.lang)}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className="dialog__content">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className="table__container">
              {showTableState && (
                <Table size="medium">
                  <TableBody>
                    {mapObjToArrayOfObjects(spaltenState).map((entity, key) => {
                      return (
                        <TableRow key={`common_components_artikelverwaltung_coSpaltenlisteDialog_${key}`}>
                          <TableCell align="left" component="th" className="table__rowTitle">
                            <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', generateArtikelEntityLabel(entity), objGlobalState.lang)}</Typography>
                          </TableCell>
                          <TableCell align="right">{createSwitchForEntity(entity)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button fullWidth onClick={sendSelectedTableSpalten} color="primary" className="button__middle" variant="contained">
              {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button onClick={closeDialogOnClose} fullWidth className="button__middle">
              {language('BUTTON', 'TITEL', 'ABBRECHEN', objGlobalState.lang)}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CoSpaltenlisteDialog;
