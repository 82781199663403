import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import language from '../../Common/language/language';
import { mouseScrollHorizontally } from '../../Common/helperFunctions/mouseScrollHorizontally';
import { generateDate } from '../../Common/helperFunctions/date';
import { isObjectEmpty } from '../../Common/helperFunctions/objects';

// import -> material ui -> core & icons
import { Dialog, DialogContent, DialogTitle, Grid, Typography, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

// component -> React-root
const CoWareneingang = ({ objGlobalState, objHistorieStateAndFunctions }) => {
  const { open, data } = objHistorieStateAndFunctions.vorgaengeDialogState.wareneingang;

  const [dialogIsRenderedState, setDialogIsRenderedState] = useState(false);
  const [lieferscheinState, setLieferscheinState] = useState({});
  const [einlagerungState, setEinlagerungState] = useState({});

  const lieferscheinTable = {
    tableId: 'wareneingang_lieferschein',
    ref: useRef(),
    title: 'LIEFERSCHEIN',
    data: lieferscheinState,
    headers: ['LIEFERSCHEIN'],
    exeptions: [
      {
        name: 'LIEFERSCHEIN',
        handler: (key, header, data, lastTableCells) => {
          return objHistorieStateAndFunctions.handleExeptionWithOneSetOfFiles(key, header, data, 'LIEFERSCHEIN', lastTableCells);
        }
      }
    ]
  };

  const einlagerungTable = {
    tableId: 'wareneingang_einlagerung',
    ref: useRef(),
    title: 'EINLAGERUNG',
    data: einlagerungState,
    headers: ['STELLPLATZKENNZEICHNUNG', 'STELLPLATZFOTOS', 'DATUM'],
    exeptions: [
      {
        name: 'STELLPLATZFOTOS',
        handler: (key, header, data, lastTableCells) => {
          return objHistorieStateAndFunctions.handleExeptionWithOneSetOfFiles(key, header, data, 'STELLPLATZFOTOS', lastTableCells);
        }
      }
    ]
  };

  // function ->
  const handleDialogOnRendered = () => {
    setDialogIsRenderedState(true);
  };

  // function ->
  const mapParentDataToWareneingangData = () => {
    const { lieferscheine: parentLieferscheine, stellplaetze: parentEinlagerung } = data;

    const wareneingangLieferschein = {
      lieferschein: parentLieferscheine ?? []
    };

    const wareneingangEinlagerung = {
      stellplatzkennzeichnung: parentEinlagerung?.[0]?.pitch,
      stellplatzfotos: parentEinlagerung?.[0]?.statusPhotos,
      datum: parentEinlagerung?.[0]?.date ? generateDate(parentEinlagerung[0].date).justDate : ''
    };

    setLieferscheinState(parentLieferscheine ? wareneingangLieferschein : {});
    setEinlagerungState(parentEinlagerung?.[0] ? wareneingangEinlagerung : []);
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    mapParentDataToWareneingangData();

    return () => {
      isMounted = false;
    };
  }, []);

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const lieferscheinTableElement = lieferscheinTable.ref.current;
      const einlagerungTableElement = einlagerungTable.ref.current;

      mouseScrollHorizontally(lieferscheinTableElement);
      mouseScrollHorizontally(einlagerungTableElement);
    }

    return () => (isMounted = false);
  }, [dialogIsRenderedState]);

  return (
    <Dialog
      fullWidth
      onClose={() => objHistorieStateAndFunctions.closeVorgaengeDialogOnClose(objHistorieStateAndFunctions.VORGAENGE.WARENEINGANG)}
      maxWidth="md"
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onRendered={handleDialogOnRendered}
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Typography align="left" className="typography__dialogTitle">
              {language('DIALOG', 'TITEL', 'WARENEINGANG', objGlobalState.lang)}
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <IconButton className="button__iconButton--close" onClick={() => objHistorieStateAndFunctions.closeVorgaengeDialogOnClose(objHistorieStateAndFunctions.VORGAENGE.WARENEINGANG)}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className="dialog__content">
        <Grid container spacing={1}>
          {!isObjectEmpty(lieferscheinState) && (
            <Grid item xs={12}>
              {objHistorieStateAndFunctions.createTable(lieferscheinTable)}
            </Grid>
          )}
          {!isObjectEmpty(einlagerungState) && (
            <Grid item xs={12}>
              {objHistorieStateAndFunctions.createTable(einlagerungTable)}
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CoWareneingang;
