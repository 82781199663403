import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import language from '../../../language/language';
import { mouseScrollHorizontally } from '../../../helperFunctions/mouseScrollHorizontally';
import { createAxiosInstance } from '../../../helperFunctions/axios';
import generateArtikelEntityLabel from '../../../helperFunctions/generateArtikelEntityLabel';
import { useSnackbar } from 'notistack';
import { generateCSVString } from '../../../helperFunctions/generateCSVString';
import { TABLE_TYPES } from '../../../types/types';

// import -> material ui -> core
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { VerticalAlignBottom } from '@material-ui/icons';

// import -> components
import CoPaginationTableFooter from '../../CoPaginationTableFooter';
import CoSpaltenlisteDialog from '../../Artikel/CoSpaltenlisteDialog';
import CoSpaltenreihenfolgeDialog from '../../Artikel/CoSpaltenreihenfolgeDialog';
import CoTransporteinheitentabelle from './Append/CoTransporteinheitentabelle';
import { exportTableAsCSV } from '../../../helperFunctions/exportTable';

const INITIAL_ELEMENTS_PER_PAGE = 5;

// component -> React-root
const CoBestandstabelle = ({ objGlobalState, objParentState }) => {
  const heightFour = objParentState.heightFour;
  const isKommissionierung = objParentState.kommissionierung;

  const { enqueueSnackbar } = useSnackbar();

  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const [spaltenlisteDialogState, setSpaltenlisteDialogState] = useState(false);
  const [spaltenreihenfolgeDialogState, setSpaltenreihenfolgeDialogState] = useState(false);
  const [bestandState, setBestandState] = useState([]);
  const [rerunEffectState, setRerunEffectState] = useState(false);

  const [transporteinheitViewState, setTransporteinheitViewState] = useState({ open: false, artikelId: null });

  // this state is used when the parent is Warenausgangkommissionierung
  const [ausgewaehlteBuchungsIdState, setAusgewaehlteBuchungsIdState] = useState();

  const [showTableState, setShowTableState] = useState(false);
  const [tableValuesLabelsState, setTablesValuesLabelsState] = useState([]);
  const [tableHeadersState, setTableHeadersState] = useState([]);
  const [elementsPerPageState, setElementsPerPageState] = useState(INITIAL_ELEMENTS_PER_PAGE);
  const [numOfPagesState, setNumOfPagesState] = useState(1);
  const [currentPageState, setCurrentPageState] = useState(1);
  const tableRef = useRef();

  // function ->
  const openSpaltenlisteDialog = () => {
    setSpaltenlisteDialogState(true);
  };

  // function ->
  const openSpaltenreihenfolgeDialog = () => {
    setSpaltenreihenfolgeDialogState(true);
  };

  // function ->
  const stateToCSV = () => {
    const csvRows = [];
    const headerRow = tableHeadersState.map((header) => generateCSVString(language('TABLE', 'TITEL', header, objGlobalState.lang)));
    csvRows.push(headerRow.join(';'));
    bestandState.forEach((bestandItem) => {
      const valueRow = tableValuesLabelsState.map((key) => {
        const value =
          key === 'temperaturEmpfindlich'
            ? mapTemperaturEmpfindlichValueToLabel(bestandItem[key])
            : typeof bestandItem[key] === 'boolean'
            ? displayBoolean(bestandItem[key])
            : bestandItem[key]
            ? bestandItem[key]
            : '';

        return generateCSVString(value);
      });
      csvRows.push(valueRow.join(';'));
    });
    return csvRows.join('\n');
  };

  // function ->
  const chooseBestandTransporteinheitOnClick = (bestandId, buchungsId) => {
    objParentState.setAusgewaehlteBuchungsIdState({ chosen: true, buchungsId });
    const newBestandState = bestandState.map((bestandItem) => {
      bestandItem.chosen = false;
      if (bestandItem.artikelStammId === bestandId) {
        bestandItem.chosen = true;
      }
      return bestandItem;
    });
    setAusgewaehlteBuchungsIdState(buchungsId);
    setBestandState(newBestandState);
  };

  // function ->
  const downloadEANAfterRequest = (file) => {
    const blob = new Blob([file]);
    const url = URL.createObjectURL(blob);

    const name = 'traceport_ean_code';
    const type = 'png';
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${name}.${type}`);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
  };

  // function ->
  const getEANCodeFromAPI = (artikelId) => {
    authAxios
      .get(`/artikel/${artikelId}/ean-code`, {
        responseType: 'blob'
      })
      .then((response) => {
        const file = response.data;
        downloadEANAfterRequest(file);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
      });
  };

  // function ->
  const goToTransporteinheitentabelle = (artikelId) => {
    setTransporteinheitViewState({ open: true, artikelId });
  };

  // function ->
  const displayBoolean = (value) => {
    const label = value ? 'JA' : 'NEIN';
    return language('KONTENT', 'TEXT', label, objGlobalState.lang);
  };

  // function ->
  const mapTemperaturEmpfindlichValueToLabel = (value) => {
    const labels = {
      KEINE_ANGABE: '',
      FUENFZEHN_BIS_FUENFUNDZWANZIG: '15°C - 25°C',
      ZWEI_BIS_ACHT: '2°C - 8°C',
      NULL_BIS_FUENFUNDZWANZIG: '0°C - 25°C'
    };

    return labels[value];
  };

  // function ->
  const createTableHeaders = (spaltenArray) => {
    const tableHeaders = [];
    spaltenArray.forEach((spalte) => {
      if (spalte === 'artikelStammId') return;
      const spalteAsHeader = generateArtikelEntityLabel(spalte);
      tableHeaders.push(spalteAsHeader);
    });

    return tableHeaders;
  };

  // function ->
  const createTableValuesLabels = (spaltenArray) => {
    const valuesLabels = [];
    spaltenArray.forEach((spalte) => {
      if (spalte === 'artikelStammId' && spalte === 'chosen') return;
      valuesLabels.push(spalte);
    });

    return valuesLabels;
  };

  // function ->
  const handleFetchBestandSuccess = (bestandsdaten, selectedSpalten) => {
    const totalElements = bestandsdaten.totalElements;
    const elementsPerPage = +elementsPerPageState <= 0 ? 1 : elementsPerPageState;
    const numOfPages = Math.ceil(totalElements / elementsPerPage);

    setNumOfPagesState(numOfPages);

    if (currentPageState > numOfPages) {
      setCurrentPageState(numOfPages);
      return;
    }

    const tableHeaders = createTableHeaders(selectedSpalten);
    const tableValuesLabels = createTableValuesLabels(selectedSpalten);

    const bestand = bestandsdaten.content;

    setTablesValuesLabelsState(tableValuesLabels);
    setTableHeadersState(tableHeaders);
    setBestandState(bestand);

    setShowTableState(true);
  };

  // function ->
  const fetchBestandFromApi = () => {
    objGlobalState.setPageLoadingState(true);

    const page = currentPageState - 1 <= 0 ? 0 : currentPageState - 1;
    const size = elementsPerPageState <= 0 ? 1 : elementsPerPageState;

    authAxios
      .get(`/transporteinheit/bestand?page=${page}&size=${size}`)
      .then((response) => {
        const bestanddaten = response.data;
        authAxios.get('/usersettings').then((_response) => {
          const selectedBestandsuebersichtSpalten = _response.data.spaltenGewaehltBestandsuebersicht;
          handleFetchBestandSuccess(bestanddaten, selectedBestandsuebersichtSpalten);
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchBestandFromApi();
    }

    return () => (isMounted = false);
  }, [currentPageState, elementsPerPageState, rerunEffectState, objParentState.rerunEffectState]);

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, []);

  const objBestandstabelle = {
    paginationTableFooter: {
      currentPageState,
      setCurrentPageState,
      setElementsPerPageState,
      numOfPagesState,
      setNumOfPagesState,
      firstCellColSpan: 3,
      secondCellColSpan: 2,
      thirdCellColSpan: 3
    },
    spaltenlisteDialog: {
      setRerunEffectState,
      spaltenlisteDialogState,
      setSpaltenlisteDialogState,
      tableValuesLabelsState,
      tableCategory: TABLE_TYPES.BESTANDSUEBERSICHT
    },
    spaltenreihenfolgeDialog: {
      setRerunEffectState,
      spaltenreihenfolgeDialogState,
      setSpaltenreihenfolgeDialogState,
      tableCategory: TABLE_TYPES.BESTANDSUEBERSICHT
    },
    transporteinheitentabelle: {
      ausgewaehlteBuchungsIdState,
      chooseBestandTransporteinheitOnClick,
      heightFour: heightFour,
      kommissionierung: isKommissionierung,
      transporteinheitViewState,
      setTransporteinheitViewState,
      kommissionierungRerunEffectState: objParentState.rerunEffectState
    }
  };

  return (
    <>
      {spaltenlisteDialogState && <CoSpaltenlisteDialog objGlobalState={objGlobalState} objParentState={objBestandstabelle.spaltenlisteDialog} />}
      {spaltenreihenfolgeDialogState && <CoSpaltenreihenfolgeDialog objGlobalState={objGlobalState} objParentState={objBestandstabelle.spaltenreihenfolgeDialog} />}

      {transporteinheitViewState.open ? (
        <CoTransporteinheitentabelle objGlobalState={objGlobalState} objParentState={objBestandstabelle.transporteinheitentabelle} />
      ) : (
        <>
          <Box className="table__title">
            <Typography align="center" className="typography__singleTableTitle">
              {language('TABLE', 'HEADER', 'BESTAND', objGlobalState.lang)}
            </Typography>
            <div className="marginTwenty">
              {showTableState && (
                <>
                  <Button color="primary" variant="contained" onClick={openSpaltenlisteDialog} className="table__titleButton">
                    {language('BUTTON', 'TITEL', 'SPALTENAUSWAEHLEN', objGlobalState.lang)}
                  </Button>
                  <Button color="primary" variant="contained" onClick={openSpaltenreihenfolgeDialog} className="table__titleButton">
                    {language('BUTTON', 'TITEL', 'SPALTENREIHENFOLGE', objGlobalState.lang)}
                  </Button>
                  <Button data-testid="tabelle-export-artikeluebersicht" color="primary" variant="contained" onClick={() => exportTableAsCSV(stateToCSV(), 'bestand')} className="table__titleButton">
                    {language('BUTTON', 'TITEL', 'TABELLEEXPORTIEREN', objGlobalState.lang)}
                  </Button>
                </>
              )}
            </div>
          </Box>
          <TableContainer
            ref={tableRef}
            component={Paper}
            variant="outlined"
            elevation={0}
            square
            className={`coArtikel__tableContainer ${heightFour ? 'heightFour' : ''} border__noTop backgroundTable`}
          >
            {showTableState && (
              <Table size="medium" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" component="th" className="table__headerCellNoWidth">
                      <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', ' ', objGlobalState.lang)}</Typography>
                    </TableCell>
                    {tableHeadersState.map((header, key) => {
                      return (
                        <TableCell align="center" component="th" className="table__headerCell" key={`lagerverwaltung_bestandstabelle_tableHeaders_${key}`}>
                          <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', header, objGlobalState.lang)}</Typography>
                        </TableCell>
                      );
                    })}
                    <TableCell align="center" component="th" className="table__headerCell">
                      <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'TRANSPORTEINHEITEN', objGlobalState.lang)}</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bestandState.map((bestandItem, key) => {
                    return (
                      <TableRow key={`lagerverwaltung_bestandstabelle_tableRow_${key}`} className={bestandItem.chosen ? 'table__row--active' : ''}>
                        <TableCell align="center">
                          <Typography className="typography__singleTableRowContent">{bestandItem.artikelStammId}</Typography>
                        </TableCell>
                        {tableValuesLabelsState.map((value, _key) => (
                          <TableCell align="center" key={`lagerverwaltung_bestandstabelle_tableCell_${key}_${_key}`}>
                            {value === 'ean' ? (
                              <Button
                                startIcon={<VerticalAlignBottom />}
                                color="primary"
                                variant="outlined"
                                className="button__small noWrap"
                                onClick={() => getEANCodeFromAPI(bestandItem.artikelStammId)}
                              >
                                {bestandItem[value]}
                              </Button>
                            ) : (
                              <Typography className="typography__singleTableRowContent">
                                {value === 'temperaturEmpfindlich'
                                  ? mapTemperaturEmpfindlichValueToLabel(bestandItem[value])
                                  : typeof bestandItem[value] === 'boolean'
                                  ? displayBoolean(bestandItem[value])
                                  : bestandItem[value]}
                              </Typography>
                            )}
                          </TableCell>
                        ))}
                        <TableCell align="center">
                          <Button
                            data-testid={`ansehen-bestandtabelle-button-${bestandItem.artikelStammId}`}
                            color="primary"
                            variant="outlined"
                            className="button__small"
                            onClick={() => goToTransporteinheitentabelle(bestandItem.artikelStammId)}
                          >
                            {language('BUTTON', 'TITEL', 'ANSEHEN', objGlobalState.lang)}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <CoPaginationTableFooter objParent={objBestandstabelle.paginationTableFooter} objGlobalState={objGlobalState} />
              </Table>
            )}
          </TableContainer>
        </>
      )}
    </>
  );
};

export default CoBestandstabelle;
