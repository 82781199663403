import React, { Fragment, useLayoutEffect, useRef, useState } from 'react';
import language from '../../Common/language/language';
import { mouseScrollHorizontally } from '../../Common/helperFunctions/mouseScrollHorizontally';
import { createAxiosInstance, getAndCostumFiles } from '../../Common/helperFunctions/axios';
import { useSnackbar } from 'notistack';

// import -> material ui -> core & icons
import { Typography, Paper, TableRow, TableCell, TableBody, TableContainer, Table, Box, Button, TableHead, ButtonGroup } from '@material-ui/core';

// import -> components
import CoShowFiles from '../../Common/Components/CoShowFiles';
import CoAuftragstabelleDialog from './CoAuftragstabelleDialog';
import CoUploadFiles from '../../Common/Components/CoUploadFiles';

// component -> React-root
const CoLiferscheinTable = ({ objGlobalState, objVerpackungStatesAndFunctions }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [showFilesDialogState, setShowFilesDialogState] = useState({ open: false, title: '', files: [] });
  const [auftragstabelleDialogState, setAuftragstabelleDialogState] = useState({ open: false, dataset: {} });
  const [lieferscheinState, setLieferscheinState] = useState([]);
  const [uploadFilesDialogState, setUploadFilesDialogState] = useState({ open: false, title: '' });
  const [focusedAuftragIdState, setFocusedAuftragIdState] = useState('');

  const tableRef = useRef();
  const tableHeaders = ['AUFTRAGSNUMMER', 'DATUM', 'LIEFERSCHEIN', 'AUFTRAG'];

  // function ->
  const showFilesDialogOnClick = (auftragId) => {
    const title = 'LIEFERSCHEIN';
    const files = [];

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    authAxios
      .get(`/auftrag/${auftragId}/lieferscheine`)
      .then((response) => {
        const { data } = response;

        if (!data.length) {
          setShowFilesDialogState({ open: true, title: title, files: files });
        }

        data.forEach((file) => {
          getAndCostumFiles({ file: file, filesArray: files, authAxios: authAxios }).then((result) => {
            setShowFilesDialogState({ open: true, title: title, files: result });
          });
        });
      })
      .catch((error) => console.log(error));
  };

  // function ->
  const showUploadFilesDialogOnClick = (auftragId) => {
    setUploadFilesDialogState({ open: true, title: 'LIEFERSCHEIN' });
    setFocusedAuftragIdState(auftragId);
  };

  // function ->
  const showAuftrastabelleDialogOnClick = (dataset) => {
    setAuftragstabelleDialogState({ open: true, dataset: dataset });
  };

  // function ->
  const handleUploadButtonOnClick = () => {
    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    const formData = new FormData();
    lieferscheinState.forEach((lieferschein) => {
      formData.append('lieferscheine', lieferschein?.fileRef);
    });

    authAxios
      .post(`/auftrag/${focusedAuftragIdState}/lieferscheine`, formData, { headers: { 'Content-Type': 'multipart/form-data;' } })
      .then(() => {
        setUploadFilesDialogState({ open: false, title: undefined });
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT'), { variant: 'success' });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
      });
  };

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, []);

  const objVerpackungTableState = {
    auftragstabelleDialogState,
    setAuftragstabelleDialogState,
    setAuftragsAngepasstState: objVerpackungStatesAndFunctions.setAuftragsAngepasstState,
    filesState: lieferscheinState,
    setFilesState: setLieferscheinState,
    handleUploadButtonOnClick,
    showFilesDialogState,
    setShowFilesDialogState,
    uploadFilesDialogState,
    setUploadFilesDialogState
  };

  return (
    <>
      {uploadFilesDialogState.open && <CoUploadFiles objGlobalState={objGlobalState} objParentState={objVerpackungTableState} options={{ uploadButton: true }} />}
      {showFilesDialogState.open && <CoShowFiles objGlobalState={objGlobalState} objParentState={objVerpackungTableState} />}
      {auftragstabelleDialogState.open && <CoAuftragstabelleDialog objGlobalState={objGlobalState} objParentState={objVerpackungTableState} />}
      <Box className="marginFourty table__title">
        <Typography align="center" className="typography__singleTableTitle">
          {language('TABLE', 'HEADER', 'VERPACKUNG', objGlobalState.lang)}
        </Typography>
      </Box>
      <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className="table__container heightFive border__noTop">
        <Table size="medium" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, key) => {
                return (
                  <TableCell align="center" component="th" className="table__headerCell" key={`warenausgang_verpackung_tableHeader_${key}`}>
                    <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', header, objGlobalState.lang)}</Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {objVerpackungStatesAndFunctions.verpackungState.map((obj, key) => {
              const {
                auftragsnummer,
                datum,
                name1,
                name2,
                name3,
                emailadresse,
                telefonnummer,
                strasse,
                plz,
                ort,
                land,
                zusaetzlicheinformationen,
                referenz,
                spedition,
                sendungsart,
                versanddatum,
                id
              } = obj;

              const auftragstabelleValues = {
                auftragsnummer,
                datum,
                name1,
                name2,
                name3,
                emailadresse,
                telefonnummer,
                strasse,
                plz,
                ort,
                land,
                zusaetzlicheinformationen,
                referenz,
                spedition,
                sendungsart,
                versanddatum,
                id
              };
              return (
                <Fragment key={`warenausgang_verpackung__table_${key}`}>
                  <TableRow>
                    <TableCell align="center">
                      <Typography className="typography__singleTableRowContent">{obj.auftragsnummer}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography className="typography__singleTableRowContent">{obj.datum}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <ButtonGroup>
                        <Button className="button__small" variant="outlined" color="primary" onClick={() => showFilesDialogOnClick(id)}>
                          {language('BUTTON', 'TITEL', 'ANSEHEN', objGlobalState.lang)}
                        </Button>
                        <Button className="button__small" variant="outlined" color="primary" onClick={() => showUploadFilesDialogOnClick(id)}>
                          {language('BUTTON', 'TITEL', 'HOCHLADEN', objGlobalState.lang)}
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                    <TableCell align="center">
                      <Button className="button__small" variant="outlined" color="primary" onClick={() => showAuftrastabelleDialogOnClick(auftragstabelleValues)}>
                        {language('BUTTON', 'TITEL', 'ZUMAUFTRAG', objGlobalState.lang)}
                      </Button>
                    </TableCell>
                  </TableRow>
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CoLiferscheinTable;
