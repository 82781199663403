import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import language from '../../Common/language/language';
import { createAxiosInstance, getAndCostumFiles } from '../../Common/helperFunctions/axios';

// import -> material ui -> core & icons
import { Button, ButtonGroup, Grid, Paper, Table, TableContainer, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';

// import -> components
import CoUploadFiles from '../../Common/Components/CoUploadFiles';
import CoConfirmation from '../../Common/Components/CoConfirmation';
import CoDisplayFilesList from '../../Common/Components/CoDisplayFilesList';
import CoMultipleScanner from '../../Common/Components/CoMultipleScanner';
import CoShowFiles from '../../Common/Components/CoShowFiles';
import CoLieferungtabelle from '../../Common/Components/Warenannahme/CoLieferungtabelle';

// component -> React-root
const CoEinlagerungNoTransporteinheit = ({ objGlobalState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [rerunEffectState, setRerunEffectState] = useState(false);
  const [showFilesDialogState, setShowFilesDialogState] = useState({ open: false, files: [], title: null });
  const [confirmationDialogState, setConfirmationDialogState] = useState(false);
  const [ausgewaehlteSendungState, setAusgewaehlteSendungState] = useState({ chosen: false, sendung: {} });
  const [uploadFilesStellplatzfotosDialogState, setUploadFilesStellplatzfotosDialogState] = useState({ open: false, title: '' });
  const [uploadFilesLieferscheinDialogState, setUploadFilesLieferscheinDialogState] = useState({ open: false, title: '' });
  const [stellplatzfotosState, setStellplatzfotosState] = useState([]);
  const [lieferscheinState, setLieferscheinState] = useState([]);
  const [stellplatzkennzeichnungState, setStellplatzkennzeichnungState] = useState('');
  const [newDataValueState, setNewDataValueState] = useState({
    stellplatzkennzeichnung: '',
    sendung: ''
  });

  // function ->
  const showFilesDialogOnClick = (event, deliveryId, filesName) => {
    event.stopPropagation();
    const title = filesName.toUpperCase();
    const files = [];

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    const endpoint = filesName === 'begleitpapiere' ? `delivery/findPreviewsForDeliveryNote/${deliveryId}` : filesName === 'beschaedigungsfotos' ? `/delivery/findpreviews/${deliveryId}` : '';

    authAxios
      .get(endpoint)
      .then((response) => {
        const { data } = response;

        let dataIterator;

        if (filesName === 'begleitpapiere') {
          dataIterator = data[0]?.previewDTOList;
        }
        if (filesName === 'beschaedigungsfotos') {
          dataIterator = data;
        }

        if (!dataIterator.length) {
          setShowFilesDialogState({ open: true, title: title, files: files });
        }

        dataIterator.forEach((fileObj) => {
          getAndCostumFiles({ file: fileObj, filesArray: files, authAxios: authAxios }).then((result) => {
            setShowFilesDialogState({ open: true, title: title, files: result });
          });
        });
      })
      .catch((error) => console.log(error));
  };

  // function ->
  const showUploadFilesDialogOnClick = (targetFiles) => {
    if (targetFiles === 'stellplatzfotos') {
      setUploadFilesStellplatzfotosDialogState({ open: true, title: undefined });
    } else if (targetFiles === 'lieferschein') {
      setUploadFilesLieferscheinDialogState({ open: true, title: undefined });
    }
  };

  // function ->
  const showAusgewaehlteSendung = () => {
    // destructuring the needed data to display
    const {
      id,
      lieferant,
      spedition,
      datum,
      sendungsart,
      anzahlDerPakete,
      anzahlDerPaketeProPaketSendung,
      anzahlDerPalette,
      anzahlDerPaketeProPalette,
      sendungBeschaedigt,
      beschreibungDerBeschaedigung,
      empfangsbestaetigung,
      erstellungsDatum
    } = ausgewaehlteSendungState.sendung;

    // array -> put the destructured data in an array to map on them
    const values = [
      { name: 'nummer', value: id },
      { name: 'lieferant', value: lieferant },
      { name: 'spedition', value: spedition },
      { name: 'datum', value: datum },
      { name: 'sendungsart', value: sendungsart },
      { name: 'anzahlDerPakete', value: anzahlDerPakete },
      { name: 'anzahlDerPaketeProPaketSendung', value: anzahlDerPaketeProPaketSendung },
      { name: 'anzahlDerPalette', value: anzahlDerPalette },
      { name: 'anzahlDerPaketeProPalette', value: anzahlDerPaketeProPalette },
      { name: 'sendungBeschaedigt', value: sendungBeschaedigt },
      { name: 'beschreibungDerBeschaedigung', value: beschreibungDerBeschaedigung },
      { name: 'empfangsbestaetigung', value: empfangsbestaetigung },
      { name: 'erstellungsDatum', value: erstellungsDatum },
      { name: 'dateien' }
    ];
    return (
      <TableRow>
        {values.map((value, key) => {
          if (value.name === 'dateien') {
            return (
              <TableCell align="center" key={`wareneingang_einlagerung_ausgewaehlteSendung_TableCell_${key}`}>
                <ButtonGroup>
                  <Button onClick={(event) => showFilesDialogOnClick(event, id, 'beschaedigungsfotos')} className="button__small" variant="outlined" color="primary">
                    {language('BUTTON', 'TITEL', 'BESCHAEDIGUNGSFOTOS', objGlobalState.lang)}
                  </Button>
                  <Button onClick={(event) => showFilesDialogOnClick(event, id, 'begleitpapiere')} className="button__small" variant="outlined" color="primary">
                    {language('BUTTON', 'TITEL', 'BEGLEITPAPIERE', objGlobalState.lang)}
                  </Button>
                </ButtonGroup>
              </TableCell>
            );
          } else {
            return (
              <TableCell align="center" key={`wareneingang_einlagerung_sendungen_TableCell_${key}`} className="table__rowTitle">
                <Typography className="typography__singleTableRowContent">{value.value}</Typography>
              </TableCell>
            );
          }
        })}
      </TableRow>
    );
  };

  // function ->
  const removeAusgewahlteSendungAfterConfirm = () => {
    setAusgewaehlteSendungState({ chosen: false, sendung: {} });
  };

  // function ->
  const submitFormOnClick = (event) => {
    event.preventDefault();

    const stellplatzkennzeichnung = stellplatzkennzeichnungState;
    const sendung = ausgewaehlteSendungState.sendung?.id;

    setNewDataValueState({ stellplatzkennzeichnung, sendung });
    setConfirmationDialogState(true);
  };

  // function ->
  const closeConfirmationOnClick = () => {
    setConfirmationDialogState(false);
  };

  // function ->
  const confirmed = () => {
    objGlobalState.setPageLoadingState(true);

    const { stellplatzkennzeichnung } = newDataValueState;
    const sendungId = ausgewaehlteSendungState.sendung?.id;
    const stellplatzfotos = stellplatzfotosState.map((stellplatzfoto) => stellplatzfoto.fileRef);
    const lieferscheine = lieferscheinState.map((lieferschein) => lieferschein.fileRef);

    const formData = new FormData();

    formData.append('stellplatz', stellplatzkennzeichnung);
    stellplatzfotos.forEach((stellplatzfoto) => {
      formData.append('fotos', stellplatzfoto);
    });
    lieferscheine.forEach((lieferschein) => {
      formData.append('lieferscheine', lieferschein);
    });

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    authAxios
      .post(`/delivery/${sendungId}/einlagerung`, formData, { headers: { 'Content-Type': 'multipart/form-data;' } })
      .then(() => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT', objGlobalState.lang), { variant: 'success' });

        setStellplatzfotosState([]);
        setLieferscheinState([]);
        closeConfirmationOnClick();
        removeAusgewahlteSendungAfterConfirm();
        setRerunEffectState((pre) => !pre);
      })
      .catch((error) => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
        console.error(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  const stellplatzfotosSectionTitle = 'STELLPLATZFOTOS';
  const stellplatzfotosSection = { sectionTitle: stellplatzfotosSectionTitle, files: stellplatzfotosState };

  const lieferscheinSectionTitle = 'LIEFERSCHEIN';
  const lieferscheinSection = { sectionTitle: lieferscheinSectionTitle, files: lieferscheinState };

  const confirmationGallery = [stellplatzfotosSection, lieferscheinSection];

  const objEinlagerung = {
    confirmation: {
      closeConfirmationOnClick,
      confirmationDialogState,
      confirmed,
      confirmationGallery,
      newDataValueState
    },
    lieferungtabelle: {
      setAusgewaehlteSendungState,
      rerunEffectState
    },
    multipleScanner: {
      stellplatzkennzeichnungState,
      setStellplatzkennzeichnungState
    },
    showFiles: { showFilesDialogState, setShowFilesDialogState }
  };

  return (
    <>
      {uploadFilesStellplatzfotosDialogState.open && (
        <CoUploadFiles
          objGlobalState={objGlobalState}
          objParentState={{
            filesState: stellplatzfotosState,
            setFilesState: setStellplatzfotosState,
            uploadFilesDialogState: uploadFilesStellplatzfotosDialogState,
            setUploadFilesDialogState: setUploadFilesStellplatzfotosDialogState
          }}
        />
      )}
      {uploadFilesLieferscheinDialogState.open && (
        <CoUploadFiles
          objGlobalState={objGlobalState}
          title="LIEFERSCHEINHOCHLADEN"
          objParentState={{
            filesState: lieferscheinState,
            setFilesState: setLieferscheinState,
            uploadFilesDialogState: uploadFilesLieferscheinDialogState,
            setUploadFilesDialogState: setUploadFilesLieferscheinDialogState
          }}
        />
      )}
      {showFilesDialogState.open && <CoShowFiles objGlobalState={objGlobalState} objParentState={objEinlagerung.showFiles} />}
      {confirmationDialogState && <CoConfirmation objGlobalState={objGlobalState} objParentState={objEinlagerung.confirmation} />}

      <Grid item xs={12} className="marginThirty">
        <Grid item xs={12}>
          <CoLieferungtabelle
            objGlobalState={objGlobalState}
            objParentState={objEinlagerung.lieferungtabelle}
            options={{ heightFourty: true, noBorderTop: true, selectLieferung: true, isWareneingangEinlagerung: true }}
          />
        </Grid>
        <Grid item xs={12} className="marginThirty">
          <Typography className="typography__sectionTitle">{language('ABTEILUNG', 'TITEL', 'SENDUNG', objGlobalState.lang)}</Typography>
        </Grid>
        <Grid item xs={12} className="marginTwenty">
          {ausgewaehlteSendungState.chosen ? (
            <TableContainer component={Paper} variant="outlined" elevation={0} className="table__container">
              <Table size="medium">
                <TableBody>{showAusgewaehlteSendung()}</TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography className="typography__colorDamage">{language('KONTENT', 'TEXT', 'KEINESENDUNGAUSGEWAEHLT', objGlobalState.lang)}</Typography>
          )}
        </Grid>
        <form name="submitForm" noValidate autoComplete="on" onSubmit={submitFormOnClick}>
          <Grid item xs={12} container spacing={3} justify="flex-start" alignContent="center" alignItems="center" className="marginThirty">
            <Grid item xs={12}>
              <Typography className="typography__sectionTitle">{language('ABTEILUNG', 'TITEL', 'STELLPLATZ', objGlobalState.lang)}</Typography>
            </Grid>
            <CoMultipleScanner objGlobalState={objGlobalState} objParentState={objEinlagerung.multipleScanner} />
            <Grid item xs={12}>
              <Button onClick={() => showUploadFilesDialogOnClick('stellplatzfotos')} className="button__middle" variant="outlined" color="primary">
                {language('BUTTON', 'TITEL', 'DATEIENHOCHLADEN', objGlobalState.lang)}
              </Button>
            </Grid>
            <CoDisplayFilesList objGlobalState={objGlobalState} filesState={stellplatzfotosState} setFilesState={setStellplatzfotosState} title="STELLPLATZFOTOS" />
          </Grid>
          <Grid item xs={12} container spacing={3} justify="flex-start" alignContent="center" alignItems="center" className="marginThirty border__top">
            <Grid item xs={12}>
              <Typography className="typography__sectionTitle">{language('ABTEILUNG', 'TITEL', 'LIEFERSCHEIN', objGlobalState.lang)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={() => showUploadFilesDialogOnClick('lieferschein')} align="center" className="button__middle" variant="outlined" color="primary">
                {language('BUTTON', 'TITEL', 'LIEFERSCHEINHOCHLADEN', objGlobalState.lang)}
              </Button>
            </Grid>
            <CoDisplayFilesList objGlobalState={objGlobalState} filesState={lieferscheinState} setFilesState={setLieferscheinState} title="LIEFERSCHEIN" />
            <Grid item xs={12}>
              <Button fullWidth type="submit" color="primary" className="button__middle--greenFilled">
                {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
};

export default CoEinlagerungNoTransporteinheit;
