import PDFBarcodeJs, { PDFJS } from '../pdfScanner/pdf-barcode';

PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.min.js`;

// create and populate configs variable
const configs = {
  scale: {
    once: true,
    value: 3,
    start: 3,
    step: 0.6,
    stop: 4.8
  },
  resultOpts: {
    singleCodeInPage: true,
    multiCodesInPage: false,
    maxCodesInPage: 1
  },
  patches: ['x-small', 'small', 'medium'],
  improve: true,
  noisify: true,
  quagga: {
    inputStream: {},
    locator: {
      halfSample: false
    },
    decoder: {
      readers: ['code_128_reader', 'code_39_reader', 'code_39_vin_reader', 'ean_reader', 'upc_reader', 'ean_8_reader', 'upc_e_reader', 'i2of5_reader', '2of5_reader', 'code_93_reader'],
      multiple: true
    },
    locate: true
  }
};

const pageNr = 1;

export const scanPdf = (pdfFile, callback) => {
  PDFBarcodeJs.decodeSinglePage(pdfFile, pageNr, configs, callback);
};
