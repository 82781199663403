import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import language from '../../Common/language/language';
import { mouseScrollHorizontally } from '../../Common/helperFunctions/mouseScrollHorizontally';
import { createAxiosInstance } from '../../Common/helperFunctions/axios';
import { generateDate } from '../../Common/helperFunctions/date';
import { useSnackbar } from 'notistack';

// import -> material ui -> core & icons
import { Typography, Paper, TableRow, TableCell, TableBody, TableContainer, Table, IconButton, TableHead } from '@material-ui/core';
import { DeleteForever, Edit } from '@material-ui/icons';

// import -> components
import CoLoeschenwarnungDialog from '../../Common/Components/CoLoeschenwarnungDialog';
import CoBreachtigungbearbeitungDialog from './CoBreachtigungbearbeitungDialog';

// Component -> React root
const CoBerechtigungtabelle = ({ objGlobalState, objParentState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const [loeschenwarnungDialogState, setLoeschenwarnungDialogState] = useState({ open: false, deletedDataLabel: '' });
  const [bearbeitungDialogState, setBearbeitungDialogState] = useState({ open: false, berechtigungId: '' });
  const [focusedDataIdState, setFocusedDataIdState] = useState('');

  const tableHeaders = ['BENUTZER', 'ABLAUFDATUM', 'BEARBEITEN', 'LOESCHEN'];
  const tableRef = useRef();

  // function ->
  const handleDelteOnClick = (berechtigungId) => {
    setLoeschenwarnungDialogState({ open: true, deletedDataLabel: berechtigungId });
    setFocusedDataIdState(berechtigungId);
  };

  // function ->
  const handleBearbeitungOnClick = (berechtigungId) => {
    setBearbeitungDialogState({ open: true, berechtigungId: berechtigungId });
  };

  // function ->
  const mapBerechtigungenToState = (berechtigungen) => {
    const newState = [];

    berechtigungen.forEach((berechtigung) => {
      const ablaufdatum = generateDate(berechtigung.freigabeBis).justDate;
      const newDataObj = {
        id: berechtigung.id,
        ablaufdatum: ablaufdatum,
        benutzer: berechtigung.userName
      };

      newState.unshift(newDataObj);
    });

    objParentState.setBerechtigungenState(newState);
  };

  // function ->
  const deleteDataOnClick = () => {
    const berechtigungId = focusedDataIdState;
    authAxios
      .delete(`/file-freigabe/${berechtigungId}`)
      .then(() => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGELOESCHT', objGlobalState.lang), { variant: 'default' });
        objParentState.forceRerender();
      })
      .catch((error) => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
        console.log(error);
      });
  };

  // function ->
  const getBerechtigungenFromAPI = () => {
    const fileId = objParentState.berechtigungdatei.preview_id;

    authAxios
      .get(`/file-freigabe/${fileId}`)
      .then((response) => {
        const berechtigungen = response.data;

        mapBerechtigungenToState(berechtigungen);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getBerechtigungenFromAPI();
    }

    return () => (isMounted = false);
  }, [objParentState.forceRerenderState]);

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, []);

  const objBerechtigungentabelle = {
    ...objParentState,
    bearbeitungDialogState,
    setBearbeitungDialogState,
    loeschenwarnungDialogState,
    setLoeschenwarnungDialogState,
    deleteDataOnClick
  };

  return (
    <>
      {loeschenwarnungDialogState.open && <CoLoeschenwarnungDialog objGlobalState={objGlobalState} objParentState={objBerechtigungentabelle} />}
      {bearbeitungDialogState.open && <CoBreachtigungbearbeitungDialog objGlobalState={objGlobalState} objParentState={objBerechtigungentabelle} />}
      <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className="table__container">
        <Table size="medium" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow className="table__title">
              {tableHeaders.map((header, key) => {
                return (
                  <TableCell align="center" component="th" className="table__headerCell" key={`qualitaetssicherung_berechtigungen_TableHeaders_${key}`}>
                    <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', header, objGlobalState.lang)}</Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {objParentState.berechtigungenState.map((item, key) => {
              // destructuring the needed data to display
              const { id, benutzer, ablaufdatum } = item;

              // array -> put the destructured data in an array to map on them
              const values = [
                { name: 'benutzer', value: benutzer },
                { name: 'ablaufdatum', value: ablaufdatum },
                { name: 'bearbeiten', value: '' },
                { name: 'loeschen', value: '' }
              ];

              return (
                <TableRow key={`qualitaetssicherung_berechtigungen_TableRow_${key}`}>
                  {values.map((value, key) => {
                    if (value.name === 'bearbeiten') {
                      return (
                        <TableCell align="center" key={`qualitaetssicherung_berechtigungen_TableCell_${key}`}>
                          <IconButton
                            onClick={() => handleBearbeitungOnClick(id)}
                            className="button__icon--tableGreen"
                            title={language('BUTTON', 'TITEL', 'BEARBEITEN', objGlobalState.lang)}
                            size="small"
                          >
                            <Edit />
                          </IconButton>
                        </TableCell>
                      );
                    } else if (value.name === 'loeschen') {
                      return (
                        <TableCell align="center" key={`qualitaetssicherung_berechtigungen_TableCell_${key}`}>
                          <IconButton onClick={() => handleDelteOnClick(id)} className="button__icon--tableRed" title={language('BUTTON', 'TITEL', 'LOESCHEN', objGlobalState.lang)} size="small">
                            <DeleteForever />
                          </IconButton>
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell align="center" key={`qualitaetssicherung_berechtigungen_TableCell_${key}`}>
                          <Typography className="typography__singleTableRowContent">{value.value}</Typography>
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CoBerechtigungtabelle;
