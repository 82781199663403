import React, { useLayoutEffect, useRef } from 'react';
import language from '../../Common/language/language';
import { mouseScrollHorizontally } from '../../Common/helperFunctions/mouseScrollHorizontally';

// import -> material ui -> core, icons
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';

// component -> React-root
const CoTrackingInformation = ({ objTrackingState, objGlobalState }) => {
  const tableRef = useRef();

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, []);

  return (
    <>
      <Box className="table__title">
        <Typography align="center" className="typography__singleTableTitle">
          {language('TABLE', 'HEADER', 'TRACKINGINFORMATION', objGlobalState.lang)}
        </Typography>
      </Box>
      <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className="table__container">
        <Table size="medium">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" className="table__rowTitle">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'TRACKINGNUMMER', objGlobalState.lang)}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className="typography__singleTableRowContent">{objTrackingState.trackingState.trackingNr}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" className="table__rowTitle">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'DIENSTLEISTER', objGlobalState.lang)}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className="typography__singleTableRowContent">{objTrackingState.trackingState.dienstleister}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" className="table__rowTitle">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'PACKSTUEKANZAHL', objGlobalState.lang)}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className="typography__singleTableRowContent">{objTrackingState.trackingState.packStueckAnzahl}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" className="table__rowTitle">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'GEWICHTKG', objGlobalState.lang)}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className="typography__singleTableRowContent">{objTrackingState.trackingState.gewicht}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" className="table__rowTitle">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'BESCHREIBUNG', objGlobalState.lang)}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className="typography__singleTableRowContent">{objTrackingState.trackingState.beschreibung}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CoTrackingInformation;
