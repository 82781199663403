import React, { useState, Fragment } from 'react';
import language from '../Common/language/language';
import ServiceBreadcrumbs from '../Common/services/Breadcrumbs/ServiceBreadcrumbs';
import { Button, Container, Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import CoNotificationsTable from '../Common/Components/Warenannahme/CoAvisierungtabelle';
import CoCreateNotification from './Append/CoCreateNotification';

// component -> React-root
const CoWarenannahmeavisierung = ({ objGlobalState }) => {
  const [createDialogState, setCreateDialogState] = useState(false);

  const [rerunEffectState, setRerunEffectState] = useState(false);

  // function ->
  const showCreateDialogOnClick = () => {
    setCreateDialogState(true);
  };

  // function ->
  const rerunEffect = () => {
    setRerunEffectState((pre) => !pre);
  };

  // array -> create breadcrumbs
  const arrBreadcrumbs = [
    { url: '/', title: language('SEITE', 'TITEL', 'HOME', objGlobalState.lang) },
    { url: '/warenannahme_avisierung', title: language('SEITE', 'TITEL', 'WARENANNAHMEAVISIERUNG', objGlobalState.lang) }
  ];

  const objWarenannahmeavisierungState = {
    createDialogState,
    setCreateDialogState,
    rerunEffectState,
    rerunEffect
  };

  return (
    <Fragment>
      {createDialogState && <CoCreateNotification objGlobalState={objGlobalState} objWarenannahmeavisierungState={objWarenannahmeavisierungState} />}
      <Container component="main" maxWidth="xl" className="container__container">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ServiceBreadcrumbs arrBreadcrumbs={arrBreadcrumbs} />
          </Grid>
          <Grid item xs={12}>
            <ListItem className="container__listItem">
              <ListItemIcon>
                <img src={`../assets/images/icons/icon_avisierung.png`} alt="" className="images__headerIcon" />
              </ListItemIcon>
              <ListItemText>
                <Typography className="typography__headerTitle">{language('SEITE', 'TITEL', 'WARENANNAHMEAVISIERUNG', objGlobalState.lang)}</Typography>
              </ListItemText>
            </ListItem>
          </Grid>
          <Grid item xs={12} container justify="flex-end">
            <Button data-testid="erstellen_button_avisierung" onClick={showCreateDialogOnClick} className="button__middle" variant="contained" color="primary">
              {language('BUTTON', 'TITEL', 'ERSTELLEN', objGlobalState.lang)}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <CoNotificationsTable objGlobalState={objGlobalState} objParentState={objWarenannahmeavisierungState} />
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default CoWarenannahmeavisierung;
