import React, { useState } from 'react';
import language from '../../language/language';
import generateArtikelEntityLabel from '../../helperFunctions/generateArtikelEntityLabel';
import { createAxiosInstance } from '../../helperFunctions/axios';
import { useSnackbar } from 'notistack';
import { TABLE_TYPES } from '../../types/types';

// import -> for (drag and drop)
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';

// import -> material ui -> core && icons
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { useEffect } from 'react';

const ItemTypes = {
  SPALTE: 'splate'
};

// component -> React-root
const CoSpaltenreihenfolgeDialog = ({ objGlobalState, objParentState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const [spaltenState, setSpaltenState] = useState([]);

  // function ->
  const closeDialogOnClose = () => {
    objParentState.setSpaltenreihenfolgeDialogState(false);
  };

  // function ->
  const findTableInfos = () => {
    let postEndPoint;
    let gewaehltspaltenObjectName;
    switch (objParentState.tableCategory) {
      case TABLE_TYPES.ARTIKELUEBERSICHT:
        postEndPoint = '/usersettings/spalten-artikeluebersicht';
        gewaehltspaltenObjectName = 'spaltenGewaehltArtikeluebersicht';
        break;
      case TABLE_TYPES.BESTANDSUEBERSICHT:
        postEndPoint = '/usersettings/spalten-bestandsuebersicht';
        gewaehltspaltenObjectName = 'spaltenGewaehltBestandsuebersicht';
        break;
      case TABLE_TYPES.TRANSPORTEINHEITEN:
        postEndPoint = '/usersettings/spalten-transporteinheit-artikel';
        gewaehltspaltenObjectName = 'spaltenGewaehltTransporteinheitenProArtikel';
        break;
    }

    return { postEndPoint, gewaehltspaltenObjectName };
  };

  // function ->
  const sendSelectedTableSpalten = () => {
    const requestBody = spaltenState.map((spalte) => spalte.name);
    objGlobalState.setPageLoadingState(true);
    authAxios
      .post(findTableInfos().postEndPoint, requestBody)
      .then(() => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT'), { variant: 'success' });
        closeDialogOnClose();
        objParentState.setRerunEffectState((pre) => !pre);
      })
      .catch((error) => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
        console.log(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const submitFormOnClick = (event) => {
    event.preventDefault();
    sendSelectedTableSpalten();
  };

  // function ->
  const fetchSelectedSpaltenFromAPI = () => {
    authAxios
      .get('/usersettings')
      .then((response) => {
        const selectedTableSpalten = response.data[findTableInfos().gewaehltspaltenObjectName];
        const newSpaltenState = selectedTableSpalten.map((spalte, index) => ({ name: spalte, index, label: generateArtikelEntityLabel(spalte) }));

        setSpaltenState(newSpaltenState);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    fetchSelectedSpaltenFromAPI();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Dialog fullWidth maxWidth="md" open={objParentState.spaltenreihenfolgeDialogState} onClose={closeDialogOnClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Typography align="left" className="typography__dialogTitle">
              {language('DIALOG', 'TITEL', 'SPALTENREIHENFOLGE', objGlobalState.lang)}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className="dialog__content">
        <DndProvider backend={Backend}>
          <form name="submitForm" noValidate autoComplete="on" onSubmit={submitFormOnClick}>
            <Grid container spacing={3}>
              {spaltenState.map((spalte, index) => {
                return <SpaltenBox key={`artikelverwaltung_artikeluebersicht_spaltenreihenfolge_${index}`} spalte={spalte} spaltenState={spaltenState} setSpaltenState={setSpaltenState} />;
              })}
              <Grid item xs={12}></Grid>
              <Grid item xs={12} md={6}>
                <Button fullWidth type="submit" variant="contained" color="primary" className="button__middle">
                  {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button onClick={closeDialogOnClose} fullWidth className="button__middle">
                  {language('BUTTON', 'TITEL', 'ABBRECHEN', objGlobalState.lang)}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DndProvider>
      </DialogContent>
    </Dialog>
  );
};

const SpaltenBox = ({ spalte: propsSpalte, spaltenState, setSpaltenState }) => {
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ItemTypes.SPALTE,
      spalte: propsSpalte
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.SPALTE,
    drop: (item, monitor) => rearrangeSpaltenreihenfolge(item.spalte),
    collect: (monitor) => ({
      isOver: !!monitor.isOver()
    })
  });

  // function ->
  const rearrangeSpaltenreihenfolge = (draggedSpalte) => {
    const currentSpaltenState = [...spaltenState];
    if (draggedSpalte.name === propsSpalte.name) {
      return;
    }
    const propsSpalteIndex = propsSpalte.index;
    const draggedSpalteIndex = draggedSpalte.index;
    const draggedSpalteIndexBigger = draggedSpalte.index > propsSpalte.index;

    const newSpaltenState = currentSpaltenState
      .map((spalte) => {
        if (spalte.name === draggedSpalte.name) {
          spalte.index = propsSpalteIndex;
        } else if (draggedSpalteIndexBigger) {
          if (spalte.name === propsSpalte.name) {
            spalte.index = propsSpalteIndex + 1;
          } else if (spalte.index > propsSpalteIndex && spalte.index < draggedSpalteIndex) {
            spalte.index++;
          }
        } else if (!draggedSpalteIndexBigger) {
          if (spalte.name === propsSpalte.name) {
            spalte.index = propsSpalteIndex - 1;
          } else if (spalte.index < propsSpalteIndex && spalte.index > draggedSpalteIndex) {
            spalte.index--;
          }
        }
        return spalte;
      })
      .sort((a, b) => (a.index > b.index ? 1 : -1));

    setSpaltenState(newSpaltenState);
  };

  return (
    <Grid item xs="auto" ref={drop}>
      <Box ref={drag} style={{ opacity: isDragging ? '0.5' : '1', cursor: 'grab' }}>
        <Typography className="typography">{propsSpalte.label}</Typography>
      </Box>
    </Grid>
  );
};

export default CoSpaltenreihenfolgeDialog;
