import React, { useLayoutEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import language from '../../Common/language/language';
import { mouseScrollHorizontally } from '../../Common/helperFunctions/mouseScrollHorizontally';
import { createAxiosInstance } from '../../Common/helperFunctions/axios';

// import -> material ui -> core & icons
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';

// import -> components
import CoUploadFiles from '../../Common/Components/CoUploadFiles';
import CoConfirmation from '../../Common/Components/CoConfirmation';
import CoDisplayFilesList from '../../Common/Components/CoDisplayFilesList';
import CoMultipleScanner from '../../Common/Components/CoMultipleScanner';
import CoAuftragstabelle from '../../Common/Components/Auftragsverwaltung/CoAuftragstabelle';

// component -> React-root
const CoKommissionierungWithTransporteinheit = ({ objGlobalState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [ausgewaehlterAuftragState, setAusgewaehlterAuftragState] = useState({ ausgewaehlt: false, auftrag: {} });
  const [confirmationDialogState, setConfirmationDialogState] = useState(false);
  const [uploadFilesStellplatzfotosDialogState, setUploadFilesStellplatzfotosDialogState] = useState({ open: false, title: '' });
  const [uploadFilesLieferscheinDialogState, setUploadFilesLieferscheinDialogState] = useState({ open: false, title: '' });
  const [stellplatzfotosState, setStellplatzfotosState] = useState([]);
  const [lieferscheinState, setLieferscheinState] = useState([]);
  const [stellplatzkennzeichnungState, setStellplatzkennzeichnungState] = useState('');
  const [newDataValueState, setNewDataValueState] = useState({
    stellplatzkennzeichnung: '',
    auftragsnummer: ''
  });

  const tableRef = useRef();

  // function ->
  const showUploadFilesDialogOnClick = (targetFiles) => {
    if (targetFiles === 'stellplatzfotos') {
      setUploadFilesStellplatzfotosDialogState({ open: true, title: undefined });
    } else if (targetFiles === 'lieferschein') {
      setUploadFilesLieferscheinDialogState({ open: true, title: undefined });
    }
  };

  // function ->
  const closeConfirmationOnClick = () => {
    eval('setConfirmationState(false)');
  };

  // function ->
  const removeAusgewahlteAufAtragAfterConfirm = () => {
    setAusgewaehlterAuftragState({});
  };

  // function ->
  const submitFormOnClick = (event) => {
    event.preventDefault();

    const stellplatzkennzeichnung = stellplatzkennzeichnungState;
    const auftragsnummer = ausgewaehlterAuftragState.auftrag.auftragsnummer;

    setNewDataValueState({ stellplatzkennzeichnung, auftragsnummer });
    setConfirmationDialogState(true);
  };

  // function ->
  const confirmed = () => {
    const { stellplatzkennzeichnung } = newDataValueState;
    const auftragId = ausgewaehlterAuftragState.auftrag.id;

    const formData = new FormData();
    formData.append('stellplatz', stellplatzkennzeichnung);

    const stellplatzfotos = stellplatzfotosState.map((foto) => foto.fileRef);
    stellplatzfotos.forEach((foto) => {
      formData.append('fotos', foto);
    });

    const lieferscheine = lieferscheinState.map((lieferschein) => lieferschein.fileRef);
    lieferscheine.forEach((lieferschein) => {
      formData.append('lieferscheine', lieferschein);
    });

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    authAxios
      .post(`/auftrag/${auftragId}/kommissionierung`, formData, { headers: { 'Content-Type': 'multipart/form-data;' } })
      .then(() => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT'), { variant: 'success' });
        removeAusgewahlteAufAtragAfterConfirm();
        setAusgewaehlterAuftragState({ ausgewaehlt: false, auftrag: {} });
        closeConfirmationOnClick();
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const showAusgewaehlterAuftrag = () => {
    // destructuring the needed data to display
    const { auftragsnummer, kunde, spedition, datum, sendungsart, anzahlDerPakete, anzahlDerPalette } = ausgewaehlterAuftragState.auftrag;

    // array -> put the destructured data in an array to map on them
    const values = [
      { name: 'auftragsnummer', value: auftragsnummer },
      { name: 'kunde', value: kunde },
      { name: 'spedition', value: spedition },
      { name: 'datum', value: datum },
      { name: 'sendungsart', value: sendungsart },
      { name: 'anzahlDerPakete', value: anzahlDerPakete },
      { name: 'anzahlDerPalette', value: anzahlDerPalette }
    ];
    return (
      <TableRow>
        {values.map((value, key) => {
          return (
            <TableCell align="center" key={`warenannahme_status_sendungen_TableCell_${key}`} className="table__rowTitle">
              <Typography className="typography__singleTableRowContent">{value.value}</Typography>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, []);

  const stellplatzfotosSectionTitle = 'STELLPLATZFOTOS';
  const stellplatzfotosSection = { sectionTitle: stellplatzfotosSectionTitle, files: stellplatzfotosState };

  const lieferscheinSectionTitle = 'LIEFERSCHEIN';
  const lieferscheinSection = { sectionTitle: lieferscheinSectionTitle, files: lieferscheinState };

  const confirmationGallery = [stellplatzfotosSection, lieferscheinSection];

  const objKommissionierungNoTransporteinheit = {
    auftragstabelle: {
      kommissionierung: true,
      heightFour: true,
      setAusgewaehlterAuftragState
    },
    confirmation: {
      closeConfirmationOnClick,
      confirmationDialogState,
      confirmed,
      confirmationGallery,
      newDataValueState
    },
    multipleScanner: {
      setStellplatzkennzeichnungState
    },
    uploadFilesFotos: {
      filesState: stellplatzfotosState,
      setFilesState: setStellplatzfotosState,
      uploadFilesDialogState: uploadFilesStellplatzfotosDialogState,
      setUploadFilesDialogState: setUploadFilesStellplatzfotosDialogState
    },
    uploadFilesLieferschein: {
      filesState: lieferscheinState,
      setFilesState: setLieferscheinState,
      uploadFilesDialogState: uploadFilesLieferscheinDialogState,
      setUploadFilesDialogState: setUploadFilesLieferscheinDialogState
    }
  };

  return (
    <>
      {uploadFilesStellplatzfotosDialogState && <CoUploadFiles objGlobalState={objGlobalState} objParentState={objKommissionierungNoTransporteinheit.uploadFilesFotos} />}
      {uploadFilesLieferscheinDialogState && (
        <CoUploadFiles objGlobalState={objGlobalState} title="LIEFERSCHEINHOCHLADEN" objParentState={objKommissionierungNoTransporteinheit.uploadFilesLieferschein} />
      )}
      {confirmationDialogState && <CoConfirmation objGlobalState={objGlobalState} objParentState={objKommissionierungNoTransporteinheit.confirmation} />}
      <Grid item xs={12} className="marginThirty">
        <Grid item xs={12}>
          <CoAuftragstabelle objGlobalState={objGlobalState} objParentState={objKommissionierungNoTransporteinheit.auftragstabelle} />
        </Grid>
        <Grid item xs={12} className="marginFourty">
          <Typography className="typography__sectionTitle">{language('ABTEILUNG', 'TITEL', 'AUFTRAG', objGlobalState.lang)}</Typography>
        </Grid>
        <Grid item xs={12} className="marginTwenty">
          {ausgewaehlterAuftragState.ausgewaehlt ? (
            <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} className="table__container">
              <Table size="medium">
                <TableBody>{showAusgewaehlterAuftrag()}</TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography className="typography__colorDamage">{language('KONTENT', 'TEXT', 'KEINAUFTRAGAUSGEWAEHLT', objGlobalState.lang)}</Typography>
          )}
        </Grid>
        <form name="submitForm" noValidate autoComplete="on" onSubmit={submitFormOnClick}>
          <Grid item xs={12} container spacing={3} justify="flex-start" alignContent="center" alignItems="center" className="marginThirty">
            <Grid item xs={12}>
              <Typography className="typography__sectionTitle">{language('ABTEILUNG', 'TITEL', 'STELLPLATZ', objGlobalState.lang)}</Typography>
            </Grid>
            <CoMultipleScanner objGlobalState={objGlobalState} objParentState={objKommissionierungNoTransporteinheit.multipleScanner} />
            <Grid item xs={12}>
              <Button onClick={() => showUploadFilesDialogOnClick('stellplatzfotos')} className="button__middle" variant="outlined" color="primary">
                {language('BUTTON', 'TITEL', 'DATEIENHOCHLADEN', objGlobalState.lang)}
              </Button>
            </Grid>
            <CoDisplayFilesList objGlobalState={objGlobalState} filesState={stellplatzfotosState} setFilesState={setStellplatzfotosState} title="STELLPLATZFOTOS" />
          </Grid>
          <Grid item xs={12} container spacing={3} justify="flex-start" alignContent="center" alignItems="center" className="marginThirty border__top">
            <Grid item xs={12}>
              <Typography className="typography__sectionTitle">{language('ABTEILUNG', 'TITEL', 'LIEFERSCHEIN', objGlobalState.lang)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={() => showUploadFilesDialogOnClick('lieferschein')} align="center" className="button__middle" variant="outlined" color="primary">
                {language('BUTTON', 'TITEL', 'LIEFERSCHEINHOCHLADEN', objGlobalState.lang)}
              </Button>
            </Grid>
            <CoDisplayFilesList objGlobalState={objGlobalState} filesState={lieferscheinState} setFilesState={setLieferscheinState} title="LIEFERSCHEIN" />
            <Grid item xs={12}>
              <Button fullWidth type="submit" color="primary" className="button__middle" variant="contained" color="primary">
                {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
};

export default CoKommissionierungWithTransporteinheit;
