import React from 'react';
import language from '../../Common/language/language';
import { handleBeforeUnload } from '../../Common/eventHanlders/eventHandlers';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

// import -> material ui -> core, icons
import { Accordion, AccordionSummary, AccordionDetails, Button, Grid, Typography } from '@material-ui/core';
import { ExitToApp, ExpandMore } from '@material-ui/icons';

// component -> React-root
const CoKontoSettings = ({ objGlobalState }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  // function ->
  const signUserOutOnClick = () => {
    enqueueSnackbar(language('ALERT', 'TEXT', 'AUSGELOGGT'), { variant: 'warning' });

    objGlobalState.setAuthenticateState({ auth: false, user: {} });
    objGlobalState.setJwtoken('');
    window.removeEventListener('beforeunload', handleBeforeUnload);
    window.location.reload();
    history.push('/');
  };

  // function ->
  const sendToResetPassword = () => {
    objGlobalState.setEinstellungenDialogState(false);
    history.push('/resetpassword');
  };

  return (
    <Accordion className="accordion">
      <AccordionSummary className="accordion__summary" expandIcon={<ExpandMore />}>
        <Typography align="center" className="typography__accordionSummary">
          {language('ABTEILUNG', 'TITEL', 'KONTO', objGlobalState.lang)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button className="marginTwenty" onClick={signUserOutOnClick} variant="outlined" color="primary" size="large">
              <Typography>{language('BUTTON', 'TITEL', 'ABMELDEN', objGlobalState.lang)}</Typography>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button className="marginTwenty" onClick={sendToResetPassword} variant="outlined" color="primary" size="large">
              <Typography>{language('BUTTON', 'TITEL', 'PASSWORTZURUECKSETZEN', objGlobalState.lang)}</Typography>
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CoKontoSettings;
