import React, { Fragment, useLayoutEffect, useRef, useState } from 'react';
import language from '../../Common/language/language';
import { mouseScrollHorizontally } from '../../Common/helperFunctions/mouseScrollHorizontally';
import { createAxiosInstance } from '../../Common/helperFunctions/axios';

// import -> material ui -> core & icons
import { Typography, Paper, TableRow, TableCell, TableBody, TableContainer, Table, Box, Button, Dialog, DialogContent, IconButton, Grid, Tabs, Tab, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';

// import -> components
import CoBerechtigungenDialog from './CoBerechtigungenDialog';

// component -> React-root
const CoDocumentsTable = ({ objGlobalState, objQualitaetssicherungStateAndFunctions }) => {
  const { benutzerIstAdmin } = objQualitaetssicherungStateAndFunctions;
  const { DOKUMENTEKATEGORIE } = objQualitaetssicherungStateAndFunctions;

  const [previewDialogState, setPreviewDialogState] = useState({ open: false, image: {} });
  const [berechtigungenDialogState, setBerechtigungenDialogState] = useState({ open: false, file: {} });
  const [dokumentenkategorieState, setDokumentenkategorieState] = useState(DOKUMENTEKATEGORIE.MITTEILUNG);

  const tableRef = useRef();

  // function ->
  const checkIfCategoryEmpty = (category) => {
    return !objQualitaetssicherungStateAndFunctions.documentsState[category].length > 0;
  };

  // function ->
  const showPreviewOnClick = (preview) => {
    const previewName = preview.preview_name;
    if (!benutzerIstAdmin) {
      return;
    }
    objGlobalState.setPageLoadingState(true);

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    authAxios
      .get(`/file/${preview.preview_id}`, { responseType: 'blob' })
      .then((response) => {
        const blob = response.data;
        const url = URL.createObjectURL(blob);

        setPreviewDialogState({ open: true, image: { previewUrl: url, previewName: previewName } });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const openBerechtigungDialogOnClick = (file) => {
    setBerechtigungenDialogState({ open: true, file: file });
  };

  // function ->
  const handleDokumententartOnChange = (event, tabValue) => {
    setDokumentenkategorieState(tabValue);
  };

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, []);

  const objParentState = { berechtigungenDialogState, setBerechtigungenDialogState, previewDialogState, setPreviewDialogState };

  return (
    <>
      {previewDialogState.open && <CoPreviewDialog objGlobalState={objGlobalState} objParentState={objParentState} />}
      {berechtigungenDialogState.open && <CoBerechtigungenDialog objGlobalState={objGlobalState} objParentState={objParentState} />}
      <Box className="marginFourty table__title">
        <Typography align="center" className="typography__singleTableTitle">
          {language('TABLE', 'HEADER', 'DOKUMENTE', objGlobalState.lang)}
        </Typography>
      </Box>
      <Box className="table__title">
        <Tabs className="table__headerTabs" value={dokumentenkategorieState} indicatorColor="primary" textColor="primary" onChange={handleDokumententartOnChange}>
          <Tab
            className={`noWrap ${checkIfCategoryEmpty(DOKUMENTEKATEGORIE.VALIDIERUNGSBERICHTLAGER) ? '' : 'fontWeihgtBold'}`}
            disabled={checkIfCategoryEmpty(DOKUMENTEKATEGORIE.VALIDIERUNGSBERICHTLAGER)}
            value={DOKUMENTEKATEGORIE.VALIDIERUNGSBERICHTLAGER}
            label={language('TABLE', 'HEADER', 'VALIDIERUNGSBERICHTLAGER', objGlobalState.lang)}
          />
          <Tab
            className={`noWrap ${checkIfCategoryEmpty(DOKUMENTEKATEGORIE.VALIDIERUNGSBERICHTTRANSPORT) ? '' : 'fontWeihgtBold'}`}
            disabled={checkIfCategoryEmpty(DOKUMENTEKATEGORIE.VALIDIERUNGSBERICHTTRANSPORT)}
            value={DOKUMENTEKATEGORIE.VALIDIERUNGSBERICHTTRANSPORT}
            label={language('TABLE', 'HEADER', 'VALIDIERUNGSBERICHTTRANSPORT', objGlobalState.lang)}
          />
          <Tab
            className={`noWrap ${checkIfCategoryEmpty(DOKUMENTEKATEGORIE.ZERTIFIKAT) ? '' : 'fontWeihgtBold'}`}
            disabled={checkIfCategoryEmpty(DOKUMENTEKATEGORIE.ZERTIFIKAT)}
            value={DOKUMENTEKATEGORIE.ZERTIFIKAT}
            label={language('TABLE', 'HEADER', 'ZERTIFIKATE', objGlobalState.lang)}
          />
          <Tab
            className={`noWrap ${checkIfCategoryEmpty(DOKUMENTEKATEGORIE.MITTEILUNG) ? '' : 'fontWeihgtBold'}`}
            disabled={checkIfCategoryEmpty(DOKUMENTEKATEGORIE.MITTEILUNG)}
            value={DOKUMENTEKATEGORIE.MITTEILUNG}
            label={language('TABLE', 'HEADER', 'MITTEILUNGEN', objGlobalState.lang)}
          />
        </Tabs>
      </Box>
      <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className="table__container">
        <Table size="medium">
          <TableBody>
            {objQualitaetssicherungStateAndFunctions.documentsState[dokumentenkategorieState].map((_document, key) => {
              const benutzerIstBerechtigt = benutzerIstAdmin || _document.preview_id || _document.file_id;
              const showAdminButton =
                benutzerIstAdmin && (dokumentenkategorieState === DOKUMENTEKATEGORIE.VALIDIERUNGSBERICHTLAGER || dokumentenkategorieState === DOKUMENTEKATEGORIE.VALIDIERUNGSBERICHTTRANSPORT);
              return (
                <Fragment key={`qualitaetssicherung__table_${key}`}>
                  <TableRow>
                    <TableCell>
                      <Typography className={benutzerIstBerechtigt ? 'typography__singleTableRowTitle' : 'typography__singleTableRowTitle--disabled'}>{key + 1}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={benutzerIstBerechtigt ? 'typography__singleTableRowTitle' : 'typography__singleTableRowTitle--disabled'}>{_document.file_name.slice(0, -4)}</Typography>
                    </TableCell>
                    {showAdminButton && (
                      <TableCell align="right">
                        <Button disabled={!benutzerIstAdmin} className="button__small" variant="outlined" color="primary" onClick={() => openBerechtigungDialogOnClick(_document)}>
                          {language('BUTTON', 'TITEL', 'BERECHTIGUNGEN', objGlobalState.lang)}
                        </Button>
                      </TableCell>
                    )}
                    <TableCell align="right">
                      <Button disabled={!benutzerIstBerechtigt} className="button__small" variant="outlined" color="primary" onClick={() => showPreviewOnClick(_document)}>
                        {language('BUTTON', 'TITEL', 'VORSCHAU', objGlobalState.lang)}
                      </Button>
                    </TableCell>
                  </TableRow>
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CoDocumentsTable;

const CoPreviewDialog = ({ objGlobalState, objParentState }) => {
  //function ->
  const closePreviewDialogOnClose = () => {
    objParentState.setPreviewDialogState((pre) => {
      return {
        ...pre,
        open: false
      };
    });
  };

  // function ->
  const handleImageContextMenuOnContextMenu = (event) => {
    // prevent image download by preventing the context menu on mouse right-bottom click
    event.preventDefault();
  };

  return (
    <Dialog fullWidth maxWidth="xl" onClose={closePreviewDialogOnClose} open={objParentState.previewDialogState.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle className="dialog__titleAsHeader" id="alert-dialog-title">
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Typography className="typography">{objParentState.previewDialogState.image.previewName}</Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <IconButton className="button__iconButton--close" onClick={closePreviewDialogOnClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className="dialog__content">
        <img src={objParentState.previewDialogState.image.previewUrl} className="images__fullWidthPreview" draggable={false} alt="" onContextMenu={handleImageContextMenuOnContextMenu} />
      </DialogContent>
    </Dialog>
  );
};
