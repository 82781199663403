import React, { useState } from 'react';
import language from '../../Common/language/language';
import { changeLanguage } from '../../Common/helperFunctions/changeLanguage';

// import -> material ui -> core, icons
import { Accordion, AccordionSummary, AccordionDetails, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

// component -> React-root
const CoSpracheSetting = ({ objGlobalState }) => {
  const [sprachenState, setSprachenState] = useState(objGlobalState.lang);

  // function ->
  const setSpracheStateOnChange = (event) => {
    const sprache = event.target.value;
    setSprachenState(sprache);
  };

  return (
    <Accordion className="accordion">
      <AccordionSummary className="accordion__summary" expandIcon={<ExpandMore />}>
        <Typography align="center" className="typography__accordionSummary">
          {language('ABTEILUNG', 'TITEL', 'SPRACHE', objGlobalState.lang)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={8} className="marginTwenty">
            <FormControl className="widthOneSeventy" variant="standard">
              <InputLabel id="einstellungen_sprache">{language('FORM', 'LABEL', 'SPRACHEN', objGlobalState.lang)}</InputLabel>
              <Select
                labelId="einstellungen_sprache"
                id="einstellungen_sprache"
                value={sprachenState}
                onChange={setSpracheStateOnChange}
                label={language('FORM', 'LABEL', 'SPRACHEN', objGlobalState.lang)}
              >
                <MenuItem value={'DE'}>Deutsch</MenuItem>
                <MenuItem value={'EN'}>English</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={() => changeLanguage(objGlobalState, sprachenState)} variant="outlined" color="primary" size="large">
              <Typography>{language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}</Typography>
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CoSpracheSetting;
