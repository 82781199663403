import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import language from '../../language/language';
import { mouseScrollHorizontally } from '../../helperFunctions/mouseScrollHorizontally';
import { createAxiosInstance } from '../../helperFunctions/axios';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import generateArtikelEntityLabel from '../../helperFunctions/generateArtikelEntityLabel';
import { generateCSVString } from '../../helperFunctions/generateCSVString';
import { TABLE_TYPES } from '../../types/types';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { VerticalAlignBottom } from '@material-ui/icons';
import CoPaginationTableFooter from '../CoPaginationTableFooter';
import CoSpaltenlisteDialog from '../Artikel/CoSpaltenlisteDialog';
import CoSpaltenreihenfolgeDialog from '../Artikel/CoSpaltenreihenfolgeDialog';
import { exportTableAsCSV } from '../../helperFunctions/exportTable';
import { removeFromArray } from '../../helperFunctions/arrays';

const INITIAL_ELEMENTS_PER_PAGE = 5;

// component -> React-root
const CoArtikeltabelle = ({ objGlobalState, objParentState, options }) => {
  const { enqueueSnackbar } = useSnackbar();

  const isArtikeluebersicht = options?.isArtikeluebersicht;
  const selectArtikel = options?.selectArtikel;
  const heightFourty = options?.heightFourty;

  const history = useHistory();

  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const [spaltenlisteDialogState, setSpaltenlisteDialogState] = useState(false);
  const [spaltenreihenfolgeDialogState, setSpaltenreihenfolgeDialogState] = useState(false);
  const [artikelnState, setArtikelnState] = useState([]);
  const [rerunEffectState, setRerunEffectState] = useState(false);

  const [showTableState, setShowTableState] = useState(false);
  const [tableValuesLabelsState, setTablesValuesLabelsState] = useState([]);
  const [tableHeadersState, setTableHeadersState] = useState([]);
  const [elementsPerPageState, setElementsPerPageState] = useState(INITIAL_ELEMENTS_PER_PAGE);
  const [numOfPagesState, setNumOfPagesState] = useState(1);
  const [currentPageState, setCurrentPageState] = useState(1);
  const tableRef = useRef();

  // function ->
  const openSpaltenlisteDialog = () => {
    setSpaltenlisteDialogState(true);
  };

  const openSpaltenreihenfolgeDialog = () => {
    setSpaltenreihenfolgeDialogState(true);
  };

  // function ->
  const stateToCSV = () => {
    const csvRows = [];
    const headers = [...tableHeadersState];

    removeFromArray(headers, 'OEFFNEN');
    console.log(headers);
    const headerRow = headers.map((header) => generateCSVString(language('TABLE', 'TITEL', header, objGlobalState.lang)));
    csvRows.push(headerRow.join(';'));
    artikelnState.forEach((bestandItem) => {
      const valueRow = tableValuesLabelsState.map((value) => generateCSVString(bestandItem[value]));
      csvRows.push(valueRow.join(';'));
    });
    return csvRows.join('\n');
  };

  // function ->
  const openArtikelEdit = (artikelId) => {
    objGlobalState.setPageLoadingState(true);
    authAxios
      .get(`/artikel/${artikelId}`)
      .then((response) => {
        const artikel = response.data;
        objGlobalState.setOPBetweenComponents((pre) => ({
          ...pre,
          artikelverwaltungArtikeluebersicht_artikelverwaltungArtikel: { fromAU: true, artikel }
        }));

        history.push('/artikelverwaltung_artikel');
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const chooseArtikelOnClick = (artikel) => {
    objParentState.setAusgewaehlteArtikelState({ chosen: true, artikel: artikel });

    const currentArtikelnState = [...artikelnState];

    currentArtikelnState.map((_artikel) => {
      _artikel.chosen = false;

      if (_artikel === artikel) {
        _artikel.chosen = true;
      }

      return _artikel;
    });

    setArtikelnState(currentArtikelnState);
  };

  // function ->
  const downloadEANAfterRequest = (file) => {
    const blob = new Blob([file]);
    const url = URL.createObjectURL(blob);

    const name = 'traceport_ean_code';
    const type = 'png';
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${name}.${type}`);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
  };

  // function ->
  const getEANCodeFromAPI = (artikelId) => {
    authAxios
      .get(`/artikel/${artikelId}/ean-code`, {
        responseType: 'blob'
      })
      .then((response) => {
        const file = response.data;
        downloadEANAfterRequest(file);
      })
      .catch((error) => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
        console.log(error);
      });
  };

  // function ->
  const createTableHeaders = (spaltenArray) => {
    const tableHeaders = [];

    spaltenArray.forEach((spalte) => {
      if (spalte === 'id') return;
      const spalteAsHeader = generateArtikelEntityLabel(spalte);
      tableHeaders.push(spalteAsHeader);
    });
    if (isArtikeluebersicht) tableHeaders.push('OEFFNEN');

    return tableHeaders;
  };

  // function ->
  const createTableValuesLabels = (spaltenArray) => {
    const valuesLabels = [];
    spaltenArray.forEach((spalte) => {
      if (spalte === 'id' || spalte === 'chosen') return;
      valuesLabels.push(spalte);
    });

    return valuesLabels;
  };

  // function ->
  const handleFetchArtikelSuccess = (artikeldaten, selectedSpalten) => {
    const totalElements = artikeldaten.totalElements;
    const elementsPerPage = +elementsPerPageState <= 0 ? 1 : elementsPerPageState;
    const numOfPages = Math.ceil(totalElements / elementsPerPage);

    setNumOfPagesState(numOfPages);

    if (currentPageState > numOfPages) {
      setCurrentPageState(numOfPages);
      return;
    }

    const tableHeaders = createTableHeaders(selectedSpalten);
    const tableValuesLabels = createTableValuesLabels(selectedSpalten);

    const artikeln = artikeldaten.content.map((artikel) => {
      return { ...artikel, chosen: false };
    });

    setTablesValuesLabelsState(tableValuesLabels);
    setTableHeadersState(tableHeaders);
    setArtikelnState(artikeln);

    setShowTableState(true);
  };

  // function ->
  const fetchArtikelnFromApi = () => {
    objGlobalState.setPageLoadingState(true);

    const page = currentPageState - 1 <= 0 ? 0 : currentPageState - 1;
    const size = elementsPerPageState <= 0 ? 1 : elementsPerPageState;

    authAxios
      .get(`/artikel?page=${page}&size=${size}`)
      .then((response) => {
        const artikeldaten = response.data;
        authAxios.get('/usersettings').then((_response) => {
          const selectedArtikeluebersichtSpalten = _response.data.spaltenGewaehltArtikeluebersicht;
          handleFetchArtikelSuccess(artikeldaten, selectedArtikeluebersichtSpalten);
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchArtikelnFromApi();
    }

    return () => (isMounted = false);
  }, [currentPageState, elementsPerPageState, rerunEffectState]);

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, []);

  const objArtikeltabelle = {
    paginationTableFooter: {
      currentPageState,
      setCurrentPageState,
      setElementsPerPageState,
      numOfPagesState,
      setNumOfPagesState,
      firstCellColSpan: 3,
      secondCellColSpan: 2,
      thirdCellColSpan: 3
    },
    spaltenlisteDialog: {
      setRerunEffectState,
      spaltenlisteDialogState,
      setSpaltenlisteDialogState,
      tableCategory: TABLE_TYPES.ARTIKELUEBERSICHT
    },
    spaltenreihenfolgeDialog: {
      setRerunEffectState,
      spaltenreihenfolgeDialogState,
      setSpaltenreihenfolgeDialogState,
      tableCategory: TABLE_TYPES.ARTIKELUEBERSICHT
    }
  };

  // function ->
  const createSelectArtikelProps = (artikel) =>
    selectArtikel
      ? {
          className: artikel.chosen ? 'table__row--active' : 'table__row--hover',
          onClick: () => chooseArtikelOnClick(artikel)
        }
      : {};

  // function ->
  const displayBoolean = (value) => {
    const label = value ? 'JA' : 'NEIN';
    return language('KONTENT', 'TEXT', label, objGlobalState.lang);
  };

  // function ->
  const mapTemperaturEmpfindlichValueToLabel = (value) => {
    const labels = {
      KEINE_ANGABE: '',
      FUENFZEHN_BIS_FUENFUNDZWANZIG: '15°C - 25°C',
      ZWEI_BIS_ACHT: '2°C - 8°C',
      NULL_BIS_FUENFUNDZWANZIG: '0°C - 25°C'
    };

    return labels[value];
  };

  return (
    <>
      {spaltenlisteDialogState && <CoSpaltenlisteDialog objGlobalState={objGlobalState} objParentState={objArtikeltabelle.spaltenlisteDialog} />}
      {spaltenreihenfolgeDialogState && <CoSpaltenreihenfolgeDialog objGlobalState={objGlobalState} objParentState={objArtikeltabelle.spaltenreihenfolgeDialog} />}
      <Box className="table__title">
        <Typography align="center" className="typography__singleTableTitle">
          {language('TABLE', 'HEADER', 'ARTIKEL', objGlobalState.lang)}
        </Typography>
        <div className="marginTwenty">
          {showTableState && (
            <Button variant="contained" color="primary" onClick={openSpaltenlisteDialog} className="table__titleButton">
              {language('BUTTON', 'TITEL', 'SPALTENAUSWAEHLEN', objGlobalState.lang)}
            </Button>
          )}
          {showTableState && (
            <Button variant="contained" color="primary" onClick={openSpaltenreihenfolgeDialog} className="table__titleButton">
              {language('BUTTON', 'TITEL', 'SPALTENREIHENFOLGE', objGlobalState.lang)}
            </Button>
          )}
          {showTableState && (
            <Button variant="contained" color="primary" onClick={() => exportTableAsCSV(stateToCSV(), 'artikel')} className="table__titleButton">
              {language('BUTTON', 'TITEL', 'TABELLEEXPORTIEREN', objGlobalState.lang)}
            </Button>
          )}
        </div>
      </Box>
      <TableContainer
        ref={tableRef}
        component={Paper}
        variant="outlined"
        elevation={0}
        square
        className={`coArtikel__tableContainer ${heightFourty ? 'heightFour' : ''} border__noTop backgroundTable`}
      >
        {showTableState && (
          <Table size="medium" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center" component="th" className="table__headerCellNoWidth">
                  <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', ' ', objGlobalState.lang)}</Typography>
                </TableCell>
                {tableHeadersState.map((header, key) => {
                  return (
                    <TableCell align="center" component="th" className="table__headerCell" key={`artikelverwaltung_artikeltabelle_tableHeaders_${key}`}>
                      <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', header, objGlobalState.lang)}</Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {artikelnState.map((artikel, key) => {
                return (
                  <TableRow key={`artikelverwaltung_artikeltabelle_tableRow_${key}`} {...createSelectArtikelProps(artikel)}>
                    <TableCell align="center">
                      <Typography className="typography__singleTableRowContent">{artikel.id}</Typography>
                    </TableCell>
                    {tableValuesLabelsState.map((value, _key) => (
                      <TableCell align="center" key={`artikelverwaltung_artikeltabelle_tableCell_${key}_${_key}`}>
                        {value === 'ean' ? (
                          <Button startIcon={<VerticalAlignBottom />} color="primary" variant="outlined" className="button__small noWrap" onClick={() => getEANCodeFromAPI(artikel.id)}>
                            {artikel[value]}
                          </Button>
                        ) : (
                          <Typography className="typography__singleTableRowContent">
                            {value === 'temperaturEmpfindlich'
                              ? mapTemperaturEmpfindlichValueToLabel(artikel[value])
                              : typeof artikel[value] === 'boolean'
                              ? displayBoolean(artikel[value])
                              : artikel[value]}
                          </Typography>
                        )}
                      </TableCell>
                    ))}
                    {isArtikeluebersicht && (
                      <TableCell align="center">
                        <Button color="primary" variant="outlined" className="button__small" onClick={() => openArtikelEdit(artikel.id)}>
                          {language('BUTTON', 'TITEL', 'OEFFNEN', objGlobalState.lang)}
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
            <CoPaginationTableFooter objParent={objArtikeltabelle.paginationTableFooter} objGlobalState={objGlobalState} />
          </Table>
        )}
      </TableContainer>
    </>
  );
};

export default CoArtikeltabelle;
