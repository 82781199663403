import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import language from '../../Common/language/language';
import { useSnackbar } from 'notistack';
import { mouseScrollHorizontally } from '../../Common/helperFunctions/mouseScrollHorizontally';
import { createAxiosInstance } from '../../Common/helperFunctions/axios';

// import -> material ui -> core && icons
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import CoLogistikunternhemenSelect from '../../Common/Components/CoLogistikunternhemenSelect';

// component -> React-root
const CoAuftragstabelleDialog = ({ objGlobalState, objParentState }) => {
  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const { enqueueSnackbar } = useSnackbar();

  const [logistikunternehmenState, setLogistikunternehmenState] = useState([]);
  const [dialogIsRenderedState, setDialogIsRenderedState] = useState(false);
  const [sendungsartState, setSendungsartState] = useState({
    paketsendung: { id: 1, name: 'PAKETSENDUNG', checked: false },
    palettensendung: { id: 2, name: 'PALETTENSENDUNG', checked: false }
  });
  const [speditionState, setSpeditionState] = useState('');

  const tableRef = useRef();

  // function ->
  const closeDialogOnClose = () => {
    objParentState.setAuftragstabelleDialogState((pre) => ({ ...pre, open: false }));
  };

  // function ->
  const mapObjToArrayOfObjects = (obj) => {
    const array = [];
    for (let key in obj) {
      if (key !== 'id') {
        const element = { name: key.toUpperCase(), value: obj[key] };
        array.push(element);
      }
    }
    return array;
  };

  // function ->
  const chooseSpeditionOnClick = (event) => {
    setSpeditionState(event.target.value);
  };

  // function ->
  const handleDialogOnRendered = () => {
    setDialogIsRenderedState(true);
  };

  // function ->
  const checkSendungsart = (name) => {
    // if one is true then the other one is false
    setSendungsartState((pre) => {
      switch (name) {
        case 'paketsendung':
          return {
            paketsendung: { ...pre.paketsendung, checked: !pre.paketsendung.checked },
            palettensendung: { ...pre.palettensendung, checked: false }
          };
        case 'palettensendung':
          return {
            palettensendung: { ...pre.palettensendung, checked: !pre.palettensendung.checked },
            paketsendung: { ...pre.paketsendung, checked: false }
          };
        default:
          return pre;
      }
    });
  };

  // function ->
  const submitFormOnClick = (event) => {
    event.preventDefault();

    objGlobalState.setPageLoadingState(true);

    const sendungsartId = sendungsartState.paketsendung.checked ? sendungsartState.paketsendung.id : sendungsartState.palettensendung.checked ? sendungsartState.palettensendung.id : 0;
    const spedition = speditionState;

    const auftragId = objParentState.auftragstabelleDialogState.dataset.id;

    const requestValues = {
      shipmentTypeId: sendungsartId,
      spedition: spedition
    };

    authAxios
      .put(`/auftrag/${auftragId}/verpackung`, requestValues)
      .then(() => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'AENDERUNGENGESPEICHERT', objGlobalState.lang), { variant: 'success' });

        closeDialogOnClose();

        objParentState.setAuftragsAngepasstState((pre) => !pre);
      })
      .catch((error) => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
        console.log(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const setSenudungsart = () => {
    const sendungsartId = objParentState.auftragstabelleDialogState.dataset.sendungsart;
    const shouldCheckPaketsendung = sendungsartState.paketsendung.id === sendungsartId;
    const shouldCheckPalettensendung = sendungsartState.palettensendung.id === sendungsartId;

    if (shouldCheckPaketsendung) {
      setSendungsartState((pre) => ({
        ...pre,
        paketsendung: {
          ...pre.paketsendung,
          checked: true
        }
      }));
    } else if (shouldCheckPalettensendung) {
      setSendungsartState((pre) => ({
        ...pre,
        palettensendung: {
          ...pre.palettensendung,
          checked: true
        }
      }));
    }
  };

  // function ->
  const setSpedition = () => {
    const { spedition } = objParentState.auftragstabelleDialogState.dataset;
    const isOneOfSpeditionenSelected = logistikunternehmenState.some((_spedition) => _spedition.name === spedition);
    if (isOneOfSpeditionenSelected) {
      setSpeditionState(spedition);
    }
  };

  // function ->
  const fetchSpeditionenFromAPI = () => {
    authAxios
      .get('/speditionen')
      .then((response) => {
        const logistikunternehmen = response.data;
        setLogistikunternehmenState(logistikunternehmen);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchSpeditionenFromAPI();
    }

    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setSenudungsart();
      setSpedition();
    }

    return () => (isMounted = false);
  }, [logistikunternehmenState]);

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted && dialogIsRenderedState) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, [dialogIsRenderedState]);

  const dataValuesObjects = mapObjToArrayOfObjects(objParentState.auftragstabelleDialogState.dataset);

  const objAuftragstabelleDialog = {
    logistikunternehmenSelect: {
      selectValue: speditionState,
      handleInputOnChange: chooseSpeditionOnClick,
      speditionenToExclude: ['chors'],
      formControlVariant: 'standard'
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={objParentState.auftragstabelleDialogState.open}
        onClose={closeDialogOnClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onRendered={handleDialogOnRendered}
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
            <Grid item xs={6}>
              <Typography align="left" className="typography__dialogTitle">
                {language('DIALOG', 'TITEL', 'DETAILLIERTEANSICHT', objGlobalState.lang)}
              </Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <IconButton className="button__iconButton--close" onClick={closeDialogOnClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="dialog__content">
          <form name="submitForm" noValidate autoComplete="on" onSubmit={submitFormOnClick}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className="table__container">
                  <Table size="medium">
                    <TableBody>
                      {dataValuesObjects.map((obj, key) => {
                        if (obj.name === 'SPEDITION') {
                          return (
                            <TableRow key={`coDetailstabelledialog_table_${key}`}>
                              <TableCell align="left" component="th" className="table__rowTitle">
                                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', obj.name, objGlobalState.lang)}</Typography>
                              </TableCell>
                              <TableCell align="right">
                                <CoLogistikunternhemenSelect objGlobalState={objGlobalState} objParentState={objAuftragstabelleDialog.logistikunternehmenSelect} options={{ noLabel: true }} />
                              </TableCell>
                            </TableRow>
                          );
                        } else if (obj.name === 'SENDUNGSART') {
                          return (
                            <TableRow key={`coDetailstabelledialog_table_${key}`}>
                              <TableCell align="left" component="th" className="table__rowTitle">
                                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', obj.name, objGlobalState.lang)}</Typography>
                              </TableCell>
                              <TableCell align="right">
                                <FormControl>
                                  <FormControlLabel
                                    control={<Switch checked={sendungsartState.paketsendung.checked} onChange={() => checkSendungsart('paketsendung')} color="primary" />}
                                    label={language('FORM', 'LABEL', 'PAKETSENDUNG', objGlobalState.lang)}
                                  />
                                  <FormControlLabel
                                    control={<Switch checked={sendungsartState.palettensendung.checked} onChange={() => checkSendungsart('palettensendung')} color="primary" />}
                                    label={language('FORM', 'LABEL', 'PALETTENSENDUNG', objGlobalState.lang)}
                                  />
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          );
                        } else {
                          return (
                            <TableRow key={`coDetailstabelledialog_table_${key}`}>
                              <TableCell align="left" component="th" className="table__rowTitle">
                                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', obj.name, objGlobalState.lang)}</Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography className="typography__singleTableRowContent">{obj.value}</Typography>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button fullWidth type="submit" color="primary" className="button__middle" variant="contained" color="primary">
                  {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button onClick={closeDialogOnClose} fullWidth className="button__middle">
                  {language('BUTTON', 'TITEL', 'ABBRECHEN', objGlobalState.lang)}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoAuftragstabelleDialog;
