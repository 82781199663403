import React from 'react';
import language from '../language/language';

// import -> material ui -> core
import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';

// component -> React-root
const CoLoeschenwarnungDialog = ({ objGlobalState, objParentState }) => {
  // function ->
  const closeWarnungDialogOnClick = () => {
    objParentState.setLoeschenwarnungDialogState({ open: false, data: {} });
  };

  // function ->
  const deleteDataOnClick = () => {
    closeWarnungDialogOnClick();
    objParentState.deleteDataOnClick();
  };
  return (
    <Dialog fullWidth maxWidth="sm" open={objParentState.loeschenwarnungDialogState.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{language('DIALOG', 'TITEL', 'WARNUNG', objGlobalState.lang)}</DialogTitle>
      <DialogContent className="dialog__content">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography className="typography">{language('ALERT', 'TEXT', 'SINDSIESICHER', objGlobalState.lang)}</Typography>
            <Typography className="typography">
              {objParentState.loeschenwarnungDialogState.deletedDataLabel}{' '}
              {objParentState.loeschenwarnungDialogState.deleteSentence ? objParentState.loeschenwarnungDialogState.deleteSentence : language('ALERT', 'TEXT', 'WIRDGELOESCHT', objGlobalState.lang)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={deleteDataOnClick} className="button__middle--redOutLined">
              {language('BUTTON', 'TITEL', objParentState.loeschenwarnungDialogState.buttonTitle ? objParentState.loeschenwarnungDialogState.buttonTitle : 'LOESCHEN', objGlobalState.lang)}
            </Button>
            <Button onClick={closeWarnungDialogOnClick} className="button__middle--greenOutLined marginLeft">
              {language('BUTTON', 'TITEL', 'ABBRECHEN', objGlobalState.lang)}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CoLoeschenwarnungDialog;
