import React, { useState } from 'react';
import language from '../language/language';
import isMobile from '../isMobile/isMobile';
import { FILE_TYPES } from '../types/types';
import { readFile } from '../helperFunctions/readFile';
import { generateDate } from '../helperFunctions/date';
import deleteFileFromFilesSet from '../helperFunctions/deleteFileFromFilesSet';

// import -> material ui -> core & icons
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import { CameraAlt, Close, DeleteForever, PhotoCameraOutlined, Storage } from '@material-ui/icons';

// import -> components
import CoCamera from './CoCamera';
import CoDisplayFiles from './CoDisplayFiles';

// component -> React-root
const CoUploadFiles = ({ objGlobalState, objParentState, options }) => {
  const [displayDialogState, setDisplayDialogState] = useState(false);
  const [fileToDisplayState, setFileToDisplayState] = useState({ dataUri: '', title: '', type: '' });
  const [cameraState, setCameraState] = useState(false);
  const [gotStreamState, setGotStreamState] = useState(false);
  const [canCameraToggleState, setCanCameraToggleState] = useState(false);

  // function ->
  const displayFileOnClick = (event, file) => {
    setFileToDisplayState(file);
    setDisplayDialogState(true);
  };

  // function ->
  const canCameraStop = () => {
    if ((cameraState && gotStreamState) || (!cameraState && !gotStreamState)) {
      return true;
    } else {
      return false;
    }
  };

  // function ->
  const stopCamera = () => {
    if (isMobile.any()) {
      return;
    }
    const stream = document.querySelector('#tracePort_camera')?.srcObject;
    if (!stream) {
      return;
    }
    const tracks = stream.getTracks();
    tracks.forEach((track) => {
      track.stop();
    });
    document.querySelector('#tracePort_camera').srcObject = null;
    setGotStreamState(false);
    setCameraState(false);
  };

  // function ->
  const handleFilesOnChange = (event) => {
    // array -> the new picked files from the user
    const newFiles = [...event.target.files];

    newFiles.forEach((file) => {
      readFile(file)
        .then((result) => {
          const type = file.type.includes('image') ? FILE_TYPES.JPEG : file.type === 'application/pdf' ? FILE_TYPES.PDF : FILE_TYPES.NO_TYPE;
          if (!type) {
            return;
          }
          const { name, lastModified } = file;
          const timestamp = generateDate(lastModified).dateAndTime;
          const newFile = { fileRef: file, timestamp: timestamp, type: type, dataUri: result, name: name, beschreibung: { text: '', error: false } };
          objParentState.setFilesState((pre) => [newFile, ...pre]);
        })
        .catch((error) => console.log(error));
    });
  };

  // function ->
  const toggleCameraState = () => {
    if (canCameraStop()) {
      if (cameraState) {
        stopCamera();
      } else {
        setCameraState(true);
      }
    }
  };

  // function ->
  const closeDataUploadDialogOnClose = () => {
    if (canCameraStop()) {
      stopCamera();
      objParentState.setUploadFilesDialogState(false);
    }
  };

  const isItMobile = isMobile.any();

  const isInIframe = objGlobalState.isInIframe;
  const iconClassName = isInIframe ? 'button__icon--smallRed' : 'button__icon--smallGreen';

  // jsx -> if not browsing from mobile show this extra option
  const photosUpLoadExtraOptions = !isItMobile ? (
    <IconButton onClick={toggleCameraState} title={language('BUTTON', 'TITEL', 'KAMERAEINAUS', objGlobalState.lang)}>
      {!canCameraToggleState ? <CameraAlt className={iconClassName} /> : cameraState ? <PhotoCameraOutlined className={iconClassName} /> : <CameraAlt className={iconClassName} />}
    </IconButton>
  ) : (
    ''
  );

  const objUploadFilesState = { displayDialogState, setDisplayDialogState, fileToDisplayState, setFileToDisplayState };

  return (
    <>
      {displayDialogState && <CoDisplayFiles objGlobalState={objGlobalState} objParentState={objUploadFilesState} />}

      <Dialog
        onClose={closeDataUploadDialogOnClose}
        fullWidth
        maxWidth="sm"
        open={objParentState.uploadFilesDialogState.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
            <Grid item xs={6}>
              <Typography align="left" className="typography__dialogTitle">
                {language('DIALOG', 'TITEL', objParentState.uploadFilesDialogState.title ? objParentState.uploadFilesDialogState.title : 'DATEIENHOCHLADEN', objGlobalState.lang)}
              </Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <IconButton className="button__iconButton--close" onClick={closeDataUploadDialogOnClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="dialog__content">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <input accept="image/*,application/pdf" style={{ display: 'none' }} name="fotos" id="common-components-filesupload-input" multiple type="file" onChange={handleFilesOnChange} />
              <label htmlFor="common-components-filesupload-input">
                <IconButton component="span" title={language('BUTTON', 'TITEL', 'SPEICHERPLATZ', objGlobalState.lang)}>
                  <Storage className={iconClassName} />
                </IconButton>
              </label>
              {photosUpLoadExtraOptions}
            </Grid>
            {!isItMobile ? (
              <Grid item xs={12}>
                {cameraState ? (
                  <CoCamera
                    objGlobalState={objGlobalState}
                    objParentState={{
                      photosState: objParentState.filesState,
                      setPhotosState: objParentState.setFilesState,
                      setGotStreamState,
                      setCanCameraToggleState,
                      setCameraState
                    }}
                  />
                ) : (
                  ''
                )}
              </Grid>
            ) : (
              ''
            )}
            <Grid item xs={12} container>
              {objParentState.filesState.map((file, key) => {
                return (
                  <Grid container item xs={12} key={key} alignContent="center" alignItems="center" justify="center">
                    <Grid item xs={6}>
                      {file.type === FILE_TYPES.JPEG ? (
                        <img src={file.dataUri} className="images__gallery" alt="" onClick={(event) => displayFileOnClick(event, file)} />
                      ) : file.type === FILE_TYPES.PDF ? (
                        <Typography align="center" className="typography__gallery" onClick={(event) => displayFileOnClick(event, file)}>
                          <img src={`../assets/images/other_icons/pdf_icon.png`} alt="" className="images__textIcon" />
                          {file.name}
                        </Typography>
                      ) : (
                        ''
                      )}
                    </Grid>
                    <Grid item xs={'auto'}>
                      <IconButton
                        onClick={() => deleteFileFromFilesSet(file, objParentState.filesState, objParentState.setFilesState)}
                        className="button__icon--deleteImage"
                        title={language('BUTTON', 'TITEL', 'LOESCHEN', objGlobalState.lang)}
                        size="small"
                      >
                        <DeleteForever />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            {options?.uploadButton ? (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  disabled={!objParentState.filesState.length}
                  className={objParentState.filesState.length ? 'button__middle' : ''}
                  onClick={objParentState.handleUploadButtonOnClick}
                >
                  {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
                </Button>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoUploadFiles;
