import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import language from '../../Common/language/language';
import { createAxiosInstance } from '../../Common/helperFunctions/axios';
import { useSnackbar } from 'notistack';
import { mouseScrollHorizontally } from '../../Common/helperFunctions/mouseScrollHorizontally';

// import -> material ui -> core & icons
import { Box, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, Button, Typography, IconButton, ButtonGroup } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';

// import -> component
import CoCreateUpdateLagerortDialog from './CoCreateUpdateLagerortDialog';
import CoLoeschenwarnungDialog from '../../Common/Components/CoLoeschenwarnungDialog';
import CoAddStellplatze from './CoAddStellplaetze';
import CoStellplaetzeTableDialog from './CoStellplaetzeTableDialog';
import CoLagerorteTableDialog from './CoLagerorteTableDialog';
import { exportTableAsCSV } from '../../Common/helperFunctions/exportTable';
import { generateCSVString } from '../../Common/helperFunctions/generateCSVString';

// the first one is remove child lagerort from parent lagerort, the second one is to delete the lagerort it completely
const LOESCHEN_TYPES = {
  FROM_LAGERORT: 1,
  LAGERORT_COMPLETE: 2
};

// component -> React-root
const CoLagerorteTable = ({ objGlobalState, objParentState }) => {
  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const { enqueueSnackbar } = useSnackbar();

  const [lagerorteTableDialogState, setLagerorteTableDialogState] = useState({ open: false, lagerortObj: {}, addAsChildLagerToParentLager: false, childLagerorteansehen: true });
  const [createUpdateLagerortDialogState, setCreateUpdateLagerortDialogState] = useState({ open: false, isUpdate: false, lagerortObj: {} });
  const [addStellplaetzeDialogState, setAddStellplaetzeDialogState] = useState({ open: false, lagerortObj: {} });
  const [stellplaetzeTableDialogState, setStellplaetzeTableDialogState] = useState({ open: false, lagerortObj: {} });

  const [lagerorteState, setLagerorteState] = useState([]);
  const [rerunEffectState, setRerenderEffectState] = useState(false);

  const [loeschenwarnungDialogState, setLoeschenwarnungDialogState] = useState({ open: false, deletedDataLabel: '' });
  const [focusedDataState, setFocusedDataState] = useState({ dataId: '', loeschenType: '' });

  const isSelectable = objParentState.isSelectable;
  const childLagerorteansehen = objParentState.childLagerorteansehen;

  const tableHeaders = isSelectable
    ? [' ', 'LAGERNAME', 'LAGERART']
    : childLagerorteansehen
    ? [' ', 'LAGERNAME', 'LAGERART', 'SUBLAGERORTE', 'BEARBEITEN', 'AUSLAGERORTENTFERNEN', 'LOESCHEN', 'STELLPLAETZE']
    : [' ', 'LAGERNAME', 'LAGERART', 'SUBLAGERORTE', 'BEARBEITEN', 'LOESCHEN', 'STELLPLAETZE'];
  const tableRef = useRef();

  // function ->
  const stateToCSV = () => {
    const csvRows = [];
    const headerRow = [' ', 'LAGERNAME', 'LAGERART'].map((header) => generateCSVString(language('TABLE', 'TITEL', header, objGlobalState.lang)));
    const tableValuesLabels = ['id', 'lagername', 'lagerart'];
    csvRows.push(headerRow.join(';'));
    lagerorteState.forEach((lagerort) => {
      const valueRow = tableValuesLabels.map((key) => generateCSVString(lagerort[key]));
      csvRows.push(valueRow.join(';'));
    });
    return csvRows.join('\n');
  };

  // function ->
  const openCreateUpdateLagerortDialog = (_, isUpdate, lagerortObj = {}) => {
    setCreateUpdateLagerortDialogState({ open: true, isUpdate: !!isUpdate, lagerortObj });
  };

  // function ->
  const openAddStellplaetzeDialog = (lagerortObj) => {
    setAddStellplaetzeDialogState({ open: true, lagerortObj });
  };

  // function ->
  const openStellplaetzeTableDialog = (lagerortObj) => {
    setStellplaetzeTableDialogState({ open: true, lagerortObj });
  };

  // function ->
  const openLagerorteTableDialog = (lagerortObj, isToBeAddTo) => {
    // if true then we see minimal columns of the lagerorteTable and we can select a child lagerort (sublagerort)
    // other wise we see the child lagerorte (sublagerorte)
    const addAsChildLagerToParentLager = isToBeAddTo;

    // if we open it through Ansehen button the isToBeAddTo false and childLagerorteansehen musst be true
    // if we open it through Hinzufuegen button isToBeAddTo true and childLagerorteansehen musst be false
    const childLagerorteansehen = !isToBeAddTo;
    setLagerorteTableDialogState({ open: true, lagerortObj, addAsChildLagerToParentLager, childLagerorteansehen });
  };

  // function ->
  const openLoeschenwarnungDialog = (lagerortObj, loeschenType) => {
    const deletedDataLabel = language('KONTENT', 'TEXT', 'LAGERORT', objGlobalState.lang) + ' ' + lagerortObj.lagername;
    const deleteSentence =
      loeschenType === LOESCHEN_TYPES.LAGERORT_COMPLETE ? null : language('KONTENT', 'TEXT', 'WIRDAUSENTFERNT', objGlobalState.lang).replace('%toReplace%', objParentState.lagerortObj.lagername);
    const buttonTitle = loeschenType === LOESCHEN_TYPES.LAGERORT_COMPLETE ? null : 'ENTFERNEN';
    setLoeschenwarnungDialogState({ open: true, deletedDataLabel, deleteSentence, buttonTitle });
    setFocusedDataState({ dataId: lagerortObj.id, loeschenType });
  };

  // function ->
  const rerunEffect = () => {
    setRerenderEffectState((pre) => !pre);
  };

  // function ->
  const deleteDataOnClick = () => {
    const isDeleteFromLagerort = focusedDataState.loeschenType === LOESCHEN_TYPES.FROM_LAGERORT;

    const requestUrl = isDeleteFromLagerort ? `/lagerort/${objParentState.lagerortObj.id}/link/${focusedDataState.dataId}` : `/lagerort/${focusedDataState.dataId}`;
    authAxios
      .delete(requestUrl)
      .then(() => {
        rerunEffect();
        objParentState.rerunEffect();
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGELOESCHT'));
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
      });
  };

  // function ->
  const fetchLagerorte = () => {
    objGlobalState.setPageLoadingState(true);
    const requestUrl = childLagerorteansehen ? `/lagerort/${objParentState.lagerortObj.id}/lagerorte` : '/lagerort';
    authAxios
      .get(requestUrl)
      .then((response) => {
        const newLagerorteState = response.data.map((lagerort) => ({
          lagername: lagerort.name,
          lagerart: lagerort.lagerart,
          id: lagerort.id
        }));
        setLagerorteState(newLagerorteState);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    fetchLagerorte();

    return () => {
      isMounted = false;
    };
  }, [rerunEffectState, objParentState.rerunEffectState]);

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, []);

  const objLagerorteTable = {
    addStellplaetzeDialog: {
      addStellplaetzeDialogState,
      setAddStellplaetzeDialogState
    },
    createUpdateLagerortDialog: {
      createUpdateLagerortDialogState,
      setCreateUpdateLagerortDialogState,
      rerunEffect
    },
    lagerorteTableDialog: {
      lagerorteTableDialogState,
      setLagerorteTableDialogState,
      isFromLagerorte: true,
      rerunEffect: objParentState.rerunEffect
    },
    loeschenwarnungDialog: {
      loeschenwarnungDialogState,
      setLoeschenwarnungDialogState,
      deleteDataOnClick
    },
    stellplaetzeTable: {
      heightFour: true,
      parentRerunEffectState: rerunEffectState
    },
    stellplaetzeTableDialog: {
      stellplaetzeTableDialogState,
      setStellplaetzeTableDialogState
    }
  };

  const createSelectLagerortProps = (lagerortObj) =>
    isSelectable
      ? {
          className: objParentState.selectedLagerortIdState === lagerortObj.id ? 'table__row--active' : 'table__row--hover',
          onClick: () => objParentState.selectLagerort(lagerortObj.id)
        }
      : {};

  return (
    <>
      {lagerorteTableDialogState.open && <CoLagerorteTableDialog objGlobalState={objGlobalState} objParentState={objLagerorteTable.lagerorteTableDialog} />}
      {stellplaetzeTableDialogState.open && <CoStellplaetzeTableDialog objGlobalState={objGlobalState} objParentState={objLagerorteTable.stellplaetzeTableDialog} />}
      {loeschenwarnungDialogState.open && <CoLoeschenwarnungDialog objGlobalState={objGlobalState} objParentState={objLagerorteTable.loeschenwarnungDialog} />}
      {createUpdateLagerortDialogState.open && <CoCreateUpdateLagerortDialog objGlobalState={objGlobalState} objParentState={objLagerorteTable.createUpdateLagerortDialog} />}
      {addStellplaetzeDialogState.open && <CoAddStellplatze objGlobalState={objGlobalState} objParentState={objLagerorteTable.addStellplaetzeDialog} />}

      <Box className="table__title">
        <Typography align="center" className="typography__singleTableTitle">
          {language('TABLE', 'HEADER', 'LAGERORTE', objGlobalState.lang)}
        </Typography>
        <div className="marginTwenty">
          {
            <Button variant="contained" color="primary" onClick={() => exportTableAsCSV(stateToCSV(), 'stellplatz')} className="table__titleButton">
              {language('BUTTON', 'TITEL', 'TABELLEEXPORTIEREN', objGlobalState.lang)}
            </Button>
          }
        </div>
      </Box>
      <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className="table__container border__noTop heightFour">
        <Table size="medium" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => {
                return (
                  <TableCell align="center" component="th" className="table__headerCell" key={`lagerverwaltung_stellplaetze_lagerorte_table_header_table_cell_${index}`}>
                    <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', header, objGlobalState.lang)}</Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {lagerorteState.map((lagerortObj, index) => {
              if (objParentState.excludeId && objParentState.excludeId === lagerortObj.id) {
                return;
              }
              const lastIndex = lagerorteState.length - 1 === index;
              return (
                <TableRow key={`lagerverwaltung_stellplaetze_lagerorte_table_body_table_row_${index}`} {...createSelectLagerortProps(lagerortObj)}>
                  <TableCell className={lastIndex ? 'border__none' : ''} align="center">
                    <Typography className="typography__singleTableRowContent">{lagerortObj.id}</Typography>
                  </TableCell>
                  <TableCell className={lastIndex ? 'border__none' : ''} align="center">
                    <Typography className="typography__singleTableRowContent">{lagerortObj.lagername}</Typography>
                  </TableCell>
                  <TableCell className={lastIndex ? 'border__none' : ''} align="center">
                    <Typography className="typography__singleTableRowContent">{language('FORM', 'LABEL', lagerortObj.lagerart, objGlobalState.lang)}</Typography>
                  </TableCell>
                  {!isSelectable && (
                    <>
                      <TableCell className={lastIndex ? 'border__none' : ''} align="center">
                        <ButtonGroup>
                          <Button onClick={() => openLagerorteTableDialog(lagerortObj, true)} className="button__small" variant="outlined" color="primary">
                            {language('BUTTON', 'TITEL', 'HINZUFUEGEN', objGlobalState.lang)}
                          </Button>
                          <Button onClick={() => openLagerorteTableDialog(lagerortObj, false)} className="button__small" variant="outlined" color="primary">
                            {language('BUTTON', 'TITEL', 'ANSEHEN', objGlobalState.lang)}
                          </Button>
                        </ButtonGroup>
                      </TableCell>
                      <TableCell className={lastIndex ? 'border__none' : ''} align="center">
                        <Button onClick={(event) => openCreateUpdateLagerortDialog(event, true, lagerortObj)} className="button__small" variant="outlined" color="primary">
                          {language('BUTTON', 'TITEL', 'BEARBEITEN', objGlobalState.lang)}
                        </Button>
                      </TableCell>
                      {childLagerorteansehen && (
                        <TableCell className={lastIndex ? 'border__none' : ''} align="center">
                          <Button onClick={(event) => openLoeschenwarnungDialog(lagerortObj, LOESCHEN_TYPES.FROM_LAGERORT)} className="button__small--redOutLined">
                            {language('BUTTON', 'TITEL', 'ENTFERNEN', objGlobalState.lang)}
                          </Button>
                        </TableCell>
                      )}
                      <TableCell className={lastIndex ? 'border__none' : ''} align="center">
                        <IconButton
                          onClick={() => openLoeschenwarnungDialog(lagerortObj, LOESCHEN_TYPES.LAGERORT_COMPLETE)}
                          className="button__icon--tableRed"
                          title={language('BUTTON', 'TITEL', 'LOESCHEN', objGlobalState.lang)}
                          size="small"
                        >
                          <DeleteForever />
                        </IconButton>
                      </TableCell>
                      <TableCell className={lastIndex ? 'border__none' : ''} align="center">
                        <ButtonGroup>
                          <Button onClick={() => openAddStellplaetzeDialog(lagerortObj)} className="button__small" variant="outlined" color="primary">
                            {language('BUTTON', 'TITEL', 'HINZUFUEGEN', objGlobalState.lang)}
                          </Button>
                          <Button onClick={() => openStellplaetzeTableDialog(lagerortObj)} className="button__small" variant="outlined" color="primary">
                            {language('BUTTON', 'TITEL', 'ANSEHEN', objGlobalState.lang)}
                          </Button>
                        </ButtonGroup>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CoLagerorteTable;
