import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import language from '../../Common/language/language';
import { createAxiosInstance } from '../../Common/helperFunctions/axios';

// import -> material ui -> core && icons
import { Button, FormControl, InputLabel, MenuItem, Select, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';

// import -> components
import CoConfirmation from '../../Common/Components/CoConfirmation';

const INPUTS_NAMES = {
  LAGERNAME: 'lagername',
  LAGERART: 'lagerart'
};

// component -> React-root
const CoCreateUpdateLagerortDialog = ({ objGlobalState, objParentState }) => {
  const isUpdate = objParentState.createUpdateLagerortDialogState.isUpdate;
  const { enqueueSnackbar } = useSnackbar();

  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const [inputsState, setInputsState] = useState({
    [INPUTS_NAMES.LAGERNAME]: isUpdate ? objParentState.createUpdateLagerortDialogState.lagerortObj.lagername : '',
    [INPUTS_NAMES.LAGERART]: isUpdate ? objParentState.createUpdateLagerortDialogState.lagerortObj.lagerart : ''
  });

  const [confirmationDialogState, setConfirmationDialogState] = useState(false);
  const [newDataValueState, setNewDataValueState] = useState({
    lagername: '',
    lagerart: ''
  });

  // function ->
  const closeDialog = () => {
    objParentState.setCreateUpdateLagerortDialogState((pre) => ({ ...pre, open: false }));
  };

  // function ->
  const changeInputOnChange = (event) => {
    setInputsState((pre) => ({
      ...pre,
      [event.target.name]: event.target.value
    }));
  };

  // function ->
  const closeConfirmationOnClick = () => {
    setConfirmationDialogState(false);
  };

  // function ->
  const submitFormOnClick = (event) => {
    event.preventDefault();

    const lagername = inputsState[INPUTS_NAMES.LAGERNAME];
    const lagerart = inputsState[INPUTS_NAMES.LAGERART];

    setNewDataValueState({ lagername, lagerart });
    setConfirmationDialogState(true);
  };

  // function ->
  const confirmed = () => {
    objGlobalState.setPageLoadingState(true);

    const { lagername, lagerart } = newDataValueState;

    const requestValues = {
      name: lagername,
      lagerart
    };

    const requestMethod = isUpdate ? 'put' : 'post';
    const requestUrl = isUpdate ? `/lagerort/${objParentState.createUpdateLagerortDialogState.lagerortObj.id}` : '/lagerort';

    authAxios[requestMethod](requestUrl, requestValues)
      .then(() => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT'), { variant: 'success' });
        closeConfirmationOnClick();
        closeDialog();
        objParentState.rerunEffect();
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  const lagerortForm = {
    confirmation: {
      closeConfirmationOnClick,
      confirmationDialogState,
      confirmed,
      newDataValueState
    }
  };

  const dialogTitleText = isUpdate ? 'LAGERORTBEARBEITEN' : 'LAGERORTERSTELLEN';

  return (
    <>
      {confirmationDialogState && <CoConfirmation objGlobalState={objGlobalState} objParentState={lagerortForm.confirmation} />}

      <Dialog fullWidth maxWidth="sm" open={objParentState.createUpdateLagerortDialogState.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{language('DIALOG', 'TITEL', dialogTitleText, objGlobalState.lang)}</DialogTitle>
        <DialogContent className="dialog__content">
          <form name="submitForm" noValidate autoComplete="on" onSubmit={submitFormOnClick}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  value={inputsState[INPUTS_NAMES.LAGERNAME]}
                  onChange={changeInputOnChange}
                  name={INPUTS_NAMES.LAGERNAME}
                  fullWidth
                  type="text"
                  variant="outlined"
                  label={language('FORM', 'LABEL', 'LAGERNAME', objGlobalState.lang)}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="lagerverwaltung_stellplaetze_lagerort_lagerart_select">{language('FORM', 'LABEL', 'LAGERART', objGlobalState.lang)}</InputLabel>
                  <Select
                    value={inputsState[INPUTS_NAMES.LAGERART]}
                    onChange={changeInputOnChange}
                    name={INPUTS_NAMES.LAGERART}
                    labelId="lagerverwaltung_stellplaetze_lagerort_lagerart_select"
                    label={language('FORM', 'LABEL', 'LAGERART', objGlobalState.lang)}
                  >
                    <MenuItem value="REGALLAGER">{language('FORM', 'LABEL', 'REGALLAGER', objGlobalState.lang)}</MenuItem>
                    <MenuItem value="BLOCKLAGER">{language('FORM', 'LABEL', 'BLOCKLAGER', objGlobalState.lang)}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={12} md={6}>
                <Button fullWidth type="submit" color="primary" variant="contained" className="button__middle">
                  {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button onClick={closeDialog} fullWidth className="button__middle">
                  {language('BUTTON', 'TITEL', 'ABBRECHEN', objGlobalState.lang)}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoCreateUpdateLagerortDialog;
