import React, { useEffect, useState } from 'react';
import language from '../Common/language/language';
import { createAxiosInstance } from '../Common/helperFunctions/axios';

// import -> services
import ServiceBreadcrumbs from '../Common/services/Breadcrumbs/ServiceBreadcrumbs';

// import -> material ui -> core
import { Container, Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';

// import -> component
import CoLieferscheinTable from './Append/CoLieferscheinTable';

// component -> React-root
const CoWareneinganglieferung = ({ objGlobalState }) => {
  const [lieferscheinState, setLieferscheinState] = useState([]);

  // function ->
  const fetchLieferscheineFromApi = () => {
    objGlobalState.setPageLoadingState(true);

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);
    authAxios
      .get('/delivery/list')
      .then((response) => {
        const { data } = response;

        const newLieferscheinState = [];
        data.forEach((dataObj) => {
          if (dataObj.deliveryNote) {
            const { id } = dataObj;
            newLieferscheinState.unshift(id);
          }
        });

        setLieferscheinState(newLieferscheinState);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchLieferscheineFromApi();
    }

    return () => (isMounted = false);
  }, []);

  const objLieferscheinStatesAndFunctions = {
    lieferscheinState,
    setLieferscheinState
  };

  // array -> create breadcrumbs
  const arrBreadcrumbs = [
    { url: '/', title: language('SEITE', 'TITEL', 'HOME', objGlobalState.lang) },
    { url: '/wareneingang_lieferschein', title: language('SEITE', 'TITEL', 'WARENEINGANGLIEFERSCHEIN', objGlobalState.lang) }
  ];

  return (
    <Container component="main" maxWidth="xl" className="container__container">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ServiceBreadcrumbs arrBreadcrumbs={arrBreadcrumbs} />
        </Grid>
        <Grid item xs={12}>
          <ListItem className="container__listItem">
            <ListItemIcon>
              <img src={`../assets/images/icons/icon_lieferung.png`} alt="" className="images__headerIcon" />
            </ListItemIcon>
            <ListItemText>
              <Typography className="typography__headerTitle">{language('SEITE', 'TITEL', 'WARENEINGANGLIEFERSCHEIN', objGlobalState.lang)}</Typography>
            </ListItemText>
          </ListItem>
        </Grid>
        <Grid container item xs={12} justify="center">
          {lieferscheinState.length ? (
            <Grid xs={10} item>
              <CoLieferscheinTable objGlobalState={objGlobalState} objLieferscheinStatesAndFunctions={objLieferscheinStatesAndFunctions} />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default CoWareneinganglieferung;
