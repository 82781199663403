import axios from 'axios';
import { FILE_TYPES } from '../types/types';

// function ->
export const createAxiosInstance = (token) => {
  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return authAxios;
};

// function ->
export const getAndCostumFiles = ({ file, filesArray, authAxios }) => {
  return authAxios.get(`/file/${file.id}`, { responseType: 'blob' }).then((_response) => {
    const blobType = file.type.includes('image') || file.type === 'application/octet-stream' ? 'image/jpeg' : file.type === 'application/pdf' ? 'application/pdf' : '';
    const blob = new Blob([_response.data], { type: blobType });
    const url = URL.createObjectURL(blob);
    const type = file.type.includes('image') || file.type === 'application/octet-stream' ? FILE_TYPES.JPEG : file.type === 'application/pdf' ? FILE_TYPES.PDF : FILE_TYPES.NO_TYPE;
    const newFile = { type: type, name: file.name, dataUri: url, id: file.id };
    filesArray.push(newFile);
    return filesArray;
  });
};
