import React from 'react';
import LP from '../CoLandingPage.module.scss';

const CoPaket = ({
  kostenSonderAktion,
  einmalKosten,
  openKostenlosTestenModalOnClick,
  paketeRef,
  paketDataName,
  paketName,
  preisMonat,
  paketFunktion,
  funktionenList,
  firstListItemBlue,
  buttonLabel
}) => {
  return (
    <div className={`${LP.paket} ${LP.paket2}`} data-name={paketDataName} ref={(el) => paketeRef.current?.push(el)}>
      <div className={LP.paket__container}>
        <div className={LP.paket__containerTop}>
          <h2>{paketName}</h2>
          <h4>{paketFunktion}</h4>
        </div>
        <hr style={{ width: '100%' }} />
        <div className={LP.paket__containerBody}>
          <div className={LP.topFunktionen}>
            <span>TOPFUNKTIONEN:</span>
            <ul>
              {funktionenList.map((funktion, key) => {
                const className = firstListItemBlue && key === 0 ? LP.blueBold : '';
                return (
                  <li key={`landingpage__coFunktion_${paketDataName}_list_listitem_${key}`} className={className}>
                    {funktion}
                  </li>
                );
              })}
            </ul>
          </div>
          <hr style={{ width: '100%' }} />
          <div className={LP.einmaligKosten}>
            <span className={`${LP.kostenPerMonat} ${LP.bottom}`}>
              <strong data-type="abo-preis">{preisMonat} €</strong>
              <span className={LP.paket__perMonat}> / Monat</span>
              {paketDataName === 'paket1' && <span> je Account</span>}
              <span className={LP.paket__support}>Inklusive Support per E-Mail*</span>
              {paketDataName === 'paket1' ? (
                <span className={LP.paket__support}>
                  Mindestlaufzeit <b>3 Monate</b>
                </span>
              ) : (
                ''
              )}
            </span>
            <div className={LP.einmaligKostenWrapper}>
              <div>
                <p className={LP.einmaligKostenTitle}>EINMALIGE KOSTEN:</p>
                <span>
                  <p className={LP.einmaligKostenValue2}>
                    <strong>{einmalKosten} €</strong>
                  </p>
                </span>
              </div>
              <div>
                <ul>
                  <li>Setup und Installation TracePort Cloud-Service-Instanz</li>
                  <li>Konfiguration</li>
                  <li>User-Aktivierung</li>
                  <li>Lizenz</li>
                </ul>
              </div>
            </div>
          </div>

          <hr style={{ width: '100%' }} />
          <div className={LP.addOn}>
            <span className={LP.title}>Optional:</span>
            {paketDataName === 'paket2' ? (
              <>
                <ul>
                  <li>GPS Tracker 75 € Kaufpreis oder 65 € Miete monatlich. Mindestbestellmenge 25 Stück</li>
                  <li>
                    <div>
                      <div>Premium Support per Telefon</div>
                      <div>Mo bis Fr, 8–18 Uhr</div>
                      <div>399 € / Monat</div>
                    </div>
                  </li>
                </ul>
                <span className={LP.subTitle}>Die Zusätzlichen Kosten pro User:</span>
                <ul>
                  <li>2-4: 189,00 € / Monat</li>
                  <li>5-9: 179,00 € / Monat</li>
                  <li>Ab 10: 169,00 € / Monat</li>
                </ul>
              </>
            ) : (
              <ul>
                <li>Online-Schulung 80,00 € / Stunde</li>
              </ul>
            )}
          </div>
          <hr style={{ width: '100%' }} />
          <div className={LP.kostenlosTestenLink}>
            <span>
              <button onClick={openKostenlosTestenModalOnClick.bind(null, paketName)}>{buttonLabel}</button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoPaket;
