import React from 'react';
import language from '../Common/language/language';

// import -> material ui -> core & icons
import { Container, Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';

// import -> services
import ServiceBreadcrumbs from '../Common/services/Breadcrumbs/ServiceBreadcrumbs';
import CoAuftragstabelle from '../Common/Components/Auftragsverwaltung/CoAuftragstabelle';

// component -> React-root
const CoWarenausgangstatus = ({ objGlobalState }) => {
  const objStatus = {
    auftragstabelle: {
      warenausgangstatus: true,
      heightFive: true
    }
  };

  // array -> create breadcrumbs
  const arrBreadcrumbs = [
    { url: '/', title: language('SEITE', 'TITEL', 'HOME', objGlobalState.lang) },
    { url: '/warenausgang_status', title: language('SEITE', 'TITEL', 'WARENAUSGANGSTATUS', objGlobalState.lang) }
  ];

  return (
    <Container component="main" maxWidth="xl" className="container__container">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ServiceBreadcrumbs arrBreadcrumbs={arrBreadcrumbs} />
        </Grid>
        <Grid item xs={12}>
          <ListItem className="container__listItem">
            <ListItemIcon>
              <img src={`../assets/images/icons/icon_status.png`} alt="" className="images__headerIcon" />
            </ListItemIcon>
            <ListItemText>
              <Typography className="typography__headerTitle">{language('SEITE', 'TITEL', 'WARENAUSGANGSTATUS', objGlobalState.lang)}</Typography>
            </ListItemText>
          </ListItem>
        </Grid>
        <Grid item xs={12} className="marginThirty">
          <CoAuftragstabelle objGlobalState={objGlobalState} objParentState={objStatus.auftragstabelle} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CoWarenausgangstatus;
