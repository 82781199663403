import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import { handleBeforeUnload } from './components/Common/eventHanlders/eventHandlers';
import isMobile from './components/Common/isMobile/isMobile';
import './App.scss';
import arrMenuStructureMobile from './resources/menu/arrMenuStructureMobile.json';
import arrMenuStructureDesktop from './resources/menu/arrMenuStructureDesktop.json';
import { Backdrop, CircularProgress, CssBaseline, createMuiTheme, MuiThemeProvider, Box } from '@material-ui/core';
import CoArtikelverlauf from './components/Artikelverlauf/CoArtikelverlauf';
import CoArtikelverwaltungartikel from './components/Artikelverwaltungartikel/CoArtikelverwaltungartikel';
import CoArtikelverwaltungartikeluebersicht from './components/Artikelverwaltungartikeluebersicht/CoArtikelverwaltungartikeluebersicht';
import CoAuftragsverwaltungauftrag from './components/Auftragsverwaltungauftrag/CoAuftragsverwaltungauftrag';
import CoAuftragsverwaltungstatus from './components/Auftragsverwaltungstatus/CoAuftragsverwaltungstatus';
import CoForbidden from './components/Forbidden/CoForbidden';
import CoHistorie from './components/Historie/CoHistorie';
import CoHome from './components/Home/CoHome';
import CoInventur from './components/Inventur/CoInventur';
import CoLagerverwaltungartikeluebersicht from './components/Lagerverwaltungartikeluebersicht/CoLagerverwaltungartikeluebersicht';
import CoLagerverwaltungbestandsuebersicht from './components/Lagerverwaltungbestandsuebersicht/CoLagerverwaltungbestandsuebersicht';
import CoLagerverwaltungstellplaetze from './components/Lagerverwaltungstellplaetze/CoLagerverwaltungstellplaetze';
import CoLagerverwaltungumlagerung from './components/Lagerverwaltungumlagerung/CoLagerverwaltungumlagerung';
import CoLiveTracking from './components/LiveTracking/CoLiveTracking';
import CoNavigation from './components/Navigation/CoNavigation';
import CoPassworteingabe from './components/Passworteingabe/CoPassworteingabe';
import CoQualitaetssicherung from './components/Qualitaetssichrung/CoQualitaetssicherung';
import CoResetPasswordEmail from './components/ResetPassword/CoResetPasswordEmail';
import CoSignIn from './components/SignIn/CoSignIn';
import CoSignup from './components/SignUp/CoSignUp';
import CoTracking from './components/Tracking/CoTracking';
import CoVersandavisierung from './components/Versandavisierung/CoVersandavisierung';
import CoVersandlieferung from './components/Versandlieferung/CoVersandlieferung';
import CoWarenannahmeavisierung from './components/Warenannahmeavisierung/CoWarenannahmeavisierung';
import CoWarenannahmelieferung from './components/Warenannahmelieferung/CoWarenannahmelieferung';
import CoWarenannahmeStatus from './components/Warenannahmestatus/CoWarenannahmestatus';
import CoWarenausgangkommissionierung from './components/Warenausgangkommissionierung/CoWarenausgangkommissionierung';
import CoWarenausgangstatus from './components/Warenausgangstatus/CoWarenausgangstatus';
import CoWarenausgangverpackung from './components/Warenausgangverpackung/CoWarenausgangverpackung';
import CoWareneingangeinlagerung from './components/Wareneingangeinlagerung/CoWareneingangeinlagerung';
import CoWareneinganglieferschein from './components/Wareneinganglieferschein/CoWareneinganglieferschein';
import CoWareneingangstatus from './components/Wareneingangstatus/CoWareneingangstatus';
import CoLandingPage from './components/LandingPage/CoLandingPage';
import CoAGB from './components/LandingPage/CoAGB';
import CoDatenschutherklaerung from './components/LandingPage/CoDatenschutherklaerung';
import CoImpressum from './components/LandingPage/CoImpressum';

// function -> check if the site is being framed in another site
function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

// function -> pick the main color of traceport based on where the site is being loaded
const pickMainColor = () => {
  const awLogistikMainColor = '#e3000f';
  const tracePortMainColor = '#4caf50';
  // statement -> if the url is not null and if the site is iframed in aw logistk pick the awlogistik color
  if (inIframe()) {
    return awLogistikMainColor;
  }
  return tracePortMainColor;
};

// object -> material ui -> global style -> overwrite
const theme = createMuiTheme({
  palette: {
    primary: { main: pickMainColor(), contrastText: '#ffffff' }
  },

  typography: { fontFamily: `"Roboto", "Arial"` }
});

// component -> build private Route for internal site navigation over url
const PrivateRoute = ({ access, component: Component, objGlobalState, ...rest }) => {
  const location = useLocation();

  // array -> if user from authenticateState is avaible, than pick given access array
  const arrAccess = Object.keys(objGlobalState.authenticateState.user).length !== 0 ? objGlobalState.authenticateState.user.access : [];

  // boolean -> it is checked whether the private route access is included in the user access
  const isAccessAvaible = arrAccess.includes(access);

  return (
    <Route {...rest}>
      {objGlobalState.authenticateState.auth && isAccessAvaible ? (
        <Component objGlobalState={objGlobalState} />
      ) : objGlobalState.authenticateState.auth && !isAccessAvaible ? (
        <CoForbidden />
      ) : (
        <Redirect to={{ pathname: '/signin', state: { from: location } }} />
      )}
    </Route>
  );
};

// component -> Redirect to signin giving the location state
const RedirectToSignIn = () => {
  const location = useLocation();
  return <Redirect to={{ pathname: '/signin', state: { from: location } }} />;
};

const RedirectToHomeIfAuth = ({ component: Component, objGlobalState }) => {
  const location = useLocation();
  return objGlobalState.authenticateState.auth ? <Redirect to={{ pathname: '/', state: { from: location } }} /> : <Component objGlobalState={objGlobalState} />;
};

// component -> React-root
const App = () => {
  const history = useHistory();

  const [authenticateState, setAuthenticateState] = useState({
    auth: false,
    user: { access: [] }
  });
  const [dialogState, setDialogState] = useState({
    title: '',
    content: '',
    state: false
  });

  const [einstellungenDialogState, setEinstellungenDialogState] = useState(false);

  // variables -> the token that is needed for us to make requests
  const [jwtoken, setJwtoken] = useState('');

  const [lang, setLang] = useState('DE');

  const [menuStructureState, setMenuStructureState] = useState({
    currentMenu: isMobile.any() ? arrMenuStructureMobile : arrMenuStructureDesktop
  });
  const [pageLoadingState, setPageLoadingState] = useState(false);

  // sometimes we have to make it wait until the camera is loaded
  const [canNavBarOpenState, setCanNavBarOpenState] = useState(true);

  const [searchFormSubmitState, setSearchFormSubmitState] = useState(0);

  const [oPBetweenComponents, setOPBetweenComponents] = useState({
    artikelverwaltungArtikeluebersicht_artikelverwaltungArtikel: {
      fromAU: false,
      artikel: {}
    }
  });

  const [hideNavigationState, setHideNavigationState] = useState(false);

  const [liveTrackerState, setLiveTrackerState] = useState([]);

  const { pathname } = window.location;
  const isLandingPage = pathname === '/' || pathname === '/agb' || pathname === '/datenschutzerklaerung' || pathname === '/impressum';

  const contentClassName = () => {
    const essential = 'app__boxContent';
    const mobile = isMobile.any();
    let className = mobile ? 'app__contentMobile' : 'app__contentDesktop';
    if (!authenticateState.auth) {
      className = 'app__contentSignIn';
    }

    if (isLandingPage) {
      className = '';
    }

    return essential + ' ' + className;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    window.addEventListener('scroll', () => {
      const siteTitle = document.querySelector('.MuiListItem-root.container__listItem.MuiListItem-gutters');
      const siteTitleText = document.querySelector('.typography__headerTitle');
      const appBar = document.querySelector('#navigation-app-bar');
      const paragraph = document.querySelector('#insert-siteTitle');
      const navTitleContainer = document.querySelector('#navigation-titleBreadcrumbs-container');

      if (!siteTitle || !appBar) return;

      const topOfSiteTitle = siteTitle.offsetTop;
      const appBarHeight = appBar.clientHeight;

      if (window.scrollY + appBarHeight >= topOfSiteTitle) {
        if (isMobile.any()) navTitleContainer.classList.remove('coNavigation__titleContainer--height');
        paragraph.textContent = siteTitleText.textContent;
        paragraph.classList.add('coNavigation__topNavBreadcrumbs--active');
      } else {
        paragraph.classList.remove('coNavigation__topNavBreadcrumbs--active');
        if (isMobile.any()) navTitleContainer.classList.add('coNavigation__titleContainer--height');
      }
    });
  }, []);

  // object -> build global object for state passthrough
  const objGlobalState = {
    authenticateState,
    setAuthenticateState,
    canNavBarOpenState,
    setCanNavBarOpenState,
    dialogState,
    setDialogState,
    einstellungenDialogState,
    setEinstellungenDialogState,
    jwtoken,
    setJwtoken,
    lang,
    setLang,
    menuStructureState,
    setMenuStructureState,
    pageLoadingState,
    setPageLoadingState,
    searchFormSubmitState,
    setSearchFormSubmitState,
    oPBetweenComponents,
    setOPBetweenComponents,
    isInIframe: inIframe(),
    setHideNavigationState,
    liveTrackerState,
    setLiveTrackerState
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Backdrop open={objGlobalState.pageLoadingState} className="backdrop__container">
        <CircularProgress color="inherit" />
      </Backdrop>
      <Router history={history}>
        {objGlobalState.authenticateState.auth && !hideNavigationState ? <CoNavigation objGlobalState={objGlobalState} /> : null}
        <CssBaseline />
        <Box component="div" className={contentClassName()}>
          <Switch>
            <Route exact path="/">
              <CoLandingPage objGlobalState={objGlobalState} />
            </Route>
            <Route exact path="/agb">
              <CoAGB objGlobalState={objGlobalState} />
            </Route>
            <Route exact path="/datenschutzerklaerung">
              <CoDatenschutherklaerung objGlobalState={objGlobalState} />
            </Route>
            <Route exact path="/impressum">
              <CoImpressum objGlobalState={objGlobalState} />
            </Route>
            <Route exact path="/aktivierung">
              <RedirectToHomeIfAuth component={CoPassworteingabe} objGlobalState={objGlobalState} />
            </Route>
            <Route exact path="/passwordreset">
              <RedirectToHomeIfAuth component={CoPassworteingabe} objGlobalState={objGlobalState} />
            </Route>
            <Route exact path="/signin">
              <RedirectToHomeIfAuth component={CoSignIn} objGlobalState={objGlobalState} />
            </Route>
            <Route exact path="/signup">
              <RedirectToHomeIfAuth component={CoSignup} objGlobalState={objGlobalState} />
            </Route>
            <Route exact path="/resetpassword">
              <CoResetPasswordEmail objGlobalState={objGlobalState} />
            </Route>
            <PrivateRoute exact path="/artikelverlauf" component={CoArtikelverlauf} access={'LAGER'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/artikelverwaltung_artikel" component={CoArtikelverwaltungartikel} access={'LAGER'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/artikelverwaltung_artikeluebersicht" component={CoArtikelverwaltungartikeluebersicht} access={'LAGER'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/auftragsverwaltung_auftrag" component={CoAuftragsverwaltungauftrag} access={'LIEFERUNG'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/auftragsverwaltung_status" component={CoAuftragsverwaltungstatus} access={'LIEFERUNG'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/historie" component={CoHistorie} access={'LAGER'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/home" component={CoHome} access={'HOME'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/inventur" component={CoInventur} access={'LAGER'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/lagerverwaltung_artikeluebersicht" component={CoLagerverwaltungartikeluebersicht} access={'LAGER'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/lagerverwaltung_bestandsuebersicht" component={CoLagerverwaltungbestandsuebersicht} access={'LAGER'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/lagerverwaltung_stellplaetze" component={CoLagerverwaltungstellplaetze} access={'ADMIN'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/lagerverwaltung_umlagerung" component={CoLagerverwaltungumlagerung} access={'LAGER'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/live-tracking/:trackerId" component={CoLiveTracking} access={'LIVE_TRACKING'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/qualitaetssicherung" component={CoQualitaetssicherung} access={'TRACKING'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/tracking" component={CoTracking} access={'TRACKING'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/tracking/:logistikunternehmen/:trackingnummer" component={CoTracking} access={'TRACKING'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/versand_avisierung" component={CoVersandavisierung} access={'LIEFERUNG'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/versand_lieferung" component={CoVersandlieferung} access={'LIEFERUNG'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/warenannahme_avisierung" component={CoWarenannahmeavisierung} access={'LIEFERUNG'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/warenannahme_lieferung" component={CoWarenannahmelieferung} access={'LIEFERUNG'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/warenannahme_status" component={CoWarenannahmeStatus} access={'LIEFERUNG'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/warenausgang_kommissionierung" component={CoWarenausgangkommissionierung} access={'LIEFERUNG'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/warenausgang_status" component={CoWarenausgangstatus} access={'LIEFERUNG'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/warenausgang_verpackung" component={CoWarenausgangverpackung} access={'LIEFERUNG'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/wareneingang_einlagerung" component={CoWareneingangeinlagerung} access={'LIEFERUNG'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/wareneingang_lieferschein" component={CoWareneinganglieferschein} access={'LIEFERUNG'} objGlobalState={objGlobalState} />
            <PrivateRoute exact path="/wareneingang_status" component={CoWareneingangstatus} access={'LIEFERUNG'} objGlobalState={objGlobalState} />
            {objGlobalState.authenticateState.auth ? <Route path="*" component={CoForbidden} /> : <RedirectToSignIn />}
          </Switch>
        </Box>
      </Router>
    </MuiThemeProvider>
  );
};

export default App;
