import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import language from '../../language/language';
import { mouseScrollHorizontally } from '../../helperFunctions/mouseScrollHorizontally';
import { createAxiosInstance, getAndCostumFiles } from '../../helperFunctions/axios';
import { Box, Button, ButtonGroup, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';
import CoShowFiles from '../CoShowFiles';
import { exportTableAsCSV } from '../../helperFunctions/exportTable';
import { generateCSVString } from '../../helperFunctions/generateCSVString';
import { useSnackbar } from 'notistack';
import { generateDate } from '../../helperFunctions/date';
import CoDeleteWarning from './CoDeleteWarning';

// component -> React-root
const CoAvisierungTable = ({ objGlobalState, objParentState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const [showFilesDialogState, setShowFilesDialogState] = useState({ open: false, title: '', files: [] });
  const [notificationsState, setNotificationsState] = useState([]);
  const [warningDialogState, setWarningDialogState] = useState(false);
  const [focusedDataSetId, setFocusedDataSetId] = useState(null);

  const { isHome } = objParentState;

  // function ->
  const deleteDataSetOnClick = () => {
    objGlobalState.setPageLoadingState(true);

    const avisierungId = focusedDataSetId;

    authAxios
      .delete(`/avisierung/${avisierungId}`)
      .then(() => {
        const notificationsArray = [...notificationsState];
        const datasetIndex = notificationsState.findIndex((notification) => notification.id === focusedDataSetId);

        // if there is datasetId is not null and if we got datasetindex then delete
        if (focusedDataSetId && datasetIndex > -1) {
          notificationsArray.splice(datasetIndex, 1);
          setNotificationsState(notificationsArray);
          setFocusedDataSetId(null);
          setWarningDialogState(false);
          enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGELOESCHT', objGlobalState.lang), { variant: 'default' });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const showWarningDialogOnClick = (datasetId) => {
    setWarningDialogState(true);
    setFocusedDataSetId(datasetId);
  };

  // function ->
  const closeWarningDialogOnClick = () => {
    setWarningDialogState(false);
    setFocusedDataSetId(null);
  };

  const fetchNotificationsFromAPI = () => {
    objGlobalState.setPageLoadingState(true);

    authAxios
      .get('/avisierung')
      .then((response) => {
        const { data } = response;

        const newNotificationsState = [];
        data.forEach((dataObj) => {
          const { id, kunde, auftragsnummer, spedition, email, versendedatum, erstellungsdatum, anhang } = dataObj;

          const customVersanddatum = generateDate(versendedatum).justDate;
          const customErstellungsdatum = generateDate(erstellungsdatum).justDate;

          const fotos = anhang.filter((file) => file.id !== null);

          const newNotification = {
            id,
            kunde,
            auftragsnummer,
            spedition,
            email,
            versanddatum: customVersanddatum,
            erstellungsdatum: customErstellungsdatum,
            fotos: fotos
          };
          newNotificationsState.unshift(newNotification);
        });
        setNotificationsState(newNotificationsState);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const stateToCSV = () => {
    const csvRows = [];
    const headerRow = ['KUNDE', 'AUFTRAGSNUMMER', 'SPEDITION', 'EMAIL', 'VERSANDDATUM', 'ERSTELLUNGSDATUM'].map((header) => generateCSVString(language('TABLE', 'TITEL', header, objGlobalState.lang)));
    const tableValuesLabels = ['kunde', 'auftragsnummer', 'spedition', 'email', 'versanddatum', 'erstellungsdatum'];
    csvRows.push(headerRow.join(';'));
    notificationsState.forEach((notification) => {
      const valueRow = tableValuesLabels.map((value) => generateCSVString(notification[value]));
      csvRows.push(valueRow.join(';'));
    });
    return csvRows.join('\n');
  };

  const tableRef = useRef();

  // function ->
  const showFilesDialogOnClick = (fotos) => {
    const title = 'VORHANDENEDATEIEN';
    const files = [];

    if (!fotos.length) {
      setShowFilesDialogState({ open: true, title: title, files: files });
    }

    fotos.forEach((foto) => {
      getAndCostumFiles({ file: foto, filesArray: files, authAxios: authAxios }).then((result) => {
        setShowFilesDialogState({ open: true, title: title, files: result });
      });
    });
  };

  // function ->
  const downloadFile = (url, name, type) => {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${name}.${type}`);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
  };

  // function ->
  const downloadFilesOnClick = (fotos) => {
    const files = [];

    fotos.forEach((foto) => {
      getAndCostumFiles({ file: foto, filesArray: files, authAxios: authAxios }).then((result) => {
        if (fotos.length === files.length) {
          files.forEach((_file) => {
            const dataUri = _file.dataUri;
            const name = _file.name;
            const type = _file.type;
            downloadFile(dataUri, name, type);
          });
        }
      });
    });
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchNotificationsFromAPI();
    }

    return () => (isMounted = false);
  }, [objParentState.rerunEffectState]);

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, []);

  const objAvisierungTableState = {
    showFiles: {
      showFilesDialogState,
      setShowFilesDialogState
    },
    deleteWarning: {
      warningDialogState,
      deleteDataSetOnClick,
      closeWarningDialogOnClick
    }
  };

  const tableMaxHeightClassName = isHome ? 'maxHeight__330' : 'heightFour';

  return (
    <>
      {warningDialogState && <CoDeleteWarning objGlobalState={objGlobalState} objParentState={objAvisierungTableState.deleteWarning} />}
      {showFilesDialogState.open && <CoShowFiles objGlobalState={objGlobalState} objParentState={objAvisierungTableState.showFiles} />}
      <Box className="table__title">
        <Typography align="center" className="typography__singleTableTitle">
          {language('TABLE', 'HEADER', 'AVISIERUNG', objGlobalState.lang)}
        </Typography>
        {isHome ? (
          ''
        ) : (
          <div className="marginTwenty">
            {
              <Button variant="contained" color="primary" onClick={() => exportTableAsCSV(stateToCSV(), 'avisierung')} className="table__titleButton">
                {language('BUTTON', 'TITEL', 'TABELLEEXPORTIEREN', objGlobalState.lang)}
              </Button>
            }
          </div>
        )}
      </Box>
      <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className={`table__container ${tableMaxHeightClassName} border__noTop`}>
        <Table size="medium" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" component="th" className="table__headerCell">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'KUNDE', objGlobalState.lang)}</Typography>
              </TableCell>
              <TableCell align="center" component="th" className="table__headerCell">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'AUFTRAGSNUMMER', objGlobalState.lang)}</Typography>
              </TableCell>
              <TableCell align="center" component="th" className="table__headerCell">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'SPEDITION', objGlobalState.lang)}</Typography>
              </TableCell>
              <TableCell align="center" component="th" className="table__headerCell">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'EMAIL', objGlobalState.lang)}</Typography>
              </TableCell>
              <TableCell align="center" component="th" className="table__headerCell">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'VERSANDDATUM', objGlobalState.lang)}</Typography>
              </TableCell>
              <TableCell align="center" component="th" className="table__headerCell">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'ERSTELLUNGSDATUM', objGlobalState.lang)}</Typography>
              </TableCell>
              {isHome ? (
                ''
              ) : (
                <>
                  <TableCell align="center" component="th" className="table__headerCell">
                    <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'DATEIEN', objGlobalState.lang)}</Typography>
                  </TableCell>
                  <TableCell align="center" component="th" className="table__headerCell"></TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {notificationsState.map((item, key) => (
              <TableRow key={key}>
                <TableCell align="center">
                  <Typography className="typography__singleTableRowContent">{item.kunde}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography className="typography__singleTableRowContent">{item.auftragsnummer}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography className="typography__singleTableRowContent">{item.spedition}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography className="typography__singleTableRowContent">{item.email}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography className="typography__singleTableRowContent">{item.versanddatum}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography className="typography__singleTableRowContent">{item.erstellungsdatum}</Typography>
                </TableCell>
                {isHome ? (
                  ''
                ) : (
                  <>
                    <TableCell align="center">
                      <ButtonGroup aria-label="outlined primary button group">
                        <Button
                          onClick={() => showFilesDialogOnClick(item.fotos)}
                          className={item.fotos?.length ? 'button__small' : ''}
                          variant="outlined"
                          color="primary"
                          disabled={!item.fotos?.length}
                        >
                          {language('BUTTON', 'TITEL', 'ANSEHEN', objGlobalState.lang)}
                        </Button>
                        <Button
                          onClick={() => downloadFilesOnClick(item.fotos)}
                          className={item.fotos?.length ? 'button__small' : ''}
                          variant="outlined"
                          color="primary"
                          disabled={!item.fotos?.length}
                        >
                          {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => showWarningDialogOnClick(item.id)}
                        className="button__icon--tableRed"
                        title={language('BUTTON', 'TITEL', 'LOESCHEN', objGlobalState.lang)}
                        size="small"
                      >
                        <DeleteForever />
                      </IconButton>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CoAvisierungTable;
