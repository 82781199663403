import React from 'react';
import language from '../Common/language/language';

// import -> material ui -> core, icons
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

// import -> higher order component
import hocSuche from '../HOCSuche/HOCSuche';
import CoLogistikunternhemenSelect from '../Common/Components/CoLogistikunternhemenSelect';

// component -> React-root
const CoSuche = ({ functionsAndStates, objGlobalState }) => {
  // destructuring -> props that are from hoc
  const { logistikunternehmenState, setLogistikunternehmenState, textFieldState, setTextFieldState, chooseLogistikunternehmenFromSelect, fetchTextFieldChange, submitFormOnClick } = functionsAndStates;

  //  function ->
  const closeDialogOnClose = () => {
    setLogistikunternehmenState('');
    setTextFieldState({
      trackingnummer: { error: false },
      logistikunternehmen: { error: false }
    });
    objGlobalState.setDialogState({ title: '', content: '', state: false });
  };

  const objSuche = {
    logistikunternehmenSelect: {
      selectValue: logistikunternehmenState,
      handleInputOnChange: chooseLogistikunternehmenFromSelect,
      speditionenToExclude: ['chors', 'awl'],
      inputLabel: 'LOGISTIKUNTERNEHMEN',
      menuItemValue: 'shortcut',
      formContorlClassName: 'coSuche__formControl',
      formControlError: textFieldState.logistikunternehmen.error
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={objGlobalState.dialogState.state}
      onClose={closeDialogOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="coSuche__container"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Typography align="left" className="typography__dialogTitle">
              {language('DIALOG', 'TITEL', 'SUCHE', objGlobalState.lang)}
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <IconButton className="button__iconButton--close" onClick={closeDialogOnClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <form name="submitForm" noValidate autoComplete="on" onSubmit={submitFormOnClick}>
        <DialogContent className="dialog__content">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                name="trackingnummer"
                label={language('FORM', 'LABEL', 'TRACKINGNUMMER', objGlobalState.lang)}
                error={textFieldState.trackingnummer.error}
                helperText={textFieldState.trackingnummer.error ? language('FORM', 'ERROR', 'LEERESTEXTFELD', objGlobalState.lang) : ''}
                onChange={(event) => fetchTextFieldChange(event, 'trackingnummer')}
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <CoLogistikunternhemenSelect objGlobalState={objGlobalState} objParentState={objSuche.logistikunternehmenSelect} />
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant="contained" type="submit" color="primary" className="button">
                {language('BUTTON', 'TITEL', 'SUCHEN', objGlobalState.lang)}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
};

// component -> wrapp the CoSuche with hoc
export default hocSuche(CoSuche);
