import React, { useState } from 'react';
import language from '../Common/language/language';

// import -> services
import ServiceBreadcrumbs from '../Common/services/Breadcrumbs/ServiceBreadcrumbs';

// import -> material ui -> core & icons
import { Tabs, Tab, Button, Container, Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';

// import -> component
import CoCreateUpdateLagerortDialog from './Append/CoCreateUpdateLagerortDialog';
import CoStellplaetzeTable from './Append/CoStellplaetzeTable';
import CoCreateUpdateStellplatz from './Append/CoCreateUpdateStellplatzDialog';
import CoLagerorteTable from './Append/CoLagerorteTable';

const PAGE_CONTENT = {
  LAGERORTE: 1,
  STELLPLAETZE: 2
};

// component -> React-root
const CoLagerverwaltungstellplaetze = ({ objGlobalState }) => {
  const [pageContentState, setPageContentState] = useState(PAGE_CONTENT.STELLPLAETZE);

  const [createUpdateStellplatzDialogState, setCreateUpdateStellplatzDialogState] = useState({ open: false, isUpdate: false, stellplatzObj: {} });

  const [createUpdateLagerortDialogState, setCreateUpdateLagerortDialogState] = useState({ open: false, isUpdate: false, lagerortObj: {} });

  const [rerunEffectState, setRerunEffectState] = useState(false);

  // function ->
  const changePageContent = (_, contentType) => {
    setPageContentState(contentType);
  };

  // function ->
  const openCreateUpdateStellplatzDialog = () => {
    setCreateUpdateStellplatzDialogState({ open: true, isUpdate: false, stellplatzObj: {} });
  };

  // function ->
  const openCreateUpdateLagerortDialog = (_, isUpdate, lagerortObj = {}) => {
    setCreateUpdateLagerortDialogState({ open: true, isUpdate: !!isUpdate, lagerortObj });
  };

  // function ->
  const rerunEffect = () => {
    setRerunEffectState((pre) => !pre);
  };

  const objStellplaetze = {
    createUpdateLagerortDialog: {
      createUpdateLagerortDialogState,
      setCreateUpdateLagerortDialogState,
      rerunEffect
    },
    createUpdateStellplatzDialog: {
      createUpdateStellplatzDialogState,
      setCreateUpdateStellplatzDialogState,
      rerunEffect
    },
    lagerorteTable: {
      rerunEffectState,
      rerunEffect
    },
    stellplaetzeTable: {
      heightFour: true,
      rerunEffectState
    }
  };

  // array -> create breadcrumbs
  const arrBreadcrumbs = [
    { url: '/', title: language('SEITE', 'TITEL', 'HOME', objGlobalState.lang) },
    { url: '/lagerverwaltung_stellplaetze', title: language('SEITE', 'TITEL', 'LAGERVERWALTUNGSTELLPLAETZE', objGlobalState.lang) }
  ];

  return (
    <>
      {createUpdateStellplatzDialogState.open && <CoCreateUpdateStellplatz objGlobalState={objGlobalState} objParentState={objStellplaetze.createUpdateStellplatzDialog} />}
      {createUpdateLagerortDialogState.open && <CoCreateUpdateLagerortDialog objGlobalState={objGlobalState} objParentState={objStellplaetze.createUpdateLagerortDialog} />}
      <Container component="main" maxWidth="xl" className="container__container">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ServiceBreadcrumbs arrBreadcrumbs={arrBreadcrumbs} />
          </Grid>
          <Grid item xs={12}>
            <ListItem className="container__listItem">
              <ListItemIcon>
                <img src={`../assets/images/icons/icon_stellplaetze.png`} alt="" className="images__headerIcon" />
              </ListItemIcon>
              <ListItemText>
                <Typography className="typography__headerTitle">{language('SEITE', 'TITEL', 'LAGERVERWALTUNGSTELLPLAETZE', objGlobalState.lang)}</Typography>
              </ListItemText>
            </ListItem>
          </Grid>
          <Grid item xs={12}>
            <Tabs className="table__headerTabs" value={pageContentState} indicatorColor="primary" textColor="primary" onChange={changePageContent}>
              <Tab className="noWrap fontWeihgtBold" value={PAGE_CONTENT.STELLPLAETZE} label={language('BUTTON', 'TITEL', 'STELLPLAETZE', objGlobalState.lang)} />
              <Tab className="noWrap fontWeihgtBold" value={PAGE_CONTENT.LAGERORTE} label={language('BUTTON', 'TITEL', 'LAGERORTE', objGlobalState.lang)} />
            </Tabs>
          </Grid>
          {pageContentState === PAGE_CONTENT.STELLPLAETZE && (
            <>
              <Grid item xs={12} container justify="flex-end">
                <Button onClick={openCreateUpdateStellplatzDialog} className="button__middle" variant="contained" color="primary">
                  {language('BUTTON', 'TITEL', 'STELLPLATZERSTELLEN', objGlobalState.lang)}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <CoStellplaetzeTable objGlobalState={objGlobalState} objParentState={objStellplaetze.stellplaetzeTable} />
              </Grid>
            </>
          )}
          {pageContentState === PAGE_CONTENT.LAGERORTE && (
            <>
              <Grid item xs={12} container justify="flex-end">
                <Button onClick={openCreateUpdateLagerortDialog} className="button__middle" variant="contained" color="primary">
                  {language('BUTTON', 'TITEL', 'LAGERORTERSTELLEN', objGlobalState.lang)}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <CoLagerorteTable objGlobalState={objGlobalState} objParentState={objStellplaetze.lagerorteTable} />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default CoLagerverwaltungstellplaetze;
