import React, { useLayoutEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import language from '../../Common/language/language';
import { mouseScrollHorizontally } from '../../Common/helperFunctions/mouseScrollHorizontally';
import { createAxiosInstance } from '../../Common/helperFunctions/axios';

// import -> material ui -> core & icons
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, TextField } from '@material-ui/core';

// import -> components
import CoUploadFiles from '../../Common/Components/CoUploadFiles';
import CoConfirmation from '../../Common/Components/CoConfirmation';
import CoAuftragstabelle from '../../Common/Components/Auftragsverwaltung/CoAuftragstabelle';
import CoBestandstabelle from '../../Common/Components/Lagerverwaltung/Bestandstabelle/CoBestandstabelle';
import CoMultipleScannerWithSelectStellplatz from '../../Common/Components/CoMultipleScannerWithSelectStellplatz';

// component -> React-root
const CoKommissionierungNoTransporteinheit = ({ objGlobalState }) => {
  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const { enqueueSnackbar } = useSnackbar();

  const [rerunEffectState, setRerunEffectState] = useState(false);
  const [ausgewaehlterAuftragState, setAusgewaehlterAuftragState] = useState({ chosen: false, auftrag: {} });
  const [ausgewaehlteBuchungsIdState, setAusgewaehlteBuchungsIdState] = useState({ chosen: false, buchungsId: '' });
  const [confirmationDialogState, setConfirmationDialogState] = useState(false);
  const [uploadFilesStellplatzfotosDialogState, setUploadFilesStellplatzfotosDialogState] = useState({ open: false, title: '' });
  const [uploadFilesLieferscheinDialogState, setUploadFilesLieferscheinDialogState] = useState({ open: false, title: '' });
  const [stellplatzfotosState, setStellplatzfotosState] = useState([]);
  const [lieferscheinState, setLieferscheinState] = useState([]);
  const [selectedStellplaetzeState, setSelectedStellplaetzeState] = useState('');
  const [newDataValueState, setNewDataValueState] = useState({
    auftragsnummer: '',
    buchungsId: '',
    stellplatzkennzeichnung: '',
    menge: ''
  });

  const tableRef = useRef();

  // function ->
  const closeConfirmationOnClick = () => {
    eval('setConfirmationDialogState(false)');
  };

  // function ->
  const rerunEffect = () => {
    setRerunEffectState((pre) => !pre);
  };

  // function ->
  const removeAusgewahlteDatenAfterConfirm = () => {
    setAusgewaehlteBuchungsIdState({ chosen: false, buchungsId: '' });
    setAusgewaehlterAuftragState({ chosen: false, auftrag: {} });
  };

  // function ->
  const submitFormOnClick = (event) => {
    event.preventDefault();

    const menge = event.target.menge.value;
    const stellplatzkennzeichnung = getSelectedStellplaetze().confirmationStellplaetze;
    const auftragsnummer = ausgewaehlterAuftragState.auftrag.auftragsnummer;
    const buchungsId = ausgewaehlteBuchungsIdState.buchungsId;

    setNewDataValueState({ menge, stellplatzkennzeichnung, auftragsnummer, buchungsId });
    setConfirmationDialogState(true);
  };

  // function ->
  const getSelectedStellplaetze = () => {
    // to display in the confirmation dialog
    const confirmationStellplaetze = selectedStellplaetzeState.map((stellplatz) => stellplatz.name).join(', ');

    // to send with the request
    const requestStellplaetze = selectedStellplaetzeState.map((stellplatz) => ({ id: stellplatz.id }));

    return {
      confirmationStellplaetze,
      requestStellplaetze
    };
  };

  // function ->
  const confirmed = () => {
    objGlobalState.setPageLoadingState(true);

    const { menge, buchungsId } = newDataValueState;
    const auftragId = ausgewaehlterAuftragState.auftrag.id;

    const requestBody = {
      auftragId,
      buchungsId,
      stellplaetze: getSelectedStellplaetze().requestStellplaetze,
      menge: menge.length ? +menge : null
    };
    authAxios
      .post('/transporteinheit/lager-auftrag', requestBody)
      .then(() => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT', objGlobalState.lang), { variant: 'success' });

        rerunEffect();
        closeConfirmationOnClick();
        removeAusgewahlteDatenAfterConfirm();
      })
      .catch((error) => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });

        console.error(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const showAusgewaehlterAuftrag = () => {
    // destructuring the needed data to display
    const { auftragsnummer, kunde, spedition, datum, sendungsart, anzahlDerPakete, anzahlDerPalette } = ausgewaehlterAuftragState.auftrag;

    // array -> put the destructured data in an array to map on them
    const values = [
      { name: 'auftragsnummer', value: auftragsnummer },
      { name: 'kunde', value: kunde },
      { name: 'spedition', value: spedition },
      { name: 'datum', value: datum },
      { name: 'sendungsart', value: sendungsart },
      { name: 'anzahlDerPakete', value: anzahlDerPakete },
      { name: 'anzahlDerPalette', value: anzahlDerPalette }
    ];
    return (
      <TableRow>
        {values.map((value, key) => {
          return (
            <TableCell align="center" key={`warenannahme_status_sendungen_TableCell_${key}`} className="table__rowTitle">
              <Typography className="typography__singleTableRowContent">{value.value}</Typography>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, []);

  const stellplatzfotosSectionTitle = 'STELLPLATZFOTOS';
  const stellplatzfotosSection = { sectionTitle: stellplatzfotosSectionTitle, files: stellplatzfotosState };

  const lieferscheinSectionTitle = 'LIEFERSCHEIN';
  const lieferscheinSection = { sectionTitle: lieferscheinSectionTitle, files: lieferscheinState };

  const confirmationGallery = [stellplatzfotosSection, lieferscheinSection];

  const objKommissionierungWithTransporteinheit = {
    auftragstabelle: {
      kommissionierung: true,
      heightFour: true,
      setAusgewaehlterAuftragState
    },
    bestandstabelle: {
      setAusgewaehlteBuchungsIdState,
      heightFour: true,
      kommissionierung: true,
      rerunEffectState
    },
    confirmation: {
      closeConfirmationOnClick,
      confirmationDialogState,
      confirmed,
      confirmationGallery,
      newDataValueState
    },
    multipleScannerWithSelectStellplatz: {
      ausgewaehlteBuchungsIdState,
      stellplaetzeRequestUrl: ausgewaehlteBuchungsIdState.chosen
        ? `/transporteinheit/${ausgewaehlteBuchungsIdState.buchungsId}/lager-stellplatz?buchungs_id=${ausgewaehlteBuchungsIdState.buchungsId}`
        : '',
      setSelectedStellplaetzeState,
      rerunEffectState
    },
    uploadFilesFotos: {
      filesState: stellplatzfotosState,
      setFilesState: setStellplatzfotosState,
      uploadFilesDialogState: uploadFilesStellplatzfotosDialogState,
      setUploadFilesDialogState: setUploadFilesStellplatzfotosDialogState
    },
    uploadFilesLieferschein: {
      filesState: lieferscheinState,
      setFilesState: setLieferscheinState,
      uploadFilesDialogState: uploadFilesLieferscheinDialogState,
      setUploadFilesDialogState: setUploadFilesLieferscheinDialogState
    }
  };

  return (
    <>
      {uploadFilesStellplatzfotosDialogState && <CoUploadFiles objGlobalState={objGlobalState} objParentState={objKommissionierungWithTransporteinheit.uploadFilesFotos} />}
      {uploadFilesLieferscheinDialogState && (
        <CoUploadFiles objGlobalState={objGlobalState} title="LIEFERSCHEINHOCHLADEN" objParentState={objKommissionierungWithTransporteinheit.uploadFilesLieferschein} />
      )}
      {confirmationDialogState && <CoConfirmation objGlobalState={objGlobalState} objParentState={objKommissionierungWithTransporteinheit.confirmation} />}
      <Grid item xs={12} className="marginThirty">
        <Grid item xs={12}>
          <CoAuftragstabelle objGlobalState={objGlobalState} objParentState={objKommissionierungWithTransporteinheit.auftragstabelle} />
        </Grid>
        <Grid item xs={12} className="marginFourty">
          <Typography className="typography__sectionTitle">{language('ABTEILUNG', 'TITEL', 'AUFTRAG', objGlobalState.lang)}</Typography>
        </Grid>
        <Grid item xs={12} className="marginTwenty">
          {ausgewaehlterAuftragState.chosen ? (
            <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} className="table__container">
              <Table size="medium">
                <TableBody>{showAusgewaehlterAuftrag()}</TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography className="typography__colorDamage">{language('KONTENT', 'TEXT', 'KEINAUFTRAGAUSGEWAEHLT', objGlobalState.lang)}</Typography>
          )}
        </Grid>

        <Grid item xs={12} className="marginEighty">
          <CoBestandstabelle objGlobalState={objGlobalState} objParentState={objKommissionierungWithTransporteinheit.bestandstabelle} />
        </Grid>
        <Grid item xs={12} className="marginThirty">
          <Typography className="typography__sectionTitle">{language('ABTEILUNG', 'TITEL', 'BUCHUNGSID', objGlobalState.lang)}</Typography>
        </Grid>
        <Grid item xs={12} className="marginTwenty">
          {ausgewaehlteBuchungsIdState.chosen ? (
            <Typography className="typography__colorSuccess typography__2rem">{ausgewaehlteBuchungsIdState.buchungsId}</Typography>
          ) : (
            <Typography className="typography__colorDamage">{language('KONTENT', 'TEXT', 'KEINEBUCHUNGSIDAUSGEWAEHLT', objGlobalState.lang)}</Typography>
          )}
        </Grid>
        <form name="submitForm" noValidate autoComplete="on" onSubmit={submitFormOnClick}>
          <Grid item xs={12} container spacing={3} justify="flex-start" alignContent="center" alignItems="center" className="marginThirty">
            <CoMultipleScannerWithSelectStellplatz objGlobalState={objGlobalState} objParentState={objKommissionierungWithTransporteinheit.multipleScannerWithSelectStellplatz} />
            {/* <Grid item xs={12} container spacing={3} justify="flex-start" alignContent="center" alignItems="center" className="marginThirty border__top">
              <Grid item xs={12}>
                <Typography className="typography__sectionTitle">{language('ABTEILUNG', 'TITEL', 'LIEFERSCHEIN', objGlobalState.lang)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Button onClick={() => showUploadFilesDialogOnClick('lieferschein')} align="center" className="button__middle--greenOutLined">
                  {language('BUTTON', 'TITEL', 'LIEFERSCHEINHOCHLADEN', objGlobalState.lang)}
                </Button>
              </Grid>
              <CoDisplayFilesList objGlobalState={objGlobalState} filesState={lieferscheinState} setFilesState={setLieferscheinState} title="LIEFERSCHEIN" />
            </Grid> */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField fullWidth variant="outlined" name="menge" type="number" label={language('FORM', 'LABEL', 'MENGE', objGlobalState.lang)} />
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth type="submit" color="primary" className="button__middle" variant="contained" color="primary">
                {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
};

export default CoKommissionierungNoTransporteinheit;
