// funtion ->
export const mouseScrollHorizontally = (element) => {
  if (element) {
    let isDown = false;
    let startX;
    let scrollLeft;
    element.addEventListener('mousedown', (event) => {
      isDown = true;
      startX = event.pageX - element.offsetLeft;
      scrollLeft = element.scrollLeft;
    });
    element.addEventListener('mouseleave', (event) => {
      isDown = false;
    });
    element.addEventListener('mouseup', (event) => {
      isDown = false;
    });
    element.addEventListener('mousemove', (event) => {
      if (!isDown) {
        return;
      }
      event.preventDefault();
      const x = event.pageX - element.offsetLeft;
      const walk = x - startX;
      element.scrollLeft = scrollLeft - walk;
    });
  }
};
