import React, { useLayoutEffect, useRef, useState } from 'react';
import { mouseScrollHorizontally } from '../../Common/helperFunctions/mouseScrollHorizontally';
import language from '../../Common/language/language';
import { createAxiosInstance } from '../../Common/helperFunctions/axios';
import { useSnackbar } from 'notistack';

// import -> material-ui -> core
import { Switch, Grid, Box, Button, ButtonGroup, Typography, TextField, Table, TableContainer, TableRow, TableCell, TableBody, Tabs, Tab, Paper, Select, MenuItem } from '@material-ui/core';

// import -> components
import CoConfirmation from '../../Common/Components/CoConfirmation';
import { useEffect } from 'react';

const CoArtikelForm = ({ objGlobalState, objParentState }) => {
  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const { enqueueSnackbar } = useSnackbar();

  const [confirmationDialogState, setConfirmationDialogState] = useState(false);

  const [warengruppeOptionsState, setWarengruppeOptionsState] = useState([]);

  const [tableTabsState, setTableTabsState] = useState(1);
  const [lagertmpeingabenwahlState, setLagertmpeingabenwahlState] = useState(2);
  const [warengruppeeingabenwahlState, setWarengruppeeingabenwahlState] = useState(1);

  const [newDataValueState, setNewDataValueState] = useState({});

  const [artikeNummerFehler, setartikeNummerFehler] = useState(false);

  const tableRef = useRef();

  // function ->
  const handleLagerTmpEingabewahlOnChange = (wahlId) => {
    setLagertmpeingabenwahlState(wahlId);
  };

  // function ->
  const handleWarengruppeeingabenwahlOnChange = (wahlId) => {
    setWarengruppeeingabenwahlState(wahlId);
  };

  // function ->
  const handleTableTabsOnChange = (event, tabValue) => {
    setTableTabsState(tabValue);
  };

  // function ->
  const changeInputOnChange = (event, inputName) => {
    let value;
    const isCheckbox = event.target.type === 'checkbox';
    const isNumber = event.target.type === 'number';
    if (isCheckbox) {
      value = event.target.checked;
    } else {
      value = isNumber ? +event.target.value : event.target.value;
    }

    objParentState.setTextFieldsState((pre) => {
      return {
        ...pre,
        [inputName]: value
      };
    });

    const title = event.target.name;

    setNewDataValueState((pre) => ({
      ...pre,
      [title]: value
    }));
  };

  // function ->
  const createArticleRequest = (requestBody) => {
    objGlobalState.setPageLoadingState(true);
    authAxios
      .post('/artikel', requestBody)
      .then((res) => {
        setartikeNummerFehler(false);
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT'), { variant: 'success' });
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.message === 'Es existiert bereits ein Artikel mit derselben Artikelnummer') {
          setartikeNummerFehler(true);
          enqueueSnackbar(language('ALERT', 'TEXT', 'ARTIKELEXISTIERT', objGlobalState.lang), { variant: 'error' });
        } else {
          enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
        }
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const updateArticleRequest = (requestBody) => {
    const artikelId = objParentState.artikelToEdit.id;
    objGlobalState.setPageLoadingState(true);
    authAxios
      .put(`/artikel/${artikelId}`, requestBody)
      .then(() => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT'), { variant: 'success' });
        objParentState.endArtikelEditing();
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const submitArticleFormOnSubmit = (event) => {
    event.preventDefault();

    const valuesToConfirm = { ...newDataValueState };

    if (lagertmpeingabenwahlState === 1) {
      const lagertemperatur = objParentState.textFieldsState.lagertemperatur;
      if (lagertemperatur && lagertemperatur.length > 0) {
        const [ltMin, ltMax] = lagertemperatur.split('_bis_');

        valuesToConfirm['LAGERTEMPERATURMIN'] = +ltMin;
        valuesToConfirm['LAGERTEMPERATURMAX'] = +ltMax;
      }
    }

    delete valuesToConfirm['LAGERTEMPERATUR'];

    setConfirmationDialogState(true);
  };

  // function ->
  const closeConfirmationOnClick = () => {
    setConfirmationDialogState(false);
  };

  // function ->
  const confirmed = () => {
    const requestBody = { ...objParentState.textFieldsState };

    if (lagertmpeingabenwahlState === 1) {
      const lagertemperatur = objParentState.textFieldsState.lagertemperatur;
      if (lagertemperatur && lagertemperatur.length > 0) {
        const [ltMin, ltMax] = lagertemperatur.split('_bis_');
        requestBody.lagertemperatorMin = +ltMin;
        requestBody.lagertemperatorMax = +ltMax;
      }
    }

    delete requestBody.lagertemperatur;

    objParentState.isEdit ? updateArticleRequest(requestBody) : createArticleRequest(requestBody);
    closeConfirmationOnClick();
  };

  // function ->
  const fetchWarengruppeOptionsFromAPI = () => {
    authAxios
      .get('/artikel/warengruppen')
      .then((response) => {
        const warengruppen = response.data;
        setWarengruppeOptionsState(warengruppen);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    fetchWarengruppeOptionsFromAPI();

    return () => {
      isMounted = false;
    };
  }, []);

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, []);

  const objArtikelForm = {
    confirmation: {
      closeConfirmationOnClick,
      confirmationDialogState,
      confirmed,
      newDataValueState
    }
  };

  return (
    <>
      {confirmationDialogState && <CoConfirmation objGlobalState={objGlobalState} objParentState={objArtikelForm.confirmation} />}

      <form onSubmit={submitArticleFormOnSubmit}>
        <Box className="table__title">
          <Tabs className="table__headerTabs" value={tableTabsState} indicatorColor="primary" textColor="primary" onChange={handleTableTabsOnChange}>
            <Tab className="noWrap fontWeihgtBold" value={1} label={language('TABLE', 'HEADER', 'ARTIKEL', objGlobalState.lang)} />
            <Tab className="noWrap fontWeihgtBold" value={2} label={language('TABLE', 'HEADER', 'LAGERHINWEISE', objGlobalState.lang)} />
            <Tab className="noWrap fontWeihgtBold" value={3} label={language('TABLE', 'HEADER', 'KAUFMAENNISCHERTEIL', objGlobalState.lang)} />
          </Tabs>
        </Box>
        <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className="table__container--border coArtikelverwaltungartikel__table border__noTop">
          <Table size="medium" stickyHeader aria-label="sticky table">
            <TableBody>
              {tableTabsState === 1 && (
                <>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography" title={language('FORM', 'LABEL', 'PHARMAZENTRALNUMMER', objGlobalState.lang)}>
                        {language('FORM', 'LABEL', 'PZN', objGlobalState.lang)}
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="PZN"
                        value={objParentState.textFieldsState.pzn}
                        onChange={(event) => changeInputOnChange(event, 'pzn')}
                        placeholder={language('FORM', 'LABEL', 'PZN', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'EAN', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="EAN"
                        value={objParentState.textFieldsState.ean}
                        onChange={(event) => changeInputOnChange(event, 'ean')}
                        placeholder={language('FORM', 'LABEL', 'EAN', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'HERSTELLERNUMMER', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="HERSTELLERNUMMER"
                        value={objParentState.textFieldsState.herstellerNummer}
                        onChange={(event) => changeInputOnChange(event, 'herstellerNummer')}
                        placeholder={language('FORM', 'LABEL', 'HERSTELLERNUMMER', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'HERSTELLERNAME', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="HERSTELLERNAME"
                        value={objParentState.textFieldsState.hersteller}
                        onChange={(event) => changeInputOnChange(event, 'hersteller')}
                        placeholder={language('FORM', 'LABEL', 'HERSTELLERNAME', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography" title={language('FORM', 'LABEL', 'HERSTELLERARTIKELNUMMER', objGlobalState.lang)}>
                        {language('FORM', 'LABEL', 'ARTNRHERSTELLER', objGlobalState.lang)}
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="HERSTELLERARTIKELNUMMER"
                        value={objParentState.textFieldsState.herstellerArtikelnummer}
                        onChange={(event) => changeInputOnChange(event, 'herstellerArtikelnummer')}
                        placeholder={language('FORM', 'LABEL', 'ARTNRHERSTELLER', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'BEZEICHNUNG', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="BEZEICHNUNG"
                        value={objParentState.textFieldsState.bezeichnung}
                        onChange={(event) => changeInputOnChange(event, 'bezeichnung')}
                        placeholder={language('FORM', 'LABEL', 'BEZEICHNUNG', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'BEZEICHNUNG2', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="BEZEICHNUNG2"
                        value={objParentState.textFieldsState.bezeichnung2}
                        onChange={(event) => changeInputOnChange(event, 'bezeichnung2')}
                        placeholder={language('FORM', 'LABEL', 'BEZEICHNUNG2', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  {artikeNummerFehler === true ? (
                    <TableRow>
                      <TableCell align="left" component="th">
                        <Typography className="typography">{language('FORM', 'LABEL', 'ARTIKELNUMMER', objGlobalState.lang)}</Typography>
                      </TableCell>
                      <TableCell colSpan={3} align="right">
                        <TextField
                          fullWidth
                          variant="outlined"
                          name="ARTIKELNUMMER"
                          value={objParentState.textFieldsState.artikelnummer}
                          onChange={(event) => changeInputOnChange(event, 'artikelnummer')}
                          placeholder={language('FORM', 'LABEL', 'ARTIKELNUMMER', objGlobalState.lang)}
                          error={artikeNummerFehler}
                          helperText={language('ALERT', 'TEXT', 'EXISTIERTSCHON', objGlobalState.lang)}
                        />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell align="left" component="th">
                        <Typography className="typography">{language('FORM', 'LABEL', 'ARTIKELNUMMER', objGlobalState.lang)}</Typography>
                      </TableCell>
                      <TableCell colSpan={3} align="right">
                        <TextField
                          fullWidth
                          variant="outlined"
                          name="ARTIKELNUMMER"
                          value={objParentState.textFieldsState.artikelnummer}
                          onChange={(event) => changeInputOnChange(event, 'artikelnummer')}
                          placeholder={language('FORM', 'LABEL', 'ARTIKELNUMMER', objGlobalState.lang)}
                        />
                      </TableCell>
                    </TableRow>
                  )}

                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography" title={language('FORM', 'LABEL', 'MENGENEINHEIT', objGlobalState.lang)}>
                        {language('FORM', 'LABEL', 'ME', objGlobalState.lang)}
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <Select fullWidth name="MENGENEINHEIT" value={objParentState.textFieldsState.mengeneinheit} onChange={(event) => changeInputOnChange(event, 'mengeneinheit')}>
                        <MenuItem value="LITER">{language('FORM', 'LABEL', 'LITER', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="STUECK">{language('FORM', 'LABEL', 'STUECK', objGlobalState.lang)}</MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'MENGE', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        name="MENGE"
                        value={objParentState.textFieldsState.menge}
                        onChange={(event) => changeInputOnChange(event, 'menge')}
                        placeholder={language('FORM', 'LABEL', 'MENGE', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography" title={language('FORM', 'LABEL', 'DARREICHUNGSFORM', objGlobalState.lang)}>
                        {language('FORM', 'LABEL', 'DAR', objGlobalState.lang)}
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="DARREICHUNGSFORM"
                        value={objParentState.textFieldsState.darreichungsform}
                        onChange={(event) => changeInputOnChange(event, 'darreichungsform')}
                        placeholder={language('FORM', 'LABEL', 'DAR', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'PACKMASSELAENGE', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        name="PACKMASSELAENGE"
                        value={objParentState.textFieldsState.packmassLaenge}
                        onChange={(event) => changeInputOnChange(event, 'packmassLaenge')}
                        placeholder={language('FORM', 'LABEL', 'PACKMASSELAENGE', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'PACKMASSEHOEHE', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        name="PACKMASSEHOEHE"
                        value={objParentState.textFieldsState.packmassHoehe}
                        onChange={(event) => changeInputOnChange(event, 'packmassHoehe')}
                        placeholder={language('FORM', 'LABEL', 'PACKMASSEHOEHE', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'PACKMASSEBREITE', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        name="PACKMASSEBREITE"
                        value={objParentState.textFieldsState.packmassBreite}
                        onChange={(event) => changeInputOnChange(event, 'packmassBreite')}
                        placeholder={language('FORM', 'LABEL', 'PACKMASSEBREITE', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'PACKMASSEEINHEIT', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <Select fullWidth name="PACKMASSEEINHEIT" value={objParentState.textFieldsState.packmassEinheit} onChange={(event) => changeInputOnChange(event, 'packmassEinheit')}>
                        <MenuItem value="CM">{language('FORM', 'LABEL', 'CM', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="MM">{language('FORM', 'LABEL', 'MM', objGlobalState.lang)}</MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'GEWICHTEINHEIT', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <Select fullWidth name="GEWICHTEINHEIT" value={objParentState.textFieldsState.gewichtEinheit} onChange={(event) => changeInputOnChange(event, 'gewichtEinheit')}>
                        <MenuItem value="GRAMM">{language('FORM', 'LABEL', 'GRAMM', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="KILOGRAMM">{language('FORM', 'LABEL', 'KG', objGlobalState.lang)}</MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'GEWICHT', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        name="GEWICHT"
                        value={objParentState.textFieldsState.gewicht}
                        onChange={(event) => changeInputOnChange(event, 'gewicht')}
                        placeholder={language('FORM', 'LABEL', 'GEWICHT', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'GEBINDEEINKAUF', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        name="GEBINDEEINKAUF"
                        value={objParentState.textFieldsState.gebindeEinkauf}
                        onChange={(event) => changeInputOnChange(event, 'gebindeEinkauf')}
                        placeholder={language('FORM', 'LABEL', 'GEBINDEEINKAUF', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'GEBINDEVERKAUF', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        name="GEBINDEVERKAUF"
                        value={objParentState.textFieldsState.gebindeVerkauf}
                        onChange={(event) => changeInputOnChange(event, 'gebindeVerkauf')}
                        placeholder={language('FORM', 'LABEL', 'GEBINDEVERKAUF', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'ARZNEIMITTEL', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="left">
                      <Switch name="ARZNEIMITTEL" checked={objParentState.textFieldsState.arzneimittel} onChange={(event) => changeInputOnChange(event, 'arzneimittel')} color="primary" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'APOTHEKENPFLICHTIG', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="left">
                      <Switch
                        name="APOTHEKENPFLICHTIG"
                        checked={objParentState.textFieldsState.apothekenPflichtig}
                        onChange={(event) => changeInputOnChange(event, 'apothekenPflichtig')}
                        color="primary"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'VERSCHREIBUNGSPFLICHTIG', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="left">
                      <Switch
                        name="VERSCHREIBUNGSPFLICHTIG"
                        checked={objParentState.textFieldsState.verschreibungspflichtig}
                        onChange={(event) => changeInputOnChange(event, 'verschreibungspflichtig')}
                        color="primary"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'KUEHLKETTEWARE', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <Select fullWidth name="KUEHLKETTEWARE" value={objParentState.textFieldsState.kuehlung} onChange={(event) => changeInputOnChange(event, 'kuehlung')}>
                        <MenuItem value="KEINE_ANGABE">{language('FORM', 'LABEL', 'KEINEANGABE', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="NEIN">{language('FORM', 'LABEL', 'NEIN', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="KUEHLKETTE">{language('FORM', 'LABEL', 'KUEHLKETTE', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="KUEHLWARE">{language('FORM', 'LABEL', 'KUEHLWARE', objGlobalState.lang)}</MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'AMBIENT', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <Select fullWidth name="AMBIENT" value={objParentState.textFieldsState.ambient} onChange={(event) => changeInputOnChange(event, 'ambient')}>
                        <MenuItem value="KEINE_ANGABE">{language('FORM', 'LABEL', 'KEINEANGABE', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="NEIN">{language('FORM', 'LABEL', 'NEIN', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="AMBIENT">{language('FORM', 'LABEL', 'AMBIENT', objGlobalState.lang)}</MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'LAGERTEMPERATUR', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="left">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <ButtonGroup>
                            <Button color={lagertmpeingabenwahlState === 1 ? 'primary' : ''} onClick={handleLagerTmpEingabewahlOnChange.bind(null, 1)}>
                              {language('FORM', 'LABEL', 'AUSWAEHLEN', objGlobalState.lang)}
                            </Button>
                            <Button color={lagertmpeingabenwahlState === 2 ? 'primary' : ''} onClick={handleLagerTmpEingabewahlOnChange.bind(null, 2)}>
                              {language('FORM', 'LABEL', 'MANUELLEINGEBEN', objGlobalState.lang)}
                            </Button>
                          </ButtonGroup>
                        </Grid>

                        {lagertmpeingabenwahlState === 1 && (
                          <Grid item xs={12}>
                            <Select fullWidth name="LAGERTEMPERATUR" value={objParentState.textFieldsState.lagertemperatur} onChange={(event) => changeInputOnChange(event, 'lagertemperatur')}>
                              <MenuItem value="">{language('FORM', 'LABEL', 'KEINEANGABE', objGlobalState.lang)}</MenuItem>
                              <MenuItem value="2_bis_8">+2°C {language('FORM', 'LABEL', 'BIS', objGlobalState.lang)} +8°C</MenuItem>
                              <MenuItem value="15_bis_25">+15°C {language('FORM', 'LABEL', 'BIS', objGlobalState.lang)} +25°C</MenuItem>
                            </Select>
                          </Grid>
                        )}
                        {lagertmpeingabenwahlState === 2 && (
                          <>
                            <Grid item xs={12}>
                              <TextField
                                label="Min"
                                type="number"
                                variant="outlined"
                                name="LAGERTEMPERATURMIN"
                                value={objParentState.textFieldsState.lagertemperatorMin}
                                onChange={(event) => changeInputOnChange(event, 'lagertemperatorMin')}
                                placeholder={`${language('FORM', 'LABEL', 'LAGERTEMPERATUR', objGlobalState.lang)} - Min`}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                label="Max"
                                type="number"
                                variant="outlined"
                                name="LAGERTEMPERATURMAX"
                                value={objParentState.textFieldsState.lagertemperatorMax}
                                onChange={(event) => changeInputOnChange(event, 'lagertemperatorMax')}
                                placeholder={`${language('FORM', 'LABEL', 'LAGERTEMPERATUR', objGlobalState.lang)} - Max`}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'ZYTOSTATIKA', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <Select fullWidth name="ZYTOSTATIKA" value={objParentState.textFieldsState.zytostatika} onChange={(event) => changeInputOnChange(event, 'zytostatika')}>
                        <MenuItem value="KEINE_ANGABE">{language('FORM', 'LABEL', 'KEINEANGABE', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="NEIN">{language('FORM', 'LABEL', 'NEIN', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="ZYTOSTATIKA">{language('FORM', 'LABEL', 'ZYTOSTATIKA', objGlobalState.lang)}</MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'CHARGENKENNZEICHNUNG', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="left">
                      <Switch
                        name="CHARGENKENNZEICHNUNG"
                        checked={objParentState.textFieldsState.chargenKennzeichnung}
                        onChange={(event) => changeInputOnChange(event, 'chargenKennzeichnung')}
                        color="primary"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'MEHRWERTSTEUER', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <Select fullWidth name="MEHRWERTSTEUER" value={objParentState.textFieldsState.mwst} onChange={(event) => changeInputOnChange(event, 'mwst')}>
                        <MenuItem value={0}>0%</MenuItem>
                        <MenuItem value={7}>7%</MenuItem>
                        <MenuItem value={15}>15%</MenuItem>
                        <MenuItem value={19}>19%</MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'VERTRIEBSSTATUS', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <Select fullWidth name="VERTRIEBSSTATUS" value={objParentState.textFieldsState.vertriebsstatus} onChange={(event) => changeInputOnChange(event, 'vertriebsstatus')}>
                        <MenuItem value="KEINE_ANGABE">{language('FORM', 'LABEL', 'KEINEANGABE', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="IN_VERTRIEB">{language('FORM', 'LABEL', 'INVERTRIEB', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="AUSSER_VERTRIEB">{language('FORM', 'LABEL', 'AUSSERVERTRIEB', objGlobalState.lang)}</MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'VERKEHRSFAEHIG', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <Select fullWidth name="VERKEHRSFAEHIG" value={objParentState.textFieldsState.verkehrsfaehig} onChange={(event) => changeInputOnChange(event, 'verkehrsfaehig')}>
                        <MenuItem value="KEINE_ANGABE">{language('FORM', 'LABEL', 'KEINEANGABE', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="NICHT_VERKEHRSFAEHIG">{language('FORM', 'LABEL', 'NICHTVERKEHRSFAEHIG', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="VERKEHRSFAEHIG">{language('FORM', 'LABEL', 'VERKEHRSFAEHIG', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="VERKEHRSFAEHIGKEIT_IN_PRUEFUNG">{language('FORM', 'LABEL', 'VERKEHRSFAEHIGKEITINPRUEFUNG', objGlobalState.lang)}</MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'VERWALTUNGARTIKEL', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="left">
                      <Switch name="VERWALTUNGARTIKEL" checked={objParentState.textFieldsState.verwaltung} onChange={(event) => changeInputOnChange(event, 'verwaltung')} color="primary" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'ECOMMERCEARIKEL', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="left">
                      <Switch name="ECOMMERCEARIKEL" checked={objParentState.textFieldsState.ecommerce} onChange={(event) => changeInputOnChange(event, 'ecommerce')} color="primary" />
                    </TableCell>
                  </TableRow>
                </>
              )}
              {tableTabsState === 2 && (
                <>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'FEUCHTIGKEITSEMPFINDLICH', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <Select
                        fullWidth
                        name="FEUCHTIGKEITSEMPFINDLICH"
                        value={objParentState.textFieldsState.feuchtigkeitsEmpfindlich}
                        onChange={(event) => changeInputOnChange(event, 'feuchtigkeitsEmpfindlich')}
                      >
                        <MenuItem value="KEINE_ANGABE">{language('FORM', 'LABEL', 'KEINEANGABE', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="NEIN">{language('FORM', 'LABEL', 'NEIN', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="TROCKEN_LAGERN">{language('FORM', 'LABEL', 'TROCKENLAGERN', objGlobalState.lang)}</MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'LICHTEMPFINDLICH', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <Select fullWidth name="LICHTEMPFINDLICH" value={objParentState.textFieldsState.lichtEmpfindlich} onChange={(event) => changeInputOnChange(event, 'lichtEmpfindlich')}>
                        <MenuItem value="KEINE_ANGABE">{language('FORM', 'LABEL', 'KEINEANGABE', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="NEIN">{language('FORM', 'LABEL', 'NEIN', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="DIREKTE_SONNENEINSTRAHLUNG">{language('FORM', 'LABEL', 'VONSONNESCHUETZEN', objGlobalState.lang)}</MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'LAGEREMPFINDLICH', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <Select fullWidth name="LAGEREMPFINDLICH" value={objParentState.textFieldsState.lagerEmpfindlich} onChange={(event) => changeInputOnChange(event, 'lagerEmpfindlich')}>
                        <MenuItem value="KEINE_ANGABE">{language('FORM', 'LABEL', 'KEINEANGABE', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="NEIN">{language('FORM', 'LABEL', 'NEIN', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="LIEGEND_LAGERN">{language('FORM', 'LABEL', 'LIEGENLAGERN', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="STEHEND_LAGERN">{language('FORM', 'LABEL', 'STEHENDLAGERN', objGlobalState.lang)}</MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'TRANSPORTEMPFINDLICH', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <Select
                        fullWidth
                        name="TRANSPORTEMPFINDLICH"
                        value={objParentState.textFieldsState.transportEmpfindlich}
                        onChange={(event) => changeInputOnChange(event, 'transportEmpfindlich')}
                      >
                        <MenuItem value="KEINE_ANGABE">{language('FORM', 'LABEL', 'KEINEANGABE', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="NEIN">{language('FORM', 'LABEL', 'NEIN', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="NICHT_SCHUETTELN">{language('FORM', 'LABEL', 'NICHTRUETTELNSCHUETTELN', objGlobalState.lang)}</MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'TEMPERATUREMPFINDLICH', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <Select
                        fullWidth
                        name="TEMPERATUREMPFINDLICH"
                        value={objParentState.textFieldsState.temperaturEmpfindlich}
                        onChange={(event) => changeInputOnChange(event, 'temperaturEmpfindlich')}
                      >
                        <MenuItem value="KEINE_ANGABE">{language('FORM', 'LABEL', 'KEINEANGABE', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="FUENFZEHN_BIS_FUENFUNDZWANZIG">{language('FORM', 'LABEL', '+15°C BIS +25°C', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="ZWEI_BIS_ACHT">{language('FORM', 'LABEL', '+2°C BIS +8°C', objGlobalState.lang)}</MenuItem>
                        <MenuItem value="NULL_BIS_FUENFUNDZWANZIG">{language('FORM', 'LABEL', '+0°C BIS +25°C', objGlobalState.lang)}</MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                </>
              )}
              {tableTabsState === 3 && (
                <>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography">{language('FORM', 'LABEL', 'WARENGRUPPE', objGlobalState.lang)}</Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="left">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <ButtonGroup>
                            <Button color={warengruppeeingabenwahlState === 1 ? 'primary' : ''} onClick={handleWarengruppeeingabenwahlOnChange.bind(null, 1)}>
                              {language('FORM', 'LABEL', 'AUSWAEHLEN', objGlobalState.lang)}
                            </Button>
                            <Button color={warengruppeeingabenwahlState === 2 ? 'primary' : ''} onClick={handleWarengruppeeingabenwahlOnChange.bind(null, 2)}>
                              {language('FORM', 'LABEL', 'MANUELLEINGEBEN', objGlobalState.lang)}
                            </Button>
                          </ButtonGroup>
                        </Grid>

                        <Grid item xs={12}>
                          {warengruppeeingabenwahlState === 1 && (
                            <Select fullWidth name="WARENGRUPPE" value={objParentState.textFieldsState.warengruppe} onChange={(event) => changeInputOnChange(event, 'warengruppe')}>
                              <MenuItem value="">{language('FORM', 'LABEL', 'KEINEANGABE', objGlobalState.lang)}</MenuItem>
                              {warengruppeOptionsState.map((warengruppe, index) => (
                                <MenuItem value={warengruppe} key={`artikelverwaltung_artikel_artikelform_warengruppe_input_${index}`}>
                                  {warengruppe}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                          {warengruppeeingabenwahlState === 2 && (
                            <TextField
                              fullWidth
                              variant="outlined"
                              name="WARENGRUPPE"
                              value={objParentState.textFieldsState.warengruppe}
                              onChange={(event) => changeInputOnChange(event, 'warengruppe')}
                              placeholder={language('FORM', 'LABEL', 'WARENGRUPPE', objGlobalState.lang)}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography" title={language('FORM', 'LABEL', 'ARTIKELGROSSHANDELPREIS', objGlobalState.lang)}>
                        {language('FORM', 'LABEL', 'GROSSO', objGlobalState.lang)} &nbsp; <i style={{ fontSize: 15 }}>Netto</i>
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        name="GROSSO"
                        value={objParentState.textFieldsState.grosshandelspreis}
                        onChange={(event) => changeInputOnChange(event, 'grosshandelspreis')}
                        placeholder={language('FORM', 'LABEL', 'GROSSO', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography" title={language('FORM', 'LABEL', 'FABRIKABGABEPREIS', objGlobalState.lang)}>
                        {language('FORM', 'LABEL', 'FAP', objGlobalState.lang)} &nbsp; <i style={{ fontSize: 15 }}>Netto</i>
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        name="FAP"
                        value={objParentState.textFieldsState.herstellerAbgabepreisFap}
                        onChange={(event) => changeInputOnChange(event, 'herstellerAbgabepreisFap')}
                        placeholder={language('FORM', 'LABEL', 'FAP', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography" title={language('FORM', 'LABEL', 'ARTIKELAPOTHEKENEINKAUFSPREIS', objGlobalState.lang)}>
                        {language('FORM', 'LABEL', 'APOEKPREIS', objGlobalState.lang)} &nbsp; <i style={{ fontSize: 15 }}>Netto</i>
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        name="APOEKPREIS"
                        value={objParentState.textFieldsState.apothekenEinkaufspreis}
                        onChange={(event) => changeInputOnChange(event, 'apothekenEinkaufspreis')}
                        placeholder={language('FORM', 'LABEL', 'APOEKPREIS', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography" title={language('FORM', 'LABEL', 'ARTIKELAPOTHEKENVERKAUFSPREIS', objGlobalState.lang)}>
                        {language('FORM', 'LABEL', 'APOVKPREIS', objGlobalState.lang)} &nbsp; <i style={{ fontSize: 15 }}>Netto</i>
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        name="APOVKPREIS"
                        value={objParentState.textFieldsState.apothekenVerkaufspreis}
                        onChange={(event) => changeInputOnChange(event, 'apothekenVerkaufspreis')}
                        placeholder={language('FORM', 'LABEL', 'APOVKPREIS', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography" title={language('FORM', 'LABEL', 'ARTIKELEINKAUFSPREIS', objGlobalState.lang)}>
                        {language('FORM', 'LABEL', 'EKPREIS', objGlobalState.lang)} &nbsp; <i style={{ fontSize: 15 }}>Netto</i>
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        name="EKPREIS"
                        value={objParentState.textFieldsState.einkaufspreis}
                        onChange={(event) => changeInputOnChange(event, 'einkaufspreis')}
                        placeholder={language('FORM', 'LABEL', 'EKPREIS', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography" title={language('FORM', 'LABEL', 'ARTIKELEKRABATT', objGlobalState.lang)}>
                        {language('FORM', 'LABEL', 'EKRABATT', objGlobalState.lang)} &nbsp; <i style={{ fontSize: 15 }}>Netto</i>
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        name="EKRABATT"
                        value={objParentState.textFieldsState.einkaufsrabatt}
                        onChange={(event) => changeInputOnChange(event, 'einkaufsrabatt')}
                        placeholder={language('FORM', 'LABEL', 'EKRABATT', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography" title={language('FORM', 'LABEL', 'ARTIKELVERAUFSPREIS', objGlobalState.lang)}>
                        {language('FORM', 'LABEL', 'VKPREIS', objGlobalState.lang)} &nbsp; <i style={{ fontSize: 15 }}>Netto</i>
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        name="VKPREIS"
                        value={objParentState.textFieldsState.verkaufspreis}
                        onChange={(event) => changeInputOnChange(event, 'verkaufspreis')}
                        placeholder={language('FORM', 'LABEL', 'VKPREIS', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" component="th">
                      <Typography className="typography" title={language('FORM', 'LABEL', 'ARTIKELVKRABATT', objGlobalState.lang)}>
                        {language('FORM', 'LABEL', 'VKRABATT', objGlobalState.lang)} &nbsp; <i style={{ fontSize: 15 }}>Netto</i>
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={3} align="right">
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        name="VKRABATT"
                        value={objParentState.textFieldsState.verkaufsabatt}
                        onChange={(event) => changeInputOnChange(event, 'verkaufsabatt')}
                        placeholder={language('FORM', 'LABEL', 'VKRABATT', objGlobalState.lang)}
                      />
                    </TableCell>
                  </TableRow>
                </>
              )}
              <TableRow>
                <TableCell colSpan={4}>
                  <Button color="primary" variant="contained" className="button__middle" type="submit" fullWidth>
                    {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </form>
    </>
  );
};

export default CoArtikelForm;
