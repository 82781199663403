import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import language from '../Common/language/language';
import PasswordValidator from 'password-validator';
import { changeLanguage } from '../Common/helperFunctions/changeLanguage';
import { checkIfEmailIsInvalid } from '../Common/helperFunctions/validator';

// import -> material ui -> core, icons
import { Button, Container, Grid, Paper, TextField, Typography, Link, IconButton } from '@material-ui/core';
import { Language } from '@material-ui/icons';

// import -> component
import CoEmailVerifizierung from './Append/CoEmailverifizierung';

// component -> React-root
const CoSignUp = ({ objGlobalState }) => {
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [textFieldState, setTextFieldState] = useState({
    name: { error: false },
    email: { error: false },
    password: { error: false },
    passwordRepeat: { error: false }
  });

  const [dialogState, setDialogState] = useState(false);

  const [errorsState, setErrorsState] = useState({
    passwordsNotIdentical: false,
    passwordInvalid: false,
    emailInvalid: false
  });

  // function ->
  const checkIfTextFieldIsEmpty = (valueForCheck) => {
    //  statement -> checks if valueForCheck is not empty
    return valueForCheck ? false : true;
  };

  // function ->
  const getRidOfErrorMessages = (textField) => {
    // statement -> when writing in text field move the error message away
    switch (textField) {
      case 'email':
        setErrorsState({
          ...errorsState,
          emailInvalid: false
        });
        break;
      case 'password':
        setErrorsState({
          ...errorsState,
          passwordInvalid: false
        });
        break;
      case 'passwordRepeat':
        setErrorsState({
          ...errorsState,
          passwordsNotIdentical: false
        });
        break;
      default:
        return;
    }
  };

  // function ->
  const fetchTextFieldChange = (event, textField) => {
    // useState -> show error message depending on return boolean of checkIfTextFieldIsEmpty
    setTextFieldState({
      ...textFieldState,
      [textField]: { error: checkIfTextFieldIsEmpty(event.target.value) }
    });

    // function -> when the user write in a text field, then take away the error message
    getRidOfErrorMessages(textField);
  };

  // function ->
  const checkIfPasswordsAreNotIdentical = (passwordNrOne, passwordNrTwo) => {
    return passwordNrOne !== passwordNrTwo;
  };

  // function ->
  const checkIfPasswordIsInvalid = (password) => {
    const validator = new PasswordValidator();
    const minLength = 8;
    const maxLength = 20;
    validator.is().min(minLength).is().max(maxLength).has().lowercase().has().uppercase().has().digits().has().symbols();
    return !validator.validate(password);
  };

  // function ->
  const checkIfEmailIsUsed = (email) => {
    return email === 'maximillian@test1.de';
  };

  // function ->
  const submitFormOnClick = async (event) => {
    event.preventDefault();

    const name = event.target.name.value;
    const email = event.target.email.value.toLowerCase();
    const password = event.target.password.value;
    const passwordRepeat = event.target.passwordRepeat.value;

    const isNameTextFieldEmpty = checkIfTextFieldIsEmpty(name);
    const isEmailTextFieldEmpty = checkIfTextFieldIsEmpty(email);
    const isPasswordTextFieldEmpty = checkIfTextFieldIsEmpty(password);
    const isPasswordRepeatTextFieldEmpty = checkIfTextFieldIsEmpty(passwordRepeat);

    const isEmailInvalid = checkIfEmailIsInvalid(email);
    const isEmailUsed = checkIfEmailIsUsed(email);
    const isPasswordInvalid = checkIfPasswordIsInvalid(password);
    const arePasswordsNotIdentical = checkIfPasswordsAreNotIdentical(password, passwordRepeat);

    if (isNameTextFieldEmpty || isEmailTextFieldEmpty || isPasswordTextFieldEmpty || isPasswordRepeatTextFieldEmpty || isEmailInvalid || isPasswordInvalid || arePasswordsNotIdentical || isEmailUsed) {
      // useState -> show error message depending on return boolean of checkIfTextFieldIsEmpty

      if (isEmailUsed) {
        enqueueSnackbar(language('ALERT', 'TEXT', 'EMAILSCHONBENUTZT', objGlobalState.lang), { variant: 'error' });
      }

      setTextFieldState({
        ...textFieldState,
        name: { error: isNameTextFieldEmpty },
        email: { error: isEmailTextFieldEmpty },
        password: { error: isPasswordTextFieldEmpty },
        passwordRepeat: { error: isPasswordRepeatTextFieldEmpty }
      });

      setErrorsState({
        ...errorsState,
        passwordInvalid: isPasswordInvalid,
        emailInvalid: isEmailInvalid,
        passwordsNotIdentical: arePasswordsNotIdentical
      });
    } else {
      setDialogState(true);
    }
  };

  const sendToSignIn = (event) => {
    event.preventDefault();
    history.push('/signin', { from: location });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CoEmailVerifizierung dialogState={dialogState} setDialogState={setDialogState} objGlobalState={objGlobalState} />
      <Paper variant="outlined" square className="coSignUp__container">
        <form name="submitForm" noValidate className="coSignUp__form" autoComplete="off" onSubmit={submitFormOnClick}>
          <Grid container spacing={3}>
            <Grid item xs={12} container spacing={3}>
              <Grid item xs={12}>
                <IconButton color="inherit" onClick={() => changeLanguage(objGlobalState)} title={language('BUTTON', 'TITEL', 'SPRACHEAENDERN', objGlobalState.lang)}>
                  <Language />
                  <Typography>{objGlobalState.lang === 'DE' ? 'EN' : 'DE'}</Typography>
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Typography align="center" className="typography__formTitle">
                  {language('FORM', 'TITEL', 'REGISTRIEREN', objGlobalState.lang)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                name="name"
                label={language('FORM', 'LABEL', 'NAME', objGlobalState.lang)}
                error={textFieldState.name.error}
                helperText={textFieldState.name.error ? language('FORM', 'ERROR', 'LEERESTEXTFELD', objGlobalState.lang) : ''}
                onChange={(event) => fetchTextFieldChange(event, 'name')}
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="email"
                variant="outlined"
                name="email"
                label={language('FORM', 'LABEL', 'EMAIL', objGlobalState.lang)}
                error={textFieldState.email.error || errorsState.emailInvalid}
                helperText={
                  textFieldState.email.error
                    ? language('FORM', 'ERROR', 'LEERESTEXTFELD', objGlobalState.lang)
                    : errorsState.emailInvalid
                    ? language('FORM', 'ERROR', 'EMAILNICHTGUELTIG', objGlobalState.lang)
                    : ''
                }
                onChange={(event) => fetchTextFieldChange(event, 'email')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                variant="outlined"
                name="password"
                label={language('FORM', 'LABEL', 'PASSWORT', objGlobalState.lang)}
                error={textFieldState.password.error || errorsState.passwordInvalid}
                helperText={
                  textFieldState.password.error
                    ? language('FORM', 'ERROR', 'LEERESTEXTFELD', objGlobalState.lang)
                    : errorsState.passwordInvalid
                    ? language('FORM', 'ERROR', 'PASSWORTNICHTGUELTIG', objGlobalState.lang)
                    : ''
                }
                onChange={(event) => fetchTextFieldChange(event, 'password')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="password"
                variant="outlined"
                name="passwordRepeat"
                label={language('FORM', 'LABEL', 'PASSWORTWIEDERHOLUNG', objGlobalState.lang)}
                error={textFieldState.passwordRepeat.error || errorsState.passwordsNotIdentical}
                helperText={
                  textFieldState.passwordRepeat.error
                    ? language('FORM', 'ERROR', 'LEERESTEXTFELD', objGlobalState.lang)
                    : errorsState.passwordsNotIdentical
                    ? language('FORM', 'ERROR', 'PASSWOERTERNICHTIDENTISCH', objGlobalState.lang)
                    : ''
                }
                onChange={(event) => fetchTextFieldChange(event, 'passwordRepeat')}
              />
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant="contained" type="submit" color="primary" className="button">
                {language('BUTTON', 'TITEL', 'REGISTRIEREN', objGlobalState.lang)}
              </Button>
            </Grid>
          </Grid>
        </form>
        <Link href="" onClick={sendToSignIn} className="coSignUp__link">
          {language('KONTENT', 'TEXT', 'BENUTZERHATACCOUNT', objGlobalState.lang)}
        </Link>
      </Paper>
    </Container>
  );
};

export default CoSignUp;
