const generateArtikelEntityLabel = (entity) => {
  switch (entity) {
    case 'hersteller':
      entity = 'herstellername';
      break;
    case 'packmassLaenge':
      entity = 'packmasselaenge';
      break;
    case 'packmassBreite':
      entity = 'packmassebreite';
      break;
    case 'packmassHoehe':
      entity = 'packmassehoehe';
      break;
    case 'packmassEinheit':
      entity = 'packmasseeinheit';
      break;
    case 'kuehlung':
      entity = 'kuehlketteware';
      break;
    case 'mwst':
      entity = 'MEHRWERTSTEUER';
      break;
    case 'verwaltung':
      entity = 'VERWALTUNGARTIKEL';
      break;
    case 'ecommerce':
      entity = 'ECOMMERCEARIKEL';
      break;
    case 'grosshandelspreis':
      entity = 'ARTIKELGROSSHANDELPREIS';
      break;
    case 'herstellerAbgabepreisFap':
      entity = 'FABRIKABGABEPREIS';
      break;
    case 'apothekenEinkaufspreis':
      entity = 'artikelapothekenEinkaufspreis';
      break;
    case 'apothekenVerkaufspreis':
      entity = 'artikelapothekenVerkaufspreis';
      break;
    case 'einkaufspreis':
      entity = 'ARTIKELEINKAUFSPREIS';
      break;
    case 'einkaufsrabatt':
      entity = 'ARTIKELEKRABATT';
      break;
    case 'verkaufspreis':
      entity = 'ARTIKELVERKAUFSPREIS';
      break;
    case 'verkaufsabatt':
      entity = 'ARTIKELVKRABATT';
      break;
    case 'lagertemperatorMax':
      entity = 'lagertemperaturMax';
      break;
    case 'lagertemperatorMin':
      entity = 'lagertemperaturMin';
      break;
    case 'eCommerce':
      entity = 'ECOMMERCEARIKEL';
      break;
  }

  entity = entity.toUpperCase();

  return entity;
};

export default generateArtikelEntityLabel;
