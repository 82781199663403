import React from 'react';
import language from '../../language/language';
import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';

// component -> React-root
const CoDeleteWarning = ({ objGlobalState, objParentState }) => {
  // function ->
  const splitStringLines = (string, breakCharacter) => {
    const breakCharacterIndex = string.indexOf(breakCharacter) + 1;
    const firstLine = string.slice(0, breakCharacterIndex + 1);
    const secondLine = string.slice(breakCharacterIndex + 1);
    return [firstLine, secondLine];
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={objParentState.warningDialogState} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{language('DIALOG', 'TITEL', 'WARNUNG', objGlobalState.lang)}</DialogTitle>
      <DialogContent className="dialog__content">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography className="typography">
              {splitStringLines(language('KONTENT', 'TEXT', 'AVISIERUNGDATENSATZLOESCHEN', objGlobalState.lang), '?')[0]}
              <br />
              {splitStringLines(language('KONTENT', 'TEXT', 'AVISIERUNGDATENSATZLOESCHEN', objGlobalState.lang), '?')[1]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={objParentState.deleteDataSetOnClick} className="button__middle--redOutLined">
              {language('BUTTON', 'TITEL', 'LOESCHEN', objGlobalState.lang)}
            </Button>
            <Button onClick={objParentState.closeWarningDialogOnClick} className="button__middle--greenOutLined marginLeft">
              {language('BUTTON', 'TITEL', 'ABBRECHEN', objGlobalState.lang)}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CoDeleteWarning;
