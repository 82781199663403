import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import language from '../../Common/language/language';
import { createAxiosInstance } from '../../Common/helperFunctions/axios';
import { generateDate } from '../../Common/helperFunctions/date';

// import -> material ui -> core
import { Typography, Button, Dialog, DialogContent, Grid, DialogTitle, FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';

// import -> components
import CoConfirmation from '../../Common/Components/CoConfirmation';

// component -> React root
const CoBerechtigungErstellenDialog = ({ objGlobalState, objParentState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const initializeAblaufdatum = () => {
    const tag = '31';
    const monat = '12';
    const jahr = new Date().getFullYear();
    const inputformat = `${jahr}-${monat}-${tag}`;
    return inputformat;
  };
  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const [confirmationDialogState, setConfirmationDialogState] = useState(false);
  const [benutzerState, setBenutzerState] = useState([]);
  const [inputsState, setInputsState] = useState({
    benutzer: '',
    ablaufdatum: initializeAblaufdatum()
  });
  const [newDataValueState, setNewDataValueState] = useState({
    datei: '',
    benutzer: '',
    ablaufdatum: ''
  });

  //  function ->
  const closeBerechtigungErstellenDialogOnClick = () => {
    objParentState.setBerechtigungErstellenDialogState(false);
  };

  // function ->
  const changeInputsOnChange = (event, label) => {
    const { value } = event.target;
    setInputsState((pre) => {
      return {
        ...pre,
        [label]: value
      };
    });
  };

  // function ->
  const openConfirmationDialog = () => {
    setConfirmationDialogState(true);
  };

  // function ->
  const closeConfirmationOnClick = () => {
    setConfirmationDialogState(false);
  };

  // function ->
  const submitFormOnSubmit = (event) => {
    event.preventDefault();

    const { benutzer, ablaufdatum } = inputsState;
    const dateiname = objParentState.berechtigungdatei.preview_name;
    const benutzeremail = benutzerState.find((_benutzer) => _benutzer.id === benutzer)?.email || '';

    setNewDataValueState({
      benutzer: benutzeremail,
      ablaufdatum: ablaufdatum,
      datei: dateiname
    });

    openConfirmationDialog();
  };

  // function ->
  const confirmed = () => {
    objGlobalState.setPageLoadingState(true);

    const { benutzer: benutzerId, ablaufdatum } = inputsState;
    const dateiId = objParentState.berechtigungdatei.preview_id;
    const requestAblaufdatum = ablaufdatum.length ? generateDate(ablaufdatum).dateAndTimeForDataBase : '';

    const requestValues = {
      fileId: dateiId,
      freigabeBis: requestAblaufdatum,
      userId: benutzerId
    };

    authAxios
      .post('/file-freigabe', requestValues)
      .then((response) => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT', objGlobalState.lang), { variant: 'success' });
        closeConfirmationOnClick();
        closeBerechtigungErstellenDialogOnClick();
        objParentState.forceRerender();
      })
      .catch((error) => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
        console.log(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const getBenutzerFromAPI = () => {
    authAxios
      .get('/user/getAll')
      .then((response) => {
        const benutzer = response.data;
        setBenutzerState(benutzer);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getBenutzerFromAPI();
    }

    return () => (isMounted = false);
  }, []);

  const objBerechtigungErstellenDialogStateAndFunctions = {
    closeConfirmationOnClick,
    confirmationDialogState,
    setConfirmationDialogState,
    confirmed,
    newDataValueState,
    setNewDataValueState
  };

  return (
    <>
      {confirmationDialogState && <CoConfirmation objGlobalState={objGlobalState} objParentState={objBerechtigungErstellenDialogStateAndFunctions} />}

      <Dialog fullWidth maxWidth="sm" open={objParentState.berechtigungErstellenDialogState} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
            <Grid item xs={6}>
              <Typography align="center" className="typography__dialogTitle">
                {language('DIALOG', 'TITEL', 'BERECHTIGUNGEN', objGlobalState.lang)}
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="dialog__content">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography className="typography">{objParentState.berechtigungdatei.preview_name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <form name="submitForm" noValidate autoComplete="on" onSubmit={submitFormOnSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="qualitaetssicherung_berechtigungdialog_benutzer">{language('FORM', 'LABEL', 'BENUTZER', objGlobalState.lang)}</InputLabel>
                      <Select
                        labelId="qualitaetssicherung_berechtigungdialog_benutzer"
                        id="qualitaetssicherung_berechtigungdialog_benutzer"
                        value={inputsState.benutzer}
                        onChange={(event) => changeInputsOnChange(event, 'benutzer')}
                        label={language('FORM', 'LABEL', 'BENUTZER', objGlobalState.lang)}
                      >
                        {benutzerState.map((benutzer, key) => {
                          return (
                            <MenuItem key={`qualitaetssicherung_berechtigungdialog_benutzer_single${key}`} value={benutzer.id}>
                              {benutzer.email}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                      value={inputsState.ablaufdatum}
                      onChange={(event) => changeInputsOnChange(event, 'ablaufdatum')}
                      type="date"
                      variant="outlined"
                      label={language('FORM', 'LABEL', 'ABLAUFDATUM', objGlobalState.lang)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button type="submit" fullWidth className="button__middle" variant="contained" color="primary" size="large">
                      {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={closeBerechtigungErstellenDialogOnClick} fullWidth className="button__middle">
                      {language('BUTTON', 'TITEL', 'ABBRECHEN', objGlobalState.lang)}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoBerechtigungErstellenDialog;
