import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import language from '../Common/language/language';
import { handleBeforeUnload } from '../Common/eventHanlders/eventHandlers';
import isMobile from '../Common/isMobile/isMobile';
import { changeLanguage } from '../Common/helperFunctions/changeLanguage';

// import -> material ui -> core, icons
import { AppBar, Box, Drawer, Grid, IconButton, Toolbar, Typography } from '@material-ui/core';
import { ExitToApp, Language, Menu as MenuIcon, Search, Settings } from '@material-ui/icons';

// import -> components
import CoSideBar from './Append/CoSideBar';
import CoSuche from '../Suche/CoSuche';
import CoEinstellungen from '../Einstellungen/CoEinstellungen';
import CoTopBar from './Append/CoTopBar';
import { useEffect } from 'react';
import { createAxiosInstance } from '../Common/helperFunctions/axios';

// component -> React-root
const CoNavigation = ({ objGlobalState }) => {
  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [sideBarState, setSideBarState] = useState(false);

  // function ->
  const openOrCloseSideBarOnClick = (trigger) => {
    setSideBarState(trigger);
  };

  // function ->
  const openEinstallungenDialogOnClick = () => {
    objGlobalState.setEinstellungenDialogState(true);
  };

  // function
  const searchOnClick = () => {
    objGlobalState.setDialogState({ title: '', content: '', state: true });
  };

  // function ->
  const signUserOutOnClick = () => {
    enqueueSnackbar(language('ALERT', 'TEXT', 'AUSGELOGGT'), {
      variant: 'warning'
    });

    objGlobalState.setAuthenticateState({ auth: false, user: {} });
    objGlobalState.setJwtoken('');
    window.removeEventListener('beforeunload', handleBeforeUnload);
    window.location.reload();
    history.push('/');
  };

  const navigationClassName = () => {
    const className = 'coNavigation__border';
    const append = isMobile.any() ? '--mobile' : '--desktop';
    return className + append;
  };

  const upperPartClassName = () => {
    const className = 'coNavigation__upperPart';
    const append = isMobile.any() ? '--mobile' : '--desktop';
    return className + append;
  };

  const mapLiveTrackerToStateArray = (rawLiveTrackers) => {
    const newStateArray = rawLiveTrackers.map((liveTracker) => liveTracker.trackernummer);
    objGlobalState.setLiveTrackerState(newStateArray);
  };

  const fetchTrackernummern = () => {
    authAxios
      .get('/live-tracking/all')
      .then((response) => {
        mapLiveTrackerToStateArray(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchTrackernummern();
    }

    return () => (isMounted = false);
  }, []);

  return (
    <>
      <CoSuche objGlobalState={objGlobalState} />
      <CoEinstellungen objGlobalState={objGlobalState} />
      {isMobile.any() && (
        <Box component="div">
          <Drawer anchor={'left'} open={sideBarState && objGlobalState.canNavBarOpenState} onClick={() => openOrCloseSideBarOnClick(false)}>
            <CoSideBar objGlobalState={objGlobalState} openOrCloseSideBarOnClick={openOrCloseSideBarOnClick} liveTrackerState={objGlobalState.liveTrackerState} />
          </Drawer>
        </Box>
      )}
      <Box component="div">
        <AppBar position="fixed" id="navigation-app-bar">
          <Toolbar className="coNavigation__toolbar">
            <Grid container>
              <Grid container spacing={1} className={upperPartClassName()}>
                <Grid item xs={'auto'} className={navigationClassName()}>
                  {isMobile.any() && (
                    <IconButton color="inherit" aria-label="open drawer" onClick={() => openOrCloseSideBarOnClick(true)} edge="start">
                      <MenuIcon className="coNavigation__icon" />
                    </IconButton>
                  )}
                </Grid>
                <Grid container item xs={true} direction="row" justify="flex-start" alignItems="center" className={navigationClassName()}>
                  <Link to="/home">
                    <img src="../logo_white.png" width={200} alt="logo" className="padding_bottom--5" />
                  </Link>
                </Grid>
                <Grid item xs={true} id="navigation-titleBreadcrumbs-container" className="coNavigation__titleContainer coNavigation__titleContainer--height">
                  <p id="insert-siteTitle" className="coNavigation__topNavBreadcrumbs"></p>
                </Grid>
                <Grid item xs={'auto'}>
                  <IconButton color="inherit" onClick={searchOnClick} title={language('BUTTON', 'TITEL', 'SUCHEN', objGlobalState.lang)}>
                    <Search className="coNavigation__icon" />
                  </IconButton>
                  <IconButton color="inherit" onClick={openEinstallungenDialogOnClick} title={language('BUTTON', 'TITEL', 'EINSTELLUNGEN', objGlobalState.lang)}>
                    <Settings className="coNavigation__icon" />
                  </IconButton>
                  <IconButton color="inherit" onClick={() => changeLanguage(objGlobalState)} title={language('BUTTON', 'TITEL', 'SPRACHEAENDERN', objGlobalState.lang)}>
                    <Language className="coNavigation__icon" />
                    <Typography>{objGlobalState.lang === 'DE' ? 'EN' : 'DE'}</Typography>
                  </IconButton>
                  <IconButton color="inherit" onClick={signUserOutOnClick} title={language('BUTTON', 'TITEL', 'ABMELDEN', objGlobalState.lang)}>
                    <ExitToApp className="coNavigation__icon" />
                  </IconButton>
                </Grid>
              </Grid>
              {!isMobile.any() && (
                <Grid container className={`coNavigation__underPart ${objGlobalState.isInIframe ? 'coNavigation__underPart--inIframe' : ''}`} justify="flex-start">
                  <CoTopBar objGlobalState={objGlobalState} liveTrackerState={objGlobalState.liveTrackerState} />
                </Grid>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default CoNavigation;
