import React from 'react';
import language from '../../Common/language/language';
import isMobile from '../../Common/isMobile/isMobile';
import { FILE_TYPES } from '../../Common/types/types';

// import -> material ui -> core && icons
import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';

// component -> React-root
const CoDatenschutzDialog = ({ objGlobalState, objSignInStateAndFunctions }) => {
  // function ->
  const displayDokument = (file) => {
    window.open(file.dataUri, '_blank');
  };

  // function ->
  const closeDialogOnClose = () => {
    objSignInStateAndFunctions.setDatenschutzDialogState(false);
  };

  // function ->
  const insertTextWithLanguage = () => {
    if (objGlobalState.lang === 'DE') {
      return (
        <>
          <Typography className="typography">Sind Sie mit unseren</Typography>
          <Button onClick={() => displayDokument(objSignInStateAndFunctions.datenschutzdokumentState)} className="marginRight marginTopBottomTen button__small" variant="outlined" color="primary">
            Datenschutzrichtlinie
          </Button>
          <Button onClick={() => displayDokument(objSignInStateAndFunctions.agbdokumentState)} className="marginRight marginTopBottomTen button__small" variant="outlined" color="primary">
            AGB
          </Button>
          <Button
            onClick={() => displayDokument(objSignInStateAndFunctions.versicherungsbedingungdokumentState)}
            className="marginRight marginTopBottomTen button__small"
            variant="outlined"
            color="primary"
          >
            Versicherungsbedingung
          </Button>
          <Typography className="typography">einverstanden ?</Typography>
        </>
      );
    } else if (objGlobalState.lang === 'EN') {
      return (
        <>
          <Typography className="typography">Do you agree with our</Typography>
          <Button onClick={() => displayDokument(objSignInStateAndFunctions.datenschutzdokumentState)} className="marginRight marginTopBottomTen button__small" variant="outlined" color="primary">
            privacy policy
          </Button>
          <Button onClick={() => displayDokument(objSignInStateAndFunctions.agbdokumentState)} className="marginRight marginTopBottomTen button__small" variant="outlined" color="primary">
            AGB
          </Button>
          <Button
            onClick={() => displayDokument(objSignInStateAndFunctions.versicherungsbedingungdokumentState)}
            className="marginRight marginTopBottomTen button__small"
            variant="outlined"
            color="primary"
          >
            insurance conditions
          </Button>
          <Typography className="typography">?</Typography>
        </>
      );
    }
  };

  return (
    <>
      <Dialog open={objSignInStateAndFunctions.datenschutzDialogState} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
            <Grid item xs={6}>
              <Typography align="left" className="typography__dialogTitle">
                {language('DIALOG', 'TITEL', 'DATENSCHUTZRICHTLINIE', objGlobalState.lang)}
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="dialog__content">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {insertTextWithLanguage()}
            </Grid>
            <Grid item xs={12} md={6}>
              <Button onClick={() => objSignInStateAndFunctions.signUserInAfterAcceptance(false)} fullWidth type="submit" className="button__middle" variant="contained" color="primary">
                {language('BUTTON', 'TITEL', 'JA', objGlobalState.lang)}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button onClick={closeDialogOnClose} fullWidth className="button__middle">
                {language('BUTTON', 'TITEL', 'NEIN', objGlobalState.lang)}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoDatenschutzDialog;
