import React from 'react';
import LP from '../CoLandingPage.module.scss';

const CoFunktion = ({ imgName, title, list }) => {
  return (
    <div className={LP.main__funktionenSection}>
      <div className={LP.contentWrapper}>
        <div className={LP.container}>
          <div className={LP.imageContainer}>
            <img src={`../assets/images/tracporthome/home_main_funktionen/${imgName}`} alt="Funktionsbild" />
          </div>
          <div className={LP.textContainer}>
            <h2>{title}</h2>
            <ul>
              {list.map((listItem, key) => (
                <li key={`landingpage__coFunktion_${title}_list_listitem_${key}`}>{listItem}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoFunktion;
