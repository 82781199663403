import React, { useEffect } from 'react';
import language from '../Common/language/language';
import ServiceBreadcrumbs from '../Common/services/Breadcrumbs/ServiceBreadcrumbs';
import { Box, Container, Grid, ListItem, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import CoWarenannahmeAvisierungTable from '../Common/Components/Warenannahme/CoAvisierungtabelle';
import CoVersandAvisierungTable from '../Common/Components/Versand/CoAvisierungTable';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CoAuftragstabelle from '../Common/Components/Auftragsverwaltung/CoAuftragstabelle';
import CoLieferungtabelle from '../Common/Components/Warenannahme/CoLieferungtabelle';
import { Link } from 'react-router-dom';

// component -> Reace-root
const LiveTrackerTable = ({ objGlobalState }) => {
  return (
    <>
      <Box className="table__title">
        <Typography align="center" className="typography__singleTableTitle">
          {language('TABLE', 'HEADER', 'LIVETRACKER', objGlobalState.lang)}
        </Typography>
      </Box>
      <TableContainer component={Paper} variant="outlined" elevation={0} square className={`table__container maxHeight__330 border__noTop`}>
        <Table size="medium" aria-label="sticky table">
          <TableBody>
            {objGlobalState.liveTrackerState.map((trackernummer, key) => (
              <TableRow key={`home_live-tracking_trackersId_${key}`}>
                <TableCell align="center">
                  <Link to={`/live-tracking/${trackernummer}`} style={{ textDecoration: 'none' }}>
                    <Typography className="typography__singleTableRowContent">{trackernummer}</Typography>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

// component -> React-root
const CoHome = ({ objGlobalState }) => {
  const history = useHistory();
  const hasLiveTrackingAccess = objGlobalState.authenticateState.user.access?.includes('LIVE_TRACKING');
  const hasTrackingAccess = objGlobalState.authenticateState.user.access?.includes('TRACKING');
  const hasLieferungAccess = objGlobalState.authenticateState.user.access?.includes('LIEFERUNG');

  // function ->
  const showNavigation = () => {
    objGlobalState.setHideNavigationState(false);
  };

  // function ->
  const goToPage = (pageName) => {
    history.push(`/${pageName}`);
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    showNavigation();

    return () => (isMounted = false);
  });

  // array -> create breadcrumbs
  const arrBreadcrumbs = [{ url: '/', title: language('SEITE', 'TITEL', 'HOME', objGlobalState.lang) }];

  return (
    <Container component="main" maxWidth="xl" className="container__container">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ServiceBreadcrumbs arrBreadcrumbs={arrBreadcrumbs} />
        </Grid>
        <Grid item xs={12}>
          <ListItem className="container__listItem">
            <ListItemIcon>
              <img src={`../assets/images/icons/icon_home.png`} alt="" className="images__headerIcon" />
            </ListItemIcon>
            <ListItemText>
              <Typography className="typography__headerTitle">{language('SEITE', 'TITEL', 'HOME', objGlobalState.lang)}</Typography>
            </ListItemText>
          </ListItem>
        </Grid>
        <Grid item xs={12} container justify="center">
          <div className="coHome__logo" style={{ backgroundImage: `url(${process.env.REACT_APP_HOST}/logo_grey.png)` }}></div>
        </Grid>
        <Grid container spacing={3} className="coHome__contentWrapper">
          {hasLiveTrackingAccess ? (
            <Grid item xs={12} sm={4} className="marginEighty">
              <LiveTrackerTable objGlobalState={objGlobalState} />
            </Grid>
          ) : (
            ''
          )}
          {hasTrackingAccess ? (
            <Grid item xs={12} sm={8} className="marginEighty">
              <img
                src="../assets/images/icons/icon_tracking.png"
                title={language('ABTEILUNG', 'TITEL', 'TRACKING', objGlobalState.lang)}
                alt="tracking"
                className="coHome__sectionIconBig"
                onClick={() => goToPage('tracking')}
              />
              <img
                src="../assets/images/icons/icon_qualitaetssicherung.png"
                title={language('ABTEILUNG', 'TITEL', 'QUALITAETSSICHERUNG', objGlobalState.lang)}
                alt="qualitaetssicherung"
                className="coHome__sectionIconBig"
                onClick={() => goToPage('qualitaetssicherung')}
              />
            </Grid>
          ) : (
            ''
          )}
          {hasLieferungAccess ? (
            <>
              <Grid item xs={12} className="marginEighty">
                <Typography className="typography__sectionTitle">{language('ABTEILUNG', 'TITEL', 'WARENANNAHME', objGlobalState.lang)}</Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <CoWarenannahmeAvisierungTable objGlobalState={objGlobalState} objParentState={{}} isHome={true} />
              </Grid>
              <Grid item xs={12} md={2}>
                <img
                  src="../assets/images/icons/icon_avisierung.png"
                  title={language('ABTEILUNG', 'TITEL', 'AVISIERUNG', objGlobalState.lang)}
                  alt="warenannahme avisierung"
                  className="coHome__sectionIcon"
                  onClick={() => goToPage('warenannahme_avisierung')}
                />
                <img
                  src="../assets/images/icons/icon_lieferung.png"
                  title={language('ABTEILUNG', 'TITEL', 'LIEFERUNG', objGlobalState.lang)}
                  alt="warenannahme lieferung"
                  className="coHome__sectionIcon"
                  onClick={() => goToPage('warenannahme_lieferung')}
                />
                <img
                  src="../assets/images/icons/icon_status.png"
                  title={language('ABTEILUNG', 'TITEL', 'STATUS', objGlobalState.lang)}
                  alt="warenannahme status"
                  className="coHome__sectionIcon"
                  onClick={() => goToPage('warenannahme_status')}
                />
              </Grid>
              <Grid item xs={12} className="marginEighty">
                <Typography className="typography__sectionTitle">{language('ABTEILUNG', 'TITEL', 'WARENEINGANG', objGlobalState.lang)}</Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <CoLieferungtabelle objGlobalState={objGlobalState} objParentState={{ tableTitle: 'STATUSWARTEND' }} options={{ isHome: true, noBorderTop: true }} />
              </Grid>
              <Grid item xs={12} md={2}>
                <img
                  src="../assets/images/icons/icon_status.png"
                  title={language('ABTEILUNG', 'TITEL', 'STATUS', objGlobalState.lang)}
                  alt="wareneingang status"
                  className="coHome__sectionIcon"
                  onClick={() => goToPage('wareneingang_status')}
                />
                <img
                  src="../assets/images/icons/icon_lieferung.png"
                  title={language('ABTEILUNG', 'TITEL', 'LIEFERSCHEIN', objGlobalState.lang)}
                  alt="wareneingang lieferschein"
                  className="coHome__sectionIcon"
                  onClick={() => goToPage('wareneingang_lieferschein')}
                />
                <img
                  src="../assets/images/icons/icon_einlagerung.png"
                  title={language('ABTEILUNG', 'TITEL', 'EINLAGERUNG', objGlobalState.lang)}
                  alt="wareneingang einlagerung"
                  className="coHome__sectionIcon"
                  onClick={() => goToPage('wareneingang_einlagerung')}
                />
              </Grid>
              <Grid item xs={12} className="marginEighty">
                <Typography className="typography__sectionTitle">{language('ABTEILUNG', 'TITEL', 'WARENAUSGANG', objGlobalState.lang)}</Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <CoAuftragstabelle objGlobalState={objGlobalState} objParentState={{ home: true }} />
              </Grid>
              <Grid item xs={12} md={2}>
                <img
                  src="../assets/images/icons/icon_kommissionierung.png"
                  title={language('ABTEILUNG', 'TITEL', 'KOMMISSIONIERUNG', objGlobalState.lang)}
                  alt="warenausgang kommissionierung"
                  className="coHome__sectionIcon"
                  onClick={() => goToPage('warenausgang_kommissionierung')}
                />
                <img
                  src="../assets/images/icons/icon_verpackung.png"
                  title={language('ABTEILUNG', 'TITEL', 'VERPACKUNG', objGlobalState.lang)}
                  alt="warenausgang verpackung"
                  className="coHome__sectionIcon"
                  onClick={() => goToPage('warenausgang_verpackung')}
                />
                <img
                  src="../assets/images/icons/icon_status.png"
                  title={language('ABTEILUNG', 'TITEL', 'STATUS', objGlobalState.lang)}
                  alt="warenausgang avisierung"
                  className="coHome__sectionIcon"
                  onClick={() => goToPage('warenausgang_status')}
                />
              </Grid>
              <Grid item xs={12} className="marginEighty">
                <Typography className="typography__sectionTitle">{language('ABTEILUNG', 'TITEL', 'VERSAND', objGlobalState.lang)}</Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <CoVersandAvisierungTable objGlobalState={objGlobalState} objParentState={{ isHome: true }} />
              </Grid>
              <Grid item xs={12} md={2}>
                <img
                  src="../assets/images/icons/icon_lieferung.png"
                  title={language('ABTEILUNG', 'TITEL', 'LIEFERUNG', objGlobalState.lang)}
                  alt="versand lieferung"
                  className="coHome__sectionIcon"
                  onClick={() => goToPage('versand_lieferung')}
                />
                <img
                  src="../assets/images/icons/icon_avisierung.png"
                  title={language('ABTEILUNG', 'TITEL', 'AVISIERUNG', objGlobalState.lang)}
                  alt="versand avisierung"
                  className="coHome__sectionIcon"
                  onClick={() => goToPage('versand_avisierung')}
                />
              </Grid>
            </>
          ) : (
            ''
          )}
          <Grid item xs={12} className="marginEighty"></Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CoHome;
