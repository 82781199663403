import React, { useLayoutEffect, useRef, useState } from 'react';
import language from '../language/language';
import { mouseScrollHorizontally } from '../helperFunctions/mouseScrollHorizontally';
import { displayArraysInTable } from '../helperFunctions/displayArrayInTable';
import { FILE_TYPES } from '../types/types';
import { generateDate } from '../helperFunctions/date';

// import -> material ui -> core
import { Button, Dialog, DialogContent, DialogTitle, Grid, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { Fragment } from 'react';

// import -> components
import CoDisplayFiles from './CoDisplayFiles';

// component -> React-root
const CoConfirmation = ({ objGlobalState, objParentState, options }) => {
  // destructuring -> get the newDateValueState key from objLieferungState
  const { newDataValueState } = objParentState;

  const sendEmailCheck = options?.sendEmailCheck;

  const [displayDialogState, setDisplayDialogState] = useState(false);
  const [fileToDisplayState, setFileToDisplayState] = useState({ dataUri: '', title: '', type: '' });
  const [dialogIsRenderedState, setDialogIsRenderedState] = useState(false);
  const tableRef = useRef();

  // function ->
  const displayFileOnClick = (file) => {
    setFileToDisplayState(file);
    setDisplayDialogState(true);
  };

  // function ->
  const mapObjToArrayOfObjects = (obj) => {
    const array = [];
    for (let key in obj) {
      const element = { name: key.toUpperCase(), value: obj[key] };
      array.push(element);
    }
    return array;
  };

  // function ->
  const handleDialogOnRendered = () => {
    setDialogIsRenderedState(true);
  };

  // function ->
  const handleSendEmailCheckOnChange = (event) => {
    const { checked } = event.target;
    objParentState.setSendEmailCheckState(checked);
  };

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted && dialogIsRenderedState) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, [dialogIsRenderedState]);

  const dataValuesObjects = mapObjToArrayOfObjects(newDataValueState);

  const objConfirmationState = { displayDialogState, setDisplayDialogState, fileToDisplayState, setFileToDisplayState };

  return (
    <>
      {displayDialogState && <CoDisplayFiles objGlobalState={objGlobalState} objParentState={objConfirmationState} />}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={objParentState.confirmationDialogState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onRendered={handleDialogOnRendered}
      >
        <DialogTitle id="alert-dialog-title">{language('DIALOG', 'TITEL', 'EINGABEBESTAETIGUNG', objGlobalState.lang)}</DialogTitle>
        <DialogContent className="dialog__content">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className="table__container">
                <Table size="medium">
                  <TableBody>
                    {dataValuesObjects.map((obj, key) => {
                      obj.value = obj.value === true ? language('KONTENT', 'TEXT', 'JA', objGlobalState.lang) : obj.value;
                      obj.value = obj.value === false ? language('KONTENT', 'TEXT', 'NEIN', objGlobalState.lang) : obj.value;
                      return (
                        <Fragment key={key}>
                          <TableRow>
                            <TableCell align="left" component="th" className="table__rowTitle">
                              <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', obj.name, objGlobalState.lang)}</Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography className="typography__singleTableRowContent">
                                {obj.name === 'DATUM' || obj.name === 'VERSANDDATUM' || obj.name === 'ABLAUFDATUM'
                                  ? obj.value.length
                                    ? generateDate(obj.value).justDate
                                    : ''
                                  : displayArraysInTable(obj.value)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      );
                    })}
                    {sendEmailCheck && (
                      <TableRow>
                        <TableCell align="left" component="th" className="table__rowTitle">
                          <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'EMAILSENDEN', objGlobalState.lang)}</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Switch checked={objParentState.sendEmailCheckState} onChange={handleSendEmailCheckOnChange} color="primary" />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {objParentState.confirmationGallery
              ? objParentState.confirmationGallery.map((gallery, key) => {
                  if (Array.isArray(gallery.files) && !gallery.files.length) {
                    return '';
                  }
                  return (
                    <Grid item xs={12} container className="gallerySection__inGroup" key={key}>
                      <Grid item xs={12}>
                        <Typography className="typography">{language('KONTENT', 'TEXT', gallery.sectionTitle, objGlobalState.lang)}</Typography>
                      </Grid>

                      {gallery.files.map((_item, _key) => {
                        return (
                          <Grid container item xs={12} key={_key} alignContent="center" alignItems="center" justify="flex-start" className="galleryImageSection">
                            <Grid item xs={12}>
                              {_item.type === FILE_TYPES.JPEG ? (
                                <img src={_item.dataUri} className="images__gallery" alt="" onClick={() => displayFileOnClick(_item)} />
                              ) : _item.type === FILE_TYPES.PDF ? (
                                <Typography align="center" className="typography__gallery typography__gallery--fullWidth" onClick={() => displayFileOnClick(_item)}>
                                  <img src={`../assets/images/other_icons/pdf_icon.png`} alt="" className="images__textIcon" />
                                  {_item.name}
                                </Typography>
                              ) : (
                                ''
                              )}
                            </Grid>
                            {_item.beschreibung?.text ? (
                              <Grid item xs={12}>
                                <Typography>{_item.beschreibung.text}</Typography>
                              </Grid>
                            ) : (
                              ''
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>
                  );
                })
              : ''}
            <Grid item xs={12} md={6}>
              <Button onClick={objParentState.confirmed} fullWidth type="submit" color="primary" variant="contained" className="button__middle">
                {language('BUTTON', 'TITEL', 'BESTAETIGEN', objGlobalState.lang)}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button onClick={objParentState.closeConfirmationOnClick} fullWidth className="button__middle">
                {language('BUTTON', 'TITEL', 'ZURUECK', objGlobalState.lang)}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoConfirmation;
