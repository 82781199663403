import React, { useState } from 'react';
import language from '../Common/language/language';
import { createAxiosInstance } from '../Common/helperFunctions/axios';

// import -> services
import ServiceBreadcrumbs from '../Common/services/Breadcrumbs/ServiceBreadcrumbs';

// import -> material ui -> core
import { Container, Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';

// import -> component
import CoDocumentsTable from './Append/CoDocumentsTable';
import CoLogistikunternhemenSelect from '../Common/Components/CoLogistikunternhemenSelect';

const DOKUMENTEKATEGORIE = {
  VALIDIERUNGSBERICHTLAGER: 'VALIDIERUNGSBERICHTLAGER',
  VALIDIERUNGSBERICHTTRANSPORT: 'VALIDIERUNGSBERICHTTRANSPORT',
  ZERTIFIKAT: 'ZERTIFIKAT',
  MITTEILUNG: 'MITTEILUNG'
};

const noDocumentsState = {
  dataVorhanden: false,
  [DOKUMENTEKATEGORIE.VALIDIERUNGSBERICHTLAGER]: [],
  [DOKUMENTEKATEGORIE.VALIDIERUNGSBERICHTTRANSPORT]: [],
  [DOKUMENTEKATEGORIE.ZERTIFIKAT]: [],
  [DOKUMENTEKATEGORIE.MITTEILUNG]: []
};

// component -> React-root
const CoQualitaetssicherung = ({ objGlobalState }) => {
  const benutzerIstAdmin = objGlobalState.authenticateState.user.access.includes('ADMIN');

  const [logistikunternehmenState, setLogistikUnternehemenState] = useState('');
  const [documentsState, setDocumentsState] = useState(noDocumentsState);

  // function ->
  const chooseLogistikunternehmen = (event) => {
    const id = event.target.value;
    setLogistikUnternehemenState(id);

    // statement -> if the value is not '' then make request and get the documents
    if (id) {
      objGlobalState.setPageLoadingState(true);

      const accessToken = objGlobalState.jwtoken;
      const authAxios = createAxiosInstance(accessToken);

      authAxios
        .get(`/qualityControl/list?shippingCompanyId=${id}`)
        .then((response) => {
          const resDocuments = response.data;
          const dataVorhanden = Boolean(resDocuments.length);
          const newDocumentState = {
            dataVorhanden,
            [DOKUMENTEKATEGORIE.VALIDIERUNGSBERICHTLAGER]: [],
            [DOKUMENTEKATEGORIE.VALIDIERUNGSBERICHTTRANSPORT]: [],
            [DOKUMENTEKATEGORIE.ZERTIFIKAT]: [],
            [DOKUMENTEKATEGORIE.MITTEILUNG]: []
          };

          resDocuments.forEach((document) => {
            // there are documents who don't have categories, so assign them the category "Zertifikat".
            const kategorie =
              document.kategorie === 'VALIDIERUNGSBERICHT_LAGER'
                ? 'VALIDIERUNGSBERICHTLAGER'
                : document.kategorie === 'VALIDIERUNGSBERICHT_TRANSPORT'
                ? 'VALIDIERUNGSBERICHTTRANSPORT'
                : document.kategorie;

            newDocumentState[kategorie].unshift(document);
          });
          setDocumentsState(newDocumentState);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          objGlobalState.setPageLoadingState(false);
        });
    } else {
      setDocumentsState(noDocumentsState);
    }
  };

  const objQualitaetssicherung = {
    documententabelle: {
      DOKUMENTEKATEGORIE,
      documentsState,
      benutzerIstAdmin
    },
    logistikunternehmenSelect: {
      selectValue: logistikunternehmenState,
      handleInputOnChange: chooseLogistikunternehmen,
      speditionenToInclude: ['hellmann', 'go', 'transoflex', 'chors', 'awl'],
      inputLabel: 'LOGISTIKUNTERNEHMEN',
      menuItemValue: 'id'
    }
  };

  // array -> create breadcrumbs
  const arrBreadcrumbs = [
    { url: '/', title: language('SEITE', 'TITEL', 'HOME', objGlobalState.lang) },
    { url: '/qualitaetssicherung', title: language('SEITE', 'TITEL', 'QUALITAETSSICHERUNG', objGlobalState.lang) }
  ];

  return (
    <Container component="main" maxWidth="xl" className="container__container">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ServiceBreadcrumbs arrBreadcrumbs={arrBreadcrumbs} />
        </Grid>
        <Grid item xs={12}>
          <ListItem className="container__listItem">
            <ListItemIcon>
              <img src={`../assets/images/icons/icon_qualitaetssicherung.png`} alt="" className="images__headerIcon" />
            </ListItemIcon>
            <ListItemText>
              <Typography className="typography__headerTitle">{language('SEITE', 'TITEL', 'QUALITAETSSICHERUNG', objGlobalState.lang)}</Typography>
            </ListItemText>
          </ListItem>
        </Grid>
        <Grid container item xs={12} justify="center" className="marginThirty">
          <Grid item xs={12} sm={6}>
            <CoLogistikunternhemenSelect objParentState={objQualitaetssicherung.logistikunternehmenSelect} objGlobalState={objGlobalState} />
          </Grid>
          {documentsState.dataVorhanden && (
            <Grid xs={10} item>
              <CoDocumentsTable objGlobalState={objGlobalState} objQualitaetssicherungStateAndFunctions={objQualitaetssicherung.documententabelle} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default CoQualitaetssicherung;
