import React, { useEffect, useState } from 'react';
import { FILE_TYPES } from '../types/types';
import { generateDate } from '../helperFunctions/date';
import { language } from '../language/language';
import { useSnackbar } from 'notistack';

// import -> material-ui -> core & icons
import { Collapse, Container, Grid, IconButton, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore, FiberManualRecordTwoTone } from '@material-ui/icons';

const CoCamera = ({ objGlobalState, objParentState, options }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [devicesState, setDevicesState] = useState([]);
  const [selectedDeviceState, setSelectedDeviceState] = useState('');
  const [isDevicesListOpenState, setIsDevicesListOpenState] = useState(false);
  const [isPermissionQuestionDone, setIsPermissionQuestionDone] = useState(false);

  // function ->
  const mapDeviceToName = (device, idx) => {
    const description = device.label || `camera ${idx + 1}`;
    return {
      description,
      deviceId: device.deviceId
    };
  };

  // function ->
  const stopVideo = () => {
    const stream = document.querySelector('#tracePort_camera')?.srcObject;
    if (!stream) {
      return;
    }
    const tracks = stream.getTracks();
    tracks.forEach((track) => {
      track.stop();
    });
    document.querySelector('#tracePort_camera').srcObject = null;
    objParentState.setGotStreamState(false);
  };

  // function ->
  const getStream = (deviceId) => {
    stopVideo();
    const constraints = {
      video: { deviceId: deviceId ? { exact: deviceId } : undefined }
    };
    if (navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function') {
      return navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => gotStream(stream, deviceId))
        .catch(handleError);
    }
  };

  // function ->
  const gotStream = (stream, deviceId) => {
    setIsPermissionQuestionDone(true);
    objParentState.setCanCameraToggleState(true);

    window.stream = stream; // make stream available to console
    const videoElement = document.querySelector('#tracePort_camera');
    videoElement.srcObject = stream;
    videoElement.play();
    setSelectedDeviceState(deviceId);
    objParentState.setGotStreamState(true);
  };

  // function ->
  const handleError = () => {
    objParentState.setCameraState(false);
    enqueueSnackbar(language('ALERT', 'TEXT', 'ZUGRIFFVERWEIGERT', objGlobalState.lang), { variant: 'error' });
  };

  // function ->
  const handleItemClickOnClick = (event, deviceId) => {
    getStream(deviceId);
    setIsDevicesListOpenState(false);
  };

  // function ->
  const toggleDevicesListStateOnClick = () => {
    setIsDevicesListOpenState((pre) => !pre);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          const cameras = devices.filter((device) => device.kind === 'videoinput').map(mapDeviceToName);
          setDevicesState((pre) => [...pre, ...cameras]);
          getStream(cameras[0].deviceId);
        })
        .catch((error) => {
          console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
        });
    }

    return () => (isMounted = false);
  }, []);

  // function ->
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: mimeString });
    return blob;
  };

  // function ->
  const takePhotoOnClick = () => {
    const video = document.querySelector('#tracePort_camera');
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
    const dataUri = canvas.toDataURL();

    const blob = dataURItoBlob(dataUri);
    const file = new File([blob], `tracePort_Kamera_${generateDate().dateAndTimeWithMilliseconds}`);

    const timestamp = generateDate().dateAndTime;
    const type = FILE_TYPES.JPEG;
    let fotoAlsEmailAnhang = {};
    if (options?.fotoAlsEmailAnhang) {
      fotoAlsEmailAnhang.anhang = true;
    }
    const photo = { ...fotoAlsEmailAnhang, fileRef: file, timestamp: timestamp, dataUri: dataUri, type: type, beschreibung: { text: '', error: false } };

    objParentState.setPhotosState([photo, ...objParentState.photosState]);
  };

  const title = devicesState.find((device) => device.deviceId === selectedDeviceState)?.description;

  return isPermissionQuestionDone ? (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Container className="camera__container">
          <video className="camera__video" id="tracePort_camera"></video>
          <IconButton className="camera__takePhotoIconButton" onClick={takePhotoOnClick}>
            <FiberManualRecordTwoTone className="camera__takePhotoIcon" />
          </IconButton>
        </Container>
      </Grid>
      <Grid item xs={12}>
        <List>
          <ListItem button onClick={toggleDevicesListStateOnClick}>
            <ListItemText>{title}</ListItemText>
            {isDevicesListOpenState ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={isDevicesListOpenState} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {devicesState?.map(({ deviceId, description }) => {
                return (
                  <ListItem key={deviceId} button onClick={(event) => handleItemClickOnClick(event, deviceId)}>
                    <ListItemText primary={description} />
                  </ListItem>
                );
              })}
            </List>
          </Collapse>
        </List>
      </Grid>
    </Grid>
  ) : (
    ''
  );
};

export default CoCamera;
