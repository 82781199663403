import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import language from '../Common/language/language';
import { createAxiosInstance } from '../Common/helperFunctions/axios';

// import -> services
import ServiceBreadcrumbs from '../Common/services/Breadcrumbs/ServiceBreadcrumbs';

// import -> material ui -> core, icons
import { Container, Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';

// import -> components
import CoBubbles from './Append/CoBubbles';
import CoTimeline from './Append/CoTimeline';
import CoTrackingInformation from './Append/CoTrackingInformation';
import CoTrackingItems from './Append/CoTrackingItems';
import CoTrackingSuche from './Append/CoTrackingSuche';

// component -> React-root
const CoTracking = ({ objGlobalState }) => {
  const { logistikunternehmen, trackingnummer } = useParams();

  const [pageLoadingState, setPageLoadingState] = useState(true);
  const [trackingState, setTrackingState] = useState({});

  const objTrackingState = { trackingState, setTrackingState };

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // statement -> if trackingnummer not avaible, than set page to default
    if (trackingnummer === undefined) {
      setPageLoadingState(true);
    }
  }, [trackingnummer]);

  // array -> create breadcrumbs
  const arrBreadcrumbs = [
    { url: '/', title: language('SEITE', 'TITEL', 'HOME', objGlobalState.lang) },
    { url: '/tracking', title: language('SEITE', 'TITEL', 'TRACKING', objGlobalState.lang) },
    { url: '/tracking', title: language('SEITE', 'TITEL', 'TRACKINGERGEBNISSE', objGlobalState.lang) }
  ];

  // function -> axios -> fetch data from external server
  const apiGetByTrackingNr = useCallback((logistikunternehmen, trackingnummer) => {
    objGlobalState.setPageLoadingState(true);

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    authAxios
      .get(`/tracking?logistikunternehmen=${logistikunternehmen}&trackingnummer=${trackingnummer}`)
      .then((response) => {
        setTrackingState(response.data);
        setPageLoadingState(false);
      })
      .catch((error) => {
        console.error(error);
        setPageLoadingState(true);
        enqueueSnackbar(language('ALERT', 'TEXT', 'FALSCHETRACKINGNUMMER', objGlobalState.lang), { variant: 'error' });
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  }, []);

  useEffect(() => {
    let isMounted = true;

    // statement -> check if component is mounted
    if (isMounted) {
      // statement -> check if trackingnummer is avaible, than fetch data from external server
      if (trackingnummer) {
        apiGetByTrackingNr(logistikunternehmen, trackingnummer);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [apiGetByTrackingNr, logistikunternehmen, trackingnummer, objGlobalState.searchFormSubmitState]);

  return (
    <>
      <Container component="main" maxWidth="xl" className="container__container">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ServiceBreadcrumbs arrBreadcrumbs={arrBreadcrumbs} />
          </Grid>
          <Grid item xs={12}>
            <ListItem className="container__listItem">
              <ListItemIcon>
                <img src={`${process.env.REACT_APP_HOST}/assets/images/icons/icon_tracking.png`} alt="" className="images__headerIcon" />
              </ListItemIcon>
              <ListItemText>
                <Typography className="typography__headerTitle">{language('SEITE', 'TITEL', 'TRACKING', objGlobalState.lang)}</Typography>
              </ListItemText>
            </ListItem>
          </Grid>
          {pageLoadingState ? (
            <CoTrackingSuche objGlobalState={objGlobalState} />
          ) : (
            <>
              <Grid item xs={12}>
                <CoBubbles objGlobalState={objGlobalState} objTrackingState={objTrackingState} />
              </Grid>
              <Grid item xs={12} md={6}>
                <CoTimeline objTrackingState={objTrackingState} objGlobalState={objGlobalState} />
              </Grid>
              <Grid item xs={12} md={6}>
                <CoTrackingInformation objTrackingState={objTrackingState} objGlobalState={objGlobalState} />
              </Grid>
              {trackingState.trackingItems.length ? (
                <Grid item xs={12} md={6}>
                  <CoTrackingItems objTrackingState={objTrackingState} objGlobalState={objGlobalState} />
                </Grid>
              ) : (
                ''
              )}
            </>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default CoTracking;
