import React from 'react';
import language from '../Common/language/language';

// import -> services
import ServiceBreadcrumbs from '../Common/services/Breadcrumbs/ServiceBreadcrumbs';

// import -> material ui -> core
import { Container, Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';

// component -> React-root
const CoInventur = ({ objGlobalState }) => {
  // array -> create breadcrumbs
  const arrBreadcrumbs = [{ url: '/', title: language('SEITE', 'TITEL', 'INVENTUR', objGlobalState.lang) }];

  return (
    <Container component="main" maxWidth="xl" className="container__container">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ServiceBreadcrumbs arrBreadcrumbs={arrBreadcrumbs} />
        </Grid>
        <Grid item xs={12}>
          <ListItem className="container__listItem">
            <ListItemIcon>
              <img src={`../assets/images/icons/icon_inventur.png`} alt="" className="images__headerIcon" />
            </ListItemIcon>
            <ListItemText>
              <Typography className="typography__headerTitle">{language('SEITE', 'TITEL', 'INVENTUR', objGlobalState.lang)}</Typography>
            </ListItemText>
          </ListItem>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CoInventur;
