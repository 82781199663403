import React, { useEffect, useRef, useState } from 'react';
import language from '../../Common/language/language';
import { useSnackbar } from 'notistack';
import { createAxiosInstance } from '../../Common/helperFunctions/axios';

// import -> material ui -> core, icons
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Paper
} from '@material-ui/core';
import { Close, DeleteForever, ExpandMore } from '@material-ui/icons';

// import -> components
import CoLoeschenwarnungDialog from '../../Common/Components/CoLoeschenwarnungDialog';

const formatDialogClosed = { open: false, purpose: { erstellen: false, see: false }, format: {} };

// component -> React-root
const CoFormatSetting = ({ objGlobalState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [formatDialogState, setFormatDialogState] = useState(formatDialogClosed);
  const [formateState, setFormateState] = useState([]);
  const [loeschenwarnungDialogState, setLoeschenwarnungDialogState] = useState({ open: false, deletedDataLabel: '' });
  const [focuesdFormatState, setFocusedFormatState] = useState({});

  const tableRef = useRef();

  // function ->
  const openErstellungformatDialogOnChange = () => {
    setFormatDialogState({ open: true, purpose: { erstellen: true, see: false }, format: { id: '', text: '' } });
  };

  // function ->
  const openSeeFormatDialogOnChange = (format) => {
    setFormatDialogState({ open: true, purpose: { erstellen: false, see: true }, format: format });
  };

  // function ->
  const openWarnungDialogOnClick = (event, format) => {
    event.stopPropagation();
    const deletedDataLabel = `Format ${format.id}`;
    setLoeschenwarnungDialogState({ open: true, deletedDataLabel });
    setFocusedFormatState(format);
  };

  // function ->
  const deleteFormatOnClick = () => {
    const formatToDeleteId = focuesdFormatState.id;

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);
    authAxios
      .delete(`/usersettings/mail-vorlagen/${formatToDeleteId}`)
      .then(() => {
        deleteFormatAfterAPIRequest(formatToDeleteId);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
      });
  };

  // function ->
  const deleteFormatAfterAPIRequest = (formatToDeleteId) => {
    const currentFormateState = [...formateState];
    const newFormateState = currentFormateState.filter((format) => format.id !== formatToDeleteId);
    setFormateState(newFormateState);
  };

  // function ->
  const mapFetchFormateToFormateState = (formate) => {
    const newFormateState = [];

    formate.forEach((format) => {
      const formatId = format.id;
      const formattext = format.inhalt;

      const newDataObj = {
        id: formatId,
        text: formattext
      };

      newFormateState.unshift(newDataObj);
    });

    setFormateState(newFormateState);
  };

  // function ->
  const getFormateFromAPI = () => {
    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);
    authAxios
      .get('/usersettings/mail-vorlagen')
      .then((response) => {
        const formate = response.data;
        mapFetchFormateToFormateState(formate);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getFormateFromAPI();
    }

    return () => (isMounted = false);
  }, []);

  const objFormatSettingStateAndFunctions = {
    formatDialogState,
    setFormatDialogState,
    formateState,
    setFormateState,
    loeschenwarnungDialogState,
    setLoeschenwarnungDialogState
  };

  return (
    <>
      {loeschenwarnungDialogState.open && <CoLoeschenwarnungDialog objGlobalState={objGlobalState} objParentState={{ ...objFormatSettingStateAndFunctions, deleteDataOnClick: deleteFormatOnClick }} />}
      {formatDialogState.open && <CoFormatDialog objFormatSettingStateAndFunctions={objFormatSettingStateAndFunctions} objGlobalState={objGlobalState} />}
      <Accordion className="accordion">
        <AccordionSummary className="accordion__summary" expandIcon={<ExpandMore />}>
          <Typography align="center" className="typography__accordionSummary">
            {language('ABTEILUNG', 'TITEL', 'EMAILFORMAT', objGlobalState.lang)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3} className="marginTwenty">
            <Grid item xs={12}>
              <Button onClick={openErstellungformatDialogOnChange} variant="outlined" color="primary" size="large">
                <Typography>{language('BUTTON', 'TITEL', 'ERSTELLEN', objGlobalState.lang)}</Typography>
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="table__title">
                <Typography align="center" className="typography__singleTableTitle">
                  {language('TABLE', 'HEADER', 'FORMATE', objGlobalState.lang)}
                </Typography>
              </Box>
              <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className="table__container--border heightThree border__noTop">
                <Table size="medium" stickyHeader aria-label="sticky table">
                  <TableBody>
                    {formateState.map((item, key) => {
                      const lastTableCellClassName = key === formateState.length - 1 ? 'noBorderBottom' : '';

                      return (
                        <TableRow key={`einstellungen_formatsetting_formate_tableRow_${key}`} className="table__row--hover" onClick={() => openSeeFormatDialogOnChange(item)}>
                          <TableCell className={lastTableCellClassName} align="left">
                            <Typography className="typography__singleTableRowContent">{`Format ${item.id}`}</Typography>
                          </TableCell>
                          <TableCell className={lastTableCellClassName} align="right">
                            <IconButton
                              onClick={(event) => openWarnungDialogOnClick(event, item)}
                              className="button__icon--tableRed"
                              title={language('BUTTON', 'TITEL', 'LOESCHEN', objGlobalState.lang)}
                              size="small"
                            >
                              <DeleteForever />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

// component -> React-root
const CoFormatDialog = ({ objFormatSettingStateAndFunctions, objGlobalState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [emailtextState, setEmailtextState] = useState(objFormatSettingStateAndFunctions.formatDialogState.format.text || '');

  //  function ->
  const closeFormatDialogOnClose = () => {
    objFormatSettingStateAndFunctions.setFormatDialogState(formatDialogClosed);
  };

  // functiono ->
  const handleEmailtextOnChange = (event) => {
    const emailtext = event.target.value;
    setEmailtextState(emailtext);
  };

  // function ->
  const pushNewFormatInFormate = (format) => {
    const currentFormateState = [...objFormatSettingStateAndFunctions.formateState];
    format.text = format.inhalt;
    delete format.inhalt;
    currentFormateState.unshift(format);
    objFormatSettingStateAndFunctions.setFormateState(currentFormateState);
  };

  // function ->
  const createForamtOnClick = () => {
    const formatToCreateText = emailtextState;

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    const requestData = {
      inhalt: formatToCreateText
    };

    authAxios
      .post('/usersettings/mail-vorlagen', requestData)
      .then((response) => {
        const newFormat = response.data;
        pushNewFormatInFormate(newFormat);
        closeFormatDialogOnClose();
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT'), { variant: 'success' });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
      });
  };

  // function ->
  const updateFormatOnClick = () => {
    const formatToUpdateId = objFormatSettingStateAndFunctions.formatDialogState.format.id;
    const formatToUpdateText = emailtextState;

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    const requestData = {
      inhalt: formatToUpdateText
    };

    authAxios
      .put(`/usersettings/mail-vorlagen/${formatToUpdateId}`, requestData)
      .then(() => {
        updateFormatAfterAPIRequest(formatToUpdateId, formatToUpdateText);
        closeFormatDialogOnClose();
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT'), { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
        console.log(error);
      });
  };

  // function ->
  const updateFormatAfterAPIRequest = (formatToUpdateId, formatToUpdateText) => {
    const currentFormateState = [...objFormatSettingStateAndFunctions.formateState];
    const formatToUpdate = currentFormateState.find((format) => format.id === formatToUpdateId);
    formatToUpdate.text = formatToUpdateText;
    objFormatSettingStateAndFunctions.setFormateState(currentFormateState);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={objFormatSettingStateAndFunctions.formatDialogState.open}
      onClose={closeFormatDialogOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Typography align="left" className="typography__dialogTitle">
              {language('DIALOG', 'TITEL', 'EMAILFORMAT', objGlobalState.lang)}
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <IconButton className="button__iconButton--close" onClick={closeFormatDialogOnClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className="dialog__content">
        <form>
          <Grid container spacing={3}>
            {objFormatSettingStateAndFunctions.formatDialogState.purpose.see && (
              <Grid item xs={12}>
                <Typography className="typography">{`Format ${objFormatSettingStateAndFunctions.formatDialogState.format.id}`}</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                value={emailtextState}
                onChange={handleEmailtextOnChange}
                fullWidth
                rows="10"
                multiline
                variant="outlined"
                label={language('FORM', 'LABEL', 'FORMATTEXT', objGlobalState.lang)}
              />
            </Grid>
            <Grid item xs={12}>
              {objFormatSettingStateAndFunctions.formatDialogState.purpose.erstellen && (
                <Button onClick={createForamtOnClick} variant="outlined" color="primary" size="large">
                  <Typography>{language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}</Typography>
                </Button>
              )}
              {objFormatSettingStateAndFunctions.formatDialogState.purpose.see && (
                <Button onClick={updateFormatOnClick} variant="outlined" color="primary" size="large">
                  <Typography>{language('BUTTON', 'TITEL', 'UPDATEN', objGlobalState.lang)}</Typography>
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CoFormatSetting;
