import React from 'react';
import { useSnackbar } from 'notistack';
import language from '../../Common/language/language';
import { createAxiosInstance } from '../../Common/helperFunctions/axios';
import { generateDate } from '../../Common/helperFunctions/date';

// import -> material ui -> core
import { Button, Dialog, DialogContent, Grid, DialogTitle, TextField } from '@material-ui/core';

// component -> React-root
const CoBreachtigungbearbeitungDialog = ({ objGlobalState, objParentState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  // function ->
  const closeBearbeitungDialogOnClick = () => {
    objParentState.setBearbeitungDialogState((pre) => {
      return {
        ...pre,
        open: false
      };
    });
  };

  // function ->
  const submitFormOnSubmit = (event) => {
    event.preventDefault();
    const ablaufdatum = event.target.ablaufdatum.value;
    const berechtigungId = objParentState.bearbeitungDialogState.berechtigungId;

    const requestAblaufdatum = ablaufdatum.length ? generateDate(ablaufdatum).dateAndTimeForDataBase : '';

    const requestValues = {
      freigabeBis: requestAblaufdatum,
      id: berechtigungId
    };

    authAxios
      .put(`/file-freigabe/${berechtigungId}`, requestValues)
      .then(() => {
        closeBearbeitungDialogOnClick();
        enqueueSnackbar(language('ALERT', 'TEXT', 'AENDERUNGENGESPEICHERT', objGlobalState.lang), { variant: 'success' });
        objParentState.forceRerender();
      })
      .catch((error) => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
        console.log(error);
      });
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={objParentState.bearbeitungDialogState.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{language('DIALOG', 'TITEL', 'BEARBEITEN', objGlobalState.lang)}</DialogTitle>
      <DialogContent className="dialog__content">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <form name="submitForm" noValidate autoComplete="on" onSubmit={submitFormOnSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    name="ablaufdatum"
                    type="date"
                    variant="outlined"
                    label={language('FORM', 'LABEL', 'ABLAUFDATUM', objGlobalState.lang)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button type="submit" fullWidth className="button__middle" variant="contained" color="primary" size="large">
                    {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button onClick={closeBearbeitungDialogOnClick} fullWidth className="button__middle">
                    {language('BUTTON', 'TITEL', 'ABBRECHEN', objGlobalState.lang)}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CoBreachtigungbearbeitungDialog;
