import React, { useEffect, useState } from 'react';
import language from '../Common/language/language';
import { createAxiosInstance } from '../Common/helperFunctions/axios';
import { generateDate } from '../Common/helperFunctions/date';

// import -> services
import ServiceBreadcrumbs from '../Common/services/Breadcrumbs/ServiceBreadcrumbs';

// import -> material ui -> core
import { Container, Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';

// import -> component
import CoVerpackungTable from './Append/CoVerpackungTable';

// component -> React-root
const CoWarenausgangverpackung = ({ objGlobalState }) => {
  const [verpackungState, setVerpackungState] = useState([]);

  // state will be changed every time the Auftrag values been changed
  const [auftragsAngepasstState, setAuftragsAngepasstState] = useState(false);

  // function ->
  const fetchVerpackungenFromAPI = () => {
    objGlobalState.setPageLoadingState(true);

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    authAxios
      .get('/auftrag/all')
      .then((response) => {
        const { data } = response;

        const newVerpackungState = [];

        data.forEach((dataObj) => {
          const datum = generateDate(dataObj.datum).justDate;
          const versanddatum = dataObj.versanddatum ? generateDate(dataObj.versanddatum).dateAndTime : '';

          const shipmentType = dataObj.shipmentType.id;

          const newDataObj = {
            auftragsnummer: dataObj.auftragsnummer,
            datum: datum,
            name1: dataObj.name1,
            name2: dataObj.name2,
            name3: dataObj.name3,
            emailadresse: dataObj.email,
            telefonnummer: dataObj.telefonnummer,
            strasse: dataObj.strasse,
            plz: dataObj.plz,
            ort: dataObj.ort,
            land: dataObj.land,
            zusaetzlicheinformationen: dataObj.zusatzinformation,
            referenz: dataObj.auftragsreferenz,
            spedition: dataObj.dienstleister,
            sendungsart: shipmentType,
            versanddatum: versanddatum,
            status: 1,
            id: dataObj.id,
            lieferschein: []
          };

          newVerpackungState.unshift(newDataObj);
        });

        setVerpackungState(newVerpackungState);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchVerpackungenFromAPI();
    }

    return () => (isMounted = false);
  }, [auftragsAngepasstState]);

  const objVerpackungStatesAndFunctions = {
    auftragsAngepasstState,
    setAuftragsAngepasstState,
    verpackungState,
    setVerpackungState
  };

  // array -> create breadcrumbs
  const arrBreadcrumbs = [
    { url: '/', title: language('SEITE', 'TITEL', 'HOME', objGlobalState.lang) },
    { url: '/warenausgang_verpackung', title: language('SEITE', 'TITEL', 'WARENAUSGANGVERPACKUNG', objGlobalState.lang) }
  ];

  return (
    <Container component="main" maxWidth="xl" className="container__container">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ServiceBreadcrumbs arrBreadcrumbs={arrBreadcrumbs} />
        </Grid>
        <Grid item xs={12}>
          <ListItem className="container__listItem">
            <ListItemIcon>
              <img src={`../assets/images/icons/icon_verpackung.png`} alt="" className="images__headerIcon" />
            </ListItemIcon>
            <ListItemText>
              <Typography className="typography__headerTitle">{language('SEITE', 'TITEL', 'WARENAUSGANGVERPACKUNG', objGlobalState.lang)}</Typography>
            </ListItemText>
          </ListItem>
        </Grid>
        <Grid container item xs={12} justify="center">
          {verpackungState.length ? (
            <Grid xs={10} item>
              <CoVerpackungTable objGlobalState={objGlobalState} objVerpackungStatesAndFunctions={objVerpackungStatesAndFunctions} />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default CoWarenausgangverpackung;
