import React, { useState } from 'react';
import language from '../language/language';

// import -> material-ui -> core & icons
import { IconButton, Grid, Typography, TableRow, TableCell, TableFooter, Button, TextField } from '@material-ui/core';
import { ChevronLeft, ChevronRight, LastPage, FirstPage } from '@material-ui/icons';

const TRIGGERS = {
  INCREMENT: 1,
  DECREMENT: 2,
  FIRSTPAGER: 3,
  LASTPAGER: 4
};

const CoPaginationTableFooter = ({ objParent, objGlobalState }) => {
  const [paginationInputsState, setPaginationInputsState] = useState({
    seitennummer: '',
    zeilenProSeite: ''
  });

  // function ->
  const handlePaginationInputsOnChange = (event) => {
    let { name, value } = event.target;
    value = +value <= 0 ? '' : value;
    setPaginationInputsState((pre) => ({
      ...pre,
      [name]: value
    }));
  };

  // function ->
  const submitPaginationFormOnSubmit = (event) => {
    event.preventDefault();
    const { seitennummer, zeilenProSeite } = paginationInputsState;

    objParent.setCurrentPageState(seitennummer);
    objParent.setElementsPerPageState(zeilenProSeite);
  };

  // function ->
  const incrementPageByOne = () => {
    if (objParent.currentPageState < objParent.numOfPagesState) {
      objParent.setCurrentPageState((pre) => {
        pre = pre ? +pre : 1;
        return pre + 1;
      });
    }
  };

  // function ->
  const decrementPageByOne = () => {
    if (objParent.currentPageState > 1) {
      objParent.setCurrentPageState((pre) => +pre - 1);
    }
  };

  // function ->
  const moveToFirstPage = () => {
    objParent.setCurrentPageState(1);
  };

  // function ->
  const moveToLastPage = () => {
    objParent.setCurrentPageState(objParent.numOfPagesState);
  };

  // function ->
  const incrementOrDecrementPage = (trigger) => {
    trigger === TRIGGERS.INCREMENT && incrementPageByOne();
    trigger === TRIGGERS.DECREMENT && decrementPageByOne();
    trigger === TRIGGERS.FIRSTPAGER && moveToFirstPage();
    trigger === TRIGGERS.LASTPAGER && moveToLastPage();
  };

  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={objParent.firstCellColSpan}>
          <Typography className="typography width__min190">
            <IconButton className="padding5" onClick={incrementOrDecrementPage.bind(null, TRIGGERS.FIRSTPAGER)}>
              <FirstPage />
            </IconButton>
            <IconButton className="padding5" onClick={incrementOrDecrementPage.bind(null, TRIGGERS.DECREMENT)}>
              <ChevronLeft />
            </IconButton>
            {objParent.currentPageState <= 0 ? 1 : objParent.currentPageState} von {objParent.numOfPagesState <= 0 ? 1 : objParent.numOfPagesState}
            <IconButton className="padding5" onClick={incrementOrDecrementPage.bind(null, TRIGGERS.INCREMENT)}>
              <ChevronRight />
            </IconButton>
            <IconButton className="padding5" onClick={incrementOrDecrementPage.bind(null, TRIGGERS.LASTPAGER)}>
              <LastPage />
            </IconButton>
          </Typography>
        </TableCell>
        <TableCell colSpan={objParent.secondCellColSpan}>
          <form onSubmit={submitPaginationFormOnSubmit} className="width__min190">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  inputProps={{
                    min: 1
                  }}
                  placeholder={language('FORM', 'LABEL', 'SEITENNUMMER', objGlobalState.lang)}
                  type="number"
                  fullWidth
                  value={paginationInputsState.seitennummer}
                  name="seitennummer"
                  onChange={handlePaginationInputsOnChange}
                />
              </Grid>
              <Grid item xs={6}>
                <Button type="submit" className="button__small--cWhite" variant="contained" color="primary">
                  Ok
                </Button>
              </Grid>
            </Grid>
          </form>
        </TableCell>
        <TableCell colSpan={objParent.thirdCellColSpan}>
          <form onSubmit={submitPaginationFormOnSubmit} className="width__min270">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  inputProps={{
                    min: 1
                  }}
                  placeholder={language('FORM', 'LABEL', 'ZEILENPROSEITE', objGlobalState.lang)}
                  type="number"
                  fullWidth
                  value={paginationInputsState.zeilenProSeite}
                  name="zeilenProSeite"
                  onChange={handlePaginationInputsOnChange}
                />
              </Grid>
              <Grid item xs={6}>
                <Button type="submit" className="button__small--cWhite" variant="contained" color="primary">
                  Ok
                </Button>
              </Grid>
            </Grid>
          </form>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

export default CoPaginationTableFooter;
