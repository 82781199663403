import React, { useEffect, useState } from 'react';
import language from '../language/language';
import { useSnackbar } from 'notistack';
import { createAxiosInstance } from '../helperFunctions/axios';

// import -> material ui -> core
import { Grid, TextField, Box } from '@material-ui/core';

// import -> components
import CoMultipleScanner from './CoMultipleScanner';
import { Autocomplete } from '@material-ui/lab';

const CoMultipleScannerWithSelectStellplatz = ({ objGlobalState, objParentState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const isFreiStellplaetze = objParentState.freiStellplaetze;
  const isBelegtStellplaetze = objParentState.belegtStellplaetze;

  const [stellplatzkennzeichnungState, setStellplatzkennzeichnungState] = useState('');
  const [stellplaetzeState, setStellplaetzeState] = useState([]);
  const [displayStellplaetzeState, setDisplayStellplaetzeState] = useState([]);
  const [autoCompleteSelectedStellplaetzeState, setAutoCompleteSelectedStellplaetzeState] = useState([]);
  const [scannerSelectedStellplaetzeState, setScannerSelectedStellplaetzeState] = useState([]);

  // function ->
  // const showUploadFilesDialogOnClick = () => {
  // setUploadFilesDialogState({ open: true, title: undefined });
  // };

  // function ->
  const handleAutoCompleteStellplaetzeOnChange = (event, inputValues) => {
    setAutoCompleteSelectedStellplaetzeState(inputValues);
  };

  // function ->
  const addStellplatzkennzeichnungToSelectedStellplaetze = () => {
    const isValidStellplatz = stellplaetzeState.some((stellplatz) => stellplatz.name === stellplatzkennzeichnungState);
    if (!isValidStellplatz) {
      enqueueSnackbar(language('ALERT', 'TEXT', 'STELLPLATZNICHTVERGEBEN'), { variant: 'error' });
      return;
    }
    const isUsedStellplatz =
      autoCompleteSelectedStellplaetzeState.some((stellplatz) => stellplatz.name === stellplatzkennzeichnungState) ||
      scannerSelectedStellplaetzeState.some((stellplatz) => stellplatz.name === stellplatzkennzeichnungState);
    if (isUsedStellplatz) {
      enqueueSnackbar(language('ALERT', 'TEXT', 'STELLPLATZBEREITSAUSGEWAEHLT'), { variant: 'warning' });
      return;
    }
    const newScannerSelectedStellplaetzeState = [...scannerSelectedStellplaetzeState];

    // find the id of the corresponding stellplatzkennzeichnungState and use the returned obj
    const newSelectedStellplatzObj = stellplaetzeState.find((stellplatz) => stellplatz.name === stellplatzkennzeichnungState);
    newScannerSelectedStellplaetzeState.push(newSelectedStellplatzObj);
    setScannerSelectedStellplaetzeState(newScannerSelectedStellplaetzeState);

    // remove the options that are the same as the scannerSelectedStellplaetzeState values
    editDisplayStellplaetze(newScannerSelectedStellplaetzeState);
  };

  // function ->
  const removeStellplatzFromScannerSelectedStellplaetze = (toRemoveStellplatz) => {
    const currentScannerSelectedStellplatzeState = [...scannerSelectedStellplaetzeState];
    const newScannerSelectedStellplaetzeState = currentScannerSelectedStellplatzeState.filter((scannerSelectedStellplatz) => scannerSelectedStellplatz !== toRemoveStellplatz);
    setScannerSelectedStellplaetzeState(newScannerSelectedStellplaetzeState);

    editDisplayStellplaetze(newScannerSelectedStellplaetzeState);
  };

  // function ->
  // remove the options that are the same as the scannerSelectedStellplaetzeState values
  const editDisplayStellplaetze = (scannerSelectedStellplaetze) => {
    const currentStellplaetzeState = [...stellplaetzeState];
    const newDisplayStellplaetze = currentStellplaetzeState.filter(
      (displayStellplatz) => !scannerSelectedStellplaetze.some((scannerSelectedStellplatz) => scannerSelectedStellplatz.id === displayStellplatz.id)
    );
    setDisplayStellplaetzeState(newDisplayStellplaetze);
  };

  // function ->
  const fetchStellplaetzeFromApi = () => {
    const defaultRequestUrl = '/lagerstellplatz';
    const defaultRequestAddon = isFreiStellplaetze ? '/frei' : isBelegtStellplaetze ? '/belegt' : '';

    // this is used when the parent is kommissionierung
    const parentRequestUrl = objParentState.stellplaetzeRequestUrl;

    let requestUrl = '';
    if (parentRequestUrl) {
      requestUrl = parentRequestUrl;
    } else {
      requestUrl = defaultRequestUrl + defaultRequestAddon;
    }

    authAxios
      .get(requestUrl)
      .then((response) => {
        setStellplaetzeState(response.data);
        setDisplayStellplaetzeState(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    fetchStellplaetzeFromApi();

    return () => {
      isMounted = false;
    };
  }, [objParentState.rerunEffectState, objParentState.ausgewaehlteBuchungsIdState]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    if (stellplatzkennzeichnungState) addStellplatzkennzeichnungToSelectedStellplaetze();

    return () => {
      isMounted = false;
    };
  }, [stellplatzkennzeichnungState]);

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    objParentState.setSelectedStellplaetzeState(scannerSelectedStellplaetzeState.concat(autoCompleteSelectedStellplaetzeState));

    return () => {
      isMounted = false;
    };
  }, [scannerSelectedStellplaetzeState, autoCompleteSelectedStellplaetzeState]);

  const objMultipleScannerWithSelectStellplatz = {
    multipleScanner: {
      stellplatzkennzeichnungState,
      setStellplatzkennzeichnungState
    }
  };

  return (
    <>
      <CoMultipleScanner objGlobalState={objGlobalState} objParentState={objMultipleScannerWithSelectStellplatz.multipleScanner} options={{ hideTextField: true }} />
      {/* <Grid item xs={12}>
        <Button onClick={showUploadFilesDialogOnClick} className="button__middle--greenOutLined">
          {language('BUTTON', 'TITEL', 'DATEIENHOCHLADEN', objGlobalState.lang)}
        </Button>
      </Grid>
      <CoDisplayFilesList objGlobalState={objGlobalState} filesState={filesState} setFilesState={setFilesState} title="STELLPLATZFOTOS" /> */}
      <Grid item xs={12} className="paddingZero" />
      {scannerSelectedStellplaetzeState.length ? (
        <Grid item xs={12}>
          <Box className="border" style={{ width: 'fit-content' }}>
            {scannerSelectedStellplaetzeState.map((stellplatz, index) => {
              return (
                <div role="button" className="MuiChip-root MuiAutocomplete-tag MuiChip-deletable" key={`common_components_multiple_scanner_with_select_stellplatz_selectedScannerStellplatz_${index}`}>
                  <span className="MuiChip-label">{stellplatz.name}</span>
                  <svg
                    onClick={() => removeStellplatzFromScannerSelectedStellplaetze(stellplatz)}
                    className="MuiSvgIcon-root MuiChip-deleteIcon"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
                  </svg>
                </div>
              );
            })}
          </Box>
        </Grid>
      ) : (
        ''
      )}
      <Grid item xs={12} sm={6} md={3}>
        <Autocomplete
          multiple
          clearText=""
          openText=""
          noOptionsText={language('FORM', 'LABEL', 'KEINEOPTIONEN', objGlobalState.lang)}
          onChange={handleAutoCompleteStellplaetzeOnChange}
          options={displayStellplaetzeState}
          getOptionLabel={(option) => option.name}
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} variant="outlined" label={language('FORM', 'LABEL', 'STELLPLATZKENNZEICHNUNG', objGlobalState.lang)} />}
        />
      </Grid>
      <Grid item xs={12} className="paddingZero" />
    </>
  );
};

export default CoMultipleScannerWithSelectStellplatz;
