import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import language from '../../language/language';
import { mouseScrollHorizontally } from '../../helperFunctions/mouseScrollHorizontally';
import { displayArraysInTable } from '../../helperFunctions/displayArrayInTable';
import { generateDate } from '../../helperFunctions/date';
import { createAxiosInstance } from '../../helperFunctions/axios';

// import -> material ui -> core & icons
import { Box, Button, FormControl, InputAdornment, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';

// import -> components
import CoDetailstabelleDialog from './Append/CoDetailstabelleDialog';
import { CancelOutlined, CheckCircleOutline, Search } from '@material-ui/icons';
import { removeFromArray } from '../../helperFunctions/arrays';
import { exportTableAsCSV } from '../../helperFunctions/exportTable';
import { generateCSVString } from '../../helperFunctions/generateCSVString';

// component -> React-root
const CoAuftragstabelle = ({ objGlobalState, objParentState }) => {
  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);
  const hasLagerAccess = objGlobalState.authenticateState.user.access.includes('LAGER');

  const history = useHistory();

  const isAuftragsverwaltungstatus = objParentState.auftragsverwaltungstatus;
  const isKommissionierung = objParentState.kommissionierung;
  const isWarenausgangstatus = objParentState.warenausgangstatus;
  const isHome = objParentState.home;

  const [auftraegeState, setAuftraegeState] = useState([]);
  const [displayAuftraegeState, setDisplayAuftraegeState] = useState([]);

  const [detailstabelleDialogState, setDetailsTabelleDialogState] = useState({ open: false, dataset: {} });

  const tableRef = useRef();
  let tableHeaders = [];
  if (isAuftragsverwaltungstatus) {
    tableHeaders = ['STATUS', 'TRACKING', 'AUFTRAGSNUMMER', 'ARTIKELNUMMER', 'ERSTELLUNGSDATUM'];
  } else if (isKommissionierung) {
    tableHeaders = ['AUFTRAGSNUMMER', 'ARTIKELNUMMER', 'KUNDE', 'SPEDITION', 'DATUM', 'SENDUNGSART', 'ANZAHLDERPAKETE', 'ANZAHLDERPALETTEN'];
  } else if (isWarenausgangstatus) {
    tableHeaders = ['LIEFERSCHEINVORHANDEN', 'AUFTRAGSNUMMER', 'KUNDE', 'SPEDITION', 'DATUM', 'SENDUNGSART', 'ANZAHLDERPAKETE', 'ANZAHLDERPALETTEN'];
  } else if (isHome) {
    tableHeaders = ['AUFTRAGSNUMMER', 'ARTIKELNUMMER', 'KUNDE', 'SPEDITION', 'DATUM', 'SENDUNGSART'];
  }

  if (!hasLagerAccess) {
    removeFromArray(tableHeaders, 'ARTIKELNUMMER');
  }

  // function ->
  const stateToCSV = () => {
    let tableValuesLabels = [];
    let headers = [];

    if (isAuftragsverwaltungstatus) {
      tableValuesLabels = [
        'auftragsnummer',
        'status',
        'artikelNummer',
        'datum',
        'name1',
        'name2',
        'name3',
        'emailadresse',
        'telefonnummer',
        'strasse',
        'plz',
        'ort',
        'land',
        'zusaetzlicheinformationen',
        'referenz',
        'spedition',
        'sendungsart',
        'stellplatzkennzeichnung',
        'anzahlDerPakete',
        'anzahlDerPaketeProPaketsendung',
        'anzahlDerPaletten',
        'anzahlDerPaketeProPalette',
        'versanddatum'
      ];

      headers = [
        'AUFTRAGSNUMMER',
        'STATUS',
        'ARTIKELNUMMER',
        'DATUM',
        'NAME1',
        'NAME2',
        'NAME3',
        'EMAILADRESSE',
        'TELEFONNUMMER',
        'STRASSE',
        'PLZ',
        'ORT',
        'LAND',
        'ZUSAETZLICHEINFORMATIONEN',
        'REFERENZ',
        'SPEDITION',
        'SENDUNGSART',
        'STELLPLATZKENNZEICHNUNG',
        'ANZAHLDERPAKETE',
        'ANZAHLDERPAKETEPROPAKETSENDUNG',
        'ANZAHLDERPALETTEN',
        'ANZAHLDERPAKETEPROPALETTE',
        'VERSANDDATUM'
      ];
    } else if (isKommissionierung) {
      tableValuesLabels = ['auftragsnummer', 'artikelNummer', 'kunde', 'spedition', 'datum', 'sendungsart', 'anzahlDerPakete', 'anzahlDerPaletten'];
      headers = ['AUFTRAGSNUMMER', 'ARTIKELNUMMER', 'KUNDE', 'SPEDITION', 'DATUM', 'SENDUNGSART', 'ANZAHLDERPAKETE', 'ANZAHLDERPALETTEN'];
    } else if (isWarenausgangstatus) {
      tableValuesLabels = ['lieferscheinVorhanden', 'auftragsnummer', 'kunde', 'spedition', 'datum', 'sendungsart', 'anzahlDerPakete', 'anzahlDerPalette'];
      headers = ['LIEFERSCHEINVORHANDEN', 'AUFTRAGSNUMMER', 'KUNDE', 'SPEDITION', 'DATUM', 'SENDUNGSART', 'ANZAHLDERPAKETE', 'ANZAHLDERPALETTEN'];
    }

    if (!hasLagerAccess) {
      removeFromArray(headers, 'ARTIKELNUMMER');
      removeFromArray(tableValuesLabels, 'artikelNummer');
    }

    const csvRows = [];
    const headerRow = headers.map((header) => {
      return generateCSVString(language('TABLE', 'TITEL', header, objGlobalState.lang));
    });
    csvRows.push(headerRow.join(';'));
    auftraegeState.forEach((auftrag) => {
      const valueRow = tableValuesLabels.map((value) => {
        if (value === 'status') {
          const valueString =
            auftrag[value] === 1
              ? language('FORM', 'LABEL', 'WARTEND', objGlobalState.lang)
              : auftrag[value] === 2
              ? language('FORM', 'LABEL', 'INBEARBEITUNG', objGlobalState.lang)
              : auftrag[value] === 3
              ? language('FORM', 'LABEL', 'VERSENDET', objGlobalState.lang)
              : '';
          return generateCSVString(valueString);
        }

        if (value === 'lieferscheinVorhanden') {
          const valueString = auftrag[value] ? language('KONTENT', 'TEXT', 'JA', objGlobalState.lang) : language('KONTENT', 'TEXT', 'NEIN', objGlobalState.lang);
          return generateCSVString(valueString);
        }
        return generateCSVString(auftrag[value]);
      });
      csvRows.push(valueRow.join(';'));
    });
    return csvRows.join('\n');
  };

  // function ->
  const chooseAuftragOnClick = (auftrag) => {
    objParentState.setAusgewaehlterAuftragState({ chosen: true, auftrag: auftrag });

    const currentAuftraegeState = [...auftraegeState];

    currentAuftraegeState.map((_auftrag) => {
      _auftrag.chosen = false;

      if (_auftrag === auftrag) {
        _auftrag.chosen = true;
      }

      return _auftrag;
    });

    setAuftraegeState(currentAuftraegeState);
    setDisplayAuftraegeState(currentAuftraegeState);
  };

  // function ->
  const getNumberOfCharInString = (str, char) => {
    let result = 0;
    str.split('').forEach((sChar) => {
      if (sChar === char) {
        result += 1;
      }
    });
    return result;
  };

  // function ->
  const handleSearchTextFieldOnChange = (event) => {
    const { value } = event.target;

    // filter historieList
    let currentAuftraegeState = [...auftraegeState];
    currentAuftraegeState = currentAuftraegeState.filter((item) => {
      const smallLetterValue = value.toLowerCase().replace(/\s+/g, '');
      const smallLetterAuftragsnummer = item.auftragsnummer.toLowerCase().replace(/\s+/g, '');
      let result = true;
      smallLetterValue.split('').forEach((char) => {
        const charOverrepeated = getNumberOfCharInString(smallLetterValue, char) > getNumberOfCharInString(smallLetterAuftragsnummer, char);
        if (charOverrepeated) {
          result = false;
        }
      });
      return result;
    });

    setDisplayAuftraegeState(currentAuftraegeState);
  };

  // function ->
  const showDetailstabelleDialogOnClick = (dataset) => {
    setDetailsTabelleDialogState({ open: true, dataset: dataset });
  };

  // function ->
  const findSelectValue = (id) => {
    const dataset = auftraegeState.find((item) => item.id === id);
    return dataset.status;
  };

  // function ->
  const changeSelectValue = (event, auftragId) => {
    const { value } = event.target;

    const requestObj = {
      id: value
    };

    objGlobalState.setPageLoadingState(true);

    authAxios
      .put(`/auftrag/${auftragId}/status`, requestObj)
      .then((_) => {
        const datasetIndex = auftraegeState.findIndex((item) => item.id === auftragId);
        const currentStatusInfoState = [...auftraegeState];
        currentStatusInfoState[datasetIndex].status = event.target.value;
        setAuftraegeState(currentStatusInfoState);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const checkButtonIsEnsabled = (datasetId) => {
    const dataset = auftraegeState.find((element) => element.id === datasetId);
    return dataset.status === 3;
  };

  // function ->
  const goToTracking = (logistikunternehmen, trackingnummer) => {
    history.push(`/tracking/${logistikunternehmen}/${trackingnummer}`);
  };

  // function ->
  const fetchAuftraege = () => {
    objGlobalState.setPageLoadingState(true);

    authAxios
      .get('/auftrag/all')
      .then((response) => {
        const { data } = response;

        const newAuftraegeState = [];

        const shipmentTypes = [
          {
            name: 'PAKETSENDUNG',
            id: 1
          },
          {
            name: 'PALETTENSENDUNG',
            id: 2
          }
        ];

        data.forEach((dataObj) => {
          if (isKommissionierung && dataObj.stellplatz) return;

          const datum = generateDate(dataObj.datum).justDate;
          const versanddatum = dataObj.versanddatum ? generateDate(dataObj.versanddatum).dateAndTime : '';

          const foundShipment = shipmentTypes.find((element) => element.id === dataObj.shipmentType.id);
          const shipmentType = foundShipment ? language('KONTENT', 'TEXT', foundShipment.name, objGlobalState.lang) : '';

          const status = dataObj.status.id;

          let newDataObj = {};

          if (isAuftragsverwaltungstatus) {
            newDataObj = {
              auftragsnummer: dataObj.auftragsnummer,
              artikelNummer: dataObj.artikelNummer,
              datum: datum,
              name1: dataObj.name1,
              name2: dataObj.name2,
              name3: dataObj.name3,
              emailadresse: dataObj.email,
              telefonnummer: dataObj.telefonnummer,
              strasse: dataObj.strasse,
              plz: dataObj.plz,
              ort: dataObj.ort,
              land: dataObj.land,
              zusaetzlicheinformationen: dataObj.zusatzinformation,
              referenz: dataObj.auftragsreferenz,
              spedition: dataObj.dienstleister,
              sendungsart: shipmentType,
              stellplatzkennzeichnung: dataObj.stellplatz || '',
              anzahlDerPakete: displayArraysInTable(dataObj.anzahlPakete) || '',
              anzahlDerPaketeProPaketsendung: displayArraysInTable(dataObj.anzahlPaketeProPaket) || '',
              anzahlDerPaletten: displayArraysInTable(dataObj.anzahlPaletten) || '',
              anzahlDerPaketeProPalette: displayArraysInTable(dataObj.anzahlPaketeProPalette) || '',
              versanddatum: versanddatum,
              status: status,
              id: dataObj.id
            };
          } else if (isKommissionierung) {
            newDataObj = {
              id: dataObj.id,
              choesn: false,
              auftragsnummer: dataObj.auftragsnummer,
              artikelNummer: dataObj.artikelNummer,
              kunde: dataObj.name1,
              spedition: dataObj.dienstleister,
              datum: datum,
              sendungsart: shipmentType,
              anzahlDerPakete: displayArraysInTable([]) || '',
              anzahlDerPaletten: displayArraysInTable([]) || ''
            };
          } else if (isWarenausgangstatus) {
            newDataObj = {
              id: dataObj.id,
              lieferscheinVorhanden: dataObj.lieferscheinVorhanden,
              auftragsnummer: dataObj.auftragsnummer,
              artikelNummer: dataObj.artikelNummer,
              kunde: dataObj.name1,
              spedition: dataObj.dienstleister,
              datum: datum,
              sendungsart: shipmentType,
              anzahlDerPakete: displayArraysInTable(dataObj.anzahlPakete) || '',
              anzahlDerPalette: displayArraysInTable(dataObj.anzahlPaletten) || ''
            };
          } else if (isHome) {
            newDataObj = {
              id: dataObj.id,
              choesn: false,
              auftragsnummer: dataObj.auftragsnummer,
              artikelNummer: dataObj.artikelNummer,
              kunde: dataObj.name1,
              spedition: dataObj.dienstleister,
              datum: datum,
              sendungsart: shipmentType
            };
          }

          newAuftraegeState.unshift(newDataObj);
        });

        setAuftraegeState(newAuftraegeState);
        setDisplayAuftraegeState(newAuftraegeState);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchAuftraege();
    }

    return () => (isMounted = false);
  }, []);

  const objAuftragstabelle = {
    detailstabelleDialog: {
      detailstabelleDialogState,
      setDetailsTabelleDialogState,
      changeSelectValue,
      findSelectValue
    }
  };

  const createSelectSendungProps = (item) =>
    isKommissionierung
      ? {
          className: item.chosen ? 'table__row--active' : 'table__row--hover',
          onClick: () => chooseAuftragOnClick(item)
        }
      : {};

  const tableTitle = isAuftragsverwaltungstatus || isWarenausgangstatus ? 'STATUS' : 'AUFTRAEGE';

  const isInIframe = objGlobalState.isInIframe;
  const iconClassName = isInIframe ? 'icon__red' : 'icon__green';

  return (
    <>
      <CoDetailstabelleDialog objGlobalState={objGlobalState} objParentState={objAuftragstabelle.detailstabelleDialog} />
      <Box className="table__title">
        <Typography align="center" className="typography__singleTableTitle">
          {language('TABLE', 'HEADER', tableTitle, objGlobalState.lang)}
        </Typography>
        {isHome ? (
          ''
        ) : (
          <div className="marginTwenty">
            {
              <Button variant="contained" color="primary" onClick={() => exportTableAsCSV(stateToCSV(), 'auftrag')} className="table__titleButton">
                {language('BUTTON', 'TITEL', 'TABELLEEXPORTIEREN', objGlobalState.lang)}
              </Button>
            }
          </div>
        )}
        {isKommissionierung && (
          <TextField
            placeholder={language('FORM', 'PLACEHOLDER', 'SUCHE', objGlobalState.lang)}
            onChange={handleSearchTextFieldOnChange}
            className="coHistorie__searchTextField marginTwenty"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search className={iconClassName} color="primary" />
                </InputAdornment>
              )
            }}
          />
        )}
      </Box>
      <TableContainer
        ref={tableRef}
        component={Paper}
        variant="outlined"
        elevation={0}
        square
        className={`table__container border__noTop ${isHome ? 'maxHeight__330' : objParentState.heightFour ? 'heightFour' : objParentState.heightFive ? 'heightFive' : ''}`}
      >
        <Table size="medium" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, key) => {
                return (
                  <TableCell align="center" component="th" className="table__headerCell" key={`common_components_auftragsvewaltung_auftragstabelle_tableHeader_${key}`}>
                    <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', header, objGlobalState.lang)}</Typography>
                  </TableCell>
                );
              })}
              {isAuftragsverwaltungstatus && (
                <TableCell align="center" component="th" className="table__headerCell">
                  <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'DETAILINFORMATIONEN', objGlobalState.lang)}</Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayAuftraegeState.map((item, key) => {
              // destructuring the needed data to display
              const {
                auftragsnummer,
                datum: erstellungsdatum,
                artikelNummer,
                kunde,
                name1,
                name2,
                name3,
                emailadresse,
                telefonnummer,
                strasse,
                plz,
                ort,
                land,
                zusaetzlicheinformationen,
                referenz,
                spedition,
                versanddatum,
                sendungsart,
                stellplatzkennzeichnung,
                stellplatzfotos,
                lieferschein,
                lieferscheinVorhanden,
                anzahlDerPakete,
                anzahlDerPaketeProPaketsendung,
                anzahlDerPaletten,
                anzahlDerPaketeProPalette,
                status,
                id
              } = item;

              const detailsValue = {
                status,
                auftragsnummer,
                erstellungsdatum,
                kunde,
                name1,
                name2,
                name3,
                emailadresse,
                telefonnummer,
                strasse,
                plz,
                ort,
                land,
                zusaetzlicheinformationen,
                referenz,
                spedition,
                versanddatum,
                stellplatzkennzeichnung,
                stellplatzfotos,
                lieferschein,
                lieferscheinVorhanden,
                anzahlDerPakete,
                anzahlDerPaketeProPaketsendung,
                anzahlDerPaletten,
                anzahlDerPaketeProPalette,
                sendungsart,
                id
              };

              let values = [];

              if (isKommissionierung) {
                values = [
                  { name: 'auftragsnummer', value: auftragsnummer },
                  { name: 'artikelnummer', value: artikelNummer },
                  { name: 'kunde', value: kunde },
                  { name: 'spedition', value: spedition },
                  { name: 'datum', value: erstellungsdatum },
                  { name: 'sendungsart', value: sendungsart },
                  { name: 'anzahlDerPakete', value: anzahlDerPakete },
                  { name: 'anzahlDerPalette', value: anzahlDerPaletten }
                ];
              } else if (isAuftragsverwaltungstatus) {
                values = [
                  { name: 'status', value: status },
                  { name: 'tracking', value: null },
                  { name: 'auftragsnummer', value: auftragsnummer },
                  { name: 'artikelnummer', value: artikelNummer },
                  { name: 'erstellungsdatum', value: erstellungsdatum }
                ];
              } else if (isWarenausgangstatus) {
                values = [
                  { name: 'lieferscheinVorhanden', value: lieferscheinVorhanden },
                  { name: 'auftragsnummer', value: auftragsnummer },
                  { name: 'kunde', value: kunde },
                  { name: 'spdition', value: spedition },
                  { name: 'datum', value: erstellungsdatum },
                  { name: 'sendungsart', value: sendungsart },
                  { name: 'anzahlDerPakete', value: anzahlDerPakete },
                  { name: 'anzahlDerPalette', value: anzahlDerPaletten }
                ];
              } else if (isHome) {
                values = [
                  { name: 'auftragsnummer', value: auftragsnummer },
                  { name: 'artikelnummer', value: artikelNummer },
                  { name: 'kunde', value: kunde },
                  { name: 'spedition', value: spedition },
                  { name: 'datum', value: erstellungsdatum },
                  { name: 'sendungsart', value: sendungsart }
                ];
              }

              if (!hasLagerAccess) {
                removeFromArray(values, 'artikelnummer');
              }

              return (
                <TableRow key={`common_components_auftragsvewaltung_auftragstabelle_tableRow_${key}`} {...createSelectSendungProps(item)}>
                  {values.map((value, _key) => {
                    if (value.name === 'status') {
                      const selectClassNameForColors = status === 1 ? 'red' : status === 2 ? 'orange' : status === 3 ? 'green' : '';
                      return (
                        <TableCell align="center" key={`common_components_auftragsvewaltung_auftragstabelle_tableCell_${key}_${_key}`}>
                          <FormControl variant="standard">
                            <Select value={findSelectValue(id)} onChange={(event) => changeSelectValue(event, id)} className={`statusSelect statusSelect--${selectClassNameForColors}`}>
                              <MenuItem value={1}>{language('FORM', 'LABEL', 'WARTEND', objGlobalState.lang)}</MenuItem>
                              <MenuItem value={2}>{language('FORM', 'LABEL', 'INBEARBEITUNG', objGlobalState.lang)}</MenuItem>
                              <MenuItem value={3}>{language('FORM', 'LABEL', 'VERSENDET', objGlobalState.lang)}</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                      );
                    } else if (value.name === 'tracking') {
                      const isButtonEnabled = checkButtonIsEnsabled(id);
                      return (
                        <TableCell align="center" key={`common_components_auftragsvewaltung_auftragstabelle_tableCell_${key}_${_key}`}>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => goToTracking('hellmann', '00340370836106176669')}
                            className={isButtonEnabled ? 'button__small' : ''}
                            disabled={!isButtonEnabled}
                          >
                            {language('BUTTON', 'TITEL', 'TRACKING', objGlobalState.lang)}
                          </Button>
                        </TableCell>
                      );
                    } else if (value.name === 'lieferscheinVorhanden') {
                      return (
                        <TableCell key={`warenausgagn_status_tableCell_${key}_${_key}`} align="center">
                          {value.value ? <CheckCircleOutline className="icon__true" /> : <CancelOutlined className="icon__false" />}
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell align="center" key={`common_components_auftragsvewaltung_auftragstabelle_tableCell_${key}_${_key}`}>
                          <Typography className="typography__singleTableRowContent">{displayArraysInTable(value.value)}</Typography>
                        </TableCell>
                      );
                    }
                  })}
                  {isAuftragsverwaltungstatus && (
                    <TableCell align="center" key={`common_components_auftragsvewaltung_auftragstabelle_tableCell_details_${key}}`}>
                      <Button color="primary" variant="outlined" onClick={() => showDetailstabelleDialogOnClick(detailsValue)} className="button__small">
                        {language('BUTTON', 'TITEL', 'DETAILSANSEHEN', objGlobalState.lang)}
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CoAuftragstabelle;
