import React, { useEffect, useState } from 'react';
import language from '../Common/language/language';

// import -> material ui -> core, icons
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Select, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

// import -> components
import CoKontoSettings from './Append/CoKontoSetting';
import CoSpracheSetting from './Append/CoSpracheSetting';
import CoFormatSetting from './Append/CoFormatSetting';
import { createAxiosInstance } from '../Common/helperFunctions/axios';

// component -> React-root
const CoEinstellungen = ({ objGlobalState }) => {
  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  //  function ->
  const closeEinstellungenDialog = () => {
    objGlobalState.setEinstellungenDialogState(false);
  };

  const [backendVersionState, setBackendVersionState] = useState('');

  // function ->
  const getBackendVersionFromAPI = () => {
    authAxios
      .get('/application/version')
      .then((response) => {
        setBackendVersionState(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    getBackendVersionFromAPI();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Dialog fullWidth maxWidth="xl" open={objGlobalState.einstellungenDialogState} onClose={closeEinstellungenDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Typography align="left" className="typography__dialogTitle">
              {language('DIALOG', 'TITEL', 'EINSTELLUNGEN', objGlobalState.lang)}
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <IconButton className="button__iconButton--close" onClick={closeEinstellungenDialog}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className="dialog__content">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CoKontoSettings objGlobalState={objGlobalState} />
          </Grid>
          <Grid item xs={12}>
            <CoSpracheSetting objGlobalState={objGlobalState} />
          </Grid>
          <Grid item xs={12}>
            <CoFormatSetting objGlobalState={objGlobalState} />
          </Grid>
          <Grid item xs={12}>
            <Typography className="coEinstellungen__versionText">Backend-Version : {backendVersionState}</Typography>
            <Typography className="coEinstellungen__versionText">Frontend-Version : {process.env.REACT_APP_VERSION}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CoEinstellungen;
