import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import LPF from './CoFooter.module.scss';

const CoFooter = () => {
  const history = useHistory();
  const goToPage = (pathname) => {
    history.push(pathname);
    window.scrollTo(0, 0);
  };
  return (
    <footer>
      <div className={LPF.footer__container}>
        <div className={LPF.left}>
          <div className={LPF.up}>
            <div className={LPF.logo}>
              <Link to="/">
                <img src="../logo_green.png" alt="logo" width="160" />
              </Link>
            </div>
          </div>
          <div className={LPF.bottom}>
            <Link onClick={goToPage.bind(null, '/impressum')} to="/impressum">
              Impressum
            </Link>
            <Link onClick={goToPage.bind(null, '/datenschutzerklaerung')} to="/datenschutzerklaerung">
              Datenschutzerklärung
            </Link>
            <Link onClick={goToPage.bind(null, '/agb')} to="/agb">
              AGB
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default CoFooter;
