import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import language from '../Common/language/language';
import { mouseScrollHorizontally } from '../Common/helperFunctions/mouseScrollHorizontally';
import { createAxiosInstance, getAndCostumFiles } from '../Common/helperFunctions/axios';
import { useHistory } from 'react-router';

// import -> services
import ServiceBreadcrumbs from '../Common/services/Breadcrumbs/ServiceBreadcrumbs';

// import -> material ui -> core
import { Box, Button, Container, Grid, ListItem, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';

// import -> components
import CoShowFiles from '../Common/Components/CoShowFiles';

// component -> React-root
const CoLagerverwaltungartikeluebersicht = ({ objGlobalState }) => {
  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const history = useHistory();

  const [stellplaetzeState, setStellplaetzeState] = useState([]);
  const [showFilesDialogState, setShowFilesDialogState] = useState({ open: false, title: '', files: [] });

  const tableRef = useRef();
  const tableHeaders = ['STELLPLATZKENNZEICHNUNG', 'STELLPLATZFOTOS', 'LIEFERSCHEIN'];

  // function ->
  const goToWarenausgangkommissionierungOnClick = () => {
    history.push('/warenausgang_kommissionierung');
  };

  // function ->
  const showFilesDialogOnClick = (files, title) => {
    if (!files.length) {
      setShowFilesDialogState({ open: true, title: title, files: [] });
    }

    const filesArray = [];

    files.forEach((file) => {
      getAndCostumFiles({ file, filesArray, authAxios }).then(() => {
        setShowFilesDialogState({ open: true, title: title, files: filesArray });
      });
    });
  };

  // function ->
  const fetchStellplaetzeFromApi = () => {
    objGlobalState.setPageLoadingState(true);

    authAxios
      .get('/lagerverwaltung/uebersicht')
      .then((response) => {
        const stellplaetze = response.data;
        setStellplaetzeState(stellplaetze);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchStellplaetzeFromApi();
    }

    return () => (isMounted = false);
  }, []);

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, []);

  const objArtikeluebersicht = {
    showFiles: {
      showFilesDialogState,
      setShowFilesDialogState
    }
  };

  // array -> create breadcrumbs
  const arrBreadcrumbs = [
    { url: '/', title: language('SEITE', 'TITEL', 'HOME', objGlobalState.lang) },
    { url: '/lagerverwaltung_artikeluebersicht', title: language('SEITE', 'TITEL', 'LAGERVERWALTUNGARTIKELUEBERSICHT', objGlobalState.lang) }
  ];

  return (
    <>
      {showFilesDialogState.open && <CoShowFiles objGlobalState={objGlobalState} objParentState={objArtikeluebersicht.showFiles} />}
      <Container component="main" maxWidth="xl" className="container__container">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ServiceBreadcrumbs arrBreadcrumbs={arrBreadcrumbs} />
          </Grid>
          <Grid item xs={12}>
            <ListItem className="container__listItem">
              <ListItemIcon>
                <img src={`../assets/images/icons/icon_artikeluebersicht.png`} alt="" className="images__headerIcon" />
              </ListItemIcon>
              <ListItemText>
                <Typography className="typography__headerTitle">{language('SEITE', 'TITEL', 'LAGERVERWALTUNGARTIKELUEBERSICHT', objGlobalState.lang)}</Typography>
              </ListItemText>
            </ListItem>
          </Grid>
          <Grid item xs={12} className="marginThirty">
            <Box className="table__title">
              <Typography align="center" className="typography__singleTableTitle">
                {language('TABLE', 'HEADER', 'SENDUNGEN', objGlobalState.lang)}
              </Typography>
              <Button onClick={goToWarenausgangkommissionierungOnClick} className="table__titleButton" variant="contained" color="primary">
                {language('BUTTON', 'TITEL', 'ZURKOMMISSIONIERUNG', objGlobalState.lang)}
              </Button>
            </Box>

            <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className="table__container border__noTop">
              <Table size="medium" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((header, key) => {
                      return (
                        <TableCell align="center" component="th" className="table__headerCell" key={`lagerverwaltung_artikeluebersicht_tableHeaders_${key}`}>
                          <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', header, objGlobalState.lang)}</Typography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stellplaetzeState.map((item, key) => {
                    const { stellplatz, fotos, lieferscheine } = item;

                    return (
                      <TableRow key={`lagerverwaltung_artikeluebersicht_tableRow_${key}`}>
                        <TableCell align="center">
                          <Typography className="typography__singleTableRowContent">{stellplatz}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => showFilesDialogOnClick(fotos, 'STELLPLATZFOTOS')}
                            className={fotos.length ? 'button__small' : ''}
                            variant="outlined"
                            color="primary"
                            disabled={!fotos.length}
                          >
                            {language('BUTTON', 'TITEL', 'FOTOSANSEHEN', objGlobalState.lang)}
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => showFilesDialogOnClick(lieferscheine, 'LIEFERSCHEIN')}
                            className={lieferscheine.length ? 'button__small' : ''}
                            disabled={!lieferscheine.length}
                            variant="outlined"
                            color="primary"
                          >
                            {language('BUTTON', 'TITEL', 'LIEFERSCHEINANSEHEN', objGlobalState.lang)}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CoLagerverwaltungartikeluebersicht;
