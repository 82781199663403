import React, { useState } from 'react';
import language from '../../Common/language/language';

// import -> material ui -> core & icons
import { Typography, Button, Dialog, DialogContent, IconButton, Grid, DialogTitle } from '@material-ui/core';
import { Close } from '@material-ui/icons';

// import -> components
import CoBerechtigungErstellenDialog from './CoBerechtigungErstellenDialog';
import CoBerechtigungtabelle from './CoBerechtigungtabelle';

// component -> React root
const CoBerechtigungenDialog = ({ objGlobalState, objParentState }) => {
  const { file: berechtigungdatei, open: dialogOpen } = objParentState.berechtigungenDialogState;

  const [berechtigungErstellenDialogState, setBerechtigungErstellenDialogState] = useState(false);
  const [berechtigungenState, setBerechtigungenState] = useState([]);
  const [forceRerenderState, setForceRerenderState] = useState(false);

  // function ->
  const openBerechtigungErstellenDialogOnClick = () => {
    setBerechtigungErstellenDialogState(true);
  };

  //  function ->
  const closeBerechtigungDialog = () => {
    objParentState.setBerechtigungenDialogState((pre) => {
      return {
        ...pre,
        open: false
      };
    });
  };

  // function ->
  const forceRerender = () => {
    setForceRerenderState((pre) => !pre);
  };

  const objBerechtigungenDialogStateAndFunctions = {
    berechtigungdatei,
    berechtigungenState,
    setBerechtigungenState,
    berechtigungErstellenDialogState,
    setBerechtigungErstellenDialogState,
    forceRerender,
    forceRerenderState
  };

  return (
    <>
      {berechtigungErstellenDialogState && <CoBerechtigungErstellenDialog objGlobalState={objGlobalState} objParentState={objBerechtigungenDialogStateAndFunctions} />}
      <Dialog fullWidth maxWidth="md" open={dialogOpen} onClose={closeBerechtigungDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
            <Grid item xs={6}>
              <Typography align="left" className="typography__dialogTitle">
                {language('DIALOG', 'TITEL', 'BERECHTIGUNGEN', objGlobalState.lang)}
              </Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <IconButton className="button__iconButton--close" onClick={closeBerechtigungDialog}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="dialog__content">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography className="typography">{berechtigungdatei.preview_name}</Typography>
            </Grid>
            <Grid item xs={12} md={6} container justify="flex-end">
              <Button onClick={openBerechtigungErstellenDialogOnClick} className="button__middle" variant="contained" color="primary">
                {language('BUTTON', 'TITEL', 'ERSTELLEN', objGlobalState.lang)}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <CoBerechtigungtabelle objGlobalState={objGlobalState} objParentState={objBerechtigungenDialogStateAndFunctions} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoBerechtigungenDialog;
