import React, { useState } from 'react';
import language from '../Common/language/language';
import { createAxiosInstance } from '../Common/helperFunctions/axios';
import { useSnackbar } from 'notistack';

// import -> services
import ServiceBreadcrumbs from '../Common/services/Breadcrumbs/ServiceBreadcrumbs';

// import -> material ui -> core & icons
import { Button, Container, Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { VerticalAlignBottom, VerticalAlignTop } from '@material-ui/icons';

// import -> components
import CoArtikelForm from './Append/CoArtikelForm';

const artikelListeFileType = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];

// component -> React-root
const CoArtikelverwaltungartikel = ({ objGlobalState }) => {
  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const { enqueueSnackbar } = useSnackbar();

  const [fileInputState, setFileInputState] = useState('');
  const [csvArtikellisteState, setCsvArtikellisteState] = useState({});

  const { fromAU: isEdit, artikel: artikelToEdit } = objGlobalState.oPBetweenComponents.artikelverwaltungArtikeluebersicht_artikelverwaltungArtikel;

  const {
    pzn,
    ean,
    herstellerNummer,
    hersteller,
    herstellerArtikelnummer,
    bezeichnung,
    bezeichnung2,
    artikelnummer,
    mengeneinheit,
    menge,
    darreichungsform,
    packmassLaenge,
    packmassBreite,
    packmassHoehe,
    packmassEinheit,
    gewichtEinheit,
    gewicht,
    gebindeEinkauf,
    gebindeVerkauf,
    arzneimittel,
    apothekenPflichtig,
    verschreibungspflichtig,
    kuehlung,
    ambient,
    lagertemperatorMin,
    lagertemperatorMax,
    zytostatika,
    chargenKennzeichnung,
    mwst,
    vertriebsstatus,
    verkehrsfaehig,
    verwaltung,
    ecommerce,
    feuchtigkeitsEmpfindlich,
    lichtEmpfindlich,
    lagerEmpfindlich,
    temperaturEmpfindlich,
    transportEmpfindlich,
    warengruppe,
    grosshandelspreis,
    herstellerAbgabepreisFap,
    apothekenEinkaufspreis,
    apothekenVerkaufspreis,
    einkaufspreis,
    einkaufsrabatt,
    verkaufspreis,
    verkaufsabatt
  } = artikelToEdit;

  const initialTextFieldsState = {
    pzn: pzn ?? '',
    ean: ean ?? '',
    herstellerNummer: herstellerNummer ?? '',
    hersteller: hersteller ?? '',
    herstellerArtikelnummer: herstellerArtikelnummer ?? '',
    bezeichnung: bezeichnung ?? '',
    bezeichnung2: bezeichnung2 ?? '',
    artikelnummer: artikelnummer ?? '',
    mengeneinheit: mengeneinheit ?? 'LITER',
    menge: menge ?? 0,
    darreichungsform: darreichungsform ?? '',
    packmassLaenge: packmassLaenge ?? 0,
    packmassBreite: packmassBreite ?? 0,
    packmassHoehe: packmassHoehe ?? 0,
    packmassEinheit: packmassEinheit ?? 'CM',
    gewichtEinheit: gewichtEinheit ?? 'GRAMM',
    gewicht: gewicht ?? 0,
    gebindeEinkauf: gebindeEinkauf ?? 0,
    gebindeVerkauf: gebindeVerkauf ?? 0,
    arzneimittel: arzneimittel ?? false,
    apothekenPflichtig: apothekenPflichtig ?? false,
    verschreibungspflichtig: verschreibungspflichtig ?? false,
    kuehlung: kuehlung ?? 'KEINE_ANGABE',
    ambient: ambient ?? 'KEINE_ANGABE',
    lagertemperatur: '',
    lagertemperatorMin: lagertemperatorMin ?? 0,
    lagertemperatorMax: lagertemperatorMax ?? 0,
    zytostatika: zytostatika ?? 'KEINE_ANGABE',
    chargenKennzeichnung: chargenKennzeichnung ?? false,
    mwst: mwst ?? 0,
    vertriebsstatus: vertriebsstatus ?? 'KEINE_ANGABE',
    verkehrsfaehig: verkehrsfaehig ?? 'KEINE_ANGABE',
    verwaltung: verwaltung ?? false,
    ecommerce: ecommerce ?? false,
    feuchtigkeitsEmpfindlich: feuchtigkeitsEmpfindlich ?? 'KEINE_ANGABE',
    lichtEmpfindlich: lichtEmpfindlich ?? 'KEINE_ANGABE',
    lagerEmpfindlich: lagerEmpfindlich ?? 'KEINE_ANGABE',
    temperaturEmpfindlich: temperaturEmpfindlich ?? 'KEINE_ANGABE',
    transportEmpfindlich: transportEmpfindlich ?? 'KEINE_ANGABE',
    warengruppe: warengruppe ?? '',
    grosshandelspreis: grosshandelspreis ?? 0,
    herstellerAbgabepreisFap: herstellerAbgabepreisFap ?? 0,
    apothekenEinkaufspreis: apothekenEinkaufspreis ?? 0,
    apothekenVerkaufspreis: apothekenVerkaufspreis ?? 0,
    einkaufspreis: einkaufspreis ?? 0,
    einkaufsrabatt: einkaufsrabatt ?? 0,
    verkaufspreis: verkaufspreis ?? 0,
    verkaufsabatt: verkaufsabatt ?? 0
  };

  const [textFieldsState, setTextFieldsState] = useState(initialTextFieldsState);

  // function ->
  const endArtikelEditing = () => {
    objGlobalState.setOPBetweenComponents((pre) => ({
      ...pre,
      artikelverwaltungArtikeluebersicht_artikelverwaltungArtikel: { fromAU: false, artikel: {} }
    }));
    setTextFieldsState({
      pzn: '',
      ean: '',
      herstellerNummer: '',
      hersteller: '',
      herstellerArtikelnummer: '',
      bezeichnung: '',
      bezeichnung2: '',
      artikelnummer: '',
      mengeneinheit: 'LITER',
      menge: 0,
      darreichungsform: '',
      packmassLaenge: 0,
      packmassBreite: 0,
      packmassHoehe: 0,
      packmassEinheit: 'CM',
      gewichtEinheit: 'GRAMM',
      gewicht: 0,
      gebindeEinkauf: 0,
      gebindeVerkauf: 0,
      arzneimittel: false,
      apothekenPflichtig: false,
      verschreibungspflichtig: false,
      kuehlung: 'KEINE_ANGABE',
      ambient: 'KEINE_ANGABE',
      lagertemperatur: '',
      lagertemperatorMin: 0,
      lagertemperatorMax: 0,
      zytostatika: 'KEINE_ANGABE',
      chargenKennzeichnung: false,
      mwst: 0,
      vertriebsstatus: 'KEINE_ANGABE',
      verkehrsfaehig: 'KEINE_ANGABE',
      verwaltung: false,
      ecommerce: false,
      feuchtigkeitsEmpfindlich: 'KEINE_ANGABE',
      lichtEmpfindlich: 'KEINE_ANGABE',
      lagerEmpfindlich: 'KEINE_ANGABE',
      temperaturEmpfindlich: 'KEINE_ANGABE',
      transportEmpfindlich: 'KEINE_ANGABE',
      warengruppe: '',
      grosshandelspreis: 0,
      herstellerAbgabepreisFap: 0,
      apothekenEinkaufspreis: 0,
      apothekenVerkaufspreis: 0,
      einkaufspreis: 0,
      einkaufsrabatt: 0,
      verkaufspreis: 0,
      verkaufsabatt: 0
    });
  };

  // function ->
  const handleCsvUploadOnChange = (event) => {
    const datei = event.target.files[0];
    const inputValue = event.target.value;
    setFileInputState(inputValue);
    if (!datei) return;
    const dateiType = datei.type;
    if (artikelListeFileType.includes(dateiType)) {
      setCsvArtikellisteState(datei);
    }
  };

  // function ->
  const csvArtikellisteSpeichern = () => {
    const formData = new FormData();
    formData.append('file', csvArtikellisteState);
    objGlobalState.setPageLoadingState(true);

    authAxios
      .post('/artikel/upload', formData, { headers: { 'Content-Type': 'multipart/form-data;' } })
      .then((response) => {
        handleArtikelUploadSuccess();
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const handleArtikelUploadSuccess = () => {
    setCsvArtikellisteState({});
    setFileInputState('');
  };

  // function ->
  const downloadSampleAfterRequest = (file) => {
    const blob = new Blob([file]);
    const url = URL.createObjectURL(blob);

    const name = 'traceport_artikel_muster';
    const type = 'xlsx';
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${name}.${type}`);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
  };

  // function ->
  const getSampleFromAPI = () => {
    authAxios
      .get('/artikel/muster-datei', {
        responseType: 'blob'
      })
      .then((response) => {
        const file = response.data;
        downloadSampleAfterRequest(file);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
      });
  };

  const objArtikel = {
    artikelForm: {
      artikelToEdit,
      endArtikelEditing,
      isEdit,
      textFieldsState,
      setTextFieldsState
    }
  };

  // array -> create breadcrumbs
  const arrBreadcrumbs = [
    { url: '/', title: language('SEITE', 'TITEL', 'HOME', objGlobalState.lang) },
    { url: '/artikelverwaltung_artikel', title: language('SEITE', 'TITEL', 'ARTIKELVERWALTUNGARTIKEL', objGlobalState.lang) }
  ];

  return (
    <>
      <Container component="main" maxWidth="xl" className="container__container">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ServiceBreadcrumbs arrBreadcrumbs={arrBreadcrumbs} />
          </Grid>
          <Grid item xs={12}>
            <ListItem className="container__listItem">
              <ListItemIcon>
                <img src={`../assets/images/icons/icon_artikelanlegen.png`} alt="" className="images__headerIcon" />
              </ListItemIcon>
              <ListItemText>
                <Typography className="typography__headerTitle">{language('SEITE', 'TITEL', 'ARTIKELVERWALTUNGARTIKEL', objGlobalState.lang)}</Typography>
              </ListItemText>
            </ListItem>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="marginThirty coArtikelverwaltungartikel__contentContainer">
          {!isEdit ? (
            <Grid item xs={12} container spacing={3} md={5} lg={3} className="coArtikelverwaltungartikel__buttonContainer">
              <Grid container spacing={3} item xs={12} sm={6} md={12}>
                <Grid item xs={12}>
                  <input
                    type="file"
                    accept={artikelListeFileType.join(',')}
                    style={{ display: 'none' }}
                    id="artikelverwaltung_artikel_artikelliste"
                    value={fileInputState}
                    onChange={handleCsvUploadOnChange}
                  />
                  <label htmlFor="artikelverwaltung_artikel_artikelliste">
                    <Button startIcon={<VerticalAlignTop />} component="span" variant="contained" className="button__middle noWrap" color="primary">
                      {language('BUTTON', 'TITEL', 'ARTIKELLISTEHOCHLADEN')}
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={12}>
                  {csvArtikellisteState.name && (
                    <Grid item xs={12}>
                      <Grid item container spacing={1} xs={12}>
                        <Grid item xs={12}>
                          <Typography className="typography__breakWord">{csvArtikellisteState.name}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Button className="button__small" color="primary" variant="contained" onClick={csvArtikellisteSpeichern}>
                            {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <Button onClick={getSampleFromAPI} startIcon={<VerticalAlignBottom />} component="span" variant="contained" className="noWrap button__middle" color="primary">
                  {language('BUTTON', 'TITEL', 'MUSTERHERUNTERLADEN', objGlobalState.lang)}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12} container spacing={3} md={5} lg={3} className="coArtikelverwaltungartikel__buttonContainer">
              <Grid item xs={12} sm={6} md={12}>
                <Typography className="typography">{language('KONTENT', 'TEXT', 'ARTIKELPLATZHALTERBEARBEITEN', objGlobalState.lang).replace('_platzhalter_', artikelToEdit.pzn ?? '')}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <Button className="button__middle--redFilled" onClick={endArtikelEditing}>
                  {language('BUTTON', 'TITEL', 'BEARBEITUNGABBRECHEN', objGlobalState.lang)}
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={7} lg={9}>
            <CoArtikelForm objGlobalState={objGlobalState} objParentState={objArtikel.artikelForm} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CoArtikelverwaltungartikel;
