import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import language from '../../Common/language/language';
import { mouseScrollHorizontally } from '../../Common/helperFunctions/mouseScrollHorizontally';
import { generateDate } from '../../Common/helperFunctions/date';
import { isObjectEmpty } from '../../Common/helperFunctions/objects';

// import -> material ui -> core
import { Button, Dialog, DialogContent, DialogTitle, Grid, TableRow, TableCell, Typography, ButtonGroup, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

// component -> React-root
const CoWarenannahme = ({ objGlobalState, objHistorieStateAndFunctions }) => {
  const { open, data } = objHistorieStateAndFunctions.vorgaengeDialogState.warenannahme;

  const [dialogIsRenderedState, setDialogIsRenderedState] = useState(false);
  const [lieferungState, setLieferungState] = useState({});
  const [statusState, setStatusState] = useState({});

  const lieferungTable = {
    tableId: 'warenannahme_lieferung',
    ref: useRef(),
    title: 'LIEFERUNG',
    data: lieferungState,
    headers: [
      'STATUS',
      'LIEFERANT',
      'SPEDITION',
      'DATUM',
      'SENDUNGSART',
      'ANZAHLDERPAKETE',
      'ANZAHLDERPAKETEPROPAKETSENDUNG',
      'ANZAHLDERPALETTEN',
      'ANZAHLDERPAKETEPROPALETTE',
      'SENDUNGBESCHAEDIGT',
      'BESCHREIBUNGDERBESCHAEDIGUNG',
      'EMPFANGSBESTAETIGUNG',
      'ERSTELLUNGSDATUM',
      'DATEIEN'
    ],
    exeptions: [
      {
        name: 'DATEIEN',
        handler: (key, header, data, lastTableCells) => {
          return (
            <TableRow key={key}>
              <TableCell component="th" scope="row" className={`table__rowTitle coHistorie__dialogTableRowTitle ${lastTableCells ? 'coHistorie__lastTableCell' : ''}`}>
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', header, objGlobalState.lang)}</Typography>
              </TableCell>
              <TableCell align="left" className={lastTableCells ? 'coHistorie__lastTableCell' : ''}>
                <ButtonGroup orientation="vertical">
                  <Button
                    onClick={() => objHistorieStateAndFunctions.showFilesDialogOnClick('BESCHAEDIGUNGSFOTOS', data.beschaedigungsfotos)}
                    className="button__small"
                    variant="outlined"
                    color="primary"
                  >
                    {language('BUTTON', 'TITEL', 'BESCHAEDIGUNGSFOTOS', objGlobalState.lang)}
                  </Button>
                  <Button onClick={() => objHistorieStateAndFunctions.showFilesDialogOnClick('LIEFERSCHEINE', data.lieferscheine)} className="button__small" variant="outlined" color="primary">
                    {language('BUTTON', 'TITEL', 'LIEFERSCHEINE', objGlobalState.lang)}
                  </Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          );
        }
      }
    ]
  };

  const statusTable = {
    tableId: 'warenannahme_status',
    ref: useRef(),
    title: 'STATUS',
    data: statusState,
    headers: ['STELLPLATZKENNZEICHNUNG', 'STELLPLATZFOTOS', 'DATUM'],
    exeptions: [
      {
        name: 'STELLPLATZFOTOS',
        handler: (key, header, data, lastTableCells) => {
          return objHistorieStateAndFunctions.handleExeptionWithOneSetOfFiles(key, header, data, 'STELLPLATZFOTOS', lastTableCells);
        }
      }
    ]
  };

  // function ->
  const handleDialogOnRendered = () => {
    setDialogIsRenderedState(true);
  };

  // function ->
  const mapParentDataToWarenannahmeData = () => {
    const { lieferung: parentLieferung, warenannahme: parentWarenannahme } = data;

    const statusTypes = [
      { name: 'WARTEND', id: 1 },
      { name: 'INBEARBEITUNG', id: 2 },
      { name: 'EINGELAGERT', id: 3 }
    ];

    const shipmentTypes = [
      {
        name: 'PAKETSENDUNG',
        id: 1
      },
      {
        name: 'PALETTENSENDUNG',
        id: 2
      }
    ];

    const confirmations = [
      {
        name: 'ELEKTRONISCH',
        id: 1
      },
      {
        name: 'UEBERBEGLEITPAPIERE',
        id: 2
      }
    ];
    // 07.12.1110 is the date we get when the user don't enter a date value; we then send a default date value
    const date = generateDate(parentLieferung?.deliveryDate).justDate === '07.12.1110' ? '' : generateDate(parentLieferung?.deliveryDate).justDate;
    const packagesPerUnit = parentLieferung?.packagesPerUnit.map((element) => element.quantity);

    const foundShipment = shipmentTypes.find((element) => element.id === parentLieferung?.shipmentType.id);
    const shipmentType = foundShipment ? language('KONTENT', 'TEXT', foundShipment.name, objGlobalState.lang) : '';

    const foundConfirmation = confirmations.find((element) => element.id === parentLieferung?.confirmation.id);
    const confirmation = foundConfirmation ? language('KONTENT', 'TEXT', foundConfirmation.name, objGlobalState.lang) : '';

    const status = statusTypes.find((type) => {
      return type.id === parentLieferung.currentStatus?.id;
    });

    const warenannahmeLieferung = {
      status: status ? language('KONTENT', 'TEXT', status.name, objGlobalState.lang) : '',
      lieferant: parentLieferung?.supplier ?? '',
      spedition: parentLieferung?.shippingCompany ?? '',
      datum: date,
      sendungsart: shipmentType,
      anzahlderpakete: foundShipment?.id === 1 ? parentLieferung?.quantity : '',
      anzahlderpaketepropaketsendung: foundShipment?.id === 1 ? packagesPerUnit : [],
      anzahlderpaletten: foundShipment?.id === 2 ? parentLieferung?.quantity : '',
      anzahlderpaketepropalette: foundShipment?.id === 2 ? packagesPerUnit : [],
      sendungbeschaedigt: parentLieferung?.damage ? language('KONTENT', 'TEXT', 'JA', objGlobalState.lang) : '',
      beschreibungderbeschaedigung: parentLieferung?.damageDescription ?? '',
      empfangsbestaetigung: confirmation,
      erstellungsdatum: generateDate(parentLieferung?.dateCreated).justDate,
      id: parentLieferung?.id,
      dateien: { beschaedigungsfotos: data.beschaedigungsFotos ?? [], lieferscheine: data.warenannahme?.lieferscheine?.previewDTOList ?? [] }
    };

    const warenannahmeStatus = {
      stellplatzkennzeichnung: parentWarenannahme?.stellplatz?.pitch ?? '',
      stellplatzfotos: parentWarenannahme?.stellplatz?.statusPhotos ?? [],
      datum: parentWarenannahme?.stellplatz?.date ? generateDate(parentWarenannahme?.stellplatz.date).justDate : ''
    };

    setLieferungState(parentLieferung ? warenannahmeLieferung : {});
    setStatusState(parentWarenannahme.stellplatz ? warenannahmeStatus : {});
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    mapParentDataToWarenannahmeData();

    return () => {
      isMounted = false;
    };
  }, []);

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const lieferungTableElement = lieferungTable.ref.current;
      const statusTableElement = statusTable.ref.current;
      mouseScrollHorizontally(lieferungTableElement);
      mouseScrollHorizontally(statusTableElement);
    }

    return () => (isMounted = false);
  }, [dialogIsRenderedState]);

  return (
    <Dialog
      fullWidth
      onClose={() => objHistorieStateAndFunctions.closeVorgaengeDialogOnClose(objHistorieStateAndFunctions.VORGAENGE.WARENANNAHME)}
      maxWidth="md"
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onRendered={handleDialogOnRendered}
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Typography align="left" className="typography__dialogTitle">
              {language('DIALOG', 'TITEL', 'WARENANNAHME', objGlobalState.lang)}
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <IconButton className="button__iconButton--close" onClick={() => objHistorieStateAndFunctions.closeVorgaengeDialogOnClose(objHistorieStateAndFunctions.VORGAENGE.WARENANNAHME)}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className="dialog__content">
        <Grid container spacing={1}>
          {!isObjectEmpty(lieferungState) && (
            <Grid item xs={12}>
              {objHistorieStateAndFunctions.createTable(lieferungTable)}
            </Grid>
          )}
          {!isObjectEmpty(statusState) && (
            <Grid item xs={12}>
              {objHistorieStateAndFunctions.createTable(statusTable)}
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CoWarenannahme;
