import React from 'react';
import PDFViewer from 'pdf-viewer-reactjs';
import language from '../language/language';
import isMobile from '../isMobile/isMobile';
import { FILE_TYPES } from '../types/types';

// import -> material ui -> core & icons
import { Button, Dialog, DialogContent, IconButton, DialogTitle, Grid } from '@material-ui/core';
import { Close } from '@material-ui/icons';

// component -> React-root
const CoDisplayFiles = ({ objGlobalState, objParentState }) => {
  //function ->
  const closeDisplayDialogOnClose = () => {
    objParentState.setDisplayDialogState(false);
  };

  // function ->
  const downloadFile = (file) => {
    const url = file.dataUri;
    const name = file.name;
    const type = file.type;
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${name}.${type}`);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
  };

  const printData = (file) => {
    if (isMobile.any()) {
      window.open(file.dataUri, '_blank');

      return;
    }

    const printWindow = window.open('', '', 'height=800,width=900');
    const printWindowTitle = file.name;
    const div = document.createElement('div');

    if (file.type === FILE_TYPES.PDF) {
      const embed = document.createElement('embed');
      embed.type = 'application/pdf';
      embed.src = file.dataUri;
      embed.style.width = '100%';
      embed.style.height = '100%';
      div.appendChild(embed);
    } else if (file.type === FILE_TYPES.JPEG) {
      const image = new Image();
      image.src = file.dataUri;
      image.style.width = '100%';
      div.className = 'printed-div';
      div.appendChild(image);
      image.addEventListener('load', (event) => {
        printWindow.print();
        printWindow.close();
      });
    } else {
      return;
    }

    printWindow.document.write(
      `<html><head><style>@media all {.printed-div {display: none;}}@media print {.printed-div {display: block;}}</style><title>${printWindowTitle}</title></head><body></body></html>`
    );
    printWindow.document.body.appendChild(div);
  };

  return (
    <Dialog maxWidth="md" onClose={closeDisplayDialogOnClose} open={objParentState.displayDialogState} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" scroll="paper">
      <DialogTitle className="dialog__titleAsHeader" id="alert-dialog-title">
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Button name="herunterladen-button" onClick={() => downloadFile(objParentState.fileToDisplayState)} className="button__middle" variant="contained" color="primary">
              {language('BUTTON', 'TITEL', 'HERUNTERLADEN', objGlobalState.lang)}
            </Button>
            <Button name="drucken-button" onClick={() => printData(objParentState.fileToDisplayState)} className="button__middle" variant="contained" color="primary">
              {language('BUTTON', 'TITEL', 'DRUCKEN', objGlobalState.lang)}
            </Button>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <IconButton className="button__iconButton--close" onClick={closeDisplayDialogOnClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className="dialog__content">
        {objParentState.fileToDisplayState.type === FILE_TYPES.JPEG ? (
          <img src={objParentState.fileToDisplayState.dataUri} className="images__fullWidthPreview" alt="" />
        ) : objParentState.fileToDisplayState.type === FILE_TYPES.PDF ? (
          <PDFViewer document={{ url: objParentState.fileToDisplayState.dataUri }} scale={1} />
        ) : (
          ''
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CoDisplayFiles;
