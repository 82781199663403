import React, { useEffect, useState } from 'react';
import QrReader from 'react-qr-reader';
import language from '../language/language';

// import -> material ui -> core & icons
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';

// import -> components
import CoBarCodeScanner from './CoBarCodeScanner';

// component -> React-root
const CoMultipleScanner = ({ objGlobalState, objParentState, options }) => {
  const [scannerState, setScannerState] = useState('');
  const [scanLoadedState, setScanLoadedState] = useState({ qr: false, bar: false });
  const [scanErrorState, setScanErrorState] = useState({ qr: false, bar: false });
  const [barCodeResultState, setBarCodeResultState] = useState({ data: null, result: '' });

  // function ->
  const handleScannerRadioButtonOnChange = (event) => {
    setScannerState((pre) => {
      const newState = event.target.value;

      if (pre === 'bar' && scanLoadedState.bar) {
        setScanLoadedState((_pre) => ({ qr: _pre.qr, bar: false }));
        return newState;
      } else if (pre === 'qr' && scanLoadedState.qr) {
        setScanLoadedState((_pre) => ({ bar: _pre.bar, qr: false }));
        return newState;
      } else if (pre === '') {
        return newState;
      } else if (scanErrorState.qr || scanErrorState.bar) {
        return newState;
      } else {
        return pre;
      }
    });
  };

  // function ->
  const handleQrScanOnScan = (data) => {
    if (data) {
      objParentState.setStellplatzkennzeichnungState(data);
    }
  };

  // function ->
  const handleQrScanErrorOnError = (err) => {
    console.error(err);
    setScanErrorState((pre) => ({ bar: pre.bar, qr: true }));
  };

  // function ->
  const handleQrScanOnload = () => {
    setScanLoadedState((pre) => ({ bar: pre.bar, qr: true }));
  };

  // function ->
  const changeStellplatzkennzeichnungOnChange = (event) => {
    objParentState.setStellplatzkennzeichnungState(event.target.value);
  };

  useEffect(() => {
    if ((scannerState === 'bar' && scanLoadedState.bar) || scanErrorState.bar) {
      objGlobalState.setCanNavBarOpenState(true);
    } else if ((scannerState === 'qr' && scanLoadedState.qr) || scanErrorState.qr) {
      objGlobalState.setCanNavBarOpenState(true);
    } else if (scannerState === '') {
      objGlobalState.setCanNavBarOpenState(true);
    } else {
      objGlobalState.setCanNavBarOpenState(false);
    }
  }, [scanLoadedState, scannerState, scanErrorState]);

  useEffect(() => {
    // Use the value from Barcode scanning results.
    let isMounted = true;
    if (isMounted) {
      objParentState.setStellplatzkennzeichnungState(barCodeResultState.result);
    }

    return () => (isMounted = false);
  }, [barCodeResultState]);

  const objMultipleScannerStateAndFunctions = {
    barCodeResultState,
    setBarCodeResultState,
    scanErrorState,
    setScanErrorState,
    scanLoadedState,
    setScanLoadedState
  };

  return (
    <>
      <Grid item xs={12}>
        <FormControl className="coMultipleScanner__formControl">
          <FormLabel component="legend">
            <Typography className="typography">{language('FORM', 'LABEL', 'SCANNERART', objGlobalState.lang)}</Typography>
          </FormLabel>
          <RadioGroup className="coMultipleScanner__radioGroup" aria-label="scannerart" value={scannerState} onChange={handleScannerRadioButtonOnChange}>
            <FormControlLabel value="" control={<Radio color="primary" />} label={language('FORM', 'LABEL', 'KEINE', objGlobalState.lang)} />
            <FormControlLabel value="bar" control={<Radio color="primary" />} label={language('FORM', 'LABEL', 'BAR', objGlobalState.lang)} />
            <FormControlLabel value="qr" control={<Radio color="primary" />} label={language('FORM', 'LABEL', 'QR', objGlobalState.lang)} />
          </RadioGroup>
        </FormControl>
      </Grid>
      {scannerState === 'qr' || scannerState === 'bar' ? (
        <Grid item xs={12} sm={6} lg={4}>
          {scannerState === 'qr' ? <QrReader onLoad={handleQrScanOnload} delay={300} onError={handleQrScanErrorOnError} onScan={handleQrScanOnScan} /> : ''}
          {scannerState === 'bar' ? <CoBarCodeScanner objParentState={objMultipleScannerStateAndFunctions} /> : ''}
        </Grid>
      ) : (
        ''
      )}
      {options?.hideTextField ? (
        ''
      ) : (
        <Grid item xs={12}>
          <TextField
            fullWidth
            className="coMultipleScanner__textField"
            value={objParentState.stellplatzkennzeichnungState}
            onChange={changeStellplatzkennzeichnungOnChange}
            type="text"
            variant="outlined"
            name="stellplatzkennzeichnung"
            label={language('FORM', 'LABEL', options?.textFieldTitle ? options?.textFieldTitle : 'STELLPLATZKENNZEICHNUNG', objGlobalState.lang)}
          />
        </Grid>
      )}
    </>
  );
};

export default CoMultipleScanner;
