import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useSnackbar } from 'notistack';
import language from '../../Common/language/language';
import { createAxiosInstance } from '../../Common/helperFunctions/axios';
import { mouseScrollHorizontally } from '../../Common/helperFunctions/mouseScrollHorizontally';

// import -> material ui -> core && icons
import { Box, Tabs, Tab, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, Button, Typography, IconButton } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';

// import -> components
import CoLoeschenwarnungDialog from '../../Common/Components/CoLoeschenwarnungDialog';
import CoCreateUpdateStellplatz from './CoCreateUpdateStellplatzDialog';
import CoLagerorteTableDialog from './CoLagerorteTableDialog';
import { exportTableAsCSV } from '../../Common/helperFunctions/exportTable';
import { generateCSVString } from '../../Common/helperFunctions/generateCSVString';

// the first one is remove stellplatz from lagerort, the second one is to delete it completely
const LOESCHEN_TYPES = {
  FROM_LAGERORT: 1,
  STELLPLATZ_COMPLETE: 2
};

const STELLPLAETZE_CATEGORIES = {
  ALLE: 1,
  FREI: 2,
  BELEGT: 3
};

// component -> React-root
const CoStellplaetzeTable = ({ objGlobalState, objParentState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const isForLagerort = objParentState.isForLagerort;
  const lagerortId = isForLagerort ? objParentState.lagerortObj.id : '';
  const lagerortName = isForLagerort ? objParentState.lagerortObj.lagername : '';

  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const [createUpdateStellplatzDialogState, setCreateUpdateStellplatzDialogState] = useState({ open: false, isUpdate: false, stellplatzObj: {} });
  const [lagerorteTableDialogState, setLagerorteTableDialogState] = useState({ open: false, stellplatzObj: {} });
  const [loeschenwarnungDialogState, setLoeschenwarnungDialogState] = useState({ open: false, deletedDataLabel: '', deleteSentence: '', buttonTitle: '' });

  const [focusedDataState, setFocusedDataState] = useState({ dataId: '', loeschenType: '' });

  const [stellplaetzeCategoryState, setStellplaetzeCategoryState] = useState(STELLPLAETZE_CATEGORIES.ALLE);
  const [allStellplaetzeState, setAllStellplaetzeState] = useState([]);
  const [freiStellplaetzeState, setFreiStellplaetzeState] = useState([]);
  const [belegtStellplaetzeState, setBelegtStellplaetzeState] = useState([]);
  const [rerunEffectState, setRerenderEffectState] = useState(false);

  const tableHeaders = isForLagerort
    ? [' ', 'KENNZEICHNUNG', 'BARCODE', 'BUCHUNGSID', 'ZULAGERORTHINZUFUEGEN', 'AUSLAGERORTENTFERNEN', 'BEARBEITEN', 'LOESCHEN']
    : [' ', 'KENNZEICHNUNG', 'BARCODE', 'BUCHUNGSID', 'ZULAGERORTHINZUFUEGEN', 'BEARBEITEN', 'LOESCHEN'];
  const tableRef = useRef();

  // function ->
  const stateToCSV = () => {
    const csvRows = [];
    const headerRow = [' ', 'KENNZEICHNUNG'].map((header) => generateCSVString(language('TABLE', 'TITEL', header, objGlobalState.lang)));
    const tableValuesLabels = ['id', 'name'];
    csvRows.push(headerRow.join(';'));
    const stellplaetze = pickStellplaetzeState();
    stellplaetze.forEach((stellplatz) => {
      const valueRow = tableValuesLabels.map((key) => {
        const value = typeof stellplatz[key] === 'string' ? stellplatz[key].replace('-', ' ') : stellplatz[key];
        return generateCSVString(value);
      });
      csvRows.push(valueRow.join(';'));
    });
    return csvRows.join('\n');
  };

  // function ->
  const rerunEffect = () => {
    setRerenderEffectState((pre) => !pre);
  };

  // function ->
  const openCreateUpdateStellplatzDialog = (stellplatzObj) => {
    setCreateUpdateStellplatzDialogState({ open: true, isUpdate: true, stellplatzObj });
  };

  // function ->
  const openLagerorteTableDialog = (stellplatzObj) => {
    setLagerorteTableDialogState({ open: true, stellplatzObj });
  };

  // function ->
  const openLoeschenwarnungDialog = (dataId, loeschenType) => {
    const deletedDataLabel = language('KONTENT', 'TEXT', 'STELLPLATZ', objGlobalState.lang) + ' ' + dataId;
    const deleteSentence = loeschenType === LOESCHEN_TYPES.STELLPLATZ_COMPLETE ? null : language('KONTENT', 'TEXT', 'WIRDAUSENTFERNT', objGlobalState.lang).replace('%toReplace%', lagerortName);
    const buttonTitle = loeschenType === LOESCHEN_TYPES.STELLPLATZ_COMPLETE ? null : 'ENTFERNEN';
    setLoeschenwarnungDialogState({ open: true, deletedDataLabel, deleteSentence, buttonTitle });
    setFocusedDataState({ dataId, loeschenType });
  };

  // function ->
  const getTransporteinheitFromAPI = (stellplatzObj) => {
    authAxios
      .post('/transporteinheit/lager-buchungsid', { name: stellplatzObj.name })
      .then((response) => {
        const buchungsIdsArray = response.data;
        const buchungsIds = [];
        buchungsIdsArray.forEach((buchungsId) => {
          buchungsIds.push(buchungsId.id);
        });
        const buchungsIdsText = buchungsIds.join(', ');

        let stellplaetzeState = [];
        let setStellplaetzeState = () => '';

        if (stellplaetzeCategoryState === STELLPLAETZE_CATEGORIES.FREI) {
          stellplaetzeState = freiStellplaetzeState;
          setStellplaetzeState = setFreiStellplaetzeState;
        } else if (stellplaetzeCategoryState === STELLPLAETZE_CATEGORIES.BELEGT) {
          stellplaetzeState = belegtStellplaetzeState;
          setStellplaetzeState = setBelegtStellplaetzeState;
        } else {
          stellplaetzeState = allStellplaetzeState;
          setStellplaetzeState = setAllStellplaetzeState;
        }

        const newStellplaetzeState = stellplaetzeState.map((_stellplatzObj) => {
          if (stellplatzObj.id === _stellplatzObj.id) {
            _stellplatzObj.buchungsIds = buchungsIdsText;
          }
          return _stellplatzObj;
        });

        setStellplaetzeState(newStellplaetzeState);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // function ->
  const changeStellplaetzeCategory = (_, category) => {
    setStellplaetzeCategoryState(category);
  };

  // function ->
  const downloadBarcodeAfterRequest = (file) => {
    const blob = new Blob([file]);
    const url = URL.createObjectURL(blob);

    const name = 'traceport_stellplatz_barcode';
    const type = 'png';
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${name}.${type}`);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
  };

  // function ->
  const getStallplatzBarcodeFromAPI = (stellplatzId) => {
    authAxios
      .get(`/lagerstellplatz/${stellplatzId}/barcode`, {
        responseType: 'blob'
      })
      .then((response) => {
        const file = response.data;
        downloadBarcodeAfterRequest(file);
      })
      .catch((error) => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
        console.log(error);
      });
  };

  // function ->
  const deleteDataOnClick = () => {
    const isDeleteFromLagerort = focusedDataState.loeschenType === LOESCHEN_TYPES.FROM_LAGERORT;
    const requestUrl = isDeleteFromLagerort ? `/lagerort/${lagerortId}/stellplatz/${focusedDataState.dataId}` : `/lagerstellplatz/${focusedDataState.dataId}`;
    authAxios
      .delete(requestUrl)
      .then(() => {
        rerunEffect();
        enqueueSnackbar(language('ALERT', 'TEXT', isDeleteFromLagerort ? 'DATENENTFERNT' : 'DATENGELOESCHT'));
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
      });
  };

  // function ->
  const fetchStellplaetze = (category) => {
    const stellplaetzeCategoryAddon = category === STELLPLAETZE_CATEGORIES.FREI ? '/frei' : category === STELLPLAETZE_CATEGORIES.BELEGT ? '/belegt' : '';
    const requestUrl = isForLagerort ? `/lagerort/${lagerortId}/stellplatz` : `/lagerstellplatz${stellplaetzeCategoryAddon}`;

    objGlobalState.setPageLoadingState(true);

    authAxios
      .get(requestUrl)
      .then((response) => {
        if (category === STELLPLAETZE_CATEGORIES.FREI) {
          setFreiStellplaetzeState(response.data.sort((a, b) => (a.id < b.id ? 1 : -1)));
        } else if (category === STELLPLAETZE_CATEGORIES.BELEGT) {
          setBelegtStellplaetzeState(response.data.sort((a, b) => (a.id < b.id ? 1 : -1)));
        } else {
          setAllStellplaetzeState(response.data.sort((a, b) => (a.id < b.id ? 1 : -1)));
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    fetchStellplaetze(STELLPLAETZE_CATEGORIES.ALLE);
    fetchStellplaetze(STELLPLAETZE_CATEGORIES.FREI);
    fetchStellplaetze(STELLPLAETZE_CATEGORIES.BELEGT);

    return () => {
      isMounted = false;
    };
  }, [rerunEffectState, objParentState.rerunEffectState]);

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, []);

  const objStellplaetzeTable = {
    createUpddateStellplatzDialog: {
      createUpdateStellplatzDialogState,
      setCreateUpdateStellplatzDialogState,
      rerunEffect
    },
    lagerorteTableDialog: {
      lagerorteTableDialogState,
      setLagerorteTableDialogState,
      addStellplatz: true
    },
    loeschenwarnungDialog: {
      loeschenwarnungDialogState,
      setLoeschenwarnungDialogState,
      deleteDataOnClick
    }
  };

  const pickStellplaetzeState = () => {
    if (stellplaetzeCategoryState === STELLPLAETZE_CATEGORIES.FREI) {
      return freiStellplaetzeState;
    } else if (stellplaetzeCategoryState === STELLPLAETZE_CATEGORIES.BELEGT) {
      return belegtStellplaetzeState;
    } else {
      return allStellplaetzeState;
    }
  };

  return (
    <>
      {createUpdateStellplatzDialogState.open && <CoCreateUpdateStellplatz objGlobalState={objGlobalState} objParentState={objStellplaetzeTable.createUpddateStellplatzDialog} />}
      {loeschenwarnungDialogState.open && <CoLoeschenwarnungDialog objGlobalState={objGlobalState} objParentState={objStellplaetzeTable.loeschenwarnungDialog} />}
      {lagerorteTableDialogState.open && <CoLagerorteTableDialog objGlobalState={objGlobalState} objParentState={objStellplaetzeTable.lagerorteTableDialog} />}

      <Box className="table__title">
        <Typography align="center" className="typography__singleTableTitle">
          {language('TABLE', 'HEADER', 'STELLPLAETZE', objGlobalState.lang)}
        </Typography>
        <div className="marginTwenty">
          {
            <Button variant="contained" color="primary" onClick={() => exportTableAsCSV(stateToCSV(), 'stellplatz')} className="table__titleButton">
              {language('BUTTON', 'TITEL', 'TABELLEEXPORTIEREN', objGlobalState.lang)}
            </Button>
          }
        </div>
      </Box>
      {!isForLagerort && (
        <Box className="table__title">
          <Tabs className="table__headerTabs" value={stellplaetzeCategoryState} indicatorColor="primary" textColor="primary" onChange={changeStellplaetzeCategory}>
            <Tab className="noWrap fontWeihgtBold" value={STELLPLAETZE_CATEGORIES.ALLE} label={language('TABLE', 'HEADER', 'ALLE', objGlobalState.lang)} />
            <Tab className="noWrap fontWeihgtBold" value={STELLPLAETZE_CATEGORIES.FREI} label={language('TABLE', 'HEADER', 'FREI', objGlobalState.lang)} />
            <Tab className="noWrap fontWeihgtBold" value={STELLPLAETZE_CATEGORIES.BELEGT} label={language('TABLE', 'HEADER', 'BELEGT', objGlobalState.lang)} />
          </Tabs>
        </Box>
      )}
      <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className={`table__container border__noTop ${objParentState.heightFour ? 'heightFour' : ''}`}>
        <Table size="medium" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => {
                return (
                  <TableCell align="center" component="th" className="table__headerCell" key={`lagerverwaltung_stellplaetze_stellplaetze_table_header_table_cell_${index}`}>
                    <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', header, objGlobalState.lang)}</Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {pickStellplaetzeState().map((stellplatzObj, index) => {
              const lastIndex = pickStellplaetzeState().length - 1 === index;
              return (
                <TableRow key={`lagerverwaltung_stellplaetze_stellplaetze_table_body_table_row_${index}`}>
                  <TableCell className={lastIndex ? 'border__none' : ''} align="center">
                    <Typography className="typography__singleTableRowContent">{stellplatzObj.id}</Typography>
                  </TableCell>
                  <TableCell className={lastIndex ? 'border__none' : ''} align="center">
                    <Typography className="typography__singleTableRowContent">{stellplatzObj.name}</Typography>
                  </TableCell>
                  <TableCell className={lastIndex ? 'border__none' : ''} align="center">
                    <Button onClick={() => getStallplatzBarcodeFromAPI(stellplatzObj.id)} className="button__small" variant="outlined" color="primary">
                      {language('BUTTON', 'TITEL', 'HERUNTERLADEN', objGlobalState.lang)}
                    </Button>
                  </TableCell>
                  <TableCell className={lastIndex ? 'border__none' : ''} align="center">
                    {stellplatzObj.buchungsIds != null ? (
                      <Typography className="typography__singleTableRowContent">{stellplatzObj.buchungsIds}</Typography>
                    ) : (
                      <Button onClick={() => getTransporteinheitFromAPI(stellplatzObj)} className="button__small" variant="outlined" color="primary">
                        {language('BUTTON', 'TITEL', 'ZEIGEN', objGlobalState.lang)}
                      </Button>
                    )}
                  </TableCell>
                  <TableCell className={lastIndex ? 'border__none' : ''} align="center">
                    <Button onClick={() => openLagerorteTableDialog(stellplatzObj)} className="button__small" variant="outlined" color="primary">
                      {language('BUTTON', 'TITEL', 'HINZUFUEGEN', objGlobalState.lang)}
                    </Button>
                  </TableCell>
                  {isForLagerort && (
                    <TableCell className={lastIndex ? 'border__none' : ''} align="center">
                      <Button onClick={() => openLoeschenwarnungDialog(stellplatzObj.id, LOESCHEN_TYPES.FROM_LAGERORT)} className="button__small--redOutLined">
                        {language('BUTTON', 'TITEL', 'ENTFERNEN', objGlobalState.lang)}
                      </Button>
                    </TableCell>
                  )}
                  <TableCell className={lastIndex ? 'border__none' : ''} align="center">
                    <Button onClick={() => openCreateUpdateStellplatzDialog(stellplatzObj)} className="button__small" variant="outlined" color="primary">
                      {language('BUTTON', 'TITEL', 'BEARBEITEN', objGlobalState.lang)}
                    </Button>
                  </TableCell>
                  <TableCell className={lastIndex ? 'border__none' : ''} align="center">
                    <IconButton
                      onClick={() => openLoeschenwarnungDialog(stellplatzObj.id, LOESCHEN_TYPES.STELLPLATZ_COMPLETE)}
                      className="button__icon--tableRed"
                      title={language('BUTTON', 'TITEL', 'LOESCHEN', objGlobalState.lang)}
                      size="small"
                    >
                      <DeleteForever />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CoStellplaetzeTable;
