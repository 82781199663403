import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import language from '../../Common/language/language';

// import -> material ui -> core, icons
import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';

// component -> React-root
const CoEmailVerifizierung = ({ dialogState, setDialogState, objGlobalState }) => {
  const history = useHistory();
  const location = useLocation();

  const sendToSignIn = (event) => {
    setDialogState(false);
    history.push('/signin', { from: location });
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={dialogState} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="coEmailverifizierung__container">
      <DialogTitle id="alert-dialog-title">{language('DIALOG', 'TITEL', 'VERIFIZIERUNG', objGlobalState.lang)}</DialogTitle>
      <DialogContent className="dialog__content">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography className="typography">{language('KONTENT', 'TEXT', 'VERIFIZIERUNGNACHRICHT', objGlobalState.lang)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth variant="contained" type="submit" color="primary" className="button" onClick={sendToSignIn}>
              {language('BUTTON', 'TITEL', 'ZURANMELDUNG', objGlobalState.lang)}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CoEmailVerifizierung;
