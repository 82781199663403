import React from 'react';
import language from '../../Common/language/language';
import { useHistory, useLocation } from 'react-router';

// import -> material ui -> core
import { Dialog, DialogTitle, DialogContent, Typography, Grid, Button } from '@material-ui/core';

// component -> React-root
const CoEmailSentDialog = ({ objGlobalState, objParentState }) => {
  const location = useLocation();
  const history = useHistory();
  // function ->
  const sendToSignIn = () => {
    history.push('/signin', { from: location });
  };
  return (
    <Dialog fullWidth maxWidth="md" open={objParentState.emailSentDialogState} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <Typography align="left" className="typography__dialogTitle">
          {language('ALERT', 'TEXT', 'EMAILGESENDET', objGlobalState.lang)}
        </Typography>
      </DialogTitle>
      <DialogContent className="dialog__content">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography className="typography typography__message">{language('KONTENT', 'TEXT', 'EMAILGESENDET', objGlobalState.lang)}</Typography>;
          </Grid>
          <Grid item xs={12}>
            <Button onClick={sendToSignIn} fullWidth color="primary" variant="contained" className="button__middle">
              {language('BUTTON', 'TITEL', 'ZURANMELDUNG', objGlobalState.lang)}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CoEmailSentDialog;
