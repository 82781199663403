import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import language from '../Common/language/language';
import { FILE_TYPES } from '../Common/types/types';
import { mouseScrollHorizontally } from '../Common/helperFunctions/mouseScrollHorizontally';
import { createAxiosInstance, getAndCostumFiles } from '../Common/helperFunctions/axios';

// import -> services
import ServiceBreadcrumbs from '../Common/services/Breadcrumbs/ServiceBreadcrumbs';

// import -> material ui -> core & icons
import { Box, Button, Container, Grid, ListItem, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { Forward } from '@material-ui/icons';

// import -> components
import CoShowFiles from '../Common/Components/CoShowFiles';
import CoConfirmation from '../Common/Components/CoConfirmation';
import CoUploadFiles from '../Common/Components/CoUploadFiles';
import CoDisplayFilesList from '../Common/Components/CoDisplayFilesList';
import CoMultipleScanner from '../Common/Components/CoMultipleScanner';

// component -> React-root
const CoLagerverwaltungumlagerung = ({ objGlobalState }) => {
  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const { enqueueSnackbar } = useSnackbar();

  const [stellplaetzeState, setStellplaetzeState] = useState([]);
  const [displayStellplaetzeState, setDisplayStellplaetzeState] = useState([]);
  const [showFilesDialogState, setShowFilesDialogState] = useState({ open: false, title: '', files: [] });
  const [confirmationDialogState, setConfirmationDialogState] = useState(false);
  const [uploadFilesDialogState, setUploadFilesDialogState] = useState({ open: false, title: '' });
  const [filesState, setFilesState] = useState([]);
  const [altstellplatzkennzeichnungState, setAltstellplatzkennzeichnungState] = useState('');
  const [neustellplatzkennzeichnungState, setNeustellplatzkennzeichnungState] = useState('');
  const [focusedStellplatzInputState, setFocusedStellplatzInputState] = useState('neu');
  const [newDataValueState, setNewDataValueState] = useState({
    altstellplatzkennzeichnung: '',
    neustellplatzkennzeichnung: ''
  });

  const tableRef = useRef();
  const tableHeaders = ['STELLPLATZKENNZEICHNUNG', 'STELLPLATZFOTOS', 'LIEFERSCHEIN', ' '];

  const neustellplatzInputRef = useRef();

  // function ->
  const showFilesDialogOnClick = (files, title) => {
    if (!files.length) {
      setShowFilesDialogState({ open: true, title: title, files: [] });
    }

    const filesArray = [];

    files.forEach((file) => {
      getAndCostumFiles({ file, filesArray, authAxios }).then(() => {
        setShowFilesDialogState({ open: true, title: title, files: filesArray });
      });
    });
  };
  // function ->
  const showUploadFilesDialogOnClick = () => {
    setUploadFilesDialogState({ open: true, title: undefined });
  };

  // function ->
  const getNumberOfCharInString = (str, char) => {
    let result = 0;
    str.split('').forEach((sChar) => {
      if (sChar === char) {
        result += 1;
      }
    });
    return result;
  };

  // function ->
  const handleAltstellplatzkennzeichnungChange = (event) => {
    const { value } = event.target;
    setAltstellplatzkennzeichnungState(value);

    // filter stellplatzlist
    let currentStellplaetzeState = [...stellplaetzeState];
    currentStellplaetzeState = currentStellplaetzeState.filter((stellplatz) => {
      const smallLetterValue = value.toLowerCase();
      const smallLetterKennzeichnung = stellplatz.stellplatz.toLowerCase();
      let result = true;
      smallLetterValue.split('').forEach((char) => {
        const charOverrepeated = getNumberOfCharInString(smallLetterValue, char) > getNumberOfCharInString(smallLetterKennzeichnung, char);
        if (charOverrepeated) {
          result = false;
        }
      });
      return result;
    });
    setDisplayStellplaetzeState(currentStellplaetzeState);
  };

  // function ->
  const handleNeustellplatzkennzeichnungChange = (event) => {
    setNeustellplatzkennzeichnungState(event.target.value);
  };

  // function ->
  const handleAltstellplatzInputFocus = () => {
    setFocusedStellplatzInputState('alt');
  };

  // function ->
  const handleNeustellplatzInputFocus = () => {
    setFocusedStellplatzInputState('neu');
  };

  // function ->
  const choseInputForScannerResults = () => {
    if (focusedStellplatzInputState === 'neu') {
      return setNeustellplatzkennzeichnungState;
    } else if (focusedStellplatzInputState === 'alt') {
      return setAltstellplatzkennzeichnungState;
    }
  };

  // function ->
  const pickAltstellplatzOnClick = (stellplatz) => {
    setAltstellplatzkennzeichnungState(stellplatz);
    neustellplatzInputRef.current.querySelector('input').focus();
  };

  // function ->
  const submitFormOnClick = (event) => {
    event.preventDefault();

    const altstellplatzkennzeichnung = altstellplatzkennzeichnungState;
    const neustellplatzkennzeichnung = neustellplatzkennzeichnungState;

    setNewDataValueState({ altstellplatzkennzeichnung, neustellplatzkennzeichnung });
    setConfirmationDialogState(true);
  };

  // function ->
  const closeConfirmationOnClick = () => {
    setConfirmationDialogState(false);
  };

  // function ->
  const confirmed = () => {
    objGlobalState.setPageLoadingState(true);
    setTimeout(() => {
      closeConfirmationOnClick();
      setFilesState([]);
      enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT'), { variant: 'success' });
      objGlobalState.setPageLoadingState(false);
    }, 1500);
  };

  // function ->
  const fetchStellplaetzeFromApi = () => {
    objGlobalState.setPageLoadingState(true);

    authAxios
      .get('/lagerverwaltung/uebersicht')
      .then((response) => {
        const stellplaetze = response.data;
        setStellplaetzeState(stellplaetze);
        setDisplayStellplaetzeState(stellplaetze);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchStellplaetzeFromApi();
    }

    return () => (isMounted = false);
  }, []);

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, []);

  const photosSectionTitle = 'NEUSTELLPLATZFOTOS';
  const photosSection = { sectionTitle: photosSectionTitle, files: filesState };
  const confirmationGallery = [photosSection];

  const objLagerverwaltungumlagerungStateAndFunctions = {
    closeConfirmationOnClick,
    confirmationDialogState,
    setConfirmationDialogState,
    confirmationGallery,
    confirmed,
    filesState,
    setFilesState,
    newDataValueState,
    setNewDataValueState,
    showFilesDialogState,
    setShowFilesDialogState,
    stellplaetzeState,
    setStellplaetzeState,
    setStellplatzkennzeichnungState: choseInputForScannerResults(),
    uploadFilesDialogState,
    setUploadFilesDialogState
  };

  // array -> create breadcrumbs
  const arrBreadcrumbs = [
    { url: '/', title: language('SEITE', 'TITEL', 'HOME', objGlobalState.lang) },
    { url: '/lagerverwaltung_umlagerung', title: language('SEITE', 'TITEL', 'LAGERVERWALTUNGUMLAGERUNG', objGlobalState.lang) }
  ];

  return (
    <>
      {showFilesDialogState.open && <CoShowFiles objGlobalState={objGlobalState} objParentState={objLagerverwaltungumlagerungStateAndFunctions} />}
      {uploadFilesDialogState.open && <CoUploadFiles objGlobalState={objGlobalState} objParentState={objLagerverwaltungumlagerungStateAndFunctions} />}
      {confirmationDialogState && <CoConfirmation objGlobalState={objGlobalState} objParentState={objLagerverwaltungumlagerungStateAndFunctions} />}

      <Container component="main" maxWidth="xl" className="container__container">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ServiceBreadcrumbs arrBreadcrumbs={arrBreadcrumbs} />
          </Grid>
          <Grid item xs={12}>
            <ListItem className="container__listItem">
              <ListItemIcon>
                <img src={`../assets/images/icons/icon_umlagerung.png`} alt="" className="images__headerIcon" />
              </ListItemIcon>
              <ListItemText>
                <Typography className="typography__headerTitle">{language('SEITE', 'TITEL', 'LAGERVERWALTUNGUMLAGERUNG', objGlobalState.lang)}</Typography>
              </ListItemText>
            </ListItem>
          </Grid>
          <Grid item xs={12} className="marginThirty">
            <Box className="table__title">
              <Typography align="center" className="typography__singleTableTitle">
                {language('TABLE', 'HEADER', 'STELLPLAETZE', objGlobalState.lang)}
              </Typography>
            </Box>
            <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className="table__container coLagerverwaltungumlagerung__tableContainer border__noTop">
              <Table size="medium" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((header, key) => {
                      return (
                        <TableCell align="center" component="th" className="table__headerCell" key={`lagerverwaltung_artikeluebersicht_tableHeaders_${key}`}>
                          <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', header, objGlobalState.lang)}</Typography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayStellplaetzeState.map((item, key) => {
                    const { stellplatz, fotos, lieferscheine } = item;

                    return (
                      <TableRow key={`lagerverwaltung_artikeluebersicht_tableRow_${key}`}>
                        <TableCell align="center">
                          <Typography className="typography__singleTableRowContent">{stellplatz}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => showFilesDialogOnClick(fotos, 'STELLPLATZFOTOS')}
                            className={fotos.length ? 'button__small' : ''}
                            variant="outlined"
                            color="primary"
                            disabled={!fotos.length}
                          >
                            {language('BUTTON', 'TITEL', 'FOTOSANSEHEN', objGlobalState.lang)}
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => showFilesDialogOnClick(lieferscheine, 'LIEFERSCHEIN')}
                            className={lieferscheine.length ? 'button__small' : ''}
                            variant="outlined"
                            color="primary"
                            disabled={!lieferscheine.length}
                          >
                            {language('BUTTON', 'TITEL', 'LIEFERSCHEINANSEHEN', objGlobalState.lang)}
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button onClick={() => pickAltstellplatzOnClick(stellplatz)} className="button__small--cWhite" variant="contained" color="primary">
                            {language('BUTTON', 'TITEL', 'UMLAGERN', objGlobalState.lang)}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <form name="submitForm" noValidate autoComplete="on" onSubmit={submitFormOnClick}>
              <Grid container spacing={3} justify="flex-start" alignContent="center" alignItems="center" className="marginFourty">
                <Grid item xs={12} md={5}>
                  <TextField
                    autoFocus
                    fullWidth
                    value={altstellplatzkennzeichnungState}
                    onChange={handleAltstellplatzkennzeichnungChange}
                    type="text"
                    variant="outlined"
                    name="altstellplatzkennzeichnung"
                    label={language('FORM', 'LABEL', 'ALTSTELLPLATZKENNZEICHNUNG', objGlobalState.lang)}
                    onFocus={handleAltstellplatzInputFocus}
                  />
                </Grid>
                <Grid item xs={12} md={2} className="coLagerverwaltungumlagerung__forwardIconSection">
                  <Forward className="coLagerverwaltungumlagerung__forwardIcon" color="primary" />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    ref={neustellplatzInputRef}
                    fullWidth
                    value={neustellplatzkennzeichnungState}
                    onChange={handleNeustellplatzkennzeichnungChange}
                    type="text"
                    variant="outlined"
                    name="neustellplatzkennzeichnung"
                    label={language('FORM', 'LABEL', 'NEUSTELLPLATZKENNZEICHNUNG', objGlobalState.lang)}
                    onFocus={handleNeustellplatzInputFocus}
                  />
                </Grid>
                <CoMultipleScanner objGlobalState={objGlobalState} objParentState={objLagerverwaltungumlagerungStateAndFunctions} options={{ hideTextField: true }} />
                <Grid item xs={12}>
                  <Button onClick={showUploadFilesDialogOnClick} className="button__middle" variant="contained" color="primary">
                    {language('BUTTON', 'TITEL', 'DATEIENHOCHLADEN', objGlobalState.lang)}
                  </Button>
                </Grid>
                <CoDisplayFilesList objGlobalState={objGlobalState} filesState={filesState} setFilesState={setFilesState} title="NEUSTELLPLATZFOTOS" />
                <Grid item xs={12}>
                  <Button fullWidth type="submit" color="primary" className="button__middle" variant="contained" color="primary">
                    {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CoLagerverwaltungumlagerung;
