import React, { useLayoutEffect, useRef, useState } from 'react';
import language from '../../../..//language/language';
import { mouseScrollHorizontally } from '../../../..//helperFunctions/mouseScrollHorizontally';
import { createAxiosInstance } from '../../../..//helperFunctions/axios';

// import -> material ui -> core && icons
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableHead, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useEffect } from 'react';

// component -> React-root
const CoStellplaetzeTableDialog = ({ objGlobalState, objParentState }) => {
  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const [dialogIsRenderedState, setDialogIsRenderedState] = useState(false);
  const [stellplaetzeState, setStellplaetzeState] = useState([]);

  const tableHeaders = ['NAME'];
  const tableRef = useRef();

  // function ->
  const closeDialogOnClose = () => {
    objParentState.setStellplaetzeTableDialogState((pre) => ({ ...pre, open: false }));
  };

  // function ->
  const handleDialogOnRendered = () => {
    setDialogIsRenderedState(true);
  };

  // function ->
  const fetchStellplaetze = () => {
    const buchungsId = objParentState.stellplaetzeTableDialogState.buchungsId;
    authAxios
      .get(`/transporteinheit/${buchungsId}/lager-stellplatz?buchungs_id=${buchungsId}`)
      .then((response) => {
        setStellplaetzeState(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    fetchStellplaetze();

    return () => {
      isMounted = false;
    };
  }, []);

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted && dialogIsRenderedState) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, [dialogIsRenderedState]);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={objParentState.stellplaetzeTableDialogState.open}
      onClose={closeDialogOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onRendered={handleDialogOnRendered}
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Typography align="left" className="typography__dialogTitle">
              {language('DIALOG', 'TITEL', 'STELLPLAETZE', objGlobalState.lang)}
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <IconButton className="button__iconButton--close" onClick={closeDialogOnClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className="dialog__content">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className="table__container heightFour">
              <Table size="medium" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((header, key) => {
                      return (
                        <TableCell align="center" component="th" className="table__headerCell" key={`lagerverwaltung_bestandsuebersicht_transporteinheiten_stellplaetze_table_TableHeaders_${key}`}>
                          <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', header, objGlobalState.lang)}</Typography>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stellplaetzeState.map((stellplatz, key) => {
                    return (
                      <TableRow key={`lagerverwaltung_bestandsuebersicht_transporteinheiten_stellplaetze_table_TableRow_${key}`}>
                        <TableCell align="center">
                          <Typography className="typography__singleTableRowContent">{stellplatz.name}</Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CoStellplaetzeTableDialog;
