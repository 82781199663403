import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import language from '../../Common/language/language';
import { mouseScrollHorizontally } from '../../Common/helperFunctions/mouseScrollHorizontally';
import { displayArraysInTable } from '../../Common/helperFunctions/displayArrayInTable';
import { createAxiosInstance, getAndCostumFiles } from '../../Common/helperFunctions/axios';
import { generateDate } from '../../Common/helperFunctions/date';
import { useHistory } from 'react-router-dom';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import CoShowFiles from '../../Common/Components/CoShowFiles';
import { exportTableAsCSV } from '../../Common/helperFunctions/exportTable';
import { removeFromArray } from '../../Common/helperFunctions/arrays';
import { generateCSVString } from '../../Common/helperFunctions/generateCSVString';

// component -> React-root
const CoLieferungTable = ({ objGlobalState, objParentState }) => {
  const history = useHistory();

  const [showFilesDialogState, setShowFilesDialogState] = useState({ open: false, title: '', files: [], scan: false });
  const [lieferungInfoState, setLieferungInfoState] = useState([]);
  const [dialogIsRenderedState, setDialogIsRenderedState] = useState(false);

  const tableRef = useRef();
  const tableHeaders = [
    'KUNDE',
    'AUFTRAGSNUMMER',
    'SPEDITION',
    'DATUM',
    'TRACKINGNUMMER',
    'LIVETRACKER',
    'SENDUNGSART',
    'ANZAHLDERPAKETE',
    'ANZAHLDERPAKETEPROPAKETSENDUNG',
    'ANZAHLDERPALETTEN',
    'ANZAHLDERPAKETEPROPALETTE',
    'VERSANDBESTAETIGUNG',
    'ERSTELLUNGSDATUM',
    'LABEL',
    'BEGLEITPAPIERE'
  ];

  const hasLiveTrackingAccess = objGlobalState.authenticateState.user.access?.includes('LIVE_TRACKING');

  if (!hasLiveTrackingAccess) {
    removeFromArray(tableHeaders, 'LIVETRACKER');
  }

  // function ->
  const splitStringLines = (string, breakCharacter) => {
    const breakCharacterIndex = string.indexOf(breakCharacter) + 1;
    const firstLine = string.slice(0, breakCharacterIndex);
    const secondLine = string.slice(breakCharacterIndex);
    return [firstLine, secondLine];
  };

  // function ->
  const goToLiveTracking = (trackernummer) => {
    history.push(`/live-tracking/${trackernummer}`);
  };

  // function ->
  const goToTracking = (logistikunternehmen, trackingnummer) => {
    history.push(`/tracking/${logistikunternehmen}/${trackingnummer}`);
  };

  // function ->
  const stateToCSV = () => {
    const csvRows = [];
    const headers = [...tableHeaders];
    const tableValuesLabels = [
      'kunde',
      'auftragsnummer',
      'spedition',
      'datum',
      'trackingnummer',
      'liveTracker',
      'sendungsart',
      'anzahlDerPakete',
      'anzahlDerPaketeProPaketSendung',
      'anzahlDerPalette',
      'anzahlDerPaketeProPalette',
      'versandbestaetigung',
      'erstellungsDatum'
    ];

    removeFromArray(headers, 'LABEL');
    removeFromArray(headers, 'BEGLEITPAPIERE');

    if (!hasLiveTrackingAccess) {
      removeFromArray(tableValuesLabels, 'liveTracker');
    }

    const headerRow = headers.map((header) => generateCSVString(language('TABLE', 'TITEL', header, objGlobalState.lang)));

    csvRows.push(headerRow.join(';'));
    lieferungInfoState.forEach((lieferung) => {
      const valueRow = tableValuesLabels.map((value) => generateCSVString(lieferung[value]));
      csvRows.push(valueRow.join(';'));
    });
    return csvRows.join('\n');
  };

  // function ->
  const showFilesDialogOnClick = (filesList, filesCategory, scan) => {
    const title = filesCategory.toUpperCase();
    const files = [...filesList];

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    if (!files.length) {
      setShowFilesDialogState({ open: true, title: title, files: files });
      return;
    }

    files.forEach((fileObj) => {
      getAndCostumFiles({ file: fileObj, filesArray: files, authAxios: authAxios }).then((response) => {
        setShowFilesDialogState({ open: true, title: title, files: response, scan: scan });
      });
    });
  };
  // function ->
  const closeDialogOnClose = () => {
    objParentState.setLieferungTableDialogState(false);
  };

  // function ->
  const fetchLieferungInformationFromAPI = () => {
    objGlobalState.setPageLoadingState(true);

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    authAxios
      .get('/versand-lieferung')
      .then((response) => {
        const { data } = response;

        const shipmentTypes = [
          {
            name: 'PACKETSENDUNG',
            id: 1
          },
          {
            name: 'PALETTENSENDUNG',
            id: 2
          }
        ];

        const confirmations = [
          {
            name: 'ELEKTRONISCH',
            id: 1
          },
          {
            name: 'BEGLEITPAPIERE',
            id: 2
          }
        ];

        const newLieferungInfoState = [];
        data.forEach((dataObj) => {
          const date = dataObj.versanddatum && generateDate(dataObj.versanddatum).dateAndTime;

          const foundShipment = shipmentTypes.find((element) => element.name === dataObj.sendungsart);
          const shipmentType = foundShipment ? language('KONTENT', 'TEXT', foundShipment.name, objGlobalState.lang) : '';

          const foundConfirmation = confirmations.find((element) => element.name === dataObj.bestaetigungsart);
          const confirmation = foundConfirmation ? language('KONTENT', 'TEXT', foundConfirmation.name, objGlobalState.lang) : '';
          const newDataObj = {
            kunde: dataObj.kunde,
            auftragsnummer: dataObj.auftragsnummer,
            spedition: dataObj.spedition,
            datum: date,
            trackingnummer: dataObj.trackingnummer,
            liveTracker: dataObj.liveTrackerNummer,
            sendungsart: shipmentType,
            anzahlDerPakete: dataObj.anzahlPakete || '',
            anzahlDerPaketeProPaketSendung: displayArraysInTable(dataObj.anzahlPaketeProPaket) || '',
            anzahlDerPalette: dataObj.anzahlPaletten || '',
            anzahlDerPaketeProPalette: displayArraysInTable(dataObj.anzahlPaketeProPalette) || '',
            versandbestaetigung: confirmation,
            erstellungsDatum: generateDate(dataObj.erstellungsDatum).dateAndTime,
            label: dataObj.label,
            begleitpapiere: dataObj.versandbestaetigung,
            id: dataObj.id
          };
          newLieferungInfoState.unshift(newDataObj);
        });
        setLieferungInfoState(newLieferungInfoState);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const handleDialogOnRendered = () => {
    setDialogIsRenderedState(true);
  };

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, [dialogIsRenderedState]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchLieferungInformationFromAPI();
    }

    return () => (isMounted = false);
  }, []);

  const objLieferungTableState = { showFilesDialogState, setShowFilesDialogState };

  return (
    <>
      <CoShowFiles objGlobalState={objGlobalState} objParentState={objLieferungTableState} options={{ scan: showFilesDialogState.scan }} />
      <Dialog
        fullWidth
        maxWidth="xl"
        onClose={closeDialogOnClose}
        open={objParentState.lieferungTableDialogState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onRendered={handleDialogOnRendered}
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
            <Grid item xs={6}>
              <Typography align="left" className="typography__dialogTitle">
                {language('TABLE', 'HEADER', 'LIEFERUNGSDATEN', objGlobalState.lang)}
              </Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <IconButton className="button__iconButton--close" onClick={closeDialogOnClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="dialog__content">
          <Box className="table__title">
            <div className="marginTwenty">
              {
                <Button variant="contained" color="primary" onClick={() => exportTableAsCSV(stateToCSV(), 'lieferung')} className="table__titleButton">
                  {language('BUTTON', 'TITEL', 'TABELLEEXPORTIEREN', objGlobalState.lang)}
                </Button>
              }
            </div>
          </Box>
          <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className="table__container">
            <Table size="medium" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className="table__title">
                  {tableHeaders.map((header, key) => {
                    return (
                      <TableCell align="left" component="th" className="table__headerCell" key={`versand_lieferung_infoTableHeaders_${key}`}>
                        {header === 'ANZAHLDERPAKETEPROPAKETSENDUNG' || header === 'ANZAHLDERPAKETEPROPALETTE' ? (
                          <>
                            <Typography className="typography__singleTableRowTitle">{splitStringLines(language('TABLE', 'TITEL', header, objGlobalState.lang), '\n')[0]}</Typography>
                            <Typography className="typography__singleTableRowTitle">{splitStringLines(language('TABLE', 'TITEL', header, objGlobalState.lang), '\n')[1]}</Typography>
                          </>
                        ) : (
                          <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', header, objGlobalState.lang)}</Typography>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {lieferungInfoState.map((item, key) => {
                  // destructuring the needed data to display
                  const {
                    kunde,
                    auftragsnummer,
                    spedition,
                    datum,
                    trackingnummer,
                    liveTracker,
                    sendungsart,
                    anzahlDerPakete,
                    anzahlDerPaketeProPaketSendung,
                    anzahlDerPalette,
                    anzahlDerPaketeProPalette,
                    versandbestaetigung,
                    erstellungsDatum,
                    label,
                    begleitpapiere
                  } = item;

                  // array -> put the destructured data in an array to map on them
                  const values = [
                    { name: 'kunde', value: kunde },
                    { name: 'auftragsnummer', value: auftragsnummer },
                    { name: 'spedition', value: spedition },
                    { name: 'datum', value: datum },
                    { name: 'trackingnummer', value: trackingnummer },
                    hasLiveTrackingAccess ? { name: 'liveTracker', value: liveTracker } : null,
                    { name: 'sendungsart', value: sendungsart },
                    { name: 'anzahlDerPakete', value: anzahlDerPakete },
                    { name: 'anzahlDerPaketeProPaketSendung', value: anzahlDerPaketeProPaketSendung },
                    { name: 'anzahlDerPalette', value: anzahlDerPalette },
                    { name: 'anzahlDerPaketeProPalette', value: anzahlDerPaketeProPalette },
                    { name: 'versandbestaetigung', value: versandbestaetigung },
                    { name: 'erstellungsDatum', value: erstellungsDatum },
                    { name: 'label', value: label },
                    { name: 'begleitpapiere', value: begleitpapiere }
                  ];

                  return (
                    <TableRow key={`versand_lieferung_infoTableRow_${key}`}>
                      {values.map((value, key) => {
                        if (value === null) return;
                        if (value.name === 'liveTracker') {
                          return (
                            <TableCell align="center" key={`versand_lieferung_infoTableCell_${key}`}>
                              <Button onClick={() => goToLiveTracking(value.value)} className="button__small" variant="outlined" color="primary">
                                {value.value}
                              </Button>
                            </TableCell>
                          );
                        } else if (value.name === 'trackingnummer' && value.value !== null) {
                          return (
                            <TableCell align="center" key={`versand_lieferung_infoTableCell_${key}`}>
                              <Button onClick={() => goToTracking(values.find((e) => e.name === 'spedition').value, value.value)} className="button__small" variant="outlined" color="primary">
                                {value.value}
                              </Button>
                            </TableCell>
                          );
                        } else if (value.name === 'begleitpapiere' || value.name === 'label') {
                          return (
                            <TableCell align="left" key={`versand_lieferung_infoTableCell_${key}`}>
                              <Button onClick={() => showFilesDialogOnClick(value.value, value.name, value.name === 'label')} className="button__small" variant="outlined" color="primary">
                                {language('BUTTON', 'TITEL', 'ANSEHEN', objGlobalState.lang)}
                              </Button>
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell align="left" key={`versand_lieferung_infoTableCell_${key}`}>
                              <Typography className="typography__singleTableRowContent">{value.value}</Typography>
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoLieferungTable;
