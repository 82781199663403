import React from 'react';
import language from '../../Common/language/language';

// import -> material ui -> core, icons
import { Button, Container, Grid, TextField } from '@material-ui/core';

// import -> higher order component
import hocSuche from '../../HOCSuche/HOCSuche';
import CoLogistikunternhemenSelect from '../../Common/Components/CoLogistikunternhemenSelect';

// component -> React-root
const CoTrackingSuche = ({ objGlobalState, functionsAndStates }) => {
  // destructuring -> props that are from hoc
  const { logistikunternehmenState, textFieldState, chooseLogistikunternehmenFromSelect, fetchTextFieldChange, submitFormOnClick } = functionsAndStates;

  const objSuche = {
    logistikunternehmenSelect: {
      selectValue: logistikunternehmenState,
      handleInputOnChange: chooseLogistikunternehmenFromSelect,
      speditionenToExclude: ['chors', 'awl'],
      inputLabel: 'LOGISTIKUNTERNEHMEN',
      menuItemValue: 'shortcut',
      formContorlClassName: 'coSuche__formControl',
      formControlError: textFieldState.logistikunternehmen.error
    }
  };

  return (
    <Container component="div" maxWidth="sm">
      <form name="submitForm" noValidate autoComplete="on" onSubmit={submitFormOnClick}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="text"
              variant="outlined"
              name="trackingnummer"
              label={language('FORM', 'LABEL', 'TRACKINGNUMMER', objGlobalState.lang)}
              error={textFieldState.trackingnummer.error}
              helperText={textFieldState.trackingnummer.error ? language('FORM', 'ERROR', 'LEERESTEXTFELD', objGlobalState.lang) : ''}
              onChange={(event) => fetchTextFieldChange(event, 'trackingnummer')}
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <CoLogistikunternhemenSelect objGlobalState={objGlobalState} objParentState={objSuche.logistikunternehmenSelect} />
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth variant="contained" type="submit" color="primary" className="button">
              {language('BUTTON', 'TITEL', 'SUCHEN', objGlobalState.lang)}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default hocSuche(CoTrackingSuche, false);
