import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import language from '../../../../language/language';
import { mouseScrollHorizontally } from '../../../../helperFunctions/mouseScrollHorizontally';
import { createAxiosInstance } from '../../../../helperFunctions/axios';
import generateArtikelEntityLabel from '../../../../helperFunctions/generateArtikelEntityLabel';
import { useSnackbar } from 'notistack';
import { generateCSVString } from '../../../../helperFunctions/generateCSVString';
import { TABLE_TYPES } from '../../../../types/types';

// import -> material ui -> core
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { VerticalAlignBottom } from '@material-ui/icons';

// import -> components
import CoPaginationTableFooter from '../../../CoPaginationTableFooter';
import CoSpaltenlisteDialog from '../../../Artikel/CoSpaltenlisteDialog';
import CoSpaltenreihenfolgeDialog from '../../../Artikel/CoSpaltenreihenfolgeDialog';
import CoStellplaetzeTableDialog from './CoStellplaetzeTableDialog';
import { exportTableAsCSV } from '../../../../helperFunctions/exportTable';

const INITIAL_ELEMENTS_PER_PAGE = 5;

// component -> React-root
const CoTransporteinheitentabelle = ({ objGlobalState, objParentState }) => {
  const { enqueueSnackbar } = useSnackbar();
  const artikelId = objParentState.transporteinheitViewState.artikelId;

  const heightFour = objParentState.heightFour;
  const isKommissionierung = objParentState.kommissionierung;

  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const [stellplaetzeTableDialogState, setStellplaetzeTableDialogState] = useState({ open: false, buchungsId: '' });

  const [spaltenlisteDialogState, setSpaltenlisteDialogState] = useState(false);
  const [spaltenreihenfolgeDialogState, setSpaltenreihenfolgeDialogState] = useState(false);
  const [transporteinheitenState, setTransporteinheitenState] = useState([]);
  const [rerunEffectState, setRerunEffectState] = useState(false);

  const [showTableState, setShowTableState] = useState(false);
  const [tableValuesLabelsState, setTablesValuesLabelsState] = useState([]);
  const [tableHeadersState, setTableHeadersState] = useState([]);
  const [elementsPerPageState, setElementsPerPageState] = useState(INITIAL_ELEMENTS_PER_PAGE);
  const [numOfPagesState, setNumOfPagesState] = useState(1);
  const [currentPageState, setCurrentPageState] = useState(1);
  const tableRef = useRef();

  // function ->
  const openSpaltenlisteDialog = () => {
    setSpaltenlisteDialogState(true);
  };

  // function ->
  const openSpaltenreihenfolgeDialog = () => {
    setSpaltenreihenfolgeDialogState(true);
  };

  // function ->
  const stateToCSV = () => {
    const csvRows = [];
    const headerRow = tableHeadersState.map((header) => generateCSVString(language('TABLE', 'TITEL', header, objGlobalState.lang)));
    csvRows.push(headerRow.join(';'));
    transporteinheitenState.forEach((transporteinheit) => {
      const valueRow = tableValuesLabelsState.map((key) => {
        const value =
          key === 'temperaturEmpfindlich'
            ? mapTemperaturEmpfindlichValueToLabel(transporteinheit[key])
            : typeof transporteinheit[key] === 'boolean'
            ? displayBoolean(transporteinheit[key])
            : transporteinheit[key]
            ? transporteinheit[key]
            : '';

        return generateCSVString(value);
      });
      csvRows.push(valueRow.join(';'));
    });

    return csvRows.join('\n');
  };

  // function ->
  const downloadEANAfterRequest = (file) => {
    const blob = new Blob([file]);
    const url = URL.createObjectURL(blob);

    const name = 'traceport_ean_code';
    const type = 'png';
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${name}.${type}`);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
  };

  // function ->
  const getEANCodeFromAPI = (artikelId) => {
    authAxios
      .get(`/artikel/${artikelId}/ean-code`, {
        responseType: 'blob'
      })
      .then((response) => {
        const file = response.data;
        downloadEANAfterRequest(file);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
      });
  };

  // function ->
  const displayBoolean = (value) => {
    const label = value ? 'JA' : 'NEIN';
    return language('KONTENT', 'TEXT', label, objGlobalState.lang);
  };

  // function ->
  const mapTemperaturEmpfindlichValueToLabel = (value) => {
    const labels = {
      KEINE_ANGABE: '',
      FUENFZEHN_BIS_FUENFUNDZWANZIG: '15°C - 25°C',
      ZWEI_BIS_ACHT: '2°C - 8°C',
      NULL_BIS_FUENFUNDZWANZIG: '0°C - 25°C'
    };

    return labels[value];
  };

  // function ->
  const goToArtikel = () => {
    objParentState.setTransporteinheitViewState({ open: false, artikelId: null });
  };

  // function ->
  const createTableHeaders = (spaltenArray) => {
    const tableHeaders = [];
    spaltenArray.forEach((spalte) => {
      if (spalte === 'artikelStammId') return;
      const spalteAsHeader = generateArtikelEntityLabel(spalte);
      tableHeaders.push(spalteAsHeader);
    });

    return tableHeaders;
  };

  // function ->
  const createTableValuesLabels = (spaltenArray) => {
    const valuesLabels = [];
    spaltenArray.forEach((spalte) => {
      if (spalte === 'artikelStammId') return;
      valuesLabels.push(spalte);
    });

    return valuesLabels;
  };

  // function ->
  const showStellplaetzeTableDialog = (buchungsId) => {
    setStellplaetzeTableDialogState({ open: true, buchungsId });
  };

  // function ->
  const handleTransporteinheitenSuccess = (transporteinheitendaten, selectedSpalten) => {
    const totalElements = transporteinheitendaten.totalElements;
    const elementsPerPage = +elementsPerPageState <= 0 ? 1 : elementsPerPageState;
    const numOfPages = Math.ceil(totalElements / elementsPerPage);

    setNumOfPagesState(numOfPages);

    if (currentPageState > numOfPages) {
      setCurrentPageState(numOfPages);
      return;
    }

    const tableHeaders = createTableHeaders(selectedSpalten);
    const tableValuesLabels = createTableValuesLabels(selectedSpalten);

    const transporteinheiten = transporteinheitendaten.content;

    let isChosenTransporteinheit = false;

    if (isKommissionierung) {
      // Because of the rendering between the two tables (Transporteinheit and Bestand) this is the way to check if an item is chosen
      // only used when the parent is Warenausgangkommissionierung
      transporteinheiten.forEach((transporteinheit) => {
        const chosen = transporteinheit.buchungsId === objParentState.ausgewaehlteBuchungsIdState && !isChosenTransporteinheit;
        transporteinheit.chosen = chosen;
        if (chosen && !isChosenTransporteinheit) isChosenTransporteinheit = true;
      });
    }

    setTablesValuesLabelsState(tableValuesLabels);
    setTableHeadersState(tableHeaders);
    setTransporteinheitenState(transporteinheiten);

    setShowTableState(true);
  };

  // function ->
  const fetchTransporteinheitenFromApi = () => {
    objGlobalState.setPageLoadingState(true);

    const page = currentPageState - 1 <= 0 ? 0 : currentPageState - 1;
    const size = elementsPerPageState <= 0 ? 1 : elementsPerPageState;

    authAxios
      .get(`/transporteinheit/bestand/${artikelId}?artikel_id=${artikelId}&page=${page}&size=${size}`)
      .then((response) => {
        const transporteinheitendaten = response.data;
        console.log(transporteinheitendaten);
        authAxios.get('/usersettings').then((_response) => {
          const selectedTransporteinheitenSpalten = _response.data.spaltenGewaehltTransporteinheitenProArtikel;
          handleTransporteinheitenSuccess(transporteinheitendaten, selectedTransporteinheitenSpalten);
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchTransporteinheitenFromApi();
    }

    return () => (isMounted = false);
  }, [currentPageState, elementsPerPageState, rerunEffectState, objParentState.kommissionierungRerunEffectState]);

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, []);

  const objBestandstabelle = {
    paginationTableFooter: {
      currentPageState,
      setCurrentPageState,
      setElementsPerPageState,
      numOfPagesState,
      setNumOfPagesState,
      firstCellColSpan: 3,
      secondCellColSpan: 2,
      thirdCellColSpan: 3
    },
    spaltenlisteDialog: {
      setRerunEffectState,
      spaltenlisteDialogState,
      setSpaltenlisteDialogState,
      tableValuesLabelsState,
      tableCategory: TABLE_TYPES.TRANSPORTEINHEITEN
    },
    spaltenreihenfolgeDialog: {
      setRerunEffectState,
      spaltenreihenfolgeDialogState,
      setSpaltenreihenfolgeDialogState,
      tableCategory: TABLE_TYPES.TRANSPORTEINHEITEN
    },
    stellplaetzeTableDialog: {
      stellplaetzeTableDialogState,
      setStellplaetzeTableDialogState
    }
  };

  // function ->
  const chooseTransporteinheitOnClick = (transporteinheit) => {
    const newTransporteinheitenState = transporteinheitenState.map((_transporteinheit) => {
      _transporteinheit.chosen = false;
      if (transporteinheit === _transporteinheit) {
        _transporteinheit.chosen = true;
      }
      return _transporteinheit;
    });
    setTransporteinheitenState(newTransporteinheitenState);
    objParentState.chooseBestandTransporteinheitOnClick(artikelId, transporteinheit.buchungsId);
  };

  // function ->
  const createSelectBestandProps = (transporteinheit) => {
    return isKommissionierung
      ? {
          className: transporteinheit.chosen ? 'table__row--active' : 'table__row--hover',
          onClick: () => chooseTransporteinheitOnClick(transporteinheit)
        }
      : {};
  };
  return (
    <>
      {stellplaetzeTableDialogState.open && <CoStellplaetzeTableDialog objGlobalState={objGlobalState} objParentState={objBestandstabelle.stellplaetzeTableDialog} />}
      {spaltenlisteDialogState && <CoSpaltenlisteDialog objGlobalState={objGlobalState} objParentState={objBestandstabelle.spaltenlisteDialog} />}
      {spaltenreihenfolgeDialogState && <CoSpaltenreihenfolgeDialog objGlobalState={objGlobalState} objParentState={objBestandstabelle.spaltenreihenfolgeDialog} />}

      <Box className="table__title">
        <Typography align="center" className="typography__singleTableTitle">
          {language('TABLE', 'HEADER', 'TRANSPORTEINHEITEN', objGlobalState.lang)} - {language('TABLE', 'HEADER', 'ARTIKEL', objGlobalState.lang)} ({artikelId})
        </Typography>
        <div className="marginTwenty">
          {showTableState && (
            <>
              <Button variant="contained" color="primary" onClick={goToArtikel} className="table__titleButton">
                {language('BUTTON', 'TITEL', 'ZURUECK', objGlobalState.lang)}
              </Button>
              <Button variant="contained" color="primary" onClick={openSpaltenlisteDialog} className="table__titleButton">
                {language('BUTTON', 'TITEL', 'SPALTENAUSWAEHLEN', objGlobalState.lang)}
              </Button>
              <Button variant="contained" color="primary" onClick={openSpaltenreihenfolgeDialog} className="table__titleButton">
                {language('BUTTON', 'TITEL', 'SPALTENREIHENFOLGE', objGlobalState.lang)}
              </Button>
              <Button
                data-testid="transporteinheiten-tabelle-export-button"
                variant="contained"
                color="primary"
                onClick={() => exportTableAsCSV(stateToCSV(), 'transporteinheit')}
                className="table__titleButton"
              >
                {language('BUTTON', 'TITEL', 'TABELLEEXPORTIEREN', objGlobalState.lang)}
              </Button>
            </>
          )}
        </div>
      </Box>
      <TableContainer
        ref={tableRef}
        component={Paper}
        variant="outlined"
        elevation={0}
        square
        className={`coBestandstabelle__tableContainer ${heightFour ? 'heightFour' : ''} border__noTop backgroundTable`}
      >
        {showTableState && (
          <Table size="medium" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableHeadersState.map((header, key) => {
                  return (
                    <TableCell align="center" component="th" className="table__headerCell" key={`lagerverwaltung_transporteinheitentabelle_tableHeaders_${key}`}>
                      <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', header, objGlobalState.lang)}</Typography>
                    </TableCell>
                  );
                })}
                <TableCell align="center" component="th" className="table__headerCell">
                  <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'STELLPLAETZE', objGlobalState.lang)}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transporteinheitenState.map((transporteinheit, key) => {
                return (
                  <TableRow key={`lagerverwaltung_transporteinheitentabelle_tableRow_${key}`} {...createSelectBestandProps(transporteinheit)}>
                    {tableValuesLabelsState.map((value, _key) => (
                      <TableCell align="center" key={`lagerverwaltung_transporteinheitentabelle_tableCell_${key}_${_key}`}>
                        {value === 'ean' ? (
                          <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<VerticalAlignBottom />}
                            className="button__small noWrap"
                            onClick={() => getEANCodeFromAPI(transporteinheit.artikelStammId)}
                          >
                            {transporteinheit[value]}
                          </Button>
                        ) : (
                          <Typography className="typography__singleTableRowContent">
                            {value === 'temperaturEmpfindlich'
                              ? mapTemperaturEmpfindlichValueToLabel(transporteinheit[value])
                              : typeof transporteinheit[value] === 'boolean'
                              ? displayBoolean(transporteinheit[value])
                              : transporteinheit[value]}
                          </Typography>
                        )}
                      </TableCell>
                    ))}
                    <TableCell align="center">
                      <Button variant="outlined" color="primary" className="button__small" onClick={() => showStellplaetzeTableDialog(transporteinheit.buchungsId)}>
                        {language('BUTTON', 'TITEL', 'ANSEHEN', objGlobalState.lang)}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <CoPaginationTableFooter objParent={objBestandstabelle.paginationTableFooter} objGlobalState={objGlobalState} />
          </Table>
        )}
      </TableContainer>
    </>
  );
};

export default CoTransporteinheitentabelle;
