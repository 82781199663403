import React, { useLayoutEffect, useRef, useState } from 'react';
import language from '../../../language/language';
import { mouseScrollHorizontally } from '../../../helperFunctions/mouseScrollHorizontally';
import { createAxiosInstance, getAndCostumFiles } from '../../../helperFunctions/axios';

// import -> material ui -> core && icons
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

// import -> components
import CoShowFiles from '../../../Components/CoShowFiles';

// component -> React-root
const CoDetailstabelleDialog = ({ objGlobalState, objParentState }) => {
  const [dialogIsRenderedState, setDialogIsRenderedState] = useState(false);
  const [showFilesDialogState, setShowFilesDialogState] = useState({ open: false, title: '', files: [] });

  const tableRef = useRef();

  // function ->
  const showFilesDialogOnClick = (auftragId, title) => {
    const endpoint = title === 'LIEFERSCHEIN' ? 'lieferscheine' : title === 'STELLPLATZFOTOS' ? 'stellplatz_fotos' : '';

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    authAxios
      .get(`/auftrag/${auftragId}/${endpoint}`)
      .then((response) => {
        const { data } = response;
        const files = [];

        if (!data.length) {
          setShowFilesDialogState({ open: true, title: title, files: files });
        }

        data.forEach((file) => {
          getAndCostumFiles({ file: file, filesArray: files, authAxios: authAxios }).then((result) => {
            setShowFilesDialogState({ open: true, title: title, files: result });
          });
        });
      })
      .catch((error) => console.log(error));
  };

  // function ->
  const closeDialogOnClose = () => {
    objParentState.setDetailsTabelleDialogState((pre) => ({ ...pre, open: false }));
  };

  // function ->
  const mapObjToArrayOfObjects = (obj) => {
    const array = [];
    for (let key in obj) {
      if (key !== 'id') {
        const element = { name: key.toUpperCase(), value: obj[key], id: obj.id };

        array.push(element);
      }
    }
    return array;
  };

  // function ->
  const handleDialogOnRendered = () => {
    setDialogIsRenderedState(true);
  };

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted && dialogIsRenderedState) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, [dialogIsRenderedState]);

  const dataValuesObjects = mapObjToArrayOfObjects(objParentState.detailstabelleDialogState.dataset);

  const objDetailstabelleState = {
    showFilesDialogState,
    setShowFilesDialogState
  };

  return (
    <>
      {showFilesDialogState.open && <CoShowFiles objGlobalState={objGlobalState} objParentState={objDetailstabelleState} />}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={objParentState.detailstabelleDialogState.open}
        onClose={closeDialogOnClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onRendered={handleDialogOnRendered}
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
            <Grid item xs={6}>
              <Typography align="left" className="typography__dialogTitle">
                {language('DIALOG', 'TITEL', 'DETAILLIERTEANSICHT', objGlobalState.lang)}
              </Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <IconButton className="button__iconButton--close" onClick={closeDialogOnClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="dialog__content">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className="table__container">
                <Table size="medium">
                  <TableBody>
                    {dataValuesObjects.map((obj, key) => {
                      const { id } = obj;
                      if (obj.name === 'STATUS') {
                        const status = objParentState.findSelectValue(id);
                        const selectClassNameForColors = status === 1 ? 'red' : status === 2 ? 'orange' : status === 3 ? 'green' : '';

                        return (
                          <TableRow key={`coDetailstabelledialog_table_${key}`}>
                            <TableCell align="left" component="th" className="table__rowTitle">
                              <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', obj.name, objGlobalState.lang)}</Typography>
                            </TableCell>
                            <TableCell align="right">
                              <FormControl variant="standard">
                                <Select
                                  value={objParentState.findSelectValue(id)}
                                  onChange={(event) => objParentState.changeSelectValue(event, id)}
                                  className={`statusSelect statusSelect--${selectClassNameForColors}`}
                                >
                                  <MenuItem value={1}>{language('FORM', 'LABEL', 'WARTEND', objGlobalState.lang)}</MenuItem>
                                  <MenuItem value={2}>{language('FORM', 'LABEL', 'INBEARBEITUNG', objGlobalState.lang)}</MenuItem>
                                  <MenuItem value={3}>{language('FORM', 'LABEL', 'VERSENDET', objGlobalState.lang)}</MenuItem>
                                </Select>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        );
                      } else if (obj.name === 'STELLPLATZFOTOS' || obj.name === 'LIEFERSCHEIN') {
                        return (
                          <TableRow key={`coDetailstabelledialog_table_${key}`}>
                            <TableCell align="left" component="th" className="table__rowTitle">
                              <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', obj.name, objGlobalState.lang)}</Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Button color="primary" variant="outlined" className="button__small" onClick={() => showFilesDialogOnClick(id, obj.name)}>
                                {language('BUTTON', 'TITEL', 'ANSEHEN', objGlobalState.lang)}
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      } else {
                        return (
                          <TableRow key={`coDetailstabelledialog_table_${key}`}>
                            <TableCell align="left" component="th" className="table__rowTitle">
                              <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', obj.name, objGlobalState.lang)}</Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography className="typography__singleTableRowContent">{obj.value}</Typography>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoDetailstabelleDialog;
