import React from 'react';
import language from '../../Common/language/language';

// import -> material-ui -> core
import { Container, Grid } from '@material-ui/core';

// component -> react-root
function CoBubbles({ objGlobalState, objTrackingState }) {
  // function ->
  const pickClassName = (position) => {
    //destructuring -> get the icons array from the trackingState
    const {
      trackingState: { trackingIcons }
    } = objTrackingState;

    const trackingIcon = trackingIcons.find((icon) => icon.position === position);

    return Boolean(trackingIcon.status);
  };

  return (
    <Container component="div" maxWidth="xl" className="coTracking__bubblesContainer">
      <Grid container spacing={3}>
        <Grid item md={1} xs={2}>
          <img
            src={`../../assets/images/tracking_state_icons/recieve.png`}
            alt=""
            className={`images__trackingIcon  images__trackingIcon--${pickClassName(0) ? 'done' : 'inProgress'}`}
            title={language('IMAGES', 'TITEL', 'SENDUNGERFASST', objGlobalState.lang)}
            data-position={0}
          />
        </Grid>
        <Grid item md={1} xs={2}>
          <img
            src={`../../assets/images/tracking_state_icons/truck.png`}
            alt=""
            className={`images__trackingIcon images__trackingIcon--${pickClassName(1) ? 'done' : 'inProgress'}`}
            title={language('IMAGES', 'TITEL', 'SENDUNGZUMZUSTELLDEPOT', objGlobalState.lang)}
            data-position={1}
          />
        </Grid>
        <Grid item md={1} xs={2}>
          <img
            src={`../../assets/images/tracking_state_icons/depot.png`}
            alt=""
            className={`images__trackingIcon images__trackingIcon--${pickClassName(2) ? 'done' : 'inProgress'}`}
            title={language('IMAGES', 'TITEL', 'SENDUNGIMZUSTELLDEPOT', objGlobalState.lang)}
            data-position={2}
          />
        </Grid>
        <Grid item md={1} xs={2}>
          <img
            src={`../../assets/images/tracking_state_icons/truck.png`}
            alt=""
            className={`images__trackingIcon images__trackingIcon--${pickClassName(3) ? 'done' : 'inProgress'}`}
            title={language('IMAGES', 'TITEL', 'SENDUNGZUMEMPFAENGER', objGlobalState.lang)}
            data-position={3}
          />
        </Grid>
        <Grid item md={1} xs={2}>
          <img
            src={`../../assets/images/tracking_state_icons/check.png`}
            alt=""
            className={`images__trackingIcon images__trackingIcon--${pickClassName(4) ? 'done' : 'inProgress'}`}
            title={language('IMAGES', 'TITEL', 'SENDUNGZUGESTELLT', objGlobalState.lang)}
            data-position={4}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default CoBubbles;
