import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import language from '../../Common/language/language';
import { displayArraysInTable } from '../../Common/helperFunctions/displayArrayInTable';
import { generateDate } from '../../Common/helperFunctions/date';
import { mouseScrollHorizontally } from '../../Common/helperFunctions/mouseScrollHorizontally';
import { isObjectEmpty } from '../../Common/helperFunctions/objects';

// import -> material ui -> core & icons
import { Dialog, DialogContent, DialogTitle, Grid, Typography, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

// component -> React-root
const CoWarenausgang = ({ objGlobalState, objHistorieStateAndFunctions }) => {
  const { open, data } = objHistorieStateAndFunctions.vorgaengeDialogState.warenausgang;

  const [dialogIsRenderedState, setDialogIsRenderedState] = useState(false);
  const [auftragState, setAuftragState] = useState({});
  const [kommissionierungState, setKommissionierungState] = useState({});

  const auftragTable = {
    tableId: 'warenausgang_auftrag',
    ref: useRef(),
    title: 'AUFTRAG',
    data: auftragState,
    headers: [
      'STATUS',
      'AUFTRAGSNUMMER',
      'DATUM',
      'NAME1',
      'NAME2',
      'NAME3',
      'EMAILADRESSE',
      'TELEFONNUMMER',
      'STRASSE',
      'PLZ',
      'ORT',
      'LAND',
      'ZUSAETZLICHEINFORMATIONEN',
      'REFERENZ',
      'SPEDITION',
      'VERSANDDATUM',
      'ANZAHLDERPAKETE',
      'ANZAHLDERPAKETEPROPAKETSENDUNG',
      'ANZAHLDERPALETTEN',
      'ANZAHLDERPAKETEPROPALETTE',
      'SENDUNGSART'
    ],
    exeptions: []
  };

  const kommissionierungTable = {
    tableId: 'warenausgang_kommissionierung',
    ref: useRef(),
    title: 'KOMMISSIONIERUNG',
    data: kommissionierungState,
    headers: ['STELLPLATZKENNZEICHNUNG', 'STELLPLATZFOTOS', 'LIEFERSCHEIN'],
    exeptions: [
      {
        name: 'STELLPLATZFOTOS',
        handler: (key, header, data, lastTableCells) => {
          return objHistorieStateAndFunctions.handleExeptionWithOneSetOfFiles(key, header, data, 'STELLPLATZFOTOS', lastTableCells);
        }
      },
      {
        name: 'LIEFERSCHEIN',
        handler: (key, header, data, lastTableCells) => {
          return objHistorieStateAndFunctions.handleExeptionWithOneSetOfFiles(key, header, data, 'LIEFERSCHEIN', lastTableCells);
        }
      }
    ]
  };

  // function ->
  const handleDialogOnRendered = () => {
    setDialogIsRenderedState(true);
  };

  // function ->
  const mapParentDataToWarenausgangData = () => {
    const { auftrag: parentAuftrag, kommisionierung: parentKommissionierung } = data;

    const shipmentTypes = [
      {
        name: 'PAKETSENDUNG',
        id: 1
      },
      {
        name: 'PALETTENSENDUNG',
        id: 2
      }
    ];

    const versanddatum = parentAuftrag?.versanddatum ? generateDate(parentAuftrag?.versanddatum).dateAndTime : '';

    const foundShipment = shipmentTypes.find((element) => element.id === parentAuftrag?.shipmentType.id);
    const shipmentType = foundShipment ? language('KONTENT', 'TEXT', foundShipment.name, objGlobalState.lang) : '';

    let status = 'WARTEND';
    switch (parentAuftrag?.status.id) {
      case 1:
        status = 'WARTEND';
        break;
      case 2:
        status = 'INBEARBEITUNG';
        break;
      case 3:
        status = 'VERSENDET';
    }

    const warenausgangAuftrag = {
      auftragsnummer: parentAuftrag?.auftragsnummer,
      datum: parentAuftrag.datum ? generateDate(parentAuftrag.datum).justDate : '',
      name1: parentAuftrag?.name1,
      name2: parentAuftrag?.name2,
      name3: parentAuftrag?.name3,
      emailadresse: parentAuftrag?.email,
      telefonnummer: parentAuftrag?.telefonnummer,
      strasse: parentAuftrag?.strasse,
      plz: parentAuftrag?.plz,
      ort: parentAuftrag?.ort,
      land: parentAuftrag?.land,
      zusaetzlicheinformationen: parentAuftrag?.zusatzinformation,
      referenz: parentAuftrag?.auftragsreferenz,
      spedition: parentAuftrag?.dienstleister,
      sendungsart: shipmentType,
      stellplatzkennzeichnung: parentAuftrag?.stellplatz,
      anzahlderpakete: displayArraysInTable(parentAuftrag?.anzahlPakete),
      anzahlderpaketeProPaketsendung: displayArraysInTable(parentAuftrag?.anzahlPaketeProPaket),
      anzahlderpaletten: displayArraysInTable(parentAuftrag?.anzahlPaletten),
      anzahlderpaketeProPalette: displayArraysInTable(parentAuftrag?.anzahlPaketeProPalette),
      versanddatum: versanddatum,
      status: language('FORM', 'LABEL', status, objGlobalState.lang),
      id: parentAuftrag?.id
    };

    const warenausgangKommissionierung = {
      stellplatzkennzeichnung: parentKommissionierung?.stellplatz,
      stellplatzfotos: parentKommissionierung?.stellplatzFiles,
      lieferschein: parentKommissionierung?.lieferscheinFiles
    };

    setAuftragState(parentAuftrag ? warenausgangAuftrag : {});
    setKommissionierungState(parentKommissionierung ? warenausgangKommissionierung : {});
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    mapParentDataToWarenausgangData();

    return () => {
      isMounted = false;
    };
  }, []);

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const auftragTableElement = auftragTable.ref.current;
      const kommissionierungTableElement = kommissionierungTable.ref.current;
      mouseScrollHorizontally(auftragTableElement);
      mouseScrollHorizontally(kommissionierungTableElement);
    }

    return () => (isMounted = false);
  }, [dialogIsRenderedState]);

  return (
    <Dialog
      fullWidth
      onClose={() => objHistorieStateAndFunctions.closeVorgaengeDialogOnClose(objHistorieStateAndFunctions.VORGAENGE.WARENAUSGANG)}
      maxWidth="md"
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onRendered={handleDialogOnRendered}
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
          <Grid item xs={6}>
            <Typography align="left" className="typography__dialogTitle">
              {language('DIALOG', 'TITEL', 'WARENAUSGANG', objGlobalState.lang)}
            </Typography>
          </Grid>
          <Grid item xs={6} container justify="flex-end">
            <IconButton className="button__iconButton--close" onClick={() => objHistorieStateAndFunctions.closeVorgaengeDialogOnClose(objHistorieStateAndFunctions.VORGAENGE.WARENAUSGANG)}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className="dialog__content">
        <Grid container spacing={1}>
          {!isObjectEmpty(auftragState) && (
            <Grid item xs={12}>
              {objHistorieStateAndFunctions.createTable(auftragTable)}
            </Grid>
          )}
          {!isObjectEmpty(kommissionierungState) && (
            <Grid item xs={12}>
              {objHistorieStateAndFunctions.createTable(kommissionierungTable)}
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CoWarenausgang;
