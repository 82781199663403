import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import language from '../../Common/language/language';
import { createAxiosInstance } from '../../Common/helperFunctions/axios';

// import -> material ui -> core && icons
import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';

// import -> components
import CoConfirmation from '../../Common/Components/CoConfirmation';

// component -> React-root
const CoAddStellplatze = ({ objGlobalState, objParentState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const [confirmationDialogState, setConfirmationDialogState] = useState(false);

  const [stellplatzanzahlState, setStellplatzanzahlState] = useState('');

  const [newDataValueState, setNewDataValueState] = useState({
    stellplatzanzahl: ''
  });

  // function ->
  const closeDialog = () => {
    objParentState.setAddStellplaetzeDialogState((pre) => ({ ...pre, open: false }));
  };

  // function ->
  const changeStellplatzanzahlOnChange = (event) => {
    if (Number.isInteger(+event.target.value) && !event.target.value.includes('.')) {
      setStellplatzanzahlState(event.target.value);
    }
  };

  // function ->
  const closeConfirmationOnClick = () => {
    setConfirmationDialogState(false);
  };

  // function ->
  const submitFormOnClick = (event) => {
    event.preventDefault();

    const stellplatzanzahl = stellplatzanzahlState;

    setNewDataValueState({ stellplatzanzahl });
    setConfirmationDialogState(true);
  };

  // function ->
  const confirmed = () => {
    objGlobalState.setPageLoadingState(true);

    const { stellplatzanzahl } = newDataValueState;

    const requestValues = {
      anzahl: +stellplatzanzahl
    };

    const lagerortId = objParentState.addStellplaetzeDialogState.lagerortObj.id;

    authAxios
      .post(`/lagerort/${lagerortId}/stellplatz`, requestValues)
      .then(() => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT'), { variant: 'success' });
        closeConfirmationOnClick();
        closeDialog();
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  const addStellplaetze = {
    confirmation: {
      closeConfirmationOnClick,
      confirmationDialogState,
      confirmed,
      newDataValueState
    }
  };

  return (
    <>
      {confirmationDialogState && <CoConfirmation objGlobalState={objGlobalState} objParentState={addStellplaetze.confirmation} />}

      <Dialog fullWidth maxWidth="sm" open={objParentState.addStellplaetzeDialogState.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{language('DIALOG', 'TITEL', 'STELLPLAETZEHINZUFUEGEN', objGlobalState.lang)}</DialogTitle>
        <DialogContent className="dialog__content">
          <form name="submitForm" noValidate autoComplete="on" onSubmit={submitFormOnClick}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography className="typography">
                  {language('KONTENT', 'TEXT', 'LAGERORT', objGlobalState.lang)}: {objParentState.addStellplaetzeDialogState.lagerortObj.lagername}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={stellplatzanzahlState}
                  onChange={changeStellplatzanzahlOnChange}
                  fullWidth
                  variant="outlined"
                  label={language('FORM', 'LABEL', 'STELLPLATZANZAHL', objGlobalState.lang)}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={12} md={6}>
                <Button fullWidth type="submit" color="primary" className="button__middle" variant="contained">
                  {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button onClick={closeDialog} fullWidth className="button__middle">
                  {language('BUTTON', 'TITEL', 'ABBRECHEN', objGlobalState.lang)}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoAddStellplatze;
