import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import language from '../Common/language/language';
import { generateDate } from '../Common/helperFunctions/date';
import { mouseScrollHorizontally } from '../Common/helperFunctions/mouseScrollHorizontally';
import { displayArraysInTable } from '../Common/helperFunctions/displayArrayInTable';
import { createAxiosInstance, getAndCostumFiles } from '../Common/helperFunctions/axios';

// import -> services
import ServiceBreadcrumbs from '../Common/services/Breadcrumbs/ServiceBreadcrumbs';

// import -> material ui -> core & icons
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { ContactlessOutlined, YoutubeSearchedFor } from '@material-ui/icons';

// import -> component
import CoConfirmation from '../Common/Components/CoConfirmation';
import CoLogistikunternhemenSelect from '../Common/Components/CoLogistikunternhemenSelect';
import CoShowFiles from '../Common/Components/CoShowFiles';
import CoArtikeltabelle from '../Common/Components/Artikelverwaltung/CoArtikeltabelle';
import { exportTableAsCSV } from '../Common/helperFunctions/exportTable';
import { generateCSVString } from '../Common/helperFunctions/generateCSVString';
import { removeFromArray } from '../Common/helperFunctions/arrays';

// component -> react-root
const CoAuftragsverwaltungauftrag = ({ objGlobalState }) => {
  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);
  const hasLagerAccess = objGlobalState.authenticateState.user.access.includes('LAGER');

  const { enqueueSnackbar } = useSnackbar();

  const [showFilesDialogState, setShowFilesDialogState] = useState({ open: false, files: [], title: null });
  const [confirmationDialogState, setConfirmationDialogState] = useState(false);
  const [sendungenState, setSendungenState] = useState([]);
  const [ausgewaehlteSendungState, setAusgewaehlteSendungState] = useState({ chosen: false, sendung: {} });
  const [speditionState, setSpeditionState] = useState('');
  const [auftragsnummerState, setAuftragsnummerState] = useState('');
  const [sendungsartState, setSendungsartState] = useState({
    paketsendung: { id: 1, name: 'PAKETSENDUNG', checked: false },
    palettensendung: { id: 2, name: 'PALETTENSENDUNG', checked: false }
  });
  const [newDataValueState, setNewDataValueState] = useState({
    auftragsnummer: '',
    datum: '',
    sendung: '',
    name1: '',
    name2: '',
    name3: '',
    telefonnummer: '',
    emailadresse: '',
    strasse: '',
    plz: '',
    ort: '',
    land: '',
    zusaetzlicheInformationen: '',
    referenz: '',
    spedition: '',
    versanddatum: '',
    uhrzeit: '',
    sendungsart: ''
  });
  const [sendEmailCheckState, setSendEmailCheckState] = useState(false);
  const [ausgewaehlteArtikelState, setAusgewaehlteArtikelState] = useState({ chosen: false, artikel: {} });

  const tableRef = useRef();
  const tableHeaders = ['NUMMER', 'LIEFERANT', 'SPEDITION', 'DATUM', 'ERSTELLUNGSDATUM', 'BEGLEITPAPIERE'];

  // function ->
  const stateToCSV = () => {
    const csvRows = [];
    const headers = [...tableHeaders];
    removeFromArray(headers, 'BEGLEITPAPIERE');

    const headerRow = headers.map((header) => generateCSVString(language('TABLE', 'TITEL', header, objGlobalState.lang)));
    const tableValuesLabels = ['id', 'lieferant', 'spedition', 'datum', 'erstellungsDatum'];
    csvRows.push(headerRow.join(';'));
    sendungenState.forEach((sendung) => {
      const valueRow = tableValuesLabels.map((value) => generateCSVString(sendung[value]));
      csvRows.push(valueRow.join(';'));
    });
    return csvRows.join('\n');
  };

  // function ->
  const showFilesDialogOnClick = (event, deliveryId) => {
    event.stopPropagation();
    const title = 'BEGLEITPAPIERE';
    const files = [];

    authAxios
      .get(`delivery/findPreviewsForDeliveryNote/${deliveryId}`)
      .then((response) => {
        const { data } = response;
        const dataIterator = data[0].previewDTOList;

        if (!dataIterator.length) {
          setShowFilesDialogState({ open: true, title: title, files: files });
        }

        dataIterator.forEach((fileObj) => {
          getAndCostumFiles({ file: fileObj, filesArray: files, authAxios: authAxios }).then((result) => {
            setShowFilesDialogState({ open: true, title: title, files: result });
          });
        });
      })
      .catch((error) => console.log(error));
  };

  // function ->
  const chooseSendungOnClick = (sendung) => {
    setAusgewaehlteSendungState({ chosen: true, sendung: sendung });

    const currentSendungenState = [...sendungenState];

    currentSendungenState.map((_sendung) => {
      _sendung.chosen = false;

      if (_sendung === sendung) {
        _sendung.chosen = true;
      }

      return _sendung;
    });

    setSendungenState(currentSendungenState);
  };

  // function ->
  const showAusgewaehlteSendung = () => {
    // destructuring the needed data to display
    const { id, lieferant, spedition, datum, erstellungsDatum } = ausgewaehlteSendungState.sendung;

    // array -> put the destructured data in an array to map on them
    const values = [
      { name: 'nummer', value: id },
      { name: 'lieferant', value: lieferant },
      { name: 'spedition', value: spedition },
      { name: 'datum', value: datum },
      { name: 'erstellungsDatum', value: erstellungsDatum },
      { name: 'begleitpapiere' }
    ];
    return (
      <TableRow>
        {values.map((value, key) => {
          if (value.name === 'begleitpapiere') {
            return (
              <TableCell align="center" key={`auftragsverwaltung_auftrag_ausgewaehlteSendung_TableCell_${key}`}>
                <Button onClick={(event) => showFilesDialogOnClick(event, id)} color="primary" variant="outlined" className="button__small">
                  {language('BUTTON', 'TITEL', 'ANSEHEN', objGlobalState.lang)}
                </Button>
              </TableCell>
            );
          } else {
            return (
              <TableCell align="center" key={`auftragsverwaltung_auftrag_sendungen_TableCell_${key}`} className="table__rowTitle">
                <Typography className="typography__singleTableRowContent">{displayArraysInTable(value.value)}</Typography>
              </TableCell>
            );
          }
        })}
      </TableRow>
    );
  };

  // function ->
  const removeAusgewahlteSendungAfterConfirm = () => {
    const newSendungenState = sendungenState.filter((sendung) => sendung != ausgewaehlteSendungState.sendung);
    setSendungenState(newSendungenState);
    setAusgewaehlteSendungState({ chosen: false, sendung: {} });
  };

  // function ->
  const generateRandomNumber = (minNumber, maxNumber) => {
    const result = Math.floor(Math.random() * (maxNumber - minNumber + 1) + minNumber);

    return result;
  };

  // function ->
  const generateAuftragsnummer = () => {
    // the smaple is A777-DC3
    const capitalLetterCahrCodesInterval = [65, 90];

    const char1 = String.fromCharCode(generateRandomNumber(capitalLetterCahrCodesInterval[0], capitalLetterCahrCodesInterval[1]));
    const char2 = String(generateRandomNumber(0, 9));
    const char3 = String(generateRandomNumber(0, 9));
    const char4 = String(generateRandomNumber(0, 9));
    const char5 = '-';
    const char6 = String.fromCharCode(generateRandomNumber(capitalLetterCahrCodesInterval[0], capitalLetterCahrCodesInterval[1]));
    const char7 = String.fromCharCode(generateRandomNumber(capitalLetterCahrCodesInterval[0], capitalLetterCahrCodesInterval[1]));
    const char8 = String(generateRandomNumber(0, 9));

    const result = char1 + char2 + char3 + char4 + char5 + char6 + char7 + char8;

    setAuftragsnummerState(result);
  };

  // function ->
  const showAusgewaehlteArtikel = () => {
    const { id, pzn, hersteller, herstellerNummer, bezeichnung, artikelnummer } = ausgewaehlteArtikelState.artikel;
    const values = [id, pzn, hersteller, herstellerNummer, bezeichnung, artikelnummer];

    return (
      <TableRow>
        {values.map((value, key) => {
          return (
            <TableCell align="center" key={`artikelverwaltung_transporteinheiten_ausgewaehlteArtikel_tableCell_${key}`}>
              <Typography className="typography__singleTableRowContent">{value}</Typography>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  // function ->
  const handleAuftragsnummerOnChange = (event) => {
    setAuftragsnummerState(event.target.value);
  };

  // function ->
  const handleSpeditionOnChange = (event) => {
    setSpeditionState(event.target.value);
  };

  // function ->
  const checkSendungsart = (name) => {
    // if one is true then the other one is false
    setSendungsartState((pre) => {
      switch (name) {
        case 'paketsendung':
          return {
            paketsendung: { ...pre.paketsendung, checked: !pre.paketsendung.checked },
            palettensendung: { ...pre.palettensendung, checked: false }
          };
        case 'palettensendung':
          return {
            palettensendung: { ...pre.palettensendung, checked: !pre.palettensendung.checked },
            paketsendung: { ...pre.paketsendung, checked: false }
          };
        default:
          return pre;
      }
    });
  };

  // function ->
  const submitFormOnClick = (event) => {
    event.preventDefault();

    const auftragsnummer = event.target.auftragsnummer.value;
    const datum = event.target.datum.value;
    const sendung = ausgewaehlteSendungState.sendung?.id;
    const artikel = ausgewaehlteArtikelState.artikel?.id;
    const name1 = event.target.name1.value;
    const name2 = event.target.name2.value;
    const name3 = event.target.name3.value;
    const telefonnummer = event.target.telefonnummer.value;
    const emailadresse = event.target.emailadresse.value;
    const strasse = event.target.strasse.value;
    const plz = event.target.plz.value;
    const ort = event.target.ort.value;
    const land = event.target.land.value;
    const zusaetzlicheInformationen = event.target.zusaetzlicheInformationen.value;
    const referenz = event.target.referenz.value;
    const spedition = speditionState;
    const versanddatum = event.target.versanddatum.value;
    const uhrzeit = event.target.uhrzeit.value;
    const sendungsart = sendungsartState.paketsendung.checked
      ? language('KONTENT', 'TEXT', sendungsartState.paketsendung.name, objGlobalState.lang)
      : sendungsartState.palettensendung.checked
      ? language('KONTENT', 'TEXT', sendungsartState.palettensendung.name, objGlobalState.lang)
      : '';

    setNewDataValueState({
      auftragsnummer,
      datum,
      sendung,
      artikel,
      name1,
      name2,
      name3,
      telefonnummer,
      emailadresse,
      strasse,
      plz,
      ort,
      land,
      zusaetzlicheInformationen,
      referenz,
      spedition,
      versanddatum,
      uhrzeit,
      sendungsart
    });
    setConfirmationDialogState(true);
  };

  // function ->
  const closeConfirmationOnClick = () => {
    setConfirmationDialogState(false);
  };

  // function ->
  const confirmed = () => {
    objGlobalState.setPageLoadingState(true);

    const {
      auftragsnummer,
      datum,
      sendung,
      artikel,
      name1,
      name2,
      name3,
      telefonnummer,
      emailadresse,
      strasse,
      plz,
      ort,
      land,
      zusaetzlicheInformationen,
      referenz,
      spedition,
      versanddatum,
      uhrzeit
    } = newDataValueState;

    const shipmentTypeId = sendungsartState.paketsendung.checked ? sendungsartState.paketsendung.id : sendungsartState.palettensendung.checked ? sendungsartState.palettensendung.id : 0;

    const requestVersanddatum = versanddatum ? generateDate(`${versanddatum} ${uhrzeit}`).dateAndTimeForDataBase : '';

    const requestValues = {
      auftragsnummer,
      auftragsreferenz: referenz,
      datum,
      deliveryId: sendung,
      name1,
      name2,
      name3,
      telefonnummer,
      email: emailadresse,
      strasse,
      plz,
      ort,
      land,
      zusatzinformation: zusaetzlicheInformationen,
      dienstleister: spedition,
      versanddatum: requestVersanddatum,
      shipmentTypeId: shipmentTypeId,
      artikelStammId: artikel
    };

    const requestUrl = hasLagerAccess ? '/auftrag/artikel' : '/auftrag';

    authAxios
      .post(requestUrl, requestValues)
      .then((response) => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT'), { variant: 'success' });
        closeConfirmationOnClick();
        removeAusgewahlteSendungAfterConfirm();
        setSendEmailCheckState(false);

        if (!sendEmailCheckState) return;

        const auftragId = response.data.id;
        authAxios
          .post(`/auftrag/${auftragId}/sende-auftragsbestaetigung`)
          .then(() => {
            enqueueSnackbar(language('ALERT', 'TEXT', 'EMAILGESENDET'), { variant: 'success' });
          })
          .catch((error) => {
            console.log(error);
            enqueueSnackbar(language('ALERT', 'TEXT', 'EMAILNICHTGESENDET'), { variant: 'error' });
          });
      })
      .catch((error) => {
        const auftragDuplicatedMessage = error?.response?.data?.errors?.[0];
        const duplicationMessage = 'DUPLICATE_AUFTRAGSNUMMER';
        if (auftragDuplicatedMessage === duplicationMessage) {
          enqueueSnackbar(language('ALERT', 'TEXT', 'AUFTRAGSNUMMERVORHANDEN'), { variant: 'error' });
        } else {
          enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
        }
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const fetchLieferungInformationFromAPI = () => {
    objGlobalState.setPageLoadingState(true);

    authAxios
      .get('/delivery/list')
      .then((response) => {
        const { data } = response;

        const newSendungenState = [];
        data.forEach((dataObj) => {
          if (!dataObj.currentStatus || dataObj.currentStatus?.id !== 3) {
            return;
          }
          // 07.12.1110 is the date we get when the user don't enter a date value; we then send a default date value
          const date = generateDate(dataObj.deliveryDate).justDate === '07.12.1110' ? '' : generateDate(dataObj.deliveryDate).justDate;

          const newdataObj = {
            id: dataObj.id,
            chosen: false,
            lieferant: dataObj.supplier || '',
            spedition: dataObj.shippingCompany || '',
            datum: date,
            erstellungsDatum: generateDate(dataObj.dateCreated).justDate
          };
          newSendungenState.unshift(newdataObj);
        });

        setSendungenState(newSendungenState);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchLieferungInformationFromAPI();
    }

    return () => (isMounted = false);
  }, []);

  const objAuftrag = {
    confirmation: {
      closeConfirmationOnClick,
      confirmationDialogState,
      setConfirmationDialogState,
      confirmed,
      sendEmailCheckState,
      setSendEmailCheckState,
      newDataValueState
    },
    logistikunternehmenSelect: {
      selectValue: speditionState,
      handleInputOnChange: handleSpeditionOnChange,
      speditionenToExclude: ['chors']
    },
    showFiles: {
      showFilesDialogState,
      setShowFilesDialogState
    },
    artikeltabelle: {
      setAusgewaehlteArtikelState
    }
  };

  // array -> create breadcrumbs
  const arrBreadcrumbs = [
    { url: '/', title: language('SEITE', 'TITEL', 'HOME', objGlobalState.lang) },
    { url: '/auftragsverwaltung_auftrag', title: language('SEITE', 'TITEL', 'AUFTRAGSVERWALTUNGAUFTRAG', objGlobalState.lang) }
  ];

  return (
    <>
      {confirmationDialogState && <CoConfirmation objGlobalState={objGlobalState} objParentState={objAuftrag.confirmation} options={{ sendEmailCheck: true }} />}
      {showFilesDialogState.open && <CoShowFiles objGlobalState={objGlobalState} objParentState={objAuftrag.showFiles} />}

      <Container component="main" maxWidth="xl" className="container__container">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ServiceBreadcrumbs arrBreadcrumbs={arrBreadcrumbs} />
          </Grid>
          <Grid item xs={12}>
            <ListItem className="container__listItem">
              <ListItemIcon>
                <img src={`../assets/images/icons/icon_auftrag.png`} alt="" className="images__headerIcon" />
              </ListItemIcon>
              <ListItemText>
                <Typography className="typography__headerTitle">{language('SEITE', 'TITEL', 'AUFTRAGSVERWALTUNGAUFTRAG', objGlobalState.lang)}</Typography>
              </ListItemText>
            </ListItem>
          </Grid>
          <Grid item xs={12} className="marginThirty">
            <form name="submitForm" noValidate autoComplete="on" onSubmit={submitFormOnClick}>
              <Grid container spacing={3}>
                <Grid container spacing={3} item xs={12}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      value={auftragsnummerState}
                      onChange={handleAuftragsnummerOnChange}
                      type="text"
                      variant="outlined"
                      name="auftragsnummer"
                      label={language('FORM', 'LABEL', 'AUFTRAGSNUMMER', objGlobalState.lang)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={generateAuftragsnummer} color="primary" title={language('BUTTON', 'TITEL', 'ERZEUGEN', objGlobalState.lang)}>
                              <YoutubeSearchedFor />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      disabled
                      fullWidth
                      type="text"
                      value={generateDate().dateForInputField}
                      variant="outlined"
                      name="datum"
                      label={language('FORM', 'LABEL', 'DATUM', objGlobalState.lang)}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} className="marginThirty">
                  <Box className="table__title">
                    <Typography align="center" className="typography__singleTableTitle">
                      {language('TABLE', 'HEADER', 'SENDUNGEN', objGlobalState.lang)}
                    </Typography>
                    <div className="marginTwenty">
                      {
                        <Button variant="contained" color="primary" onClick={() => exportTableAsCSV(stateToCSV(), 'sendung')} className="table__titleButton">
                          {language('BUTTON', 'TITEL', 'TABELLEEXPORTIEREN', objGlobalState.lang)}
                        </Button>
                      }
                    </div>
                  </Box>
                  <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className="table__container--border heightFour border__noTop">
                    <Table size="medium" stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {tableHeaders.map((header, key) => {
                            return (
                              <TableCell align="center" component="th" className="table__headerCell" key={`auftragsverwaltung_auftrag_sendungen_TableHeaders_${key}`}>
                                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', header, objGlobalState.lang)}</Typography>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sendungenState.map((item, key) => {
                          // destructuring the needed data to display
                          const { lieferant, spedition, datum, erstellungsDatum, id } = item;

                          // array -> put the destructured data in an array to map on them
                          const values = [
                            { name: 'nummer', value: id },
                            { name: 'lieferant', value: lieferant },
                            { name: 'spedition', value: spedition },
                            { name: 'datum', value: datum },
                            { name: 'erstellungsDatum', value: erstellungsDatum },
                            { name: 'begleitpapier' }
                          ];

                          const lastTableCellClassName = key === sendungenState.length - 1 ? 'noBorderBottom' : '';

                          return (
                            <TableRow
                              key={`auftragsverwaltung_auftrag_sendungen_TableRow_${key}`}
                              className={item.chosen ? 'table__row--active' : 'table__row--hover'}
                              onClick={() => chooseSendungOnClick(item)}
                            >
                              {values.map((value, _key) => {
                                if (value.name === 'begleitpapier') {
                                  return (
                                    <TableCell className={lastTableCellClassName} align="center" key={`auftragsverwaltung_auftrag_sendungen_TableCell_${key}_${_key}`}>
                                      <Button onClick={(event) => showFilesDialogOnClick(event, id)} className="button__small" variant="outlined" color="primary">
                                        {language('BUTTON', 'TITEL', 'ANSEHEN', objGlobalState.lang)}
                                      </Button>
                                    </TableCell>
                                  );
                                } else {
                                  return (
                                    <TableCell className={lastTableCellClassName} align="center" key={`auftragsverwaltung_auftrag_sendungen_TableCell_${key}_${_key}`}>
                                      <Typography className="typography__singleTableRowContent">{displayArraysInTable(value.value)}</Typography>
                                    </TableCell>
                                  );
                                }
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} className="marginThirty">
                  <Typography className="typography__sectionTitle">{language('ABTEILUNG', 'TITEL', 'SENDUNG', objGlobalState.lang)}</Typography>
                </Grid>
                <Grid item xs={12} className="marginTwenty">
                  {ausgewaehlteSendungState.chosen ? (
                    <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} className="table__container">
                      <Table size="medium">
                        <TableBody>{showAusgewaehlteSendung()}</TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Typography className="typography__colorDamage">{language('KONTENT', 'TEXT', 'KEINESENDUNGAUSGEWAEHLT', objGlobalState.lang)}</Typography>
                  )}
                </Grid>
                {hasLagerAccess ? (
                  <>
                    <Grid item xs={12} className="marginEighty">
                      <CoArtikeltabelle objGlobalState={objGlobalState} objParentState={objAuftrag.artikeltabelle} options={{ heightFourty: true, selectArtikel: true }} />
                    </Grid>
                    <Grid item xs={12} className="marginThirty">
                      <Typography className="typography__sectionTitle">{language('ABTEILUNG', 'TITEL', 'ARTIKEL', objGlobalState.lang)}</Typography>
                    </Grid>
                    <Grid item xs={12} className="marginTwenty">
                      {ausgewaehlteArtikelState.chosen ? (
                        <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} className="table__container">
                          <Table size="medium">
                            <TableBody>{showAusgewaehlteArtikel()}</TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <Typography className="typography__colorDamage">{language('KONTENT', 'TEXT', 'KEINEARTIKELAUSGEWAEHLT', objGlobalState.lang)}</Typography>
                      )}
                    </Grid>
                  </>
                ) : (
                  ''
                )}
                <Grid container spacing={3} item xs={12} className="marginTwenty">
                  <Grid item xs={12}>
                    <Typography className="typography__sectionTitle--small">{language('ABTEILUNG', 'TITEL', 'KUNDENDATEN', objGlobalState.lang)}</Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField fullWidth rows="3" multiline variant="outlined" name="name1" label={`${language('FORM', 'LABEL', 'NAME', objGlobalState.lang)} 1`} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField fullWidth rows="3" multiline variant="outlined" name="name2" label={`${language('FORM', 'LABEL', 'NAME', objGlobalState.lang)} 2`} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField fullWidth rows="3" multiline variant="outlined" name="name3" label={`${language('FORM', 'LABEL', 'NAME', objGlobalState.lang)} 3`} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField fullWidth type="tel" variant="outlined" name="telefonnummer" label={language('FORM', 'LABEL', 'TELEFONNUMMER', objGlobalState.lang)} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField fullWidth type="email" variant="outlined" name="emailadresse" label={language('FORM', 'LABEL', 'EMAILADRESSE', objGlobalState.lang)} />
                  </Grid>
                </Grid>
                <Grid container spacing={3} item xs={12} className="marginTwenty">
                  <Grid item xs={12}>
                    <Typography className="typography__sectionTitle--small">{language('ABTEILUNG', 'TITEL', 'ADRESSDATEN', objGlobalState.lang)}</Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField fullWidth type="text" variant="outlined" name="strasse" label={language('FORM', 'LABEL', 'STRASSE', objGlobalState.lang)} />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField fullWidth type="text" variant="outlined" name="plz" label={language('FORM', 'LABEL', 'PLZ', objGlobalState.lang)} />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField fullWidth type="text" variant="outlined" name="ort" label={language('FORM', 'LABEL', 'ORT', objGlobalState.lang)} />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField fullWidth type="text" variant="outlined" name="land" label={language('FORM', 'LABEL', 'LAND', objGlobalState.lang)} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField fullWidth rows="3" multiline variant="outlined" name="zusaetzlicheInformationen" label={language('FORM', 'LABEL', 'ZUSAETZLICHEINFORMATIONEN', objGlobalState.lang)} />
                  </Grid>
                </Grid>
                <Grid container spacing={3} item xs={12} className="marginTwenty">
                  <Grid item xs={12}>
                    <Typography className="typography__sectionTitle--small">{language('ABTEILUNG', 'TITEL', 'AUFTRAGSREFERENZ', objGlobalState.lang)}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      type="text"
                      variant="outlined"
                      name="referenz"
                      label={language('FORM', 'LABEL', 'REFERENZ', objGlobalState.lang)}
                      placeholder={language('FORM', 'PLACEHOLDER', 'EMAILADRESSEODERTELEFONNUMMER', objGlobalState.lang)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} item xs={12} justify="flex-start" alignContent="center" alignItems="center" className="marginTwenty">
                  <Grid item xs={12}>
                    <Typography className="typography__sectionTitle--small">{language('ABTEILUNG', 'TITEL', 'SPEDITION', objGlobalState.lang)}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CoLogistikunternhemenSelect objGlobalState={objGlobalState} objParentState={objAuftrag.logistikunternehmenSelect} />
                  </Grid>
                </Grid>
                <Grid container spacing={3} item xs={12} className="marginTwenty">
                  <Grid item xs={12}>
                    <Typography className="typography__sectionTitle--small">{language('ABTEILUNG', 'TITEL', 'VERSAND', objGlobalState.lang)}</Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                      type="date"
                      variant="outlined"
                      name="versanddatum"
                      label={language('FORM', 'LABEL', 'VERSANDDATUM', objGlobalState.lang)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      InputLabelProps={{
                        shrink: true
                      }}
                      fullWidth
                      type="time"
                      variant="outlined"
                      name="uhrzeit"
                      label={language('FORM', 'LABEL', 'UHRZEIT', objGlobalState.lang)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset" className="coAuftragsverwaltungauftrag__formControl">
                      <FormLabel className="coAuftragsverwaltungauftrag__formLabel">
                        <Typography className="typography">{language('FORM', 'LABEL', 'SENDUNGSART', objGlobalState.lang)}</Typography>
                      </FormLabel>
                      <FormControlLabel
                        control={<Switch checked={sendungsartState.paketsendung.checked} onChange={() => checkSendungsart('paketsendung')} color="primary" />}
                        label={language('FORM', 'LABEL', 'PAKETSENDUNG', objGlobalState.lang)}
                      />
                      <FormControlLabel
                        control={<Switch checked={sendungsartState.palettensendung.checked} onChange={() => checkSendungsart('palettensendung')} color="primary" />}
                        label={language('FORM', 'LABEL', 'PALETTENSENDUNG', objGlobalState.lang)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button fullWidth type="submit" color="primary" className="button__middle" variant="contained">
                    {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CoAuftragsverwaltungauftrag;
