import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import language from '../../Common/language/language';
import isMobile from '../../Common/isMobile/isMobile';
import { generateDate } from '../../Common/helperFunctions/date';
import { createAxiosInstance, getAndCostumFiles } from '../../Common/helperFunctions/axios';
import { readFile } from '../../Common/helperFunctions/readFile';
import { FILE_TYPES } from '../../Common/types/types';
import { mouseScrollHorizontally } from '../../Common/helperFunctions/mouseScrollHorizontally';

// import -> material ui -> core && icons
import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@material-ui/core';
import { CameraAlt, PhotoCameraOutlined, Storage } from '@material-ui/icons';

// import -> components
import CoConfirmation from '../../Common/Components/CoConfirmation';
import CoCamera from '../../Common/Components/CoCamera';
import CoDisplayFilesList from '../../Common/Components/CoDisplayFilesList';
import CoLogistikunternhemenSelect from '../../Common/Components/CoLogistikunternhemenSelect';
import { Autocomplete } from '@material-ui/lab';

// component -> React-root
const CoCreateNotification = ({ objGlobalState, objVersandavisierungState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [auftraegeState, setAuftraegeState] = useState([]);
  const [dialogIsRenderedState, setDialogIsRenderedState] = useState(false);
  const [cameraState, setCameraState] = useState(false);
  const [gotStreamState, setGotStreamState] = useState(false);
  const [mailformateState, setMailformateState] = useState([]);
  const [selectedMailformatState, setSelectedMailformatState] = useState('');
  const [neuFileState, setNeuFileState] = useState([]);
  const [altFileState, setAltFileState] = useState([]);
  const [confirmationDialogState, setConfirmationDialogState] = useState(false);
  const [canCameraToggleState, setCanCameraToggleState] = useState(false);
  const [inputsState, setInputsState] = useState({
    auftragsnummer: '',
    kunde: '',
    email: '',
    spedition: '',
    versanddatum: '',
    emailtext: ''
  });
  const [emailSendenCheckedState, setEmailSendenCheckedState] = useState(false);
  const [newDataValueState, setNewDataValueState] = useState({
    auftragsnummer: '',
    kunde: '',
    email: '',
    spedition: '',
    versanddatum: '',
    emailsenden: ''
  });

  const tableRef = useRef();

  // function ->
  const handleInputOnChange = (event, inputName) => {
    const inputValue = event.target.value;

    setInputsState((pre) => ({
      ...pre,
      [inputName]: inputValue
    }));
  };

  // function ->
  const handleEmailSendenCheckedOnChange = (event) => {
    const checked = event.target.checked;
    setEmailSendenCheckedState(checked);
  };

  // function ->
  const handleSelectedMailFormatOnChange = (event) => {
    const selectedFormat = event.target.value;
    setSelectedMailformatState(selectedFormat);
    setInputsState((pre) => ({
      ...pre,
      emailtext: selectedFormat
    }));
  };

  // function ->
  const handleDialogOnRendered = () => {
    setDialogIsRenderedState(true);
  };

  // function ->
  const canCameraStop = () => {
    if ((cameraState && gotStreamState) || (!cameraState && !gotStreamState)) {
      return true;
    } else {
      return false;
    }
  };

  // function ->
  const handleAuftragsnummerOnChange = (event) => {
    const auftragValue = event.target.value + '';
    const foundedAuftrag = auftraegeState.find((auftrag) => auftrag.auftragsnummer.toLowerCase() === auftragValue.toLowerCase());
    setInputsState((pre) => ({ ...pre, auftragsnummer: auftragValue }));

    if (foundedAuftrag) {
      setInputsState((pre) => ({
        ...pre,
        kunde: foundedAuftrag.kunde || pre.kunde,
        email: foundedAuftrag.email || pre.email,
        spedition: foundedAuftrag.spedition || pre.spedition,
        versanddatum: foundedAuftrag.versanddatum || pre.versanddatum
      }));
      getAuftragsemailanlageFromAPI(foundedAuftrag.id);
    } else {
      setAltFileState([]);
    }
  };

  // function ->
  const stopCamera = () => {
    if (isMobile.any()) {
      return;
    }
    const stream = document.querySelector('#tracePort_camera')?.srcObject;
    if (!stream) {
      return;
    }
    const tracks = stream.getTracks();
    tracks.forEach((track) => {
      track.stop();
    });
    document.querySelector('#tracePort_camera').srcObject = null;
    setGotStreamState(false);
    setCameraState(false);
  };

  // function ->
  const handleFilesOnChange = (event) => {
    // array -> the new picked files from the user
    const newFiles = [...event.target.files];
    newFiles.forEach((file) => {
      readFile(file)
        .then((result) => {
          const type = file.type.includes('image') ? FILE_TYPES.JPEG : file.type === 'application/pdf' ? FILE_TYPES.PDF : FILE_TYPES.NO_TYPE;
          if (!type) {
            return;
          }
          const { name, lastModified } = file;
          const timestamp = generateDate(lastModified).dateAndTime;
          const newFile = { fileRef: file, timestamp: timestamp, type: type, dataUri: result, name: name, beschreibung: { text: '', error: false } };
          setNeuFileState((pre) => [newFile, ...pre]);
        })
        .catch((error) => console.log(error));
    });
  };

  // function ->
  const toggleCameraState = () => {
    if (cameraState) {
      if (canCameraStop()) {
        stopCamera();
      }
    } else {
      setCameraState(true);
    }
  };

  // function ->
  const closeCreateDialogOnClick = () => {
    if (!canCameraStop()) {
      return;
    }
    stopCamera();
    objVersandavisierungState.setCreateDialogState(false);
  };

  // function ->
  const closeConfirmationOnClick = () => {
    setConfirmationDialogState(false);
  };

  // function ->
  const setAuftragOnClick = (_, auftrag) => {
    if (!auftrag) return;
    const { auftragsnummer, kunde, email, spedition, versanddatum, id } = auftrag;

    setInputsState((pre) => ({
      auftragsnummer,
      kunde: kunde || pre.kunde,
      email: email || pre.email,
      spedition: spedition || pre.spedition,
      versanddatum: versanddatum || pre.versanddatum,
      emailtext: pre.emailtext
    }));

    getAuftragsemailanlageFromAPI(id);
  };

  // function ->
  const getAuftragsemailanlageFromAPI = (auftragId) => {
    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);
    const files = [];

    authAxios
      .get(`/auftrag/${auftragId}/mail-anhang`)
      .then((response) => {
        const { data } = response;
        data.forEach((file) => {
          getAndCostumFiles({ file: file, filesArray: files, authAxios: authAxios }).then((result) => {
            if (files.length === data.length) {
              setAltFileState(result);
            }
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // function ->
  const submitFormOnClick = (event) => {
    event.preventDefault();
    if (!canCameraStop()) {
      return;
    }
    stopCamera();

    const { auftragsnummer, kunde, email, spedition, versanddatum } = inputsState;
    const emailsenden = emailSendenCheckedState ? language('KONTENT', 'TEXT', 'JA', objGlobalState.lang) : language('KONTENT', 'TEXT', 'NEIN', objGlobalState.lang);

    setNewDataValueState({ auftragsnummer, kunde, email, spedition, versanddatum, emailsenden });
    setConfirmationDialogState(true);
  };

  // function ->
  const confirmed = () => {
    const { auftragsnummer, kunde, email, spedition, versanddatum } = inputsState;
    const emailInhalt = inputsState.emailtext;
    const auftragId = auftraegeState.find((auftrag) => auftrag.auftragsnummer === auftragsnummer)?.id;
    const anhang = neuFileState.map((file) => file.fileRef);
    const anhangIds = altFileState.map((file) => file.id);
    const requestVersanddatum = versanddatum.length ? generateDate(versanddatum).dateAndTimeForDataBase : '';
    const formData = new FormData();

    formData.append('kunde', kunde);
    formData.append('email', email);
    formData.append('spedition', spedition);
    formData.append('versendedatum', requestVersanddatum);
    formData.append('emailInhalt', emailInhalt);
    anhang.forEach((file) => {
      formData.append('anhang', file);
    });
    anhangIds.forEach((fileId) => {
      formData.append('anhangIds', fileId);
    });
    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    authAxios
      .post(`/auftrag/${auftragId}/avisierung`, formData, { headers: { 'Content-Type': 'multipart/form-data;' } })
      .then((response) => {
        if (emailSendenCheckedState) {
          authAxios
            .post(`/auftrag/${auftragId}/sende-avisierung`)
            .then(() => {
              enqueueSnackbar(language('ALERT', 'TEXT', 'EMAILGESENDET'), { variant: 'success' });
            })
            .catch((error) => {
              console.log(error);
              enqueueSnackbar(language('ALERT', 'TEXT', 'EMAILNICHTGESENDET'), { variant: 'error' });
            });
        }

        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT', objGlobalState.lang), { variant: 'success' });

        closeConfirmationOnClick();
        closeCreateDialogOnClick();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        objVersandavisierungState.rerunEffect();
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const getAuftraegeFromAPI = () => {
    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    authAxios
      .get('/auftrag/all')
      .then((response) => {
        const auftraege = response.data;

        const newAuftraegeState = [];

        auftraege.forEach((auftrag) => {
          const { id, auftragsnummer, email, name1: kunde, dienstleister: spedition, versanddatum } = auftrag;
          const inputVersanddatum = generateDate(versanddatum).dateForInputField;

          const newAuftraegeItem = { id, auftragsnummer, email, kunde, spedition, versanddatum: inputVersanddatum };

          newAuftraegeState.unshift(newAuftraegeItem);
        });

        setAuftraegeState(newAuftraegeState);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // function ->
  const mapFetchMailFormateToMailformateState = (formate) => {
    const newFormateState = [];

    formate.forEach((format) => {
      const formatId = format.id;
      const formattext = format.inhalt;

      const newDataObj = {
        id: formatId,
        text: formattext
      };

      newFormateState.unshift(newDataObj);
    });

    setMailformateState(newFormateState);
  };

  // function ->
  const getMailformateFromAPI = () => {
    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);
    authAxios
      .get('/usersettings/mail-vorlagen')
      .then((response) => {
        const formate = response.data;
        mapFetchMailFormateToMailformateState(formate);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getAuftraegeFromAPI();
      getMailformateFromAPI();
    }

    return () => (isMounted = false);
  }, []);

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, [dialogIsRenderedState, auftraegeState]);

  const isItMobile = isMobile.any();

  const isInIframe = objGlobalState.isInIframe;
  const iconClassName = isInIframe ? 'button__icon--smallRed' : 'button__icon--smallGreen';

  // jsx -> if browsing from mobile show the camera icon and not the storage icon
  const photosUploadIcon = isItMobile ? (
    <IconButton component="span" title={language('BUTTON', 'TITEL', 'KAMERA', objGlobalState.lang)}>
      <CameraAlt className={iconClassName} />
    </IconButton>
  ) : (
    <IconButton component="span" title={language('BUTTON', 'TITEL', 'SPEICHERPLATZ', objGlobalState.lang)}>
      <Storage className={iconClassName} />
    </IconButton>
  );

  // jsx -> if not browsing from mobile show this extra option
  const photosUpLoadExtraOptions = !isItMobile ? (
    <IconButton onClick={toggleCameraState} title={language('BUTTON', 'TITEL', 'KAMERAEINAUS', objGlobalState.lang)}>
      {!canCameraToggleState ? <CameraAlt className={iconClassName} /> : cameraState ? <PhotoCameraOutlined className={iconClassName} /> : <CameraAlt className={iconClassName} />}
    </IconButton>
  ) : (
    ''
  );

  const filesSectionTitle = 'ANHAENGE';
  const filesSection = { sectionTitle: filesSectionTitle, files: altFileState.concat(neuFileState) };
  const confirmationGallery = [filesSection];

  const objCreateNotification = {
    camera: {
      setGotStreamState,
      setCanCameraToggleState,
      setCameraState
    },
    confirmation: {
      closeConfirmationOnClick,
      confirmed,
      confirmationDialogState,
      confirmationGallery,
      newDataValueState
    },
    logistikunternehmenSelect: {
      selectValue: inputsState.spedition,
      handleInputOnChange: (event) => handleInputOnChange(event, 'spedition'),
      speditionenToExclude: ['chors']
    }
  };

  return (
    <>
      {confirmationDialogState && <CoConfirmation objGlobalState={objGlobalState} objParentState={objCreateNotification.confirmation} />}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={objVersandavisierungState.createDialogState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onRendered={handleDialogOnRendered}
      >
        <DialogTitle id="alert-dialog-title">{language('DIALOG', 'TITEL', 'NEUSATZERSTELLEN', objGlobalState.lang)}</DialogTitle>
        <DialogContent className="dialog__content">
          <form name="submitForm" noValidate autoComplete="on" onSubmit={submitFormOnClick}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Autocomplete
                  clearText=""
                  openText=""
                  onChange={setAuftragOnClick}
                  onInputChange={handleAuftragsnummerOnChange}
                  options={auftraegeState}
                  getOptionLabel={(option) => option.auftragsnummer}
                  filterSelectedOptions
                  renderInput={(params) => <TextField {...params} variant="outlined" label={language('FORM', 'LABEL', 'AUFTRAGSNUMMER', objGlobalState.lang)} />}
                />
              </Grid>
              <Grid item xs={12} md={6} className="marginThirty">
                <TextField
                  value={inputsState.kunde}
                  onChange={(event) => handleInputOnChange(event, 'kunde')}
                  fullWidth
                  type="text"
                  variant="outlined"
                  label={language('FORM', 'LABEL', 'KUNDE', objGlobalState.lang)}
                />
              </Grid>
              <Grid item xs={12} md={6} className="marginThirty">
                <TextField
                  value={inputsState.email}
                  onChange={(event) => handleInputOnChange(event, 'email')}
                  fullWidth
                  type="text"
                  variant="outlined"
                  label={language('FORM', 'LABEL', 'EMAILADRESSE', objGlobalState.lang)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CoLogistikunternhemenSelect objGlobalState={objGlobalState} objParentState={objCreateNotification.logistikunternehmenSelect} />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={inputsState.versanddatum}
                  onChange={(event) => handleInputOnChange(event, 'versanddatum')}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  type="date"
                  variant="outlined"
                  label={language('FORM', 'LABEL', 'VERSANDDATUM', objGlobalState.lang)}
                />
              </Grid>
              <Grid container spacing={3} item xs={12} className="marginTwenty border__top">
                <Grid item xs={12} md={6}>
                  <Typography className="typography">{language('KONTENT', 'TEXT', 'EMAIL', objGlobalState.lang)}</Typography>

                  <FormControl component="fieldset">
                    <FormControlLabel
                      control={<Switch color="primary" disabled={!inputsState?.email?.length} checked={emailSendenCheckedState} onChange={handleEmailSendenCheckedOnChange} />}
                      label={language('FORM', 'LABEL', 'EMAILSENDEN', objGlobalState.lang)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="versand_avisierung_mailformat_label">{language('FORM', 'LABEL', 'EMAILFORMAT', objGlobalState.lang)}</InputLabel>
                    <Select
                      disabled={!emailSendenCheckedState}
                      value={selectedMailformatState}
                      onChange={handleSelectedMailFormatOnChange}
                      labelId="versand_avisierung_mailformat_label"
                      label={language('FORM', 'LABEL', 'EMAILFORMAT', objGlobalState.lang)}
                    >
                      {mailformateState.map((mailformat, key) => {
                        return (
                          <MenuItem key={key} value={mailformat.text}>
                            {`Format ${mailformat.id}`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    value={inputsState.emailtext}
                    onChange={(event) => handleInputOnChange(event, 'emailtext')}
                    rows="10"
                    multiline
                    disabled={!emailSendenCheckedState}
                    variant="outlined"
                    label={language('FORM', 'LABEL', 'EMAILTEXT', objGlobalState.lang)}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography className="typography">{language('FORM', 'TITEL', 'ANHAENGEN', objGlobalState.lang)}</Typography>
                <input accept="image/*,application/pdf" style={{ display: 'none' }} name="fotos" id="versand-avisierung-input-foto" multiple type="file" onChange={handleFilesOnChange} />
                <label htmlFor="versand-avisierung-input-foto">{photosUploadIcon}</label>
                {photosUpLoadExtraOptions}
              </Grid>
              {!isItMobile && (
                <Grid item xs={12}>
                  {cameraState && (
                    <CoCamera
                      objGlobalState={objGlobalState}
                      objParentState={{ ...objCreateNotification.camera, photosState: neuFileState, setPhotosState: setNeuFileState }}
                      options={{ fotoAlsEmailAnhang: true }}
                    />
                  )}
                </Grid>
              )}
              <CoDisplayFilesList objGlobalState={objGlobalState} filesState={altFileState} setFilesState={setAltFileState} />
              <CoDisplayFilesList objGlobalState={objGlobalState} filesState={neuFileState} setFilesState={setNeuFileState} />
              <Grid item xs={12} md={6}>
                <Button fullWidth type="submit" color="primary" className="button__middle" variant="contained" color="primary">
                  {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button onClick={closeCreateDialogOnClick} fullWidth className="button__middle">
                  {language('BUTTON', 'TITEL', 'ABBRECHEN', objGlobalState.lang)}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoCreateNotification;
