import React, { useLayoutEffect, useRef } from 'react';
import language from '../../Common/language/language';
import { mouseScrollHorizontally } from '../../Common/helperFunctions/mouseScrollHorizontally';

// import -> material ui -> core, icons
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';

// component -> React-root
const CoTrackingItems = ({ objTrackingState, objGlobalState }) => {
  const tableRef = useRef();

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, []);
  return (
    <>
      <Box className="table__title">
        <Typography align="center" className="typography__singleTableTitle">
          {language('TABLE', 'HEADER', 'ARTIKELINFORMATIONEN', objGlobalState.lang)}
        </Typography>
      </Box>
      <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className="table__container">
        <Table size="medium">
          <TableBody>
            <TableRow>
              <TableCell component="th" className="table__rowTitle">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'ARTIKELNUMMER', objGlobalState.lang)}</Typography>
              </TableCell>
              <TableCell component="th" className="table__rowTitle">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'ANZAHL', objGlobalState.lang)}</Typography>
              </TableCell>
              <TableCell component="th" className="table__rowTitle">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'GEWICHTKG', objGlobalState.lang)}</Typography>
              </TableCell>
              <TableCell component="th" className="table__rowTitle">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'BESCHREIBUNG', objGlobalState.lang)}</Typography>
              </TableCell>
            </TableRow>
            {objTrackingState.trackingState.trackingItems.map((item, key) => {
              return (
                <TableRow key={key}>
                  <TableCell align="left">
                    {/*statement -> if there is just one item in the trackingitems array then leave the articleNr. column blank */}
                    <Typography className="typography__singleTableRowContent">{objTrackingState.trackingState.trackingItems.length > 1 ? key + 1 : ''}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography className="typography__singleTableRowContent">{item.anzahl}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography className="typography__singleTableRowContent">{item.gewicht}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography className="typography__singleTableRowContent">{item.beschreibung}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CoTrackingItems;
