import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import language from '../../Common/language/language';
import { createAxiosInstance } from '../../Common/helperFunctions/axios';

// import -> material ui -> core && icons
import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';

// import -> components
import CoConfirmation from '../../Common/Components/CoConfirmation';

// component -> React-root
const CoCreateUpdateStellplatz = ({ objGlobalState, objParentState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const isUpdate = objParentState.createUpdateStellplatzDialogState.isUpdate;

  // the stellplatz, which would be edited
  const stellplatzObj = objParentState.createUpdateStellplatzDialogState.stellplatzObj;

  const [confirmationDialogState, setConfirmationDialogState] = useState(false);

  const [stellplatzkennzeichnungState, setStellplatzkennzeichnungState] = useState(isUpdate ? stellplatzObj.name : '');

  const [newDataValueState, setNewDataValueState] = useState({
    stellplatzkennzeichnung: ''
  });

  // function ->
  const closeDialog = () => {
    objParentState.setCreateUpdateStellplatzDialogState((pre) => ({ ...pre, open: false }));
  };

  // function ->
  const changeStellplatzname = (event) => {
    setStellplatzkennzeichnungState(event.target.value);
  };

  // function ->
  const closeConfirmationOnClick = () => {
    setConfirmationDialogState(false);
  };

  // function ->
  const submitFormOnClick = (event) => {
    event.preventDefault();

    const stellplatzkennzeichnung = stellplatzkennzeichnungState;

    setNewDataValueState({ stellplatzkennzeichnung });
    setConfirmationDialogState(true);
  };

  // function ->
  const confirmed = () => {
    objGlobalState.setPageLoadingState(true);

    const { stellplatzkennzeichnung } = newDataValueState;

    const requestValues = {
      name: stellplatzkennzeichnung
    };

    const stellplatzId = stellplatzObj.id;

    const requestMethod = isUpdate ? 'put' : 'post';
    const requestUrl = isUpdate ? `/lagerstellplatz/${stellplatzId}` : '/lagerstellplatz';

    authAxios[requestMethod](requestUrl, requestValues)
      .then(() => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT'), { variant: 'success' });
        objParentState.rerunEffect();
        closeConfirmationOnClick();
        closeDialog();
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  const addStellplaetze = {
    confirmation: {
      closeConfirmationOnClick,
      confirmationDialogState,
      confirmed,
      newDataValueState
    }
  };

  const dialogTitleText = isUpdate ? 'STELLPLATZBEARBEITEN' : 'STELLPLATZERSTELLEN';

  return (
    <>
      {confirmationDialogState && <CoConfirmation objGlobalState={objGlobalState} objParentState={addStellplaetze.confirmation} />}

      <Dialog fullWidth maxWidth="sm" open={objParentState.createUpdateStellplatzDialogState.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{language('DIALOG', 'TITEL', dialogTitleText, objGlobalState.lang)}</DialogTitle>
        <DialogContent className="dialog__content">
          <form name="submitForm" noValidate autoComplete="on" onSubmit={submitFormOnClick}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  value={stellplatzkennzeichnungState}
                  onChange={changeStellplatzname}
                  fullWidth
                  variant="outlined"
                  label={language('FORM', 'LABEL', 'STELLPLATZKENNZEICHNUNG', objGlobalState.lang)}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={12} md={6}>
                <Button fullWidth type="submit" color="primary" variant="contained" className="button__middle">
                  {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button onClick={closeDialog} fullWidth className="button__middle">
                  {language('BUTTON', 'TITEL', 'ABBRECHEN', objGlobalState.lang)}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoCreateUpdateStellplatz;
