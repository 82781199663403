import React from 'react';
import language from '../Common/language/language';

// import -> services
import ServiceBreadcrumbs from '../Common/services/Breadcrumbs/ServiceBreadcrumbs';

// import -> material ui -> core
import { Container, Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';

// import -> components
import CoArtikeltabelle from '../Common/Components/Artikelverwaltung/CoArtikeltabelle';

// component -> React-root
const CoArtikelverwaltungartikeluebersicht = ({ objGlobalState }) => {
  // array -> create breadcrumbs
  const arrBreadcrumbs = [
    { url: '/', title: language('SEITE', 'TITEL', 'HOME', objGlobalState.lang) },
    { url: '/artikelverwaltung_artikeluebersicht', title: language('SEITE', 'TITEL', 'ARTIKELVERWALTUNGARTIKELUEBERSICHT', objGlobalState.lang) }
  ];

  return (
    <>
      <Container component="main" maxWidth="xl" className="container__container">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ServiceBreadcrumbs arrBreadcrumbs={arrBreadcrumbs} />
          </Grid>
          <Grid item xs={12}>
            <ListItem className="container__listItem">
              <ListItemIcon>
                <img src={`../assets/images/icons/icon_artikeluebersicht.png`} alt="" className="images__headerIcon" />
              </ListItemIcon>
              <ListItemText>
                <Typography className="typography__headerTitle">{language('SEITE', 'TITEL', 'ARTIKELVERWALTUNGARTIKELUEBERSICHT', objGlobalState.lang)}</Typography>
              </ListItemText>
            </ListItem>
          </Grid>
          <Grid item xs={12} className="marginThirty">
            <CoArtikeltabelle objGlobalState={objGlobalState} options={{ isArtikeluebersicht: true }} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CoArtikelverwaltungartikeluebersicht;
