import React, { useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import language from '../Common/language/language';
import { displayArraysInTable } from '../Common/helperFunctions/displayArrayInTable';
import { createAxiosInstance, getAndCostumFiles } from '../Common/helperFunctions/axios';

// import -> services
import ServiceBreadcrumbs from '../Common/services/Breadcrumbs/ServiceBreadcrumbs';

// import -> material ui -> core & icons
import { Paper, Table, Button, ButtonGroup, Container, Grid, ListItem, ListItemIcon, ListItemText, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';

// import -> components
import CoConfirmation from '../Common/Components/CoConfirmation';
import CoUploadFiles from '../Common/Components/CoUploadFiles';
import CoDisplayFilesList from '../Common/Components/CoDisplayFilesList';
import CoMultipleScanner from '../Common/Components/CoMultipleScanner';
import CoShowFiles from '../Common/Components/CoShowFiles';
import CoLieferungtabelle from '../Common/Components/Warenannahme/CoLieferungtabelle';

// component -> React-root
const CoWarenannahmeStatus = ({ objGlobalState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [rerunEffectState, setRerunEffekcState] = useState(true);
  const [showFilesDialogState, setShowFilesDialogState] = useState({ open: false, files: [], title: null });
  const [confirmationDialogState, setConfirmationDialogState] = useState(false);
  const [uploadFilesDialogState, setUploadFilesDialogState] = useState({ open: false, title: '' });
  const [filesState, setFilesState] = useState([]);
  const [ausgewaehlteSendungState, setAusgewaehlteSendungState] = useState({ chosen: false, sendung: {} });
  const [stellplatzkennzeichnungState, setStellplatzkennzeichnungState] = useState('');
  const [newDataValueState, setNewDataValueState] = useState({
    stellplatzkennzeichnung: '',
    sendung: ''
  });

  // function ->
  const showUploadFilesDialogOnClick = () => {
    setUploadFilesDialogState({ open: true, title: undefined });
  };

  // function ->
  const showFilesDialogOnClick = (event, deliveryId, filesName) => {
    event.stopPropagation();
    const title = filesName.toUpperCase();
    const files = [];

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    const endpoint = filesName === 'begleitpapiere' ? `delivery/findPreviewsForDeliveryNote/${deliveryId}` : filesName === 'beschaedigungsfotos' ? `/delivery/findpreviews/${deliveryId}` : '';

    authAxios
      .get(endpoint)
      .then((response) => {
        const { data } = response;

        let dataIterator;

        if (filesName === 'begleitpapiere') {
          dataIterator = data[0]?.previewDTOList;
        }
        if (filesName === 'beschaedigungsfotos') {
          dataIterator = data;
        }

        if (!dataIterator.length) {
          setShowFilesDialogState({ open: true, title: title, files: files });
        }

        dataIterator.forEach((fileObj) => {
          getAndCostumFiles({ file: fileObj, filesArray: files, authAxios: authAxios }).then((result) => {
            setShowFilesDialogState({ open: true, title: title, files: result });
          });
        });
      })
      .catch((error) => console.log(error));
  };

  // function ->
  const removeAusgewahlteSendungAfterConfirm = () => {
    setAusgewaehlteSendungState({ chosen: false, sendung: {} });
  };

  // function ->
  const submitFormOnClick = (event) => {
    event.preventDefault();

    const stellplatzkennzeichnung = stellplatzkennzeichnungState;
    const sendung = ausgewaehlteSendungState.sendung?.id;

    setNewDataValueState({ stellplatzkennzeichnung, sendung });
    setConfirmationDialogState(true);
  };

  // function ->
  const closeConfirmationOnClick = () => {
    setConfirmationDialogState(false);
  };

  // function ->
  const confirmed = () => {
    objGlobalState.setPageLoadingState(true);

    const { stellplatzkennzeichnung } = newDataValueState;
    const sendungId = ausgewaehlteSendungState.sendung?.id;
    const stellplatzfotos = filesState.map((file) => file.fileRef);

    const formData = new FormData();

    formData.append('stellplatz', stellplatzkennzeichnung);
    stellplatzfotos.forEach((stellplatzfoto) => {
      formData.append('fotos', stellplatzfoto);
    });

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    authAxios
      .post(`/delivery/${sendungId}/annahme`, formData, { headers: { 'Content-Type': 'multipart/form-data;' } })
      .then(() => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT', objGlobalState.lang), { variant: 'success' });

        setFilesState([]);
        closeConfirmationOnClick();
        removeAusgewahlteSendungAfterConfirm();
        setRerunEffekcState((pre) => !pre);
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const showAusgewaehlteSendung = () => {
    // destructuring the needed data to display
    const {
      id,
      lieferant,
      spedition,
      datum,
      sendungsart,
      anzahlDerPakete,
      anzahlDerPaketeProPaketSendung,
      anzahlDerPalette,
      anzahlDerPaketeProPalette,
      sendungBeschaedigt,
      beschreibungDerBeschaedigung,
      empfangsbestaetigung,
      erstellungsDatum
    } = ausgewaehlteSendungState.sendung;

    // array -> put the destructured data in an array to map on them
    const values = [
      { name: 'nummer', value: id },
      { name: 'lieferant', value: lieferant },
      { name: 'spedition', value: spedition },
      { name: 'datum', value: datum },
      { name: 'sendungsart', value: sendungsart },
      { name: 'anzahlDerPakete', value: anzahlDerPakete },
      { name: 'anzahlDerPaketeProPaketSendung', value: anzahlDerPaketeProPaketSendung },
      { name: 'anzahlDerPalette', value: anzahlDerPalette },
      { name: 'anzahlDerPaketeProPalette', value: anzahlDerPaketeProPalette },
      { name: 'sendungBeschaedigt', value: sendungBeschaedigt },
      { name: 'beschreibungDerBeschaedigung', value: beschreibungDerBeschaedigung },
      { name: 'empfangsbestaetigung', value: empfangsbestaetigung },
      { name: 'erstellungsDatum', value: erstellungsDatum },
      { name: 'dateien' }
    ];
    return (
      <TableRow>
        {values.map((value, key) => {
          if (value.name === 'dateien') {
            return (
              <TableCell align="center" key={`warenannahme_status_ausgewaehlteSendung_TableCell_${key}`}>
                <ButtonGroup>
                  <Button onClick={(event) => showFilesDialogOnClick(event, id, 'beschaedigungsfotos')} className="button__small" variant="outlined" color="primary">
                    {language('BUTTON', 'TITEL', 'BESCHAEDIGUNGSFOTOS', objGlobalState.lang)}
                  </Button>
                  <Button onClick={(event) => showFilesDialogOnClick(event, id, 'begleitpapiere')} className="button__small" variant="outlined" color="primary">
                    {language('BUTTON', 'TITEL', 'BEGLEITPAPIERE', objGlobalState.lang)}
                  </Button>
                </ButtonGroup>
              </TableCell>
            );
          } else {
            return (
              <TableCell align="center" key={`warenannahme_status_sendungen_TableCell_${key}`} className="table__rowTitle">
                <Typography className="typography__singleTableRowContent">{displayArraysInTable(value.value)}</Typography>
              </TableCell>
            );
          }
        })}
      </TableRow>
    );
  };

  const photosSectionTitle = 'STELLPLATZFOTOS';
  const photosSection = { sectionTitle: photosSectionTitle, files: filesState };
  const confirmationGallery = [photosSection];

  const objStatus = {
    confirmation: {
      closeConfirmationOnClick,
      confirmationDialogState,
      confirmed,
      confirmationGallery,
      newDataValueState
    },
    lieferungtabelle: {
      setAusgewaehlteSendungState,
      rerunEffectState
    },
    multipleScanner: {
      stellplatzkennzeichnungState,
      setStellplatzkennzeichnungState
    },
    showFiles: { showFilesDialogState, setShowFilesDialogState },
    uploadFiles: {
      uploadFilesDialogState,
      setUploadFilesDialogState,
      filesState,
      setFilesState
    }
  };

  // array -> create breadcrumbs
  const arrBreadcrumbs = [
    { url: '/', title: language('SEITE', 'TITEL', 'HOME', objGlobalState.lang) },
    { url: '/warenannahme_status', title: language('SEITE', 'TITEL', 'WARENANNAHMESTATUS', objGlobalState.lang) }
  ];

  return (
    <>
      {uploadFilesDialogState.open && <CoUploadFiles objGlobalState={objGlobalState} objParentState={objStatus.uploadFiles} />}
      {confirmationDialogState && <CoConfirmation objGlobalState={objGlobalState} objParentState={objStatus.confirmation} />}
      {showFilesDialogState.open && <CoShowFiles objGlobalState={objGlobalState} objParentState={objStatus.showFiles} />}
      <Container component="main" maxWidth="xl" className="container__container">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ServiceBreadcrumbs arrBreadcrumbs={arrBreadcrumbs} />
          </Grid>
          <Grid item xs={12}>
            <ListItem className="container__listItem">
              <ListItemIcon>
                <img src={`../assets/images/icons/icon_status.png`} alt="" className="images__headerIcon" />
              </ListItemIcon>
              <ListItemText>
                <Typography className="typography__headerTitle">{language('SEITE', 'TITEL', 'WARENANNAHMESTATUS', objGlobalState.lang)}</Typography>
              </ListItemText>
            </ListItem>
          </Grid>
          <Grid item xs={12} className="marginThirty">
            <Grid item xs={12}>
              <CoLieferungtabelle
                objGlobalState={objGlobalState}
                objParentState={objStatus.lieferungtabelle}
                options={{ heightFourty: true, noBorderTop: true, selectLieferung: true, isWarenannahmestatus: true }}
              />
            </Grid>
            <Grid item xs={12} className="marginThirty">
              <Typography className="typography__sectionTitle">{language('ABTEILUNG', 'TITEL', 'SENDUNG', objGlobalState.lang)}</Typography>
            </Grid>
            <Grid item xs={12} className="marginTwenty">
              {ausgewaehlteSendungState.chosen ? (
                <TableContainer component={Paper} variant="outlined" elevation={0} className="table__container">
                  <Table size="medium">
                    <TableBody>{showAusgewaehlteSendung()}</TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography className="typography__colorDamage">{language('KONTENT', 'TEXT', 'KEINESENDUNGAUSGEWAEHLT', objGlobalState.lang)}</Typography>
              )}
            </Grid>
            <form name="submitForm" noValidate autoComplete="on" onSubmit={submitFormOnClick}>
              <Grid container spacing={3} justify="flex-start" alignContent="center" alignItems="center" className="marginThirty">
                <CoMultipleScanner objGlobalState={objGlobalState} objParentState={objStatus.multipleScanner} />
                <Grid item xs={12}>
                  <Button onClick={showUploadFilesDialogOnClick} className="button__middle" variant="outlined" color="primary">
                    {language('BUTTON', 'TITEL', 'DATEIENHOCHLADEN', objGlobalState.lang)}
                  </Button>
                </Grid>
                <CoDisplayFilesList objGlobalState={objGlobalState} filesState={filesState} setFilesState={setFilesState} title="STELLPLATZFOTOS" />
                <Grid item xs={12}>
                  <Button fullWidth type="submit" color="primary" className="button__middle" variant="contained" color="primary">
                    {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CoWarenannahmeStatus;
