import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import language from '../Common/language/language';
import { createAxiosInstance } from '../Common/helperFunctions/axios';
import { handleBeforeUnload } from '../Common/eventHanlders/eventHandlers';
import { FILE_TYPES } from '../Common/types/types';
import { changeLanguage } from '../Common/helperFunctions/changeLanguage';
import isMobile from '../Common/isMobile/isMobile';

// import -> material ui -> core, icons
import { Button, Container, Grid, Paper, TextField, Typography, Link, IconButton } from '@material-ui/core';
import { Language } from '@material-ui/icons';

// import -> components
import CoDatenschutzDialog from './Append/CoDatenschutzDialog';

// component -> React-root
const CoSignIn = ({ objGlobalState }) => {
  const history = useHistory();
  const { state } = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  const noPointer = { cursor: 'default' };

  const [textFieldState, setTextFieldState] = useState({
    email: { error: false },
    password: { error: false }
  });
  const [datenschutzdokumentState, setDatenschutzdokumentState] = useState([]);
  const [agbdokumentState, setAgbdokumentState] = useState([]);
  const [versicherungsbedingungdokumentState, setVersicherungsbedingungdokumentState] = useState([]);
  const [datenschutzDialogState, setDatenschutzDialogState] = useState(false);
  const [tokenState, setTokenState] = useState('');
  const [serverStatue, setServerStatue] = useState(false);

  // function ->
  const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((char) => {
          return '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  };

  // funtion ->
  const logUserOut = () => {
    enqueueSnackbar(language('ALERT', 'TEXT', 'WIEDERANMELDEN', objGlobalState.lang), { variant: 'warning' });
    setTimeout(() => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      objGlobalState.setAuthenticateState({ auth: false, user: {} });
      objGlobalState.setJwtoken('');
      window.location.reload();
    }, 2000);
  };

  // function -> axios -> post data to external server
  const apiPostLogin = (email, password) => {
    axios
      .post(process.env.REACT_APP_API_HOST + '/user/login', {
        username: email,
        password: password
      })
      .then((response) => {
        const { token } = response.data;
        setTokenState(token);
        checkIfUserDatenschutzAccepted(token);
      })
      .catch((error) => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'FALSCHEMAILODERPASSWORT', objGlobalState.lang), {
          variant: 'error'
        });
        console.log(error);
      });
  };

  // function ->
  const showDatenschutzDialog = () => {
    setDatenschutzDialogState(true);
  };

  // function ->
  const fetchDatenschutzdokumente = (token) => {
    const authAxios = createAxiosInstance(token);
    authAxios.get('/usersettings/bedingungen').then((response) => {
      const { data } = response;
      data.forEach((file) => {
        const { file_name: fileName, file_id: fileId } = file;

        authAxios
          .get(`/file/${fileId}`, { responseType: 'blob' })
          .then((_response) => {
            const blobType = 'application/pdf';
            const blob = new Blob([_response.data], { type: blobType });
            const url = URL.createObjectURL(blob);
            const type = FILE_TYPES.PDF;
            const newFile = { type: type, name: fileName, dataUri: url };

            switch (true) {
              case fileName?.includes('AGB'):
                setAgbdokumentState(newFile);
                break;
              case fileName?.includes('Datenschutz'):
                setDatenschutzdokumentState(newFile);
                break;
              case fileName?.includes('DSLV'):
                setVersicherungsbedingungdokumentState(newFile);
            }
          })
          .then((_response) => {
            showDatenschutzDialog();
          });
      });
    });
  };

  // function ->
  const checkIfUserDatenschutzAccepted = (token) => {
    const authAxios = createAxiosInstance(token);
    return authAxios
      .get('/usersettings')
      .then((response) => {
        const { data } = response;
        if (data.bedingungAkzeptiert) {
          signUserInAfterAcceptance(true, token);
        } else {
          fetchDatenschutzdokumente(token);
        }
      })
      .catch((error) => console.log(error));
  };

  // function ->
  const acceptDatenschutz = (authAxios, tokenExpiration) => {
    const requestValues = {
      akzeptiert: true
    };
    authAxios
      .post('/usersettings/bedingungen', requestValues)
      .then(() => {
        getUserData(authAxios, tokenExpiration);
      })
      .catch((error) => console.log(error));
  };

  const getUserData = (authAxios, tokenExpiration) => {
    // function -> axios instance -> get the logged in user from external server
    authAxios.get('/user/whoami').then((users) => {
      const user = users.data;
      user.access.push('HOME');
      enqueueSnackbar(language('ALERT', 'TEXT', 'EINGELOGGT', objGlobalState.lang), { variant: 'success' });
      objGlobalState.setAuthenticateState({
        auth: true,
        user: user
      });
      const tokenAlreadyExpired = tokenExpiration - new Date().getTime();
      setTimeout(logUserOut, tokenAlreadyExpired - 2000);

      history.push('/home');
    });
  };

  // function ->
  const signUserInAfterAcceptance = (alreadyAccepeted, token) => {
    const accessToken = tokenState || token;
    const authAxios = createAxiosInstance(accessToken);

    const tokenExpiration = new Date(parseJwt(accessToken).exp * 1000);

    objGlobalState.setJwtoken(accessToken);

    if (!alreadyAccepeted) {
      acceptDatenschutz(authAxios, tokenExpiration);
      return;
    }
    getUserData(authAxios, tokenExpiration);
  };

  // function ->
  const checkIfTextFieldIsEmpty = (valueForCheck) => {
    //  statement -> checks if valueForCheck is not empty
    return valueForCheck ? false : true;
  };

  // function ->
  const fetchTextFieldChange = (event, textField) => {
    // useState -> show error message depending on return boolean of checkIfTextFieldIsEmpty
    setTextFieldState({
      ...textFieldState,
      [textField]: { error: checkIfTextFieldIsEmpty(event.target.value) }
    });
  };

  // function ->
  const submitFormOnClick = (event) => {
    event.preventDefault();

    const email = event.target.email.value.toLowerCase();
    const password = event.target.password.value;

    const isEmailTextFieldEmpty = checkIfTextFieldIsEmpty(email);
    const isPasswordTextFieldEmpty = checkIfTextFieldIsEmpty(password);

    if (isEmailTextFieldEmpty || isPasswordTextFieldEmpty) {
      // useState -> show error message depending on return boolean of checkIfTextFieldIsEmpty
      setTextFieldState({
        ...textFieldState,
        email: { error: isEmailTextFieldEmpty },
        password: { error: isPasswordTextFieldEmpty }
      });
    } else {
      apiPostLogin(email, password);
    }
  };

  // function ->
  const sendToResetPassword = (event) => {
    event.preventDefault();
    history.push('/resetpassword');
  };

  // function ->
  const showNavigation = () => {
    objGlobalState.setHideNavigationState(false);
  };

  useEffect(() => {
    let isMounted = true;
    if (!isMounted) return;

    showNavigation();

    return () => (isMounted = false);
  });

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      axios
        .get(`${process.env.REACT_APP_API_HOST}/application/health`)
        .then((response) => {
          const { data } = response;
          setServerStatue(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return () => (isMounted = false);
  }, []);

  const objSignInStateAndFunctions = {
    agbdokumentState,
    setAgbdokumentState,
    datenschutzDialogState,
    setDatenschutzDialogState,
    datenschutzdokumentState,
    setDatenschutzdokumentState,
    signUserInAfterAcceptance,
    versicherungsbedingungdokumentState,
    setVersicherungsbedingungdokumentState
  };

  const mobile = isMobile.any();

  return (
    <>
      {datenschutzDialogState && <CoDatenschutzDialog objGlobalState={objGlobalState} objSignInStateAndFunctions={objSignInStateAndFunctions} />}
      <Container maxWidth="xs" className={`${mobile ? 'coSignIn__center' : ''}`}>
        <Paper variant="outlined" square className="container__anmeldungscontainer">
          <Grid container>
            <form name="submitForm" noValidate className="form__anmeldungsform" autoComplete="off" onSubmit={submitFormOnClick}>
              <Grid container spacing={3}>
                <Grid container spacing={3}>
                  <Grid item xs={6} md={8}>
                    <IconButton color="inherit" onClick={() => changeLanguage(objGlobalState)} title={language('BUTTON', 'TITEL', 'SPRACHEAENDERN', objGlobalState.lang)}>
                      <Language />
                      <Typography>{objGlobalState.lang === 'DE' ? 'EN' : 'DE'}</Typography>
                    </IconButton>
                  </Grid>
                  {serverStatue ? (
                    <Grid item xs={6} md={4}>
                      <img
                        title={language('IMAGES', 'TITEL', 'VERBINDUNG', objGlobalState.lang)}
                        width={30}
                        src={`../assets/images/icons/icon_hacken.png`}
                        alt=""
                        className="coSignIn__serverStatueIcon"
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={6} md={4}>
                      <img
                        title={language('IMAGES', 'TITEL', 'VERBINDUNGSFEHLER', objGlobalState.lang)}
                        width={30}
                        src={`../assets/images/icons/icon_cancel.png`}
                        alt=""
                        className="coSignIn__serverStatueIcon"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography align="center" className="typography__formTitle">
                      {language('FORM', 'TITEL', 'ANMELDEN', objGlobalState.lang)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="email"
                    variant="outlined"
                    name="email"
                    label={language('FORM', 'LABEL', 'EMAIL', objGlobalState.lang)}
                    error={textFieldState.email.error}
                    helperText={textFieldState.email.error ? language('FORM', 'ERROR', 'LEERESTEXTFELD', objGlobalState.lang) : ''}
                    onChange={(event) => fetchTextFieldChange(event, 'email')}
                    autoFocus
                    disabled={!serverStatue}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="password"
                    variant="outlined"
                    name="password"
                    label={language('FORM', 'LABEL', 'PASSWORT', objGlobalState.lang)}
                    error={textFieldState.password.error}
                    helperText={textFieldState.password.error ? language('FORM', 'ERROR', 'LEERESTEXTFELD', objGlobalState.lang) : ''}
                    onChange={(event) => fetchTextFieldChange(event, 'password')}
                    disabled={!serverStatue}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button disabled={!serverStatue} fullWidth variant="contained" type="submit" color="primary" className="button">
                    {language('BUTTON', 'TITEL', 'ANMELDEN', objGlobalState.lang)}
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Grid item xs={12} container justify="center">
              {!serverStatue ? (
                <Link style={noPointer} underline="false" color="text.primary">
                  {language('KONTENT', 'TEXT', 'PASSWORTVERGESSENZURUECKSETZEN', objGlobalState.lang)}
                </Link>
              ) : (
                <Link href="" onClick={sendToResetPassword} className="cIlink">
                  {language('KONTENT', 'TEXT', 'PASSWORTVERGESSENZURUECKSETZEN', objGlobalState.lang)}
                </Link>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default CoSignIn;
