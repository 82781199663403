import React, { useEffect } from 'react';
import Quagga from 'quagga';

const CoBarCodeScanner = ({ objParentState }) => {
  // function ->
  const handleScanOnDetected = (data) => {
    const result = data.codeResult.code;
    objParentState.setBarCodeResultState({ data: data, result: result });
  };

  // function ->
  const handleProcessedOnProcessed = () => {
    objParentState.setScanLoadedState((pre) => ({ qr: pre.qr, bar: true }));

    // stop the call back right after its first call
    Quagga.offProcessed(handleProcessedOnProcessed);
  };

  // function ->
  const initializeQuagga = () => {
    Quagga.init(
      {
        inputStream: {
          name: 'Live',
          type: 'LiveStream',
          target: document.querySelector('#common_component_bar_code_scanner')
        },
        decoder: {
          readers: [
            'code_128_reader',
            'ean_reader',
            'ean_8_reader',
            'code_39_reader',
            'code_39_vin_reader',
            'codabar_reader',
            'upc_reader',
            'upc_e_reader',
            'i2of5_reader',
            '2of5_reader',
            'code_93_reader'
          ]
        },
        frequency: 1
      },
      function (error) {
        if (error) {
          objParentState.setScanErrorState((pre) => ({ qr: pre.qr, bar: true }));
          return console.log(error);
        }
        Quagga.start();
      }
    );
    Quagga.onDetected(handleScanOnDetected);
    Quagga.onProcessed(handleProcessedOnProcessed);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      initializeQuagga();
    }

    return () => {
      isMounted = false;
      Quagga.offDetected(handleScanOnDetected);
      Quagga.stop();
    };
  }, []);

  return (
    <section>
      <section className="coBarCodeScanner">
        <div id="common_component_bar_code_scanner" className="coBarCodeScanner__camera"></div>
      </section>
    </section>
  );
};

export default CoBarCodeScanner;
