import React, { useLayoutEffect, useRef } from 'react';
import language from '../../Common/language/language';
import { generateDate } from '../../Common/helperFunctions/date';
import { mouseScrollHorizontally } from '../../Common/helperFunctions/mouseScrollHorizontally';

// import -> material ui -> core, icons
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';

// component -> React-root
const CoTimeline = ({ objTrackingState, objGlobalState }) => {
  const tableRef = useRef();
  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, []);

  return (
    <>
      <Box className="table__title">
        <Typography align="center" className="typography__singleTableTitle">
          {language('TABLE', 'HEADER', 'TRACKINGHISTORIE', objGlobalState.lang)}
        </Typography>
      </Box>
      <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className="table__container">
        <Table size="medium">
          <TableBody>
            <TableRow>
              <TableCell component="th" className="table__rowTitle">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'STATUS', objGlobalState.lang)}</Typography>
              </TableCell>
              <TableCell component="th" className="table__rowTitle">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'DATUM', objGlobalState.lang)}</Typography>
              </TableCell>
              <TableCell component="th" className="table__rowTitle">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'UHRZEIT', objGlobalState.lang)}</Typography>
              </TableCell>
              <TableCell component="th" className="table__rowTitle ">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'ORT', objGlobalState.lang)}</Typography>
              </TableCell>
            </TableRow>
            {objTrackingState.trackingState.trackingHistory.map((item, key) => (
              <TableRow key={key}>
                <TableCell align="left">
                  {/*the replace is done because of the wordbreaks in css*/}
                  <Typography className="typography__singleTableRowContent">{item.status.replace('/', ' / ')}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography className="typography__singleTableRowContent">{generateDate(item.zeitstempel).justDate}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography className="typography__singleTableRowContent">{generateDate(item.zeitstempel).justTime}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography className="typography__singleTableRowContent">{item.ort}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CoTimeline;
