import React from 'react';
import language from '../Common/language/language';

// import -> services
import ServiceBreadcrumbs from '../Common/services/Breadcrumbs/ServiceBreadcrumbs';

// import -> material ui -> core & icons
import { Container, Grid, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';

// import -> components
import CoKommissionierungNoTransporteinheit from './Append/CoKommissionierungNoTransporteinheit';
import CoKommissionierungWithTransporteinheit from './Append/CoKommissionierungWithTransporteinheit';

// component -> React-root
const CoWarenausgangkommissionierung = ({ objGlobalState }) => {
  const withTransporteinheit = objGlobalState.authenticateState.user.access.includes('LAGER');

  // array -> create breadcrumbs
  const arrBreadcrumbs = [
    { url: '/', title: language('SEITE', 'TITEL', 'HOME', objGlobalState.lang) },
    { url: '/warenausgang_kommissionierung', title: language('SEITE', 'TITEL', 'WARENAUSGANGKOMMISSIONIERUNG', objGlobalState.lang) }
  ];

  return (
    <Container component="main" maxWidth="xl" className="container__container">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ServiceBreadcrumbs arrBreadcrumbs={arrBreadcrumbs} />
        </Grid>
        <Grid item xs={12}>
          <ListItem className="container__listItem">
            <ListItemIcon>
              <img src={`../assets/images/icons/icon_kommissionierung.png`} alt="" className="images__headerIcon" />
            </ListItemIcon>
            <ListItemText>
              <Typography className="typography__headerTitle">{language('SEITE', 'TITEL', 'WARENAUSGANGKOMMISSIONIERUNG', objGlobalState.lang)}</Typography>
            </ListItemText>
          </ListItem>
        </Grid>
        {withTransporteinheit ? <CoKommissionierungWithTransporteinheit objGlobalState={objGlobalState} /> : <CoKommissionierungNoTransporteinheit objGlobalState={objGlobalState} />}
      </Grid>
    </Container>
  );
};

export default CoWarenausgangkommissionierung;
