import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { language } from '../../Common/language/language';

// import -> material ui -> core, icons
import { Divider, List, ListItem, ListItemIcon, ListItemText, Accordion, AccordionSummary, Typography, AccordionDetails } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

// components -> lvl-1
const CoSideBarTitleFirst = ({ item, lang }) => {
  return (
    <ListItem disabled={!item.visible} className="paddingTopZero">
      <ListItemText>
        <div className="coNavigation__listItemTitleFirst">{language('MENU', 'TITEL', item.label, lang)}</div>
      </ListItemText>
    </ListItem>
  );
};

// components -> lvl-1
const CoSideBarTitle = ({ item, lang }) => {
  return (
    <ListItem disabled={!item.visible}>
      <ListItemText>
        <div className="coNavigation__listItemTitle">{language('MENU', 'TITEL', item.label, lang)}</div>
      </ListItemText>
    </ListItem>
  );
};

// components -> lvl-2
const CoSideBarLinks = ({ objGlobalState, item, lang, openOrCloseSideBarOnClick, liveTrackerState }) => {
  const history = useHistory();
  const hasLiveTrackingAccess = objGlobalState.authenticateState.user.access?.includes('LIVE_TRACKING');
  const [liveTrackingAccordionExpandedState, setLiveTrackingAccordionExpandedState] = useState(false);

  // function ->
  const toggleAccordionOnClick = () => {
    setLiveTrackingAccordionExpandedState((pre) => !pre);
  };

  // function ->
  const handleAccordionOnClick = (event) => {
    event.stopPropagation();
  };

  // function ->
  const handleTrackerIdOnClick = (event, trackernummer) => {
    event.stopPropagation();
    openOrCloseSideBarOnClick(false);
    goToLiveTracking(trackernummer);
  };

  // function ->
  const goToLiveTracking = (trackernummer) => {
    history.push(`/live-tracking/${trackernummer}`);
  };

  if (item.label === 'LIVETRACKING') {
    return (
      <ListItem disabled={!item.visible} className="coNavigation__liveTracking">
        <Accordion className="coNavigation__liveTrackingAccordion" disabled={!hasLiveTrackingAccess} expanded={liveTrackingAccordionExpandedState} onClick={handleAccordionOnClick}>
          <AccordionSummary
            onClick={toggleAccordionOnClick}
            className="coNavigation__liveTrackingAccordionSummary"
            expandIcon={hasLiveTrackingAccess ? <ExpandMore className="coNavigation__liveTrackingAccordionExpandIcon" /> : null}
          >
            <ListItemIcon>
              <img src={`${process.env.REACT_APP_HOST}/assets/images/icons/${item.icon}`} alt="" className="coNavigation__listIcon" />
            </ListItemIcon>
            <ListItemText>
              <Typography className="coNavigation__listItemText">{language('MENU', 'LINKS', item.label, lang)}</Typography>
            </ListItemText>
          </AccordionSummary>
          <AccordionDetails className="coNavigation__liveTrackingAccordionDetails">
            <List>
              {liveTrackerState.map((trackernummer, key) => (
                <ListItem button key={`navigation_sidebar_live-tracking_trackersId_${key}`} onClick={(event) => handleTrackerIdOnClick(event, trackernummer)}>
                  <ListItemText>{trackernummer}</ListItemText>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </ListItem>
    );
  }
  return (
    <ListItem button disabled={!item.visible}>
      <ListItemIcon>
        <img src={`${process.env.REACT_APP_HOST}/assets/images/icons/${item.icon}`} alt="" className="coNavigation__listIcon" />
      </ListItemIcon>
      <ListItemText>
        <div className="coNavigation__listItemText">{language('MENU', 'LINKS', item.label, lang)}</div>
      </ListItemText>
    </ListItem>
  );
};

// components -> lvl-1
const CoSideBarMenu = ({ objGlobalState, item, lang, openOrCloseSideBarOnClick, liveTrackerState }) => {
  return (
    <>
      {item.visible && item.label !== 'LIVETRACKING' ? (
        <Link to={item.url} className="coNavigation__link">
          <CoSideBarLinks objGlobalState={objGlobalState} item={item} lang={lang} />
        </Link>
      ) : (
        <CoSideBarLinks item={item} objGlobalState={objGlobalState} lang={lang} openOrCloseSideBarOnClick={openOrCloseSideBarOnClick} liveTrackerState={liveTrackerState} />
      )}
    </>
  );
};

// component -> React-root
const CoSideBar = ({ objGlobalState, openOrCloseSideBarOnClick, liveTrackerState }) => {
  let triggerTitle = true;
  const { lang } = objGlobalState;

  // function ->
  const compareAccessAndBuildNewView = () => {
    const arrCurrentMenu = objGlobalState.menuStructureState.currentMenu;
    const arrUserAccess = objGlobalState.authenticateState.user.access;
    // loop -> iterate over current menu and compare with user access that content is similar
    arrCurrentMenu.forEach((currentMenuItem) => {
      arrUserAccess.forEach((accessItem) => {
        // variable -> function check if access from user is include in access from menu
        const isAccessSimilar = accessItem === currentMenuItem.access;
        // statement -> if access from user is similar to access from menu, than set choosen link to visible true
        if (isAccessSimilar) {
          currentMenuItem.visible = true;
        }
      });
    });
  };

  compareAccessAndBuildNewView();

  return (
    <List className="coNavigation__sideBar">
      {/* loop -> iterate over menu object */}
      {objGlobalState.menuStructureState.currentMenu.map((currentMenuItem, key) => {
        // switch -> check which link should be displayed
        switch (true) {
          case currentMenuItem.type === 'title':
            // statement -> show first style only once
            if (triggerTitle) {
              triggerTitle = false;

              return <CoSideBarTitleFirst key={key} item={currentMenuItem} lang={lang} />;
            } else {
              return <CoSideBarTitle key={key} item={currentMenuItem} lang={lang} />;
            }
          case currentMenuItem.type === 'divider':
            return <Divider variant="middle" key={key} className="coNavigation__divider" disabled={!currentMenuItem.visible} />;
          case currentMenuItem.type === 'link':
            return (
              <CoSideBarMenu key={key} item={currentMenuItem} lang={lang} objGlobalState={objGlobalState} openOrCloseSideBarOnClick={openOrCloseSideBarOnClick} liveTrackerState={liveTrackerState} />
            );
          default:
            return null;
        }
      })}
    </List>
  );
};

export default CoSideBar;
