import React, { useLayoutEffect, useRef, useState } from 'react';
import language from '../../Common/language/language';
import { mouseScrollHorizontally } from '../../Common/helperFunctions/mouseScrollHorizontally';
import { createAxiosInstance, getAndCostumFiles } from '../../Common/helperFunctions/axios';

// import -> material ui -> core & icons
import { Typography, Paper, TableRow, TableCell, TableBody, TableContainer, Table, Box, Button, TableHead } from '@material-ui/core';
import CoShowFiles from '../../Common/Components/CoShowFiles';

// import -> components

// component -> React-root
const CoLiferscheinTable = ({ objGlobalState, objLieferscheinStatesAndFunctions }) => {
  const [showFilesDialogState, setShowFilesDialogState] = useState({ open: false, title: '', files: [] });

  const tableRef = useRef();
  const tableHeaders = ['NUMMER', 'DATEIEN'];

  // function ->
  const showFilesDialogOnClick = (deliveryId) => {
    const title = 'LIEFERSCHEIN';

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);
    authAxios
      .get(`/delivery/findPreviewsForDeliveryNote/${deliveryId}`)
      .then((response) => {
        const { data } = response;

        const dataIterator = data[0]?.previewDTOList;

        const files = [];

        if (!dataIterator.length) {
          setShowFilesDialogState({ open: true, title: title, files: files });
        }

        dataIterator.forEach((fileObj) => {
          getAndCostumFiles({ file: fileObj, filesArray: files, authAxios: authAxios }).then((result) => {
            setShowFilesDialogState({ open: true, title: title, files: result });
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, []);

  const objParentState = { showFilesDialogState, setShowFilesDialogState };

  return (
    <>
      {showFilesDialogState.open && <CoShowFiles objGlobalState={objGlobalState} objParentState={objParentState} />}
      <Box className="marginFourty  table__title">
        <Typography align="center" className="typography__singleTableTitle">
          {language('TABLE', 'HEADER', 'LIEFERSCHEIN', objGlobalState.lang)}
        </Typography>
      </Box>
      <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className="table__container border__noTop">
        <Table size="medium" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, key) => {
                return (
                  <TableCell align="center" component="th" className="table__headerCell" key={`wareneingang_lieferschein_tableHeader_${key}`}>
                    <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', header, objGlobalState.lang)}</Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {objLieferscheinStatesAndFunctions.lieferscheinState.map((value, key) => {
              return (
                <TableRow key={`wareneingagn_lieferschein_tableRow_${key}`}>
                  <TableCell align="center">
                    <Typography className="typography__singleTableRowContent">{value}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Button onClick={() => showFilesDialogOnClick(value)} className="button__small" variant="outlined" color="primary">
                      {language('BUTTON', 'TITEL', 'ANSEHEN', objGlobalState.lang)}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CoLiferscheinTable;
