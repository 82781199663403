import { removeFromArray } from './arrays';

// function ->
const deleteFileFromFilesSet = (fileToDelete, lastState, setLastState) => {
  const currentFilesState = [...lastState];
  removeFromArray(currentFilesState, fileToDelete);
  setLastState(currentFilesState);
};

export default deleteFileFromFilesSet;
