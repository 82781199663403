import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';

// import -> components
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} maxSnack={3} className="snackbarProvider__container">
      <App />
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
