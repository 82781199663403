import React, { useCallback, useState } from 'react';
import deleteFileFromFilesSet from '../helperFunctions/deleteFileFromFilesSet';
import language from '../language/language';
import { FILE_TYPES } from '../types/types';

// import -> material ui -> core & icons
import { Grid, IconButton, Typography } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';

// import -> components
import CoDisplayFiles from './CoDisplayFiles';

const CoDisplayFilesList = ({ objGlobalState, filesState, setFilesState, title, disabled }) => {
  const [displayDialogState, setDisplayDialogState] = useState(false);
  const [fileToDisplayState, setFileToDisplayState] = useState({ dataUri: '', title: '', type: '' });

  // function ->
  const displayFileOnClick = (file) => {
    setFileToDisplayState(file);
    setDisplayDialogState(true);
  };

  const objShowFilesListState = { displayDialogState, setDisplayDialogState, fileToDisplayState, setFileToDisplayState };

  // function ->
  const mapFilesToList = () => {
    return filesState.map((file, key) => {
      return (
        <Grid container item xs={12} key={key} alignContent="center" alignItems="center" justify="flex-start" className="galleryImageSection">
          <Grid item xs={12} sm={6}>
            {file.type === FILE_TYPES.JPEG ? (
              <img src={file.dataUri} className={`images__gallery ${disabled ? 'images--disabled' : ''}`} alt="" onClick={() => displayFileOnClick(file)} />
            ) : file.type === FILE_TYPES.PDF ? (
              <Typography align="center" className={`typography__gallery ${disabled ? 'typography__gallery--disabled' : ''}`} onClick={() => displayFileOnClick(file)}>
                <img src={`../assets/images/other_icons/pdf_icon.png`} alt="" className="images__textIcon" />
                {file.name}
              </Typography>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={'auto'}>
            <IconButton
              onClick={() => deleteFileFromFilesSet(file, filesState, setFilesState)}
              className="button__icon--deleteImage"
              title={language('BUTTON', 'TITEL', 'LOESCHEN', objGlobalState.lang)}
              size="small"
            >
              <DeleteForever />
            </IconButton>
          </Grid>
        </Grid>
      );
    });
  };

  return filesState.length ? (
    <>
      {displayDialogState && <CoDisplayFiles objGlobalState={objGlobalState} objParentState={objShowFilesListState} />}

      <Grid item xs={12} container className="gallerySection__inGroup">
        <Grid item xs={12}>
          {title && <Typography className="typography">{language('KONTENT', 'TEXT', title, objGlobalState.lang)}</Typography>}
        </Grid>
        {mapFilesToList()}
      </Grid>
    </>
  ) : (
    ''
  );
};

export default CoDisplayFilesList;
