import React, { useState } from 'react';
import language from '../../Common/language/language';
// import -> material ui -> core
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

// import -> components
import CoLieferungtabelle from '../../Common/Components/Warenannahme/CoLieferungtabelle';

// component -> React-root
const CoLieferungTable = ({ objGlobalState, objWarenannahmelieferungStateAndFunctions }) => {
  // function ->
  const closeDialogOnClose = () => {
    objWarenannahmelieferungStateAndFunctions.setLieferungTableDialogState(false);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xl"
        onClose={closeDialogOnClose}
        open={objWarenannahmelieferungStateAndFunctions.lieferungTableDialogState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
            <Grid item xs={6}>
              <Typography align="left" className="typography__dialogTitle">
                {language('TABLE', 'HEADER', 'LIEFERUNGSDATEN', objGlobalState.lang)}
              </Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <IconButton className="button__iconButton--close" onClick={closeDialogOnClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="dialog__content">
          <CoLieferungtabelle objGlobalState={objGlobalState} options={{ noTableTitle: true }} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoLieferungTable;
