import React, { useState } from 'react';
import language from '../../Common/language/language';
import { useSnackbar } from 'notistack';
import { createAxiosInstance } from '../../Common/helperFunctions/axios';

// import -> material ui -> core && icons
import { Dialog, Button, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';

// import -> components
import CoLagerorteTable from './CoLagerorteTable';

// component -> React-root
const CoLagerorteTableDialog = ({ objGlobalState, objParentState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const [selectedLagerortIdState, setSelectedLagerortIdState] = useState('');

  // if it's not from lagerorteTable then it is from stellplaetzeTable
  const isFromLagerorte = objParentState.isFromLagerorte;

  const childLagerorteansehen = isFromLagerorte && objParentState.lagerorteTableDialogState.childLagerorteansehen;

  // function ->
  const closeDialog = () => {
    objParentState.setLagerorteTableDialogState((pre) => ({ ...pre, open: false }));
  };

  // function ->
  const selectLagerort = (lagerortId) => {
    setSelectedLagerortIdState(lagerortId);
  };

  // function ->
  const addStellplatzToLagerort = () => {
    const stellplatzId = objParentState.lagerorteTableDialogState.stellplatzObj.id;
    const lagerortId = selectedLagerortIdState;

    objGlobalState.setPageLoadingState(true);
    authAxios
      .post(`/lagerort/${lagerortId}/stellplatz/${stellplatzId}`)
      .then(() => {
        closeDialog();
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT'), { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
        console.log(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const addChildLagerortToParentLagerort = () => {
    const parentLagerortId = objParentState.lagerorteTableDialogState.lagerortObj.id;
    const childLagerortId = selectedLagerortIdState;

    objGlobalState.setPageLoadingState(true);
    authAxios
      .post(`/lagerort/${parentLagerortId}/link/${childLagerortId}`)
      .then(() => {
        closeDialog();
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT'), { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
        console.log(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  const objLagerorteTableDialog = {
    lagerorteTable: {
      selectedLagerortIdState,
      selectLagerort,
      isSelectable: objParentState.addStellplatz || objParentState.lagerorteTableDialogState.addAsChildLagerToParentLager,
      excludeId: isFromLagerorte ? objParentState.lagerorteTableDialogState.lagerortObj.id : null,
      childLagerorteansehen,
      lagerortObj: isFromLagerorte ? objParentState.lagerorteTableDialogState.lagerortObj : null,
      rerunEffect: objParentState.rerunEffect
    }
  };

  const dialogTitleText = isFromLagerorte ? 'SUBLAGERORTHINZUFUEGEN' : 'ZULAGERORTHINZUFUEGEN';
  const dialogMaxWidth = childLagerorteansehen ? 'lg' : 'sm';
  return (
    <Dialog
      fullWidth
      maxWidth={dialogMaxWidth}
      open={objParentState.lagerorteTableDialogState.open}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{language('DIALOG', 'TITEL', dialogTitleText, objGlobalState.lang)}</DialogTitle>
      <DialogContent className="dialog__content">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography className="typography">
              {isFromLagerorte
                ? `${language('KONTENT', 'TEXT', 'LAGERORT', objGlobalState.lang)}: ${objParentState.lagerorteTableDialogState.lagerortObj.lagername}`
                : `${language('KONTENT', 'TEXT', 'STELLPLATZ', objGlobalState.lang)}: ${objParentState.lagerorteTableDialogState.stellplatzObj.name}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CoLagerorteTable objGlobalState={objGlobalState} objParentState={objLagerorteTableDialog.lagerorteTable} />
          </Grid>
          {!childLagerorteansehen && (
            <>
              <Grid item xs={12} />
              <Grid item xs={12}>
                <Button
                  onClick={isFromLagerorte ? addChildLagerortToParentLagerort : addStellplatzToLagerort}
                  fullWidth
                  type="submit"
                  color="primary"
                  className="button__middle"
                  variant="contained"
                  color="primary"
                >
                  {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button onClick={closeDialog} fullWidth className="button__middle">
                  {language('BUTTON', 'TITEL', 'ABBRECHEN', objGlobalState.lang)}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CoLagerorteTableDialog;
