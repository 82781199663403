import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import language from '../../language/language';
import { mouseScrollHorizontally } from '../../helperFunctions/mouseScrollHorizontally';
import { createAxiosInstance, getAndCostumFiles } from '../../helperFunctions/axios';
import { useSnackbar } from 'notistack';
import { Box, Button, ButtonGroup, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';
import CoShowFiles from '../CoShowFiles';
import { generateCSVString } from '../../helperFunctions/generateCSVString';
import { exportTableAsCSV } from '../../helperFunctions/exportTable';
import { generateDate } from '../../helperFunctions/date';
import CoDeleteWarning from './CoDeleteWarning';

// component -> React-root
const CoAvisierungTable = ({ objGlobalState, objParentState, isHome }) => {
  const { enqueueSnackbar } = useSnackbar();
  const accessToken = objGlobalState.jwtoken;
  const authAxios = createAxiosInstance(accessToken);

  const [showFilesDialogState, setShowFilesDialogState] = useState({ open: false, title: '', files: [] });
  const [notificationsState, setNotificationsState] = useState([]);
  const [warningDialogState, setWarningDialogState] = useState(false);
  const [focusedDataSetId, setFocusedDataSetId] = useState(null);

  // function ->
  const deleteDataSetOnClick = () => {
    // make request to the server to delete the data set by changing the status
    objGlobalState.setPageLoadingState(true);

    authAxios
      .put(`/notification/change/status`, {
        id: focusedDataSetId,
        status: 2,
        updated: generateDate().dateAndTimeForDataBase
      })

      .then(() => {
        const notificationsArray = [...notificationsState];
        const datasetIndex = notificationsState.findIndex((notification) => notification.id === focusedDataSetId);

        // if there is datasetId is not null and if we got datasetindex then delete
        if (focusedDataSetId && datasetIndex > -1) {
          notificationsArray.splice(datasetIndex, 1);
          setNotificationsState(notificationsArray);
          setFocusedDataSetId(null);
          setWarningDialogState(false);
          enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGELOESCHT', objGlobalState.lang), { variant: 'default' });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const showWarningDialogOnClick = (datasetId) => {
    setWarningDialogState(true);
    setFocusedDataSetId(datasetId);
  };

  // function ->
  const closeWarningDialogOnClick = () => {
    setWarningDialogState(false);
    setFocusedDataSetId(null);
  };

  const tableRef = useRef();

  // function ->
  const fetchNotificationsFromAPI = () => {
    objGlobalState.setPageLoadingState(true);

    authAxios
      .get('/notification/list')
      .then((response) => {
        const { data } = response;
        const newNotificationsState = [];
        data.forEach((notification) => {
          if (notification.status === 1) {
            // 07.12.1110 is the date we get when the user don't enter a date value; we then send a default date value
            const date = generateDate(notification.date).justDate === '30.11.1110' ? '' : generateDate(notification.date).justDate;
            const newNotification = {
              dateien: notification.fileEntity,
              id: notification.id,
              lieferant: notification.supplier,
              spedition: notification.shippingCompany === '_' ? '' : notification.shippingCompany,
              datum: date,
              erstellungsdatum: generateDate(notification.createdAt).justDate
            };
            newNotificationsState.unshift(newNotification);
          }
        });
        setNotificationsState(newNotificationsState);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  // function ->
  const stateToCSV = () => {
    const csvRows = [];
    const headerRow = ['LIEFERANT', 'SPEDITION', 'DATUM', 'ERSTELLUNGSDATUM'].map((header) => generateCSVString(language('TABLE', 'TITEL', header, objGlobalState.lang)));
    const tableValuesLabels = ['lieferant', 'spedition', 'datum', 'erstellungsdatum'];
    csvRows.push(headerRow.join(';'));
    notificationsState.forEach((notification) => {
      const valueRow = tableValuesLabels.map((value) => generateCSVString(notification[value]));
      csvRows.push(valueRow.join(';'));
    });
    return csvRows.join('\n');
  };

  // function ->
  const showFilesDialogOnClick = (datasetId) => {
    const title = 'VORHANDENEDATEIEN';

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    authAxios
      .get(`/notification/findfiles/${datasetId}`)
      .then((response) => {
        const { data } = response;

        const files = [];

        if (!data.length) {
          setShowFilesDialogState({ open: true, title: title, files: files });
        }

        data.forEach((file) => {
          getAndCostumFiles({ file: file, filesArray: files, authAxios: authAxios }).then((result) => {
            setShowFilesDialogState({ open: true, title: title, files: result });
          });
        });
      })
      .catch((error) => console.log(error));
  };

  // function ->
  const downloadFile = (url, name, type) => {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${name}.${type}`);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );
  };

  // function ->
  const downloadFilesOnClick = (datasetId) => {
    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);

    authAxios
      .get(`/notification/findfiles/${datasetId}`)
      .then((response) => {
        const { data } = response;

        const files = [];

        data.forEach((file, key) => {
          getAndCostumFiles({ file: file, filesArray: files, authAxios: authAxios }).then((result) => {
            if (data.length === files.length) {
              files.forEach((_file) => {
                const dataUri = _file.dataUri;
                const name = _file.name;
                const type = _file.type;
                downloadFile(dataUri, name, type);
              });
            }
          });
        });
      })
      .catch((error) => {
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
        console.log(error);
      });
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchNotificationsFromAPI();
    }

    return () => (isMounted = false);
  }, [objParentState.rerunEffectState]);

  useLayoutEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const table = tableRef.current;
      mouseScrollHorizontally(table);
    }

    return () => (isMounted = false);
  }, []);

  const objAvisierungTableState = {
    showFiles: {
      showFilesDialogState,
      setShowFilesDialogState
    },

    deleteWarning: {
      warningDialogState,
      deleteDataSetOnClick,
      closeWarningDialogOnClick
    }
  };

  const tableMaxHeightClassName = isHome ? 'maxHeight__330' : 'heightFour';

  return (
    <>
      {warningDialogState && <CoDeleteWarning objGlobalState={objGlobalState} objParentState={objAvisierungTableState.deleteWarning} />}
      {showFilesDialogState.open && <CoShowFiles objGlobalState={objGlobalState} objParentState={objAvisierungTableState.showFiles} />}
      <Box className="table__title">
        <Typography align="center" className="typography__singleTableTitle">
          {language('TABLE', 'HEADER', 'AVISIERUNG', objGlobalState.lang)}
        </Typography>
        {isHome ? (
          ''
        ) : (
          <div className="marginTwenty">
            {
              <Button variant="contained" color="primary" onClick={() => exportTableAsCSV(stateToCSV(), 'avisierung')} className="table__titleButton">
                {language('BUTTON', 'TITEL', 'TABELLEEXPORTIEREN', objGlobalState.lang)}
              </Button>
            }
          </div>
        )}
      </Box>
      <TableContainer ref={tableRef} component={Paper} variant="outlined" elevation={0} square className={`table__container ${tableMaxHeightClassName} border__noTop`}>
        <Table size="medium" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center" component="th" className="table__headerCell">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'LIEFERANT', objGlobalState.lang)}</Typography>
              </TableCell>
              <TableCell align="center" component="th" className="table__headerCell">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'SPEDITION', objGlobalState.lang)}</Typography>
              </TableCell>
              <TableCell align="center" component="th" className="table__headerCell">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'DATUM', objGlobalState.lang)}</Typography>
              </TableCell>
              <TableCell align="center" component="th" className="table__headerCell">
                <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'ERSTELLUNGSDATUM', objGlobalState.lang)}</Typography>
              </TableCell>
              {isHome ? (
                ''
              ) : (
                <>
                  <TableCell align="center" component="th" className="table__headerCell">
                    <Typography className="typography__singleTableRowTitle">{language('TABLE', 'TITEL', 'DATEIEN', objGlobalState.lang)}</Typography>
                  </TableCell>
                  <TableCell align="center" component="th" className="table__headerCell"></TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {notificationsState.map((item, key) => {
              return (
                <TableRow key={key}>
                  <TableCell align="center">
                    <Typography className="typography__singleTableRowContent">{item.lieferant}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className="typography__singleTableRowContent">{item.spedition}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className="typography__singleTableRowContent">{item.datum}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className="typography__singleTableRowContent">{item.erstellungsdatum}</Typography>
                  </TableCell>
                  {isHome ? (
                    ''
                  ) : (
                    <>
                      <TableCell align="center">
                        <ButtonGroup aria-label="outlined primary button group">
                          <Button
                            data-testId={`ansehen-button-${item.id}`}
                            onClick={() => showFilesDialogOnClick(item.id)}
                            disabled={!item.dateien.length}
                            className="button__small"
                            variant="outlined"
                            color="primary"
                          >
                            {language('BUTTON', 'TITEL', 'ANSEHEN', objGlobalState.lang)}
                          </Button>
                          <Button
                            data-testId={`speichern-button-${item.id}`}
                            onClick={() => downloadFilesOnClick(item.id)}
                            disabled={!item.dateien.length}
                            className="button__small"
                            variant="outlined"
                            color="primary"
                          >
                            {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
                          </Button>
                        </ButtonGroup>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="delete_item_button"
                          onClick={() => showWarningDialogOnClick(item.id)}
                          className="button__icon--tableRed"
                          title={language('BUTTON', 'TITEL', 'LOESCHEN', objGlobalState.lang)}
                          size="small"
                        >
                          <DeleteForever />
                        </IconButton>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CoAvisierungTable;
