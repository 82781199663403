import React, { useEffect, useState } from 'react';
import { FILE_TYPES } from '../types/types';
import language from '../language/language';
import { scanPdf } from '../helperFunctions/scanPdf';

// import -> material-ui -> core
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import { Close, FileCopy } from '@material-ui/icons';

// import -> components
import CoDisplayFiles from './CoDisplayFiles';

// component -> React-root
const CoShowFiles = ({ objGlobalState, objParentState, options }) => {
  const [displayDialogState, setDisplayDialogState] = useState(false);
  const [fileToDisplayState, setFileToDisplayState] = useState({ dataUri: '', title: '', type: '' });
  const [filesToShowState, setFilesToShowState] = useState([]);

  // function ->
  const closeDialogOnClose = () => {
    objParentState.setShowFilesDialogState((pre) => ({ ...pre, open: false }));
  };

  // function ->
  const displayFileOnClick = (file) => {
    setFileToDisplayState(file);
    setDisplayDialogState(true);
  };

  // function ->
  const scanPdfFile = (file) => {
    const pdfUrl = file.dataUri;
    objGlobalState.setPageLoadingState(true);

    // function ->
    const scanCallback = (result) => {
      objGlobalState.setPageLoadingState(false);

      if (result.success) {
        const scanningResult = result.codes[0];

        const currentFilesToShowState = [...filesToShowState];
        const scanFile = currentFilesToShowState.find((element) => element === file);
        scanFile.scanned = true;
        scanFile.scanningResult = scanningResult;

        setFilesToShowState(currentFilesToShowState);
      } else {
        console.log(result.message);
      }
    };

    scanPdf(pdfUrl, scanCallback);
  };

  // function ->
  const getFilesAndAddThemToState = () => {
    const filesToShow = objParentState.showFilesDialogState.files;
    const newState = filesToShow.map((element) => {
      element.scanned = false;
      element.scanResult = '';
      return element;
    });
    setFilesToShowState(newState);
  };

  // function ->
  const copyScanResult = (file) => {
    const text = file.scanningResult;
    navigator.clipboard.writeText(text);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getFilesAndAddThemToState();
    }

    return () => (isMounted = false);
  }, [objParentState]);

  const objShowFilesState = { displayDialogState, setDisplayDialogState, fileToDisplayState, setFileToDisplayState };

  return (
    <>
      {displayDialogState && <CoDisplayFiles objGlobalState={objGlobalState} objParentState={objShowFilesState} />}
      <Dialog onClose={closeDialogOnClose} fullWidth maxWidth="sm" open={objParentState.showFilesDialogState.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle data-testid="pdf-file-reader" id="alert-dialog-title" style={{ position: 'relative' }}>
          <Grid container spacing={3} alignContent="center" alignItems="center" justify="space-between">
            <Grid item xs={6}>
              <Typography align="left" className="typography__dialogTitle">
                {language('DIALOG', 'TITEL', objParentState.showFilesDialogState.title || 'VORHANDENEDATEIEN', objGlobalState.lang)}
              </Typography>
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <IconButton className="button__iconButton--close" onClick={closeDialogOnClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="dialog__content">
          <Grid container spacing={3}>
            {objParentState.showFilesDialogState.files.length ? (
              <Grid item xs={12}>
                {filesToShowState.map((file, key) => {
                  return (
                    <Grid container item xs={12} key={key} alignContent="center" alignItems="center" justify="center">
                      <Grid item xs={12} sm={6} className="border__bottom">
                        {file.type === FILE_TYPES.JPEG ? (
                          <>
                            <img aria-label={`reader-click${file.name}`} src={file.dataUri} className="images__gallery" alt="" onClick={() => displayFileOnClick(file)} />
                            <Typography className="typography" align="center">
                              {file.name || file.fileRef?.name}
                            </Typography>
                          </>
                        ) : file.type === FILE_TYPES.PDF ? (
                          <>
                            <Typography align="center" className="typography__gallery typography__gallery--fullWidth" onClick={() => displayFileOnClick(file)}>
                              <img data-testid={`reader-click${file.name}`} src={`../assets/images/other_icons/pdf_icon.png`} alt="the_alt_text" className="images__textIcon" />
                              {file.name}
                            </Typography>
                            {options?.scan ? (
                              <Grid container xs={12} item spcing={3}>
                                <Grid item xs={12}>
                                  <Button color="primary" variatn="contained" className="button__small--cWhite" fullWidth onClick={() => scanPdfFile(file)}>
                                    {language('BUTTON', 'TITEL', 'SCAN', objGlobalState.lang)}
                                  </Button>
                                </Grid>
                                {file.scanned ? (
                                  <>
                                    <Grid item xs={10} className="marginTwenty">
                                      <Typography align="center" className="typography">
                                        {file.scanningResult}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <IconButton title={language('KONTENT', 'TEXT', 'KOPIEREN', objGlobalState.lang)} onClick={() => copyScanResult(file)}>
                                        <FileCopy className="icon__green" />
                                      </IconButton>
                                    </Grid>
                                  </>
                                ) : (
                                  ''
                                )}
                              </Grid>
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography className="typography__colorDamage">{language('KONTENT', 'TEXT', 'KEINEDATEIEN', objGlobalState.lang)}</Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CoShowFiles;
