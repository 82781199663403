import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import language from '../Common/language/language';
import { generateDate } from '../Common/helperFunctions/date';
import { createAxiosInstance } from '../Common/helperFunctions/axios';

// import -> services
import ServiceBreadcrumbs from '../Common/services/Breadcrumbs/ServiceBreadcrumbs';

// import -> material ui -> core & icons
import { Button, Container, FormControl, FormControlLabel, FormLabel, Grid, ListItem, ListItemIcon, ListItemText, Switch, TextField, Typography } from '@material-ui/core';

// import -> component
import CoUploadFiles from '../Common/Components/CoUploadFiles';
import CoConfirmation from '../Common/Components/CoConfirmation';
import CoPaketeOrPaletteInput from './Append/CoPaketeOrPaletteInput';
import CoLieferungTable from './Append/CoLieferungTable';
import CoDisplayFilesList from '../Common/Components/CoDisplayFilesList';

// constants ->

// component -> React-root
const CoWarenannahmelieferung = ({ objGlobalState }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [sendungBeschaedigtFotosState, setSendungBeschaedigtFotosState] = useState([]);
  const [begleitpapiereState, setBegleitpapiereState] = useState([]);
  const [uploadFilesSedungBeschaedigtDialogState, setUploadFilesSedungBeschaedigtDialogState] = useState({ open: false, title: '' });
  const [uploadFilesBegleitpapiereDialogState, setUploadFilesBegleitpapiereDialogState] = useState({ open: false, title: '' });
  const [confirmationDialogState, setConfirmationDialogState] = useState(false);
  const [lieferungTableDialogState, setLieferungTableDialogState] = useState(false);
  const [sendungsartState, setSendungsartState] = useState({
    paketsendung: { id: 1, name: 'PAKETSENDUNG', checked: false },
    palettensendung: { id: 2, name: 'PALETTENSENDUNG', checked: false }
  });
  const [empfangsbestaetigungState, setEmpfangsbestaetigungState] = useState({
    elektronisch: { id: 1, name: 'ELEKTRONISCH', checked: false },
    begleitpapiere: { id: 2, name: 'UEBERBEGLEITPAPIERE', checked: false }
  });
  const [sendungBeschaedigtState, setSendungBeschaedigtState] = useState(false);
  const [anzahlDerPalettenState, setAnzahlDerPalettenState] = useState('');
  const [anzahlDerPaketeState, setAnzahlDerPaketeState] = useState('');
  const [paketeProPaketsendungState, setPaketeProPaketsendungState] = useState([]);
  const [paketeProPaletteState, setPaketeProPaletteState] = useState([]);

  const [newDataValueState, setNewDataValueState] = useState({
    lieferant: '',
    spedition: '',
    datum: '',
    uhrzeit: '',
    sendungBeschaedigt: '',
    beschreibungDerBeschaedigung: '',
    sendungsart: '',
    anzahlDerPakete: '',
    anzahlDerPaketeProPaketsendung: '',
    anzahlDerPaletten: '',
    anzahlDerPaketeProPalette: '',
    empfangsbestaetigung: ''
  });

  // this state belongs the the component /Append/CoLieferungTable. I set it here for demonstration purposes
  const [lieferungInformationState, setLieferungInformationState] = useState([]);

  const empfangsbestaetigungChecked = empfangsbestaetigungState.begleitpapiere.checked || empfangsbestaetigungState.elektronisch.checked;

  // function ->
  const showLieferungTableDialogOnClick = () => {
    setLieferungTableDialogState(true);
  };

  // function ->
  const showUploadFilesDialogOnClick = (targetFiles) => {
    if (targetFiles === 'beschaedigungsfotos') {
      setUploadFilesSedungBeschaedigtDialogState({ open: true, title: undefined });
    } else if (targetFiles === 'begleitpapiere') {
      setUploadFilesBegleitpapiereDialogState({ open: true, title: undefined });
    }
  };

  // function ->
  const checkSendungsart = (name) => {
    // if one is true then the other one is false
    setSendungsartState((pre) => {
      switch (name) {
        case 'paketsendung':
          return {
            paketsendung: { ...pre.paketsendung, checked: !pre.paketsendung.checked },
            palettensendung: { ...pre.palettensendung, checked: false }
          };
        case 'palettensendung':
          return {
            palettensendung: { ...pre.palettensendung, checked: !pre.palettensendung.checked },
            paketsendung: { ...pre.paketsendung, checked: false }
          };
        default:
          return pre;
      }
    });
  };

  // function ->
  const checkEmpfangsbestaetigung = (name) => {
    // if one is true then the other one is false
    setEmpfangsbestaetigungState((pre) => {
      switch (name) {
        case 'elektronisch':
          return {
            elektronisch: { ...pre.elektronisch, checked: !pre.elektronisch.checked },
            begleitpapiere: { ...pre.begleitpapiere, checked: false }
          };
        case 'begleitpapiere':
          return {
            begleitpapiere: { ...pre.begleitpapiere, checked: !pre.begleitpapiere.checked },
            elektronisch: { ...pre.elektronisch, checked: false }
          };
        default:
          return pre;
      }
    });
  };

  // function ->
  const checkSendungBeschaedigt = () => {
    setSendungBeschaedigtState((pre) => !pre);
  };

  // function ->
  const submitFormOnClick = (event) => {
    event.preventDefault();

    const lieferant = event.target.lieferant.value;
    const spedition = event.target.spedition.value;
    const datum = event.target.datum.value;
    const uhrzeit = event.target.uhrzeit.value;
    const beschreibungDerBeschaedigung = event.target.beschreibungDerBeschaedigung.value;

    const anzahlDerPakete = anzahlDerPaketeState ? Number(anzahlDerPaketeState) : undefined;
    const anzahlDerPaletten = anzahlDerPalettenState ? Number(anzahlDerPalettenState) : undefined;
    const anzahlDerPaketeProPaketsendung = paketeProPaketsendungState.filter((element) => element !== undefined).map((element) => Number(element));
    const anzahlDerPaketeProPalette = paketeProPaletteState.filter((element) => element !== undefined).map((element) => Number(element));

    const numberInputsValues = sendungsartState.paketsendung.checked
      ? { anzahlDerPakete, anzahlDerPaketeProPaketsendung }
      : sendungsartState.palettensendung.checked
      ? { anzahlDerPaletten, anzahlDerPaketeProPalette }
      : '';
    const sendungBeschaedigtValues = sendungBeschaedigtState ? { sendungBeschaedigt: language('KONTENT', 'TEXT', 'JA', objGlobalState.lang), beschreibungDerBeschaedigung } : {};
    const sendungsartValue = sendungsartState.paketsendung.checked
      ? language('KONTENT', 'TEXT', sendungsartState.paketsendung.name, objGlobalState.lang)
      : sendungsartState.palettensendung.checked
      ? language('KONTENT', 'TEXT', sendungsartState.palettensendung.name, objGlobalState.lang)
      : '';
    const empfangsbestaetigungValue = empfangsbestaetigungState.elektronisch.checked
      ? language('KONTENT', 'TEXT', empfangsbestaetigungState.elektronisch.name, objGlobalState.lang)
      : empfangsbestaetigungState.begleitpapiere.checked
      ? language('KONTENT', 'TEXT', empfangsbestaetigungState.begleitpapiere.name, objGlobalState.lang)
      : '';

    setNewDataValueState({ lieferant, spedition, datum, uhrzeit, sendungsart: sendungsartValue, ...numberInputsValues, ...sendungBeschaedigtValues, empfangsbestaetigung: empfangsbestaetigungValue });
    setConfirmationDialogState(true);
  };

  // function ->
  const closeConfirmationOnClick = () => {
    setConfirmationDialogState(false);
  };

  // function ->
  const confirmed = () => {
    objGlobalState.setPageLoadingState(true);

    const { lieferant, spedition, datum, uhrzeit, anzahlDerPakete, anzahlDerPaketeProPaketsendung, anzahlDerPaletten, anzahlDerPaketeProPalette, sendungBeschaedigt, beschreibungDerBeschaedigung } =
      newDataValueState;

    const noDate = generateDate().noDate;
    const costumDate = datum.length && uhrzeit.length ? generateDate(`${datum} ${uhrzeit}`).dateAndTimeForDataBase : '';

    const packagesPerUnit = anzahlDerPaketeProPaketsendung || anzahlDerPaketeProPalette || [];
    const lieferscheinVorhanden = empfangsbestaetigungChecked && Boolean(begleitpapiereState.length);

    // if statement -> if no item in this array then put an item
    if (!packagesPerUnit.length) {
      packagesPerUnit.push(0);
    }
    // if no sendungsart is chosen then send 0
    const shipmentTypeId = sendungsartState.paketsendung.checked ? sendungsartState.paketsendung.id : sendungsartState.palettensendung.checked ? sendungsartState.palettensendung.id : 0;
    const quantity = anzahlDerPakete || anzahlDerPaletten || 0;
    const confirmationTypeId = empfangsbestaetigungState.elektronisch.checked
      ? empfangsbestaetigungState.elektronisch.id
      : empfangsbestaetigungState.begleitpapiere.checked
      ? empfangsbestaetigungState.begleitpapiere.id
      : 0;

    const formData = new FormData();
    const beschaedigungsfotosFiles = sendungBeschaedigt ? sendungBeschaedigtFotosState.map((element) => element.fileRef) : [];
    beschaedigungsfotosFiles.forEach((file) => {
      formData.append('files', file);
    });

    const begleitpapiereFiles = empfangsbestaetigungChecked ? begleitpapiereState.map((element) => element.fileRef) : [];
    begleitpapiereFiles.forEach((file) => {
      formData.append('previews', file);
    });

    const accessToken = objGlobalState.jwtoken;
    const authAxios = createAxiosInstance(accessToken);
    const requestValues = {
      packagesPerUnit: packagesPerUnit,
      supplier: lieferant.length ? lieferant : ' ',
      shippingCompany: spedition.length ? spedition : ' ',
      deliveryDate: datum.length ? costumDate : noDate,
      deliveryNote: lieferscheinVorhanden,
      shipmentTypeId: shipmentTypeId,
      quantity: quantity,
      damage: Boolean(sendungBeschaedigt),
      damageDescription: beschreibungDerBeschaedigung ? beschreibungDerBeschaedigung : ' ',
      confirmationTypeId: confirmationTypeId
    };

    authAxios
      .post('/delivery/create', requestValues)
      .then((response) => {
        const { id } = response.data;
        enqueueSnackbar(language('ALERT', 'TEXT', 'DATENGESPEICHERT'), { variant: 'success' });
        closeConfirmationOnClick();

        authAxios
          .post(`/file/morefiles/${id}`, formData, { headers: { 'Content-Type': 'multipart/form-data;' } })
          .then(() => {
            setSendungBeschaedigtFotosState([]);
            setBegleitpapiereState([]);
          })
          .catch((error) => {
            console.log(error);
            enqueueSnackbar(language('ALERT', 'TEXT', 'FOTOSNICHTHOCHGELADEN'), { variant: 'error' });
          });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(language('ALERT', 'TEXT', 'FEHLERAUFGETRETEN', objGlobalState.lang), { variant: 'error' });
      })
      .finally(() => {
        objGlobalState.setPageLoadingState(false);
      });
  };

  const fotosDerBeschaedigungSectionTitle = 'FOTOSDERBESCHAEDIGUNG';
  const begleitpapiereSectionTitle = 'BEGLEITPAPIERE';

  const fotosDerBeschaedigungSection = { sectionTitle: fotosDerBeschaedigungSectionTitle, files: sendungBeschaedigtFotosState };
  const begleitpapiereSection = { sectionTitle: begleitpapiereSectionTitle, files: begleitpapiereState };

  const confirmationGallery = [];

  if (sendungBeschaedigtState) {
    confirmationGallery.push(fotosDerBeschaedigungSection);
  }
  if (empfangsbestaetigungChecked) {
    confirmationGallery.push(begleitpapiereSection);
  }

  const objWarenannahmelieferungStateAndFunctions = {
    anzahlDerPaketeState,
    setAnzahlDerPaketeState,
    anzahlDerPalettenState,
    setAnzahlDerPalettenState,
    closeConfirmationOnClick,
    confirmationDialogState,
    setConfirmationDialogState,
    confirmationGallery,
    confirmed,
    lieferungInformationState,
    setLieferungInformationState,
    lieferungTableDialogState,
    setLieferungTableDialogState,
    newDataValueState,
    sendungBeschaedigtState,
    paketeProPaketsendungState,
    setPaketeProPaketsendungState,
    paketeProPaletteState,
    setPaketeProPaletteState,
    sendungBeschaedigtFotosState,
    setSendungBeschaedigtFotosState,
    sendungsartState,
    begleitpapiereState,
    setBegleitpapiereState
  };

  // array -> create breadcrumbs
  const arrBreadcrumbs = [
    { url: '/', title: language('SEITE', 'TITEL', 'HOME', objGlobalState.lang) },
    { url: '/warenannahme_lieferung', title: language('SEITE', 'TITEL', 'WARENANNAHMELIEFERUNG', objGlobalState.lang) }
  ];

  return (
    <>
      {uploadFilesSedungBeschaedigtDialogState && (
        <CoUploadFiles
          objGlobalState={objGlobalState}
          objParentState={{
            ...objWarenannahmelieferungStateAndFunctions,
            uploadFilesDialogState: uploadFilesSedungBeschaedigtDialogState,
            setUploadFilesDialogState: setUploadFilesSedungBeschaedigtDialogState,
            filesState: sendungBeschaedigtFotosState,
            setFilesState: setSendungBeschaedigtFotosState
          }}
        />
      )}
      {uploadFilesBegleitpapiereDialogState && (
        <CoUploadFiles
          objGlobalState={objGlobalState}
          objParentState={{
            ...objWarenannahmelieferungStateAndFunctions,
            uploadFilesDialogState: uploadFilesBegleitpapiereDialogState,
            setUploadFilesDialogState: setUploadFilesBegleitpapiereDialogState,
            filesState: begleitpapiereState,
            setFilesState: setBegleitpapiereState
          }}
        />
      )}
      {confirmationDialogState && <CoConfirmation objGlobalState={objGlobalState} objParentState={objWarenannahmelieferungStateAndFunctions} />}
      {lieferungTableDialogState && <CoLieferungTable objGlobalState={objGlobalState} objWarenannahmelieferungStateAndFunctions={objWarenannahmelieferungStateAndFunctions} />}
      <Container component="main" maxWidth="xl" className="container__container">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ServiceBreadcrumbs arrBreadcrumbs={arrBreadcrumbs} />
          </Grid>
          <Grid item xs={12}>
            <ListItem className="container__listItem">
              <ListItemIcon>
                <img src={`../assets/images/icons/icon_lieferung.png`} alt="" className="images__headerIcon" />
              </ListItemIcon>
              <ListItemText>
                <Typography className="typography__headerTitle">{language('SEITE', 'TITEL', 'WARENANNAHMELIEFERUNG', objGlobalState.lang)}</Typography>
              </ListItemText>
            </ListItem>
          </Grid>
          <Grid item xs={12} container justify="flex-end">
            <Button onClick={showLieferungTableDialogOnClick} className="button__middle" variant="contained" color="primary">
              {language('BUTTON', 'TITEL', 'DATENZEIGEN', objGlobalState.lang)}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <form name="submitForm" noValidate autoComplete="on" onSubmit={submitFormOnClick}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <TextField fullWidth type="text" variant="outlined" name="lieferant" label={language('FORM', 'LABEL', 'LIEFERANT', objGlobalState.lang)} autoFocus />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField fullWidth type="text" variant="outlined" name="spedition" label={language('FORM', 'LABEL', 'SPEDITION', objGlobalState.lang)} />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    name="datum"
                    label={language('FORM', 'LABEL', 'DATUM', objGlobalState.lang)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    type="time"
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    name="uhrzeit"
                    label={language('FORM', 'LABEL', 'UHRZEIT', objGlobalState.lang)}
                  />
                </Grid>
                <Grid item xs={12} className="coWarenannahmelieferung__grid--formSection">
                  <FormControl component="fieldset" className="coWarenannahmelieferung__formControl">
                    <FormLabel className="coWarenannahmelieferung__formLabel">
                      <Typography className="typography">{language('FORM', 'LABEL', 'SENDUNGSART', objGlobalState.lang)}</Typography>
                    </FormLabel>
                    <FormControlLabel
                      control={<Switch checked={sendungsartState.paketsendung.checked} onChange={() => checkSendungsart('paketsendung')} color="primary" />}
                      label={language('FORM', 'LABEL', 'PAKETSENDUNG', objGlobalState.lang)}
                    />
                    <FormControlLabel
                      control={<Switch checked={sendungsartState.palettensendung.checked} onChange={() => checkSendungsart('palettensendung')} color="primary" />}
                      label={language('FORM', 'LABEL', 'PALETTENSENDUNG', objGlobalState.lang)}
                    />
                  </FormControl>
                </Grid>
                <CoPaketeOrPaletteInput objGlobalState={objGlobalState} objWarenannahmelieferungStateAndFunctions={objWarenannahmelieferungStateAndFunctions} />
                <Grid item xs={12} className="coWarenannahmelieferung__grid--formSection">
                  <FormControl component="fieldset" className="coWarenannahmelieferung__formControl">
                    <FormControlLabel
                      control={<Switch checked={sendungBeschaedigtState} onChange={checkSendungBeschaedigt} name="sendungBeschaedigt" color="primary" />}
                      label={language('FORM', 'LABEL', 'SENDUNGBESCHAEDIGT', objGlobalState.lang)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    rows="10"
                    multiline
                    disabled={!sendungBeschaedigtState}
                    variant="outlined"
                    name="beschreibungDerBeschaedigung"
                    label={language('FORM', 'LABEL', 'BESCHREIBUNGDERBESCHAEDIGUNG', objGlobalState.lang)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button onClick={() => showUploadFilesDialogOnClick('beschaedigungsfotos')} disabled={!sendungBeschaedigtState} className="button__middle" variant="outlined" color="primary">
                    {language('BUTTON', 'TITEL', 'DATEIENHOCHLADEN', objGlobalState.lang)}
                  </Button>
                </Grid>
                <CoDisplayFilesList
                  objGlobalState={objGlobalState}
                  filesState={sendungBeschaedigtFotosState}
                  setFilesState={setSendungBeschaedigtFotosState}
                  title="FOTOSDERBESCHAEDIGUNG"
                  disabled={!sendungBeschaedigtState}
                />
                <Grid item xs={12} className="coWarenannahmelieferung__grid--formSection">
                  <FormControl component="fieldset" className="coWarenannahmelieferung__formControl">
                    <FormLabel className="coWarenannahmelieferung__formLabel">
                      <Typography className="typography">{language('FORM', 'LABEL', 'EMPFANGSBESTAETIGUNG', objGlobalState.lang)}</Typography>
                    </FormLabel>
                    <FormControlLabel
                      control={<Switch checked={empfangsbestaetigungState.elektronisch.checked} onChange={() => checkEmpfangsbestaetigung('elektronisch')} color="primary" />}
                      label={language('FORM', 'LABEL', 'ELEKTRONISCH', objGlobalState.lang)}
                    />
                    <FormControlLabel
                      control={<Switch checked={empfangsbestaetigungState.begleitpapiere.checked} onChange={() => checkEmpfangsbestaetigung('begleitpapiere')} color="primary" />}
                      label={language('FORM', 'LABEL', 'UEBERBEGLEITPAPIERE', objGlobalState.lang)}
                    />
                  </FormControl>
                </Grid>
                <Grid container item xs={12} md={8} spacing={3}>
                  <Grid item xs={12} md={4}>
                    <Button onClick={() => showUploadFilesDialogOnClick('begleitpapiere')} disabled={!empfangsbestaetigungChecked} className="button__middle" variant="outlined" color="primary">
                      {language('BUTTON', 'TITEL', 'DATEIENHOCHLADEN', objGlobalState.lang)}
                    </Button>
                  </Grid>
                </Grid>
                <CoDisplayFilesList
                  objGlobalState={objGlobalState}
                  filesState={begleitpapiereState}
                  setFilesState={setBegleitpapiereState}
                  title="BEGLEITPAPIERE"
                  disabled={!empfangsbestaetigungChecked}
                />
                <Grid item xs={12}>
                  <Button fullWidth type="submit" color="primary" className="button__middle" variant="contained" color="primary">
                    {language('BUTTON', 'TITEL', 'SPEICHERN', objGlobalState.lang)}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CoWarenannahmelieferung;
