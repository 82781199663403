// funciton ->
export const generateDate = (timestamp) => {
  // statment -> if not timestamp then genrate current date
  const date = timestamp ? new Date(timestamp) : new Date();

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().padStart(4, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const milliseconds = date.getMilliseconds();

  const monthName = date.toLocaleString('en-US', { month: 'short' });
  const time = date.toLocaleString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric', second: 'numeric' });
  const dateAndTimeForDataBaseOne = `${monthName} ${day}, ${year}, ${time}`;
  const noDateOne = 'Jan 1, 1111, 1:1:1 AM';

  const dateAndTimeForDataBase = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  const dateAndTime = `${day}.${month}.${year} - ${hours}:${minutes}:${seconds}`;
  const dateAndTimeWithMilliseconds = `${day}.${month}.${year} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  const justDate = `${day}.${month}.${year}`;
  const justTime = `${hours}:${minutes}:${seconds}`;
  const justTimeWithMilliSeconds = `${hours}:${minutes}:${seconds}.${milliseconds}`;
  const noDate = '1111-00-00T00:00:00.000';

  const dateForInputField = `${year}-${month}-${day}`;

  const resultObject = {
    dateAndTimeForDataBase,
    dateAndTime,
    dateAndTimeWithMilliseconds,
    justDate,
    justTime,
    justTimeWithMilliSeconds,
    day,
    month,
    year,
    hours,
    minutes,
    seconds,
    milliseconds,
    noDate,
    dateAndTimeForDataBaseOne,
    noDateOne,
    dateForInputField
  };

  return resultObject;
};
